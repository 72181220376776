<div class="advanced-wrapper">
	<div class="checkbox-block">
		<div style="font-weight: bold; margin: 5px 0;">{{'advanced.emailSecurity.emailSecurity' | translate}}</div>
		<div class="holder" style="margin-top: 10px;"
			 [ngClass]="{'disabled': !emailInfo.methods.secure_send}">
			<label>{{'advanced.emailSecurity.method' | translate}}</label>
			<div class="dropdown" style="top: 0;"
				 (clickOutside)="ddsComposeMethod = false;"
				 [ngClass]="{'active': ddsComposeMethod}"
				 (click)="ddsComposeMethod = !ddsComposeMethod"
				 (focus)="ddsComposeMethod"
				 (blur)="!ddsComposeMethod">
				<div class="select">
					<span>{{emailInfo.advanced.secure.method_2factor}}</span>
					<i class="fa fa-chevron-left"></i>
				</div>
				<ul class="dropdown-menu" style="top: 22px;"
					[ngClass]="{'active': ddsComposeMethod}"
					[style.max-height.px]="ddsComposeMethod ? 140 : 0">
					<li *ngFor="let method of dic.CONSTANTS.AUTH_METHOD | keyvalue"
						(click)="updateAuthMethod(method.value, $event)"
						[ngStyle]="!secureMethods.includes(method.value) && {'cursor': 'not-allowed', 'opacity': '0.5'}">
						{{method.value}}
						<i *ngIf="!secureMethods.includes(method.value)"
						   style="color:#818181;padding-left:1px;position:relative;"
						   class="zmdi zmdi-lock-outline"
						   tooltip="{{'advanced.blockedByAdmin' | translate}}">
						</i>
					</li>
				</ul>
			</div>
			<input type="{{showPassword ? 'text' : 'password'}}"
				   style="width:120px;height:inherit;margin-bottom:0;padding-top: 4px; padding-right: 25px;"
				   [hidden]="emailInfo.advanced.secure.method_2factor !== dic.CONSTANTS.AUTH_METHOD.password"
				   (blur)="updateAdvanced()"
				   [ngClass]="{'nofill': !showPassword, 'validation-error' : emailInfo.advanced.secure.method_2factor === dic.CONSTANTS.AUTH_METHOD.password && !emailInfo.advanced.secure.method_2factor_password}"
				   name="method_2factor_password"
				   autocomplete="off"
				   [(ngModel)]="emailInfo.advanced.secure.method_2factor_password"
				   placeholder="{{'advanced.emailSecurity.passwordPlaceholder' | translate}}" size="15">
			<label style="opacity:0.2;position:relative;top:-2px;left:-25px;"
				   *ngIf="emailInfo.advanced.secure.method_2factor === dic.CONSTANTS.AUTH_METHOD.password"
				   class="hidepwd">
				<input type="checkbox" [(ngModel)]="showPassword"/>
				<i class="fa fa-eye unchecked" style="color: black;"></i>
				<i class="fa fa-eye-slash checked" style="color: black;"></i>
			</label>
			<input style="width:120px;height:inherit;margin-bottom:0"
				   [hidden]="emailInfo.advanced.secure.method_2factor !== dic.CONSTANTS.AUTH_METHOD.phone
                        && emailInfo.advanced.secure.method_2factor !== dic.CONSTANTS.AUTH_METHOD.email"
				   type="text" name="method_2factor_phone"
				   placeholder="{{'advanced.emailSecurity.pinCodePlaceholder' | translate}}" size="15" disabled>
			<i *ngIf="emailInfo.advanced.secure.method_2factor === dic.CONSTANTS.AUTH_METHOD.password" style="font-weight: 400;position: relative;top: 0;margin-left:-9.5px;"
			   class="fas fa-question-circle"
			   tooltip="{{'advanced.emailSecurity.methodTooltip' | translate}}">
			</i>
			<i *ngIf="emailInfo.advanced.secure.method_2factor !== dic.CONSTANTS.AUTH_METHOD.password" style="font-weight: 400;position: relative;margin-left:3px;"
			   class="fas fa-question-circle"
			   tooltip="{{'advanced.emailSecurity.methodTooltip' | translate}}">
			</i>
			<i *ngIf="!emailInfo.methods.secure_send" tooltip="{{'advanced.authToEnable' | translate}}" style="color:#818181;padding-left:5px" class="zmdi zmdi-lock-outline"></i>
		</div>
		<div class="holder"
			 style="padding-left: 10px;"
			 *ngIf="emailInfo.advanced.secure.method_2factor === dic.CONSTANTS.AUTH_METHOD.password"
			 [ngClass]="{'disabled': !emailInfo.methods.secure_send}">
			<label style="display: inline-block;">{{'advanced.emailSecurity.passwordHint' | translate}}</label>
			<input style="width:216px;height:inherit;margin-bottom:0;margin-left:14px;"
				   (blur)="updateAdvanced()" type="text"
				   name="method_2factor_password_hint"
				   autocomplete="off"
				   [(ngModel)]="emailInfo.advanced.secure.method_2factor_password_hint"
				   placeholder="{{'advanced.emailSecurity.passwordHintPlaceholder' | translate}}" size="25">
		</div>

		<div class="holder"
			 style="padding-left: 10px;"
			 *ngIf="emailInfo.advanced.secure.method_2factor === dic.CONSTANTS.AUTH_METHOD.password">
			<app-toggle-switch style="margin-left: 0;"
							   (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.secure.use_contact_2factor_password"></app-toggle-switch>
			<label>{{'advanced.emailSecurity.contactPassword' | translate}}</label>
			<i class="fas fa-question-circle" style="font-weight: 400;position: relative; left: 2px;" tooltip="{{'advanced.emailSecurity.contactPasswordTooltip' | translate}}"></i>
		</div>

		<div class="holder" [ngClass]="{'disabled': !emailInfo.methods.secure_send}">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.secure.enable_smart_authentication"></app-toggle-switch>
			<label>{{'advanced.emailSecurity.smartAuth' | translate}}</label>
			<i style="font-weight: 400;margin-left: 5px" class="fas fa-question-circle" tooltip="{{'advanced.emailSecurity.smartAuthTooltip' | translate}}"></i>
		</div>

		<div class="holder" [ngClass]="{'disabled': !emailInfo.methods.encrypt_content && !emailInfo.methods.secure_send}">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.secure.expired_enable"></app-toggle-switch>
			<label style="width:inherit">{{'advanced.emailSecurity.emailExpire' | translate}}</label>
			<input required
				   style="width:60px;height:inherit;margin-left: 5px;box-sizing: border-box;border-radius: 4px;border: 1px solid #ccc;"
				   *ngIf="emailInfo.advanced.secure.expired_enable"
				   (change)="forceExpirationMinMax()"
				   (blur)="updateAdvanced()"
				   type="number" min="{{dic.CONSTANTS.EXPIRED_DAYS_VALUES.MIN}}" max="{{dic.CONSTANTS.EXPIRED_DAYS_VALUES.MAX}}" pattern="^([1-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|3[0-5][0-9]|36[0-5])$"
				   onkeydown="return !(event.keyCode === 69 || event.keyCode === 109 || event.keyCode === 189 || event.keyCode === 187 || event.keyCode === 190)"
				   name="expired_days"
				   [(ngModel)]="emailInfo.advanced.secure.expired_days"
				   placeholder="{{'advanced.emailSecurity.daysPlaceholder' | translate}}" size="5">
			<input style="width:60px;height:inherit;margin-left:5px;box-sizing: border-box;border-radius: 4px;border: 1px solid #ccc;padding: 2px 8px;"
				   *ngIf="!emailInfo.advanced.secure.expired_enable"
				   disabled
				   placeholder="{{'advanced.emailSecurity.neverPlaceholder' | translate}}" size="5">
			<i style="font-weight: 400; margin-left: 3px;" class="fas fa-question-circle" tooltip="{{'advanced.emailSecurity.emailExpireTooltip' | translate}}"></i>
			<i *ngIf="!emailInfo.methods.secure_send && !emailInfo.methods.encrypt_content" tooltip="{{'compose.methods.methodLockedByAdmin' | translate}}" style="color:#818181;padding-left:5px" class="zmdi zmdi-lock-outline"></i>
		</div>

		<div class="holder" [ngClass]="{'disabled': !emailInfo.sent.attachments || !emailInfo.sent.attachments.length}">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.secure.delete_attachment_enable"></app-toggle-switch>
			<label style="width:inherit">{{'advanced.emailSecurity.deleteAttachments' | translate}}</label>
			<input required
				   style="width:60px;height:inherit;margin-left: 5px;box-sizing: border-box;border-radius: 4px;border: 1px solid #ccc;"
				   *ngIf="emailInfo.advanced.secure.delete_attachment_enable"
				   (change)="forceExpirationMinMax()"
				   (blur)="updateAdvanced()"
				   type="number" min="{{dic.CONSTANTS.EXPIRED_DAYS_VALUES.MIN}}" max="{{dic.CONSTANTS.EXPIRED_DAYS_VALUES.MAX}}" pattern="^([1-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|3[0-5][0-9]|36[0-5])$"
				   onkeydown="return !(event.keyCode === 69 || event.keyCode === 109 || event.keyCode === 189 || event.keyCode === 187 || event.keyCode === 190)"
				   name="delete_attachment_days"
				   [(ngModel)]="emailInfo.advanced.secure.delete_attachment_days"
				   placeholder="{{'advanced.emailSecurity.daysPlaceholder' | translate}}" size="5">
			<input style="width:60px;height:inherit;margin-left:5px;box-sizing: border-box;border-radius: 4px;border: 1px solid #ccc;padding: 2px 8px;"
				   *ngIf="!emailInfo.advanced.secure.delete_attachment_enable"
				   disabled
				   placeholder="{{'advanced.emailSecurity.neverPlaceholder' | translate}}" size="5">
			<i style="font-weight: 400; margin-left: 3px;" class="fas fa-question-circle" tooltip="{{'advanced.emailSecurity.deleteAttachmentsTooltip' | translate}}"></i>
			<i *ngIf="!emailInfo.sent.attachments || !emailInfo.sent.attachments.length" tooltip="{{'compose.methods.methodLockedByAdmin' | translate}}" style="color:#818181;padding-left:5px" class="zmdi zmdi-lock-outline"></i>
		</div>

		<div class="holder" [ngClass]="{'disabled': true}">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.secure.enforce_attachment_encryption"></app-toggle-switch>
			<label style="display: inline-block;margin-right: 5px">{{'advanced.emailSecurity.enforceAttachmentEncryption' | translate}}</label>
			<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="{{'advanced.emailSecurity.enforceAttachmentEncryptionTooltip' | translate}}"></i>
		</div>

		<div class="holder" [ngClass]="{'disabled': true}">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.secure.attachments_preview_mode"></app-toggle-switch>
			<label style="display: inline-block;margin-right: 5px">{{'advanced.emailSecurity.attachmentsPreviewMode' | translate}}</label>
			<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="{{'advanced.emailSecurity.attachmentsPreviewModeTooltip' | translate}}"></i>
		</div>

		<div class="holder" [ngClass]="{'disabled': (!emailInfo.methods.encrypt_content && !emailInfo.methods.secure_send)}">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.secure.enable_print"></app-toggle-switch>
			<label style="display: inline-block;margin-right: 5px">{{'advanced.emailSecurity.allowPrint' | translate}}</label>
			<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="{{'advanced.emailSecurity.allowPrintTooltip' | translate}}"></i>
			<i *ngIf="!emailInfo.methods.secure_send && !emailInfo.methods.encrypt_content" tooltip="{{'advanced.encryptOrAuthToEnable' | translate}}" style="color:#818181;padding-left:5px" class="zmdi zmdi-lock-outline"></i>
		</div>

		<div class="holder" [ngClass]="{'disabled': !emailInfo.methods.encrypt_content && !emailInfo.methods.secure_send}">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.secure.secure_received"></app-toggle-switch>
			<label style="display: inline-block;margin-right: 5px">{{'advanced.emailSecurity.requireAuthReply' | translate}}</label>
			<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="{{'advanced.emailSecurity.requireAuthReplyTooltip' | translate}}"></i>
			<i *ngIf="!emailInfo.methods.secure_send && !emailInfo.methods.encrypt_content" tooltip="{{'advanced.encryptOrAuthToEnable' | translate}}" style="color:#818181;padding-left:5px" class="zmdi zmdi-lock-outline"></i>
		</div>
		<div class="holder" [ngClass]="{'disabled': !attachmentExist && !emailInfo.methods.encrypt_content && !emailInfo.methods.secure_send}">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.secure.open_only_once"></app-toggle-switch>
			<label style="display: inline-block;margin-right: 5px">{{'advanced.emailSecurity.accessOnce' | translate}}</label>
			<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="{{'advanced.emailSecurity.accessOnceTooltip' | translate}}"></i>
		</div>
	</div>
	<div class="checkbox-block" style="padding-top:5px">
		<div style="font-weight: bold; margin: 5px 0;">{{'advanced.general.general' | translate}}</div>
		<div class="holder">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.email_me.on_any_opened"></app-toggle-switch>
			<label>{{'advanced.general.notifyEmailsOpened' | translate}}</label>
			<i style="font-weight: 400;margin-left: 5px" class="fas fa-question-circle" tooltip="{{'advanced.general.notifyEmailsOpenedTooltip' | translate}}"></i>
		</div>
		<div class="holder" [ngClass]="{'disabled': !emailInfo.advanced.general.track_links}">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.email_me.on_link_clicked"></app-toggle-switch>
			<label>{{'advanced.general.notifyLinksClicked' | translate}}</label>
			<i style="font-weight: 400;margin-left: 5px" class="fas fa-question-circle" tooltip="{{'advanced.general.notifyLinksClickedTooltip' | translate}}"></i>
			<i *ngIf="!emailInfo.advanced.general.track_links" tooltip="{{'advanced.trackLinksEnable' | translate}}" style="color:#818181;padding-left:5px" class="zmdi zmdi-lock-outline"></i>
		</div>
		<div class="holder" [ngClass]="{'disabled': !emailInfo.sent.attachments || !emailInfo.sent.attachments.length}">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.email_me.on_attachment_download"></app-toggle-switch>
			<label>{{'advanced.general.notifyDownloadFiles' | translate}}</label>
			<i style="font-weight: 400;margin-left: 5px" class="fas fa-question-circle" tooltip="{{'advanced.general.notifyDownloadFilesTooltip' | translate}}"></i>
			<i *ngIf="!emailInfo.sent.attachments || !emailInfo.sent.attachments.length" tooltip="{{'advanced.encryptToEnable' | translate}}" style="color:#818181;padding-left:5px" class="zmdi zmdi-lock-outline"></i>
		</div>
		<div class="holder disabled">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.general.reply_to_enable"></app-toggle-switch>
			<label style="width:inherit">{{'advanced.general.replyTo' | translate}}</label>
			<input style="width:50%;max-width:160px;height:inherit;margin:0 5px;"
				   *ngIf="emailInfo.advanced.general.reply_to_enable"
				   (blur)="updateAdvanced()" type="text" name="reply_to_email"
				   placeholder="{{'advanced.general.replyToMyEmail' | translate}}" disabled
				   (change)="replyToEMailValidation = validateEmail(emailInfo.advanced.general.reply_to_email)"
				   [ngClass]="{'validation-error': emailInfo.advanced.general.reply_to_email && !replyToEMailValidation}"
				   [(ngModel)]="emailInfo.advanced.general.reply_to_email">
			<input style="width:50%;max-width:160px;height:inherit;margin:0 5px;"
				   *ngIf="!emailInfo.advanced.general.reply_to_enable"
				   disabled name="reply_to_email"
				   placeholder="do-not-reply@trustifi.com">
			<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="{{'advanced.general.replyToToolip' | translate}}"></i>
		</div>
		<div class="holder disabled">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.general.trustifi_wrapper"></app-toggle-switch>
			<label>{{'advanced.general.useTemplate' | translate}}</label>
			<i style="font-weight: 400;margin-left: 5px" class="fas fa-question-circle" tooltip="{{'advanced.general.useTemplateTooltip' | translate}}"></i>
		</div>
		<div class="holder disabled">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.general.track_links"></app-toggle-switch>
			<label>{{'advanced.general.trackLinks' | translate}}</label>
			<i style="font-weight: 400;margin-left: 5px" class="fas fa-question-circle" tooltip="{{'advanced.general.trackLinksTooltip' | translate}}"></i>
		</div>

		<div class="holder" [ngClass]="{'disabled': !emailInfo.methods.encrypt_content && !emailInfo.methods.secure_send}">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.general.multi_replies"></app-toggle-switch>
			<label style="display: inline-block;margin-right: 5px">{{'advanced.general.unlimitedReplies' | translate}}</label>
			<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="{{'advanced.general.unlimitedRepliesTooltip' | translate}}"></i>
			<i *ngIf="!emailInfo.methods.secure_send && !emailInfo.methods.encrypt_content" tooltip="{{'advanced.encryptOrAuthToEnable' | translate}}" style="color:#818181;padding-left:5px" class="zmdi zmdi-lock-outline"></i>
		</div>

		<div class="holder" [ngClass]="{'disabled': !emailInfo.methods.encrypt_content && !emailInfo.methods.secure_send}">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.general.enforce_reply_all"></app-toggle-switch>
			<label style="display: inline-block;margin-right: 5px">{{'advanced.general.enforceReplyAll' | translate}}</label>
			<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="{{'advanced.general.enforceReplyAllTooltip' | translate}}"></i>
			<i *ngIf="!emailInfo.methods.secure_send && !emailInfo.methods.encrypt_content" tooltip="{{'advanced.encryptOrAuthToEnable' | translate}}" style="color:#818181;padding-left:5px" class="zmdi zmdi-lock-outline"></i>
		</div>

		<div class="holder" [ngClass]="{'disabled': !emailInfo.methods.encrypt_content && !emailInfo.methods.secure_send}">
			<app-toggle-switch (onChange)="updateAdvanced()"
							   [hideText]="true"
							   [(model)]="emailInfo.advanced.general.allow_download_as_eml"></app-toggle-switch>
			<label style="display: inline-block;margin-right: 5px">{{'advanced.general.allowDownloadingAsEML' | translate}}</label>
			<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="{{'advanced.general.allowDownloadingAsEMLTooltip' | translate}}"></i>
			<i *ngIf="!emailInfo.methods.secure_send && !emailInfo.methods.encrypt_content" tooltip="{{'advanced.encryptOrAuthToEnable' | translate}}" style="color:#818181;padding-left:5px" class="zmdi zmdi-lock-outline"></i>
		</div>
	</div>
</div>
