import {Injectable} from '@angular/core';
import * as $ from 'jquery';
import {dic} from '../dictionary';
import {TranslationService} from '../services/translation.service';
import util from "util";
import { ToastrService } from 'ngx-toastr';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {

	constructor(private translateService: TranslationService, private toastr: ToastrService) {
	}

	showMessage(messageObj) {
		if (messageObj && ((messageObj.meta && messageObj.meta.display_bar) || messageObj.display_bar)) {
			if (!messageObj.meta) {
				if (messageObj.error) {
					this.toastr.error(messageObj.message);
				} else {
					this.toastr.success(messageObj.message);
				}
				return;
			}

			if (messageObj.meta.error) {
				this.toastr.error(messageObj.meta.message);
			} else {
				this.toastr.success(messageObj.meta.message);
			}
		}
	};

	showInfoMessage(msg, ...messageParams: any[]) {
		this.translateService.getTranslatedMessage(msg, (txtMsg) => {
			if (messageParams) {
				// @ts-ignore
				txtMsg = util.format(txtMsg, ...messageParams);
			}

			this.toastr.success(txtMsg);
		});
	};

	showWarnMessage(msg, ...messageParams: any[]) {
		this.translateService.getTranslatedError(msg, (txtMsg) => {
			if (messageParams) {
				// @ts-ignore
				txtMsg = util.format(txtMsg, ...messageParams);
			}

			this.toastr.warning(txtMsg);
		});
	};

	showErrorMessage(msg, ...messageParams: any[]) {
		this.translateService.getTranslatedError(msg, (txtMsg) => {
			if (messageParams) {
				// @ts-ignore
				txtMsg = util.format(txtMsg, ...messageParams);
			}

			this.toastr.error(txtMsg);
		});
	};
}
