import {Component, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {dic} from '../../dictionary';
import {RouteService} from '../../services/route.service';
import {NotificationService} from '../../services/notification.service';
import {GeneralService} from '../../services/general.service';
import {DownloadFilesService} from '../../services/download-files.service';
import {Buffer} from 'buffer';
import {TooltipDirective} from 'ng2-tooltip-directive';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import {EncryptAnimationComponent} from '../encrypt-animation/encrypt-animation.component';
import {Subject} from 'rxjs';
import * as _ from 'lodash';


@Component({
	selector: 'app-review',
	templateUrl: './review.component.html',
	styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {


	@ViewChild(EncryptAnimationComponent) encryptAnimationComponent: EncryptAnimationComponent;
	@ViewChildren(TooltipDirective) tooltipDirective;

	dic = dic;
	step: string;
	stepSubject: Subject<string> = new Subject<string>();
	stepDataSubject: Subject<object> = new Subject<object>();
	contentSubject: Subject<string> = new Subject<string>();

	senderEmailObj: any = {
		senderEmailAppearsAs: false,
		senderEmail: true,
		senderDomain: false,
		replyToEmail: false,
		replyToDomain: false,
		messageIdDomain: false,
		returnPathDomain: false
	};

	showHtml: false

	securityTeamComments: any;
	removeDomainSignature: false;
	useOpenAI: false;

	showThreats = true;
	userHash: string;
	disableActionBtn = false;
	threatProtection: any = {
		headers: {},
		url: {
			status: '',
			list: []
		},
		file: {
			status: '',
			list: []
		}
	};
	email: any = {
		htmlContent: '',
		attachments: [],
		methods: [],
		report_info: {},
		report_info_text: '',
		source: '',
		sender: '',
		subject: '',
		malicious: {},
		loaded: false
	};

	linksDropdown = false;
	encryptedContent = false;
	encryptedContentSubject: Subject<boolean> = new Subject<boolean>();
	blockNote: string;
	attachmentDownloadConfirm: any;
	previewAttachment;
	actionToConfirm = '';
	showLogs: boolean;
	showLoader = false;
	isFullScreen = false;
	followDescription = '';
	handledDescription = '';
	_ = _;

	constructor(private rs: RouteService,
				private ns: NotificationService,
				private downloadFilesService: DownloadFilesService,
				public gs: GeneralService) {
	}

	ngOnInit() {
		this.showLogs = false;
			this.step = dic.STEP.Authenticating;
		this.stepSubject.next(this.step);
		this.getMessage();

	}

	setAccessEnc() {
		const urlSplitted = location.href.split('review/');
		if (urlSplitted.length < 2) { return; }
		this.userHash = urlSplitted[1];
		const defaultHeaders = {};
		defaultHeaders[this.dic.HEADERS.xAccessEnc] = this.userHash;
		defaultHeaders[this.dic.HEADERS.xTrustifiSource] = 'miniapp';

		const localStorageFp = localStorage[this.dic.CONSTANTS.quarantinedFp];
		if (localStorageFp) {
			try {
				defaultHeaders[this.dic.HEADERS.x2FaFingerprint] = JSON.parse(localStorageFp);
			} catch (ex) {
				console.log(ex);
			}
		}

		this.rs.setDefaultHeaders(defaultHeaders);
	}

	finishLoadingEmail() {
		setTimeout(() => {
			this.gs.setIsLoadingApp(false);
		}, 800); // wait a bit so loading animation will not be cleared to fast
	}

	doActionOnEmail(action) {
		if (this.disableActionBtn) {
			return;
		}
		this.disableActionBtn = true;

		const actionData: any = {action, type: this.email.report_info.type};
		switch (action) {
			case dic.CONSTANTS.reviewerEmailAction.spam:
				actionData.content = this.email.htmlContent;
				break;

			case dic.CONSTANTS.reviewerEmailAction.globalLinks:
			case dic.CONSTANTS.reviewerEmailAction.planLinks:
				actionData.links = [];
				this.email.report_info.links.forEach((link) => {
					if (link.selected) {
						actionData.links.push(link.link);
					}
				});
				if (!actionData.links.length) {
					this.disableActionBtn = false;
					this.ns.showErrorMessage('No links selected');
					return;
				}
				break;

			case dic.CONSTANTS.reviewerEmailAction.globalAttachments:
			case dic.CONSTANTS.reviewerEmailAction.planAttachments:
				actionData.attachments = [];
				this.email.report_info.attachments.forEach((attachment) => {
					if (attachment.selected) {
						actionData.attachments.push(attachment);
					}
				});
				if (!actionData.attachments.length) {
					this.disableActionBtn = false;
					this.ns.showErrorMessage('No attachments selected');
					return;
				}
				break;

			case dic.CONSTANTS.reviewerEmailAction.planSender:
			case dic.CONSTANTS.reviewerEmailAction.globalSender:
				actionData.items = [];
				if (this.senderEmailObj.senderEmailAppearsAs) {
					actionData.items.push({value: this.email.threat_protection.resHead.from.senderMail, type: 'email'});
				}
				if (this.senderEmailObj.senderEmail) {
					actionData.items.push({value: this.email.threat_protection.resHead.from.senderMailFrom, type: 'email', sender: true});
				}
				if (this.senderEmailObj.senderDomain) {
					actionData.items.push({value: this.getEmailDomain(this.email.sender_info.email), type: 'domain'});
				}
				if (this.senderEmailObj.replyToEmail) {
					actionData.items.push({value: this.email.threat_protection.resHead.from.replyTo, type: 'email'});
				}
				if (this.senderEmailObj.replyToDomain) {
					actionData.items.push({value: this.getEmailDomain(this.email.threat_protection.resHead.from.replyTo), type: 'domain'});
				}
				if (this.senderEmailObj.messageIdDomain) {
					actionData.items.push({value: this.getEmailDomain(this.email.threat_protection.resHead.from.messageID), type: 'domain'});
				}
				if (this.senderEmailObj.returnPathDomain) {
					actionData.items.push({value: this.getEmailDomain(this.email.threat_protection.resHead.from.returnPath), type: 'domain'});
				}
				break;

			case dic.CONSTANTS.reviewerEmailAction.planSignSource:
				actionData.type = this.removeDomainSignature ? 'remove' : 'add';
				break;

			case dic.CONSTANTS.reviewerEmailAction.createIncident:
				actionData.securityTeamComments = this.securityTeamComments;
				break;

			case dic.CONSTANTS.reviewerEmailAction.rescanEmail:
				actionData.useOpenAI = this.useOpenAI;
				break;

			case dic.CONSTANTS.reviewerEmailAction.updateFollow:
				this.email.mark_for_follow = !this.email.mark_for_follow;
				actionData.enabled = this.email.mark_for_follow;
				actionData.description =  actionData.enabled ? this.followDescription : '';
				break;

			case dic.CONSTANTS.reviewerEmailAction.updateHandled:
				this.email.is_handled = !this.email.is_handled;
				if (this.email.is_handled) {
					this.email.mark_for_follow = false;
				}
				actionData.enabled = this.email.is_handled;
				actionData.description =  actionData.enabled ? this.handledDescription : '';
				break;
		}

		this.rs.doActionOnReviewEmail(actionData).then((response) => {
			this.disableActionBtn = false;
			this.actionToConfirm = '';
			this.ns.showInfoMessage(dic.MESSAGES.operationSucceeded);

			if (action === dic.CONSTANTS.reviewerEmailAction.rescanEmail) {
				if (actionData.useOpenAI) {
					this.ns.showInfoMessage(response.meta.message);
				}
				else {
					this.threatProtection = response;
					this.parseThreatProtection();
				}
			}
		}, (err) => {
			this.disableActionBtn = false;
			this.ns.showErrorMessage(err.error.message);
		});
	}

	getEmailDomain(email) {
		if (!email) {
			return;
		}
		const emailSplit = email.split('@');
		if (emailSplit.length === 2) {
			return emailSplit[1];
		}
	}

	downloadRawMail() {
		const attachment = {
			content: this.email.rawData,
			name: `${this.email.subject}.eml`,
			contentType: 'message/rfc822'
		};
		this.downloadFilesService.download(attachment.content, attachment.name, attachment.contentType);
	}

	downloadFile(attachment: any) {
		const isFileMalicious = this.threatProtection.resHash && this.threatProtection.resHash.list &&
			this.threatProtection.resHash.list.filter(item => item.name === attachment.name && item.status === 'Malicious');
		if (isFileMalicious && isFileMalicious.length) {
			this.attachmentDownloadConfirm = attachment;
			this.actionToConfirm = '';
		} else {
			this.downloadFileExecute(attachment);
		}
	}

	downloadFileExecute(attachment: any) {
		if (!attachment) {
			return;
		}

		const attachmentBuffer = Buffer.from(attachment.content, 'base64');

		if (attachmentBuffer) {
			const blob = new Blob([attachmentBuffer], {type: attachment.contentType});
			if (this.gs.detectMobile() && this.gs.isSupportedMimeType(attachment.contentType)) {
				if (this.gs.isChrome() && navigator.userAgent.match(/iPhone/i)) {
					const reader: any = new FileReader();
					reader.onload = (e) => {
						window.location.href = reader.result;
					};
					reader.readAsDataURL(blob);
				} else {
					location.href = URL.createObjectURL(blob);
				}
			} else {
				// general download
				this.downloadFilesService.download(attachmentBuffer, attachment.name, attachment.contentType);
			}
		} else {
			return; // Add Notification & Errors - Before too
		}
		this.attachmentDownloadConfirm = false;
	}

	downloadAllFiles() {
		if (this.email.attachments && this.email.attachments.length === 1) {
			this.downloadFile(this.email.attachments[0]);
		} else {
			if (this.threatProtection.resHash && this.threatProtection.resHash.status === 'Malicious') {
				this.attachmentDownloadConfirm = 'all';
			} else {
				this.downloadAllFilesExecute();
			}
		}
	}

	downloadAllFilesExecute() {
		const zip = new JSZip();
		let count = 0;
		const zipFilename = `attachments_${this.email.subject.substring(0, 50)}.zip`;

		this.email.attachments.forEach((attachment) => {
			zip.file(attachment.name, this.gs.bufferToArrayBuffer(attachment.content.data), {binary: true});
			count++;
			if (count === this.email.attachments.length) {
				zip.generateAsync({type: 'blob'}).then((content) => {
					saveAs(content, zipFilename);
				});
			}
		});
		this.attachmentDownloadConfirm = false;
	}

	showEmailContent() {
		if (!this.showThreats) {
			return;
		}

		this.showThreats = false;

		if (this.email.content) {
			setTimeout(() => {
				this.encryptAnimationComponent.updateIFrameContent(this.email.htmlContent);
			});
		} else {
			this.showLoader = true;
			this.rs.getReviewEmailInfo().then(emailInfo => {
				if (emailInfo.status !== dic.STEP.Authenticated) {
					this.encryptedContent = true;
					this.encryptedContentSubject.next(this.encryptedContent);
				} else {
					this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.DECRYPTED);
				}

				this.setStepData(emailInfo);

				setTimeout(() => {
					this.encryptAnimationComponent.updateIFrameContent();
					this.showLoader = false;
				});
			}, error => {
				this.setStepData(error);
			});
		}
	}

	confirmSuccessCb(response) {
		this.step = this.dic.STEP.Authenticated;
		this.stepSubject.next(this.step);
		this.setStepData(response);
	}

	confirmFailureCb = (response) => {
		if (response) {
			this.setStepData(response);
		}
	}

	applyAnimationCb() {
		if (this.encryptedContent) {
			this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.PROCESS);
		}
	}

	hashCopied(attachment) {
		setTimeout(() => {
			attachment.animateCopy = false;
		}, 2000);
	}

	private getMessage() {
		this.setAccessEnc();
		this.rs.getReviewEmailInfo().then(emailInfo => {
			if (emailInfo.status !== dic.STEP.Authenticated) {
				this.encryptedContent = true;
				this.encryptedContentSubject.next(this.encryptedContent);
			}
			this.finishLoadingEmail();

			this.setStepData(emailInfo);
		}, error => {
			this.setStepData(error);
		});
	}

	private setStepData(stepData) {
		this.step = dic.STEP[stepData.status] || dic.STEP.Blocked;
		this.stepSubject.next(this.step);
		this.stepDataSubject.next(stepData);

		switch (stepData.status) {
			case dic.STEP.Blocked:
				this.blockNote = stepData.blockNote || '';
				break;

			case dic.STEP.Authenticated:
				this.email = stepData;
				this.threatProtection = stepData.threat_protection;
				this.parseThreatProtection();

				this.email.category = this.threatProtection.status;

				if (this.email.report_info.links && this.email.report_info.links.length) {
					for (let idx = 0; idx < this.email.report_info.links.length; idx++) {
						this.email.report_info.links[idx] = {link: this.email.report_info.links[idx]};
					}
				}

				this.setReportType();

				this.parseContent(stepData.content);
				this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.DECRYPTED);
				this.email.loaded = true;

				this.showEmailContent();
		}
	}

	parseThreatProtection() {
		// TODO: BC
		if (!this.threatProtection.resHead) {
			this.threatProtection.resHead = this.threatProtection.headers;
		}
		if (!this.threatProtection.resHash) {
			this.threatProtection.resHash = this.threatProtection.resHash || {};
		}
		if (!this.threatProtection.resUrl) {
			this.threatProtection.resUrl = this.threatProtection.url || {};
		}
		//

		if (this.threatProtection && this.threatProtection.resHead) {
			this.threatProtection.AiRates = {
				threat_body_conf: this.threatProtection.resHead.AIresults.threat_body_conf ? this.threatProtection.resHead.AIresults.threat_body_conf > 1 ? 100 : (this.threatProtection.resHead.AIresults.threat_body_conf * 100) : 0,
				spam_body_conf: this.threatProtection.resHead.AIresults.spam_body_conf ? this.threatProtection.resHead.AIresults.spam_body_conf > 1 ? 100 : (this.threatProtection.resHead.AIresults.spam_body_conf * 100) : 0,
				graymail_body_conf: this.threatProtection.resHead.AIresults.graymail_body_conf ? this.threatProtection.resHead.AIresults.graymail_body_conf > 1 ? 100 : (this.threatProtection.resHead.AIresults.graymail_body_conf * 100) : 0
			}
		}
		if (this.threatProtection.resUrl && this.threatProtection.resUrl.list && this.threatProtection.resUrl.list.length) {
			this.threatProtection.resUrl.list = _.sortBy(this.threatProtection.resUrl.list, url => {
				return dic.CONSTANTS.statusPriority[url.status];
			});
			this.threatProtection.resUrl.list = this.gs.filterDuplicateSafeLinks(this.threatProtection.resUrl.list);
		}
		else {
			this.threatProtection.resUrl = {
				status: dic.CONSTANTS.threatProtection.status.safe,
				message: this.threatProtection.resUrl && this.threatProtection.resUrl.message || 'No malicious links detected.'
			}
		}
		if (this.threatProtection.resHash && this.threatProtection.resHash.list && this.threatProtection.resHash.list.length) {
			this.threatProtection.resHash.list = _.sortBy(this.threatProtection.resHash.list, file => {
				return dic.CONSTANTS.statusPriority[file.status];
			});
		}
		else {
			this.threatProtection.resHash = {
				status: dic.CONSTANTS.threatProtection.status.safe,
				message: this.threatProtection.resHash && this.threatProtection.resHash.message || 'No malicious attachments detected.'
			}
		}
	}

	setReportType() {
		let reportActions = '';
		// for outlook/chrome we have a specific complaint
		if (this.email.source === 'outlook' || this.email.source === 'chrome') {
			if (this.email.report_info.spam) {
				reportActions += `${reportActions ? ', ' : ''}Spam`;
			}
			if (this.email.report_info.suspicious) {
				reportActions += `${reportActions ? ', ' : ''}Suspicious`;
			}
			if (this.email.report_info.sender) {
				reportActions += `${reportActions ? ', ' : ''}Sender`;
			}
			if (this.email.report_info.links && this.email.report_info.links.length) {
				reportActions += `${reportActions ? ', ' : ''}Links`;
			}
			if (this.email.report_info.attachments && this.email.report_info.attachments.length) {
				reportActions += `${reportActions ? ', ' : ''}Attachments`;
			}
		}

		this.email.report_info_text = `${reportActions || 'Email'} (${this.email.source && this.email.source.charAt(0).toUpperCase() + this.email.source.slice(1)})`;
	}

	updateEncryptedContent(encryptedContent) {
		this.encryptedContent = encryptedContent;
	}

	setInboundStatusStyles(status) {
		switch (status) {
			case dic.CONSTANTS.threatProtection.status.malicious:
				return {color: 'darkred', icon: 'fa-bug', loading: false, displayColor: 'var(--danger)'};
			case dic.CONSTANTS.threatProtection.status.suspicious:
				return {color: 'darkred', icon: 'fa-bug', loading: false, displayColor: 'var(--danger)'};
			case dic.CONSTANTS.threatProtection.status.error:
				return {color: 'darkred', icon: 'fa-exclamation-circle', loading: false, displayColor: 'var(--danger)'};
			case dic.CONSTANTS.threatProtection.status.spam:
				return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false, displayColor: 'var(--warning)'};
			case dic.CONSTANTS.threatProtection.status.grayMail:
				return {color: 'dimgray', icon: 'fa-exclamation-circle', loading: false, displayColor: 'var(--gray2)'};
			case dic.CONSTANTS.threatProtection.status.unsecured:
				return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false, displayColor: 'var(--warning)'};
			case dic.CONSTANTS.threatProtection.status.pending:
			case dic.CONSTANTS.threatProtection.status.analyzing:
			case dic.CONSTANTS.threatProtection.status.analyzingLinks:
				return {color: 'darkorange', icon: 'fa-clock', loading: true, displayColor: 'var(--warning)'};
			case dic.CONSTANTS.threatProtection.status.safe:
				return {color: 'darkgreen', icon: 'fa-check-circle', loading: false, displayColor: 'var(--success)'};
			default:
				return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false, displayColor: 'var(--warning)'};
		}
	}

	private parseContent(content: any) {
		this.email.content = content;
		this.email.htmlContent = '';
		this.email.attachments = [];

		if (!content) {
			return;
		}

		try {
			if (content.attachments) {
				this.email.attachments = content.attachments.filter((itm) => itm.contentDisposition === 'attachment');
				if (this.email.attachments && this.email.attachments.length) {
					this.email.attachments.forEach((attachment) => {
						attachment.name = attachment.filename || 'attachment_no_name';
					});
				}
			}

			this.email.subject = content.subject || '(no subject)';
			this.email.htmlContent = '<b>From:</b> ' + (content.from && content.from.text.replace('<', '(').replace('>', ')') || '');
			if (content.to && content.to.text) {
				this.email.htmlContent += '<br><b>To:</b> ' + content.to.text;
			}
			if (content.date) {
				this.email.htmlContent += '<br><b>Date:</b> ' + new Date(content.date);
			}
			this.email.htmlContent += '<br><b>Subject:</b> ' + this.email.subject + '<br><br>';
			if (content.html) {
				this.email.htmlContent += this.gs.stripCSSStyles(content.html).html;
			} else {
				this.email.htmlContent += content.text;
			}
			this.contentSubject.next(this.email.htmlContent);
		} catch (err) {
			console.error(err);
		}
	}
}
