<div class="row-alt" *ngIf="loadingData">
	<div style="font-weight:bold;padding:20px;text-transform: uppercase;" class="loading-dots">Getting Postmark data</div>
</div>

<div class="row-alt" *ngIf="!loadingData && recipientPostmark && !recipientPostmark.recipient">
	<div style="font-weight:bold;padding:20px;text-transform: uppercase;">Postmark data is unavailable</div>
</div>

<div class="recipients-dropdown-wrapper" *ngIf="postmarkData.length > 0">
	<div class="heading" style="justify-content: normal; height: auto;">
		<app-search-dropdown
				[innerSearchField]="'recipient'"
				[searchField]="'name'"
				[clickSelected]="false"
				[dropdownObjectsInput]="postmarkDataSubject.asObservable()"
				(changeSelectedCb)="changePostmarkRecipient($event)">
		</app-search-dropdown>

		<div class="hSpace05" style="display: flex;">
			<btn style="margin-bottom: 2px;"
				 (action)="downloadAsPdf()">
				Download as PDF
			</btn>
			<btn style="margin-bottom: 2px;"
				 (action)="verifyPostmarkReceipt()">
				Verify
			</btn>
		</div>
	</div>
</div>

<div class="no-recipient-found" *ngIf="!recipientPostmark">No recipient has been found for this search</div>

<div class="row-alt" *ngIf="recipientPostmark && recipientPostmark.recipient">
	<div class="signature" id="postmark-src" style="min-width:500px;padding-right:10px;">
		<div style="background-color: rgb(111,111,111);text-align: center;color: antiquewhite">
			SECURED POSTMARKED EMAIL PROVIDES LEGAL PROOF OF SENDING
		</div>
		<div class="header">
			<img id="trustifi-logo" src="assets/images/logo-transparent.svg" style="max-height: 50px" alt="signature">
		</div>

		<div class="row">
			<strong>The email has been successfully processed and securely timestamped using {{lfs.corpName}} Postmarked Email®.</strong>
		</div>

		<div class="row trustifi-description">
			The email has been successfully processed and securely timestamped using {{lfs.corpName}} Postmarked Email®.
			{{lfs.corpName}} Postmarked Email® is a service that digitally signs the email with an electronic timestamp securely synchronized with the atomic clock at the National Institute of Science and Technology (NIST) an agency of the United States Department of Commerce. This HTML page is an official {{lfs.corpName}} Postmarked Email® receipt which contains the information needed to legally prove the contents, attachments, recipients, date, and time of the email sent.
		</div>

		<div>
			<div class="row" style="margin:0">
				<div style="width: 60%; height: 100%; float: left">
					<ul class="form-fields" style="margin:0">
						<li>
							<div>MessageID:</div>
							<div>{{recipientPostmark.id}}</div>
						</li>
						<li>
							<div>Subject:</div>
							<div class="ellipsis" style="word-break: break-all; max-width: 75%;">{{recipientPostmark.title}}</div>
						</li>
						<li>
							<div>From:</div>
							<div class="ellipsis" style="word-break: break-all; max-width: 75%;">{{recipientPostmark.sender.name}} &#60;{{recipientPostmark.sender.email}}&#62; </div>
						</li>
						<li>
							<div>To:</div>
							<div class="ellipsis" style="word-break: break-all; max-width: 75%;">{{recipientPostmark.recipient.name}} &#60;{{recipientPostmark.recipient.email}}&#62; </div>
						</li>
						<li class="attachments">
							<div>Attachments:</div>
							<ul>
								<li *ngFor="let attachment of recipientPostmark.attachments">
									<div>
										<span>name:</span>
										<span>{{attachment.name}}</span>
									</div>
									<div>
										<span>hash:</span>
										<span>{{attachment.hash}}</span>
									</div>
									<div>
										<span>id:</span>
										<span>{{attachment.id}}</span>
									</div>
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<div style="width: 38%;  height: 100%; float: right" *ngIf="recipientPostmark.delivered_at">
					<img style="float: right;" src="assets/images/trustifi-stamp-delivered.png" tooltip="Stamp Delivered" alt="Stamp Delivered">
					<div style="font-family: roboto; font-size: 9px; color: #808080; float: right;margin-top: -62px;margin-right: 6px;">{{recipientPostmark.delivered_at  | date:'MMM dd, yyyy HH:mm:ss' : 'UTC'}}</div>
				</div>
			</div>

			<div class="html2pdf__page-break" *ngIf="recipientPostmark.attachments.length>1"></div>

			<div class="row date-time">
				<div style="width:60%; height: 100%; float: left; margin-top:5px;">
					<strong>Postmark Date &amp; Time:</strong>
					<p style="margin-bottom: 5px; margin-top: 5px">{{recipientPostmark.est | date:'MMM dd, yyyy hh:mm:ss a'}} - Eastern</p>
					<p style="margin-bottom: 5px; margin-top: 5px">{{recipientPostmark.cst | date:'MMM dd, yyyy hh:mm:ss a'}} - Central</p>
					<p style="margin-bottom: 5px; margin-top: 5px">{{recipientPostmark.mst | date:'MMM dd, yyyy hh:mm:ss a'}} - Mountain</p>
					<p style="margin-bottom: 5px; margin-top: 5px">{{recipientPostmark.pst | date:'MMM dd, yyyy hh:mm:ss a'}} - Pacific</p>
					<p style="margin-bottom: 5px; margin-top: 5px">{{recipientPostmark.timestamp | date:'MMM dd, yyyy hh:mm:ss a' : 'UTC'}} - UTC</p>
					<div style="margin-bottom: 6px; margin-top: 6px"><strong>Data Fingerprint:</strong> <div>{{recipientPostmark.dataFingerPrint}}</div></div>
					<div style="margin-bottom: 6px; margin-top: 6px"><strong>RootCA Bundle Fingerprint:</strong> <div>{{recipientPostmark.rootCABundleFingerprint}}</div></div>
				</div>
				<div style="width: 38%; height: 100%; float: right; margin-top: -10px;" *ngIf="recipientPostmark.opened_at">
					<img style="float: right;" src="assets/images/trustifi-stamp-opened.png" tooltip="Stamp Opened" alt="Stamp Opened" >
					<div style="font-family: roboto; font-size: 9px; color: #808080; float: right;margin-top: -62px;margin-right: 6px;">{{recipientPostmark.opened_at | date:'MMM dd, yyyy HH:mm:ss' : 'UTC'}}</div>
				</div>
			</div>

			<div class="row">
				<div style=" width:100%; float:left">
					<hr style="border-top: dotted 1px;">

					<h6>VERIFICATION&nbsp;&nbsp;INFORMATION</h6>
				</div>
			</div>
			<div class="row trustifi-description">
				<div style=" width:100%; float:left">
					This {{lfs.corpName}} Postmarked Email® receipt, and a copy of the original email data, contains the information required to legally prove it was sent, including all attachments (if included), recipients, and the date and time. We are keeping this data safe and accessible to senders. Should there be a requirement to have the email verified, simply click the "VERIFY" link in this document and {{lfs.corpName}} will perform the verification calculations normally done by complex Public-Key Infrastructure solutions. Under certain extraordinary circumstances, independent verification may be required utilizing cryptographic procedures. If so, a sender can simply download the necessary data which will provide a complete chain of evidence for this record.
				</div>
			</div>

			<!-- The original sent email that was timestamped (we keep it for you unmodified since the timestamp was created),
				The actual timestamp that was returned from the DigiStamp server (the digital signature created by the TSA),
				The public key to verify the authenticity of the signature in the timestamp (All DigiStamp timestamps include their public keys).
				Your data will be authenticated by comparing its fingerprint to the signed fingerprint in the original timestamp.
				The digital signature in the timestamp is now decrypted, using the public key, and it is confirmed that the hash signed by
				the TSA is an exact match to the hash of the data being authenticated. A change to the protected data will prevent
				it from resolving to the same hash and, consequently, from verifying against the signed information.
				Any change to the timestamp signature will render it invalid and so the timestamp will not assert anything.
				-->
		</div>
		<div class="html2pdf__page-break"></div>
		<div class="row" style="width:100%;float:left;display:none" id="htmldata"></div>
	</div>
</div>
