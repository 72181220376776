import {
	AfterContentInit,
	Directive,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';

@Directive({ selector: '[shown]', standalone: true })
export class ShownDirective {
	@Input() public shown: boolean;

	@HostBinding('attr.hidden')
	public get attrHidden(): string | null {
		return this.shown ? null : 'hidden';
	}
}

// Select all text in input (or element) when the given flag turns on
@Directive({
	selector: '[selectAllText]',
	standalone: true
})
export class SelectAllText implements OnChanges, OnInit {

	@Input() selectAllText: boolean;

	public constructor(private element: ElementRef) {}

	ngOnInit() {
		if (this.selectAllText) {
			this.selectAll();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.selectAllText) {
			this.selectAll();
		}
	}

	selectAll(): void {
		setTimeout(() => {
			this.element.nativeElement.focus();
			this.element.nativeElement.select();
		});
	}
}

@Directive({
	selector: '[ngInit]',
	standalone: true
})
export class NgInit implements OnInit{
	@Output() ngInit = new EventEmitter();

	ngOnInit() {
		if(this.ngInit) {
			this.ngInit.emit();
		}
	}
}

@Directive({
	selector: '[scrollToTopFlag]',
	standalone: true
})
export class ScrollToTopFlagDirective implements OnChanges{

	@Input() scrollToTopFlag;
	@Output() scrollToTopFlagChange = new EventEmitter();

	constructor(public el: ElementRef) {}

	ngOnChanges(changes: SimpleChanges) {
		setTimeout(() => {
			if (this.scrollToTopFlag === true) {
				this.el.nativeElement.scrollTo({top: 0, behavior: 'smooth'});
				this.scrollToTopFlagChange.emit(false);
			}
		});
	}
}

@Directive({
	selector: '[appAutoFocus]',
	standalone: true
})
export class AutoFocusDirective implements AfterContentInit {

	@Input() appAutoFocus: boolean;
	@Input() focusDelay: number;

	public constructor(private el: ElementRef) {}

	public ngAfterContentInit(): void {
		this.checkFocus(this.appAutoFocus);
	}

	public checkFocus(focus): void {
		if (focus) {
			setTimeout(() => {
				this.el.nativeElement.focus();
			}, this.focusDelay || 1);
		}
	}
}
