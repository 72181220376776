<div class="flxClmn f-1-0" style="overflow: hidden;">

	<div class="shader" (click)="showLogs = false;" [ngClass]="{'active': showLogs}"></div>

	<!--"Handled by a reviewer" label-->
	<div class="flxRow animated fadeIn handled-banner"  style="align-items: center; letter-spacing: 1px; cursor: default; overflow: hidden; background: forestgreen; padding: 10px; border-bottom-right-radius: 5px; box-shadow: 0 0 8px rgba(0, 255, 0, 0.4); font-size: 0.85rem; position: absolute; left: 0; top: 0; white-space: nowrap; direction: rtl; color: white;"
		 *ngIf="!gs.isMobile && email.reviewed_completed">
		<i class="icon fas fa-check-circle" style="font-size: 1.2rem; padding: 0 3px 0 15px;"></i>
		<span>{{ 'quarantined.emailHandled' | translate }}</span>
	</div>

	<!--CONTENT: Blocked email-->
	<div class="flxClmn-center vSpace2 w100" style="flex:1 0; padding:15px" *ngIf="step === dic.STEP.Blocked">
		<!--info content block-->
		<div class="flxClmn-center vSpace2 t-color-secondary" style="flex:4 0; align-items: center;">
			<img src="assets/images/blockedEmail.png" style="width: 20vh; height: 20vh; filter:grayscale(1); opacity:0.3;" />
			<div class="flxClmn-start vSpace1" style="align-items: center">
				<span class="t-color-primary" style="font-size: 1.6rem; font-family: 'Roboto',sans-serif; text-align: center; font-weight: lighter">{{ 'authentication.emailNotAvailable' | translate }}</span>
			</div>
		</div>
	</div>

	<!--CONTENT: authenticated viewed email - NEW-->
	<div class="h100 w100 flxClmn" style="flex: 1 0; font-size: 0.75rem; overflow: auto;" *ngIf="step !== dic.STEP.Blocked">
		<app-encrypt-animation
			[expandInMobile] = "gs.isMobile"
			[iframeId]="'email-content-frame'"
			[encryptedContentInput]="encryptedContentSubject.asObservable()"
			[contentInput]="contentSubject.asObservable()"
			(encryptedContentEmitter)="updateEncryptedContent($event)">
		</app-encrypt-animation>
		<app-authentication
			style="flex: 1 0; height: 1px" class="flxClmn"
			*ngIf="[dic.STEP.Phone, dic.STEP.Totp, dic.STEP.Email, dic.STEP.Code, dic.STEP.Password, dic.STEP.Authenticating, dic.STEP.ssoIdp, dic.STEP.SSO, dic.STEP.Empty].indexOf(step) > -1"
			[stepDataInput]="stepDataSubject.asObservable()"
			(confirmSuccessEmitter)="confirmSuccessCb($event)"
			(confirmFailureEmitter)="confirmFailureCb($event)"
			(applyAnimationEmitter)="applyAnimationCb()"
			[userHash]="userHash"
			[parentComponent]="dic.CONSTANTS.authParentComponents.outboundQuarantined">
		</app-authentication>
		<!--Popup header-->
		<div class="flxClmn-between tp-popup-header" style="flex: 2 0; max-height: 130px; background: linear-gradient(90deg, var(--bg-primary), var(--bg-primary) 58%, var(--light-gray-at)), url('assets/images/headerBG.jpg'); background-size: cover;"
			 *ngIf="step === dic.STEP.Authenticated">
			<div class="flxRow-between" [ngStyle]="{'margin' : gs.isMobile ? '15px 0' : '0'}">
				<div class="flxRow hSpace2 ellipsis" style="align-items: center; padding-right: 5%">
					<img src="assets/images/favicon/favicon-32x32.png" style="width: 20px; margin: 0; image-rendering: -webkit-optimize-contrast;">
					<h3 class="t-color-primary ellipsis">{{email.email_subject}}</h3>
				</div>
			</div>

			<div [ngClass]="gs.isMobile ? 'flxClmn vSpace1' : 'flxRow hSpace2'"
				 [ngStyle]="{'align-items' : gs.isMobile ? 'flex-start' : 'center'}">
				<div class="flxRow hSpace1" style="align-items: center;" [ngStyle]="{'font-size' : gs.isMobile ? '14px' : '1rem'}">
					<span class="t-color-secondary">{{ 'quarantinedOutbound.category' | translate }}</span>
					<span class="t-color-warning" style="font-weight: bold;">{{email.type}}</span>
				</div>
				<div class="vertical-separator" style="border-width: 2px; margin: 0 15px; border-color: darkgrey; border-left: none;" *ngIf="!gs.isMobile"></div>
				<div class="flxRow hSpace1" style="align-items: center;">
							<span class="t-color-secondary" style="font-size: 1rem;" [ngStyle]="gs.isMobile && {'font-size' : '14px'}">
								{{ 'quarantined.emailStatus' | translate }}
								<b>{{email.quarantined_status === dic.CONSTANTS.quarantinedOutboundAction.release ? 'released' : email.quarantined_status === dic.CONSTANTS.quarantinedOutboundAction.remove ? 'removed' : email.quarantined_status === dic.CONSTANTS.quarantinedOutboundAction.pending ? 'Pending release' : email.quarantined_status}}</b>
							</span>
				</div>
				<div class="vertical-separator" style="border-width: 2px; margin: 0 15px; border-color: darkgrey; border-left: none;" *ngIf="!gs.isMobile"></div>
				<div class="flxRow hSpace1" style="font-size: 1rem; align-items: center;" [ngStyle]="gs.isMobile && {'font-size' : '14px'}">
					<span class="t-color-secondary">{{ 'quarantinedOutbound.sender' | translate }}</span>
					<span style="font-weight: bold; max-width: 400px;" class="ellipsis t-color-primary">{{email.user_email}}</span>
				</div>
			</div>

			<div class="flxRow hSpace1" style="align-items: center; flex-wrap: wrap;" [ngStyle]="gs.isMobile && {'padding' : '25px 0 30px'}">
						 <span class="t-color-secondary" style="font-size: 1rem;" *ngIf="!gs.isMobile">
							{{ 'quarantined.emailActions' | translate }}:
						</span>
				<div class="flxClmn w100 vSpace05" *ngIf="gs.isMobile">
					<span class="t-color-primary" style="font-family: 'Roboto', sans-serif; font-size: 1.1rem;">{{ 'quarantined.emailActions' | translate }}</span>
					<div style="background: linear-gradient(90deg, #9b9b9b, transparent); width: 100%; height: 1px; margin-bottom: min(15px,1vh);"></div>
				</div>
				<btn style="min-width: 125px;"
					 *ngIf="email.methods.indexOf(dic.CONSTANTS.quarantinedOutboundAction.remove) >= 0"
					 [btnTooltip]="('quarantinedOutbound.actions.removeTooltip' | translate)"
					 [displayTooltipOnTouchscreen]="false"
					 [ngStyle]="gs.isMobile && {'flex': '1 0', 'max-width': '44%', 'min-width': '38% !important', 'padding': '8px 2px', 'margin': '5px'}"
					 (action)="actionToConfirm = dic.CONSTANTS.quarantinedOutboundAction.remove;">
					{{ 'quarantinedOutbound.actions.remove' | translate }}
				</btn>
				<btn style="min-width: 125px;"
					 *ngIf="email.methods.indexOf(dic.CONSTANTS.quarantinedOutboundAction.release) >= 0"
					 [btnTooltip]="('quarantinedOutbound.actions.releaseTooltip' | translate)"
					 [displayTooltipOnTouchscreen]="false"
					 [ngStyle]="gs.isMobile && {'flex': '1 0', 'max-width': '44%', 'min-width': '38% !important', 'padding': '8px 2px', 'margin': '5px'}"
					 (action)="actionToConfirm = dic.CONSTANTS.quarantinedOutboundAction.release;">
					{{ 'quarantinedOutbound.actions.release' | translate }}
				</btn>
				<btn style="min-width: 125px;"
					 [btnTooltip]="email.reviewed_completed ? ('quarantined.emailNotHandledActionTooltip' | translate ) : ('quarantined.emailHandledActionTooltip' | translate )"
					 [displayTooltipOnTouchscreen]="false"
					 [ngStyle]="gs.isMobile && {'flex': '1 0', 'max-width': '44%', 'min-width': '38% !important', 'padding': '8px 2px', 'margin': '5px'}"
					 (action)="actionToConfirm = dic.CONSTANTS.quarantinedOutboundAction.handled;">
					{{email.reviewed_completed ? ('quarantinedOutbound.actions.emailNotHandledAction' | translate) : ('quarantinedOutbound.actions.emailHandledAction' | translate)}}
				</btn>
			</div>
			<div class="flxClmn-center" style="margin-top: -5px; padding: 0 4% 2vh; align-items: center; min-height: 45px;" *ngIf="gs.isMobile">
				<div class="flxRow" style="opacity: 0.95; font-size: 0.95rem; justify-content: flex-end"
					 [ngStyle]="gs.isMobile && {'font-size' : '0.85rem', 'margin' : '10px', 'justify-content' : 'center'}">
					<a [tooltip]="('quarantined.reviewersActivityTooltip' | translate )" show-delay="400"
					   [displayTouchscreen]="false"
					   (click)="showLogs = true;" class="hSpace05 t-color-primary">
						<i class="fa fa-list-ul" style="opacity: 0.85; font-size: 0.8rem;"></i>
						<span>{{ 'quarantined.reviewersActivity' | translate }}</span>
					</a>
				</div>
			</div>
		</div>

		<div class="tp-below-header-container" style="flex: 8 0; overflow: hidden;"
			 *ngIf="step === dic.STEP.Authenticated">
			<!--view analysis / email toggle button | Mobile-->
			<div class="flxRow-center hSpace1 threats-email-toggle-container-mobile t-color-primary" style="align-items: center; margin: 10px 0 5px; height: 50px;"
				 *ngIf="gs.isMobile && email.email_subject && email.allowReviewerShowContent">
				<span>{{'quarantined.toggleView' | translate}}:</span>
				<div class="flxRow-center" style="position: relative; overflow: hidden; border-radius: 5px; height: 30px;">
					<div class="flxRow-center hSpace05 threats-email-toggle-mobile" style="align-items: center; border-bottom-right-radius: 0; border-top-right-radius: 0;"
						 [ngClass]="showThreats && 'chosen'"
						 (click)="showThreats = true;">
						<i class="fas fa-exclamation-triangle"></i>
						<span>
							{{ 'quarantinedOutbound.analysis' | translate }}
						</span>
					</div>
					<div class="flxRow-center threats-email-toggle-mobile" style="align-items: center;border-bottom-left-radius: 0; border-top-left-radius: 0; margin-left: -1px;"
						 [ngClass]="!showThreats && 'chosen'"
						 (click)="showEmailContent()">
						<div *ngIf="showThreats || !getEmailContentInProcess" class="hSpace05">
							<i class="fa fa-envelope"></i>
							<span class="animated fadeIn" *ngIf="showThreats || !getEmailContentInProcess">
								{{'general.email' | translate | titlecase}}
							</span>
						</div>
						<i *ngIf="!showThreats && getEmailContentInProcess" class="fa fa-spinner fa-spin"></i>
					</div>
					<div id="blueSliderMobile" style="left: 0; width: 40vw; position: absolute; transition: margin 0.2s;height:100%"
						 [ngStyle]="showThreats ? {'margin-left':'0'} : {'margin-left':'40vw'}"></div>
				</div>
			</div>
			<!---->
			<div class="flxClmn" style="flex: 1 0; position: relative; overflow: auto;">
				<div class="threat-protection-grid h100" style="flex: 1 0; overflow: auto;">
					<!--Message Analysis Data Card-->
					<div class="threat-protection-card flxClmn ro10 co10"
						 *ngIf="!(gs.isMobile && !showThreats)"
						 [ngStyle]="{'height' : email.type === dic.CONSTANTS.quarantinedOutboundType.malicious ? '390px' : '340px'}">
						<div class="card-header flxRow-between" style="align-items: center;">
							<span class="card-title">
								{{ 'quarantinedOutbound.messageAnalysis' | translate }}
							</span>
						</div>
						<div class="flxClmn animated fadeIn" style="flex: 1 0; overflow: auto;">
							<div class="flxClmn vSpace05" style="margin-bottom: 10px; font-size: 0.85rem; padding: max(15px,1vw); flex: 1 0;">
								<!--Warning label-->
								<span class="flxRow t-color-danger" style="align-items: center; margin: 0 0 15px; font-size: 1.15rem;">
									<i class="fas fa-exclamation-triangle" style="padding-right: 15px"></i>
									{{email.type === dic.CONSTANTS.quarantinedOutboundType.malicious ? ('quarantinedOutbound.maliciousLink' | translate) : ''}}
									{{email.type === dic.CONSTANTS.quarantinedOutboundType.maliciousFiles ? ('quarantinedOutbound.maliciousFile' | translate) : ''}}
									{{email.type === dic.CONSTANTS.quarantinedOutboundType.inappropriateContent ? ('quarantinedOutbound.inappropriateContent' | translate) : ''}}
									{{email.type === dic.CONSTANTS.quarantinedOutboundType.rules ? ('quarantinedOutbound.blockedOutboundRule' | translate) : ''}}
									{{email.type === dic.CONSTANTS.quarantinedOutboundType.blocklistRecipient ? ('quarantinedOutbound.blocklistRecipient' | translate) : ''}}
									{{email.type === dic.CONSTANTS.quarantinedOutboundType.blocklistFilePolicy ? ('quarantinedOutbound.blocklistFilePolicy' | translate) : ''}}
									{{email.type === dic.CONSTANTS.quarantinedOutboundType.recipientFailure ? ('quarantinedOutbound.recipientFailure' | translate) : ''}}
								</span>
								<!--Warning details-->
								<div class="flxClmn-center t-bg-secondary t-color-primary" style="padding: 15px; flex: 1 0;">
									<!--outbound rule-->
									<div class="flxClmn-evenly" style="flex: 1 0; align-items: center;"
										 *ngIf="email.type === dic.CONSTANTS.quarantinedOutboundType.rules && email.reason">
										<span style="font-size: 1rem;">{{ 'quarantinedOutbound.ruleName' | translate }}: <b>{{email.reason}}</b></span>
										<span>{{ 'quarantinedOutbound.editOutboundRule' | translate }}
											<a class="brightnessDark" style="font-size: 0.8rem; text-decoration: underline;" href="{{adminPanelUrl}}" target="_blank">
														{{ 'quarantinedOutbound.adminPortal' | translate }}
													</a>
												</span>
									</div>
									<!--recipient failure-->
									<div class="flxClmn vSpace1" style="font-size: 1rem; flex: 1 0;"
										 *ngIf="email.type === dic.CONSTANTS.quarantinedOutboundType.recipientFailure && email.reason">
										<span>{{ 'quarantinedOutbound.recipientMailbox' | translate }}</span>
										<span style="margin: auto; font-weight: 500; word-break: break-word; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; padding: 0 15px">
                                                    {{email.reason}}
                                                </span>
									</div>

									<!--blocklisted attachment type-->
									<table class="w100 simpleDataTable t-color-primary" *ngIf="email.type === dic.CONSTANTS.quarantinedOutboundType.blocklistFilePolicy">

										<tr *ngIf="email.info.blockType">
											<td><b>{{ 'quarantinedOutbound.policyBlockType' | translate }}:</b></td>
											<td>
												<span style="word-break: break-word; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 8; -webkit-box-orient: vertical;">
													{{email.info.blockType}}
												</span>
											</td>
										</tr>

										<tr *ngIf="email.info.fileName">
											<td><b>{{ 'quarantinedOutbound.fileName' | translate }}:</b></td>
											<td>
												<span style="word-break: break-word; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 8; -webkit-box-orient: vertical;">
													{{email.info.fileName}}
												</span>
											</td>
										</tr>
										<tr *ngIf="email.info.type">
											<td><b>{{ 'quarantinedOutbound.fileType' | translate }}:</b></td>
											<td>
												<span style="word-break: break-word; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 8; -webkit-box-orient: vertical;">
													{{email.info.type}}
												</span>
											</td>
										</tr>
									</table>


									<!--blocklisted recipient-->
									<div class="flxClmn vSpace1" style="font-size: 1rem; flex: 1 0;"
										 *ngIf="email.type === dic.CONSTANTS.quarantinedOutboundType.blocklistRecipient">
                                                <span style="margin: auto; word-break: break-word; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; padding: 0 15px">
													{{ 'quarantinedOutbound.blocklistRecipientReason' | translate :  {email: email.reason} }}
                                                </span>
									</div>
									<!--inappropriate content-->
									<div class="flxClmn-evenly" style="flex: 1 0; align-items: center;"
										 *ngIf="email.type === dic.CONSTANTS.quarantinedOutboundType.inappropriateContent">
										<span style="text-align: center;">{{ 'quarantinedOutbound.inappropriateContentDesc' | translate }}</span>
										<span>{{ 'quarantinedOutbound.changeSettings' | translate }}
											<a class="brightnessDark" style="font-size: 0.8rem; text-decoration: underline;" href="{{adminPanelUrl}}" target="_blank">
														{{ 'quarantinedOutbound.adminPortal' | translate }}
													</a>
												</span>
									</div>
									<!--malicious links-->
									<table class="w100 simpleDataTable t-color-primary" *ngIf="email.type === dic.CONSTANTS.quarantinedOutboundType.malicious && email.info">
										<tr *ngIf="email.info.url">
											<th style="padding: 0; border: none; width: 150px;"></th>
											<th style="padding: 0; border: none;"></th>
										</tr>
										<tr *ngIf="email.info.url">
											<td><b>{{ 'quarantinedOutbound.urlAddress' | translate }}:</b></td>
											<td>
                                                        <span style="word-break: break-word; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 8; -webkit-box-orient: vertical;">
                                                            {{email.info.url}}
                                                        </span>
											</td>
										</tr>
										<tr *ngIf="email.info.domain">
											<td><b>{{ 'quarantinedOutbound.domain' | translate }}:</b></td>
											<td>
												<span>{{email.info.domain}}</span>
												<span *ngIf="email.info.is_black_list"> (Blacklist)</span>
											</td>
										</tr>
										<tr *ngIf="email.info.status">
											<td><b>{{ 'quarantinedOutbound.type' | translate }}:</b></td>
											<td>
												<span class="t-color-danger">{{email.info.status}}</span>
											</td>
										</tr>
										<tr *ngIf="email.info.sub_status && email.info.sub_status.length">
											<td><b>{{ 'quarantinedOutbound.detections' | translate }}:</b></td>
											<td>
												<div class="flxClmn vSpace1">
													<div *ngFor="let detection of email.info.sub_status" class="flxRow hSpace1 t-color-danger">
														<i class="fas fa-exclamation-triangle"></i>
														<span>{{detection.message}}</span>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td><b>{{ 'quarantinedOutbound.emailStatus' | translate }}:</b></td>
											<td>
												<span style="text-transform: capitalize;">{{email.quarantined_status}}</span>
											</td>
										</tr>
									</table>

									<!--malicious file-->
									<table class="w100 simpleDataTable t-color-primary" *ngIf="email.type === dic.CONSTANTS.quarantinedOutboundType.maliciousFiles && email.info">
										<tr *ngIf="email.info.file_name">
											<th style="padding: 0; border: none; width: 150px;"></th>
											<th style="padding: 0; border: none;"></th>
										</tr>
										<tr *ngIf="email.info.file_name">
											<td><b>{{ 'quarantinedOutbound.fileName' | translate }}:</b></td>
											<td>
												<span style="word-break: break-word; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 8; -webkit-box-orient: vertical;">
													{{email.info.file_name}}
												</span>
											</td>
										</tr>
										<tr *ngIf="email.info.malware_type">
											<td><b>{{ 'quarantinedOutbound.fileMalwareType' | translate }}:</b></td>
											<td>
												<span style="word-break: break-word; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 8; -webkit-box-orient: vertical;">
													{{email.info.malware_type}}
												</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="email-content-card is-showing-content outbound-quarantined ro24 co14" style="position: relative; margin: 0.75vw; max-height: none !important;"
						 [ngStyle]="{'height' : gs.isMobile ? 'auto !important' : ''}"
						 *ngIf="email.allowReviewerShowContent && !(gs.isMobile && showThreats)">
						<div class="threat-protection-card flxClmn w100 h100" style="position: relative; z-index: 3; margin: 0;">
							<div class="card-header flxRow-between" style="align-items: center;">
								<span class="card-title">
									{{'quarantined.emailContent' | translate}}
								</span>
							</div>

							<div [hidden]="!email.htmlContent" [ngClass]="{'flxClmn' : email.htmlContent}"
								 class="input-wrap flxClmn vSpace05 t-bg-primary" style="flex: 1 0; overflow: auto; padding: 10px;"
								 [ngStyle]="gs.isMobile && {'padding' : '0'}">
								<div class="t-bg-primary" style="padding: 15px;">
									<table class="w100 simpleDataTableShrink t-color-primary" style="table-layout: fixed; font-size: 0.85rem;">
										<tr>
											<th style="padding: 0; max-height: 0; width: 65px;"></th>
											<th style="padding: 0; max-height: 0;"></th>
										</tr>
										<tr>
											<td><b>{{'quarantinedOutbound.from' | translate}}</b></td>
											<td>
												<span class="t-color-secondary">{{email.user_email}}</span>
											</td>
										</tr>
										<tr>
											<td><b>{{'quarantinedOutbound.to' | translate}}</b></td>
											<td class="flxRow" [ngClass]="email.contentInfo.recipients.to.length>1 && 'hSpace05'" style="flex-wrap: wrap;">
												<span class="t-color-secondary" *ngFor="let recipient of email.contentInfo.recipients.to; let last = last;">
													{{recipient.name && recipient.name !== recipient.address ? recipient.name + ' (' + recipient.address + ')' : recipient.address}}
													{{last ? '' : ','}}
												</span>
											</td>
										</tr>
										<tr *ngIf="email.contentInfo.recipients.cc.length">
											<td><b>{{'quarantinedOutbound.cc' | translate}}</b></td>
											<td class="flxRow" [ngClass]="email.contentInfo.recipients.cc.length>1 && 'hSpace05'" style="flex-wrap: wrap;">
												<span class="t-color-secondary" *ngFor="let recipient of email.contentInfo.recipients.cc; let last = last;">
													{{recipient.name && recipient.name !== recipient.address ? recipient.name + ' (' + recipient.address + ')' : recipient.address}}
													{{last ? '' : ','}}
												</span>
											</td>
										</tr>
										<tr *ngIf="email.contentInfo.recipients.bcc.length">
											<td><b>{{'quarantinedOutbound.bcc' | translate}}</b></td>
											<td class="flxRow " [ngClass]="email.contentInfo.recipients.bcc.length>1 && 'hSpace05'" style="flex-wrap: wrap;">
												<span class="t-color-secondary" *ngFor="let recipient of email.contentInfo.recipients.bcc; let last = last;">
													{{recipient.name && recipient.name !== recipient.address ? recipient.name + ' (' + recipient.address + ')' : recipient.address}}
													{{last ? '' : ','}}
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div class="email-view animated fadeIn t-bg-primary" style="flex: 1 0;"
									 [ngStyle]="step !== dic.STEP.Authenticated || showThreats ? {'visibility': 'hidden', 'flex' : '0 1', 'padding' : '0' , 'max-height' : '0'} : {'visibility': 'visible', 'flex' : '1 0' , 'max-height' : '100%'}">
									<div class="email" style="flex: 1 0;">
										<iframe [ngStyle]="step !== dic.STEP.Authenticated && {'height': '0'}" style="background-color: white;"
												class="email-iframe" id="email-content-frame" src="about:blank" ></iframe>
									</div>
								</div>
								<div *ngIf="email.contentInfo.sent.attachments?.length">
									<hr style="width: 80%; margin: 10px auto;"/>

									<div class="attachments-body-container">

										<div class="flxRow hSpace2" style="margin: 0;">
											<div class="flxRow" style="flex:1 0; flex-wrap: wrap; overflow: auto" [ngStyle]="{'max-height' : gs.isMobile ? 'none' : '66px'}">
												<attachment-c
													*ngFor="let file of email.contentInfo.sent.attachments"
													[attName]="file.name"
													[attSize]="file.size"
													[downloadProgress]="file.progressPercentage"
													[isPreviewLoading]="file.previewLoading"
													(preview)="setPreviewAttachment(file)"
													(download)="downloadFile(file, false, null)">
												</attachment-c>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!--"show content" button for large size-->
							<div class="flxClmn-center" style="align-items: center; flex: 1 0; background: radial-gradient(white, #f5f8ff);"
								 [ngClass]="getEmailContentInProcess && 'shinyFX'"
								 [ngStyle]="{'overflow' : getEmailContentInProcess ? 'hidden' : 'auto'}"
								 *ngIf="!email.htmlContent">
								<btn class="show-email-large-btn"
									 [loading]="getEmailContentInProcess"
									 (action)="getQuarantinedEmailContent();">
									{{'quarantined.showEmailContent' | translate}}
								</btn>
							</div>
						</div>
					</div>
					<div class="threat-protection-card flxClmn ro14 co10" style="height: 405px;" *ngIf="!(gs.isMobile && !showThreats)"
						 [ngClass]="{'co14' : !email.allowReviewerShowContent}">
						<div class="card-header flxRow-between" style="align-items: center;">
							<span class="card-title">
								{{'quarantinedOutbound.trackingDetails' | translate}}
							</span>
						</div>
						<div class="flxClmn" style="flex: 1 0; padding: 15px; overflow: auto; z-index: 5;">
							<div class="flxClmn message-tab-content vSpace4" *ngIf="trackingData">
								<div class="flxRow hSpace1 t-color-primary" style="align-items: center;">
									<span>{{'mailbox.tracking.viewTrackingFor' | translate }}:</span>
									<app-dropdown style="width: 250px;"
												  [text]="recipientTrack.name || 'Select'"
												  [enableSearch]="true"
												  itemDisplayKey="name"
												  [items]="trackingData"
												  (onItemClicked)="recipientTrack = $event.item"></app-dropdown>
								</div>

								<app-tracking class="outbound-quarantined-tracking-wrapper"
											  [recipientTrack]="recipientTrack"
											  [emailInfo]="email">
								</app-tracking>
							</div>
							<!--loader for tracking-->
							<div class="flxClmn-center" style="align-items: center; flex: 1 0;"
								 *ngIf="!trackingData">
								<div class="loading-spinner" style="border-color: var(--bs-primary); border-bottom-color: transparent;">
								</div>
							</div>
						</div>
					</div>
					<div class="threat-protection-card flxClmn ro15 co24 md-co24" style="min-height: 350px;" *ngIf="!gs.isMobile">
						<div class="card-header flxRow-between" style="align-items: center;">
                                    <span class="card-title">
                                        {{'quarantined.reviewersActivity' | translate}} {{email.reviewer_actions && email.reviewer_actions.length ? '(' + email.reviewer_actions.length + ')' : ''}}
                                    </span>
						</div>
						<div class="flxClmn" style="flex: 1 0; align-items: center; padding: 15px; overflow: auto;"
							 [ngStyle]="{'justify-content' : !email.reviewer_actions || !email.reviewer_actions.length ? 'center' : ''}">
							<table style="text-align: left; width:100%; table-layout: fixed;" class="simpleDataTable t-color-primary" *ngIf="email.reviewer_actions && email.reviewer_actions.length">
								<tr>
									<th style="width: 20%;">{{'quarantined.reviewersActivityCreated' | translate}}</th>
									<th style="width: 25%">{{'quarantined.reviewersActivityReviewer' | translate}}</th>
									<th style="width: 50%">{{'quarantined.reviewersActivityAction' | translate}}</th>
								</tr>
								<tr *ngFor="let actionObj of email.reviewer_actions; let i = index">
									<td>
										{{actionObj.created | date:'MM/dd/yyyy HH:mm'}}
									</td>
									<td style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
										{{actionObj.reviewer}}
									</td>
									<td style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
										<span style="display: block; width: fit-content; max-width: 100%;" [tooltip]="actionObj.action">{{actionObj.action}}</span>
									</td>
								</tr>
							</table>
							<span class="t-color-primary" style="opacity: 0.7;" *ngIf="!email.reviewer_actions || !email.reviewer_actions.length">
										{{'quarantined.reviewersActivityEmpty' | translate}}
									</span>
						</div>
					</div>
				</div>

				<!--actions details container-->
				<div class="flxClmn actions-details-container" style="flex: 1 0; z-index: 20;"
					 [ngClass]="{'active' : actionToConfirm}">
					<div class="shader" style="transition: all 0.2s;" [ngClass]="{'active': actionToConfirm}"></div>
					<div class="attachments-container t-color-primary flxRow-center" style="flex: 2 0; max-height: 400px; padding: 15px; max-width: 1000px;">
						<!--ACTION SECTION CONTENT: confirm action-->
						<div class="flxClmn-between h100"
							 style="flex: 1 0;" *ngIf="actionToConfirm"
							 [ngStyle]="!gs.isMobile && {'padding': '10px 20px'}">
							<div class="confirm-text">
								<div *ngIf="actionToConfirm === dic.CONSTANTS.quarantinedOutboundAction.remove">
									{{ 'quarantinedOutbound.actions.removeEmailConfirm' | translate }}
								</div>
								<div *ngIf="actionToConfirm === dic.CONSTANTS.quarantinedOutboundAction.release">
									{{ 'quarantinedOutbound.actions.releaseEmailConfirm' | translate }}
								</div>
								<div *ngIf="actionToConfirm === dic.CONSTANTS.quarantinedOutboundAction.handled">
									{{email.reviewed_completed ? ('quarantined.actions.setNotHandled' | translate) : ('quarantined.actions.setHandled' | translate)}}
								</div>
								<ul class="action-details vSpace2">
									<li *ngIf="actionToConfirm === dic.CONSTANTS.quarantinedOutboundAction.remove">{{ 'quarantinedOutbound.actions.outboundRemoveWarning' | translate }}</li>
									<li *ngIf="actionToConfirm === dic.CONSTANTS.quarantinedOutboundAction.release">{{ 'quarantinedOutbound.actions.outboundReleaseWarning' | translate }}</li>
									<li *ngIf="actionToConfirm === dic.CONSTANTS.quarantinedOutboundAction.release">{{ 'quarantinedOutbound.actions.outboundReleaseWarning2' | translate }}</li>
									<li *ngIf="actionToConfirm === dic.CONSTANTS.quarantinedOutboundAction.handled && !email.reviewed_completed">{{ 'quarantinedOutbound.actions.handledWarning' | translate }}</li>
									<li *ngIf="actionToConfirm === dic.CONSTANTS.quarantinedOutboundAction.handled && email.reviewed_completed">{{ 'quarantinedOutbound.actions.notHandledWarning' | translate }}</li>
								</ul>
								<div class="holder vSpace2" *ngIf="actionToConfirm === dic.CONSTANTS.quarantinedOutboundAction.release">
									<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center; margin: 5px 0;">
										<input id="encrypt_content" type="checkbox"
											   [(ngModel)]="encryptContent"/>
										<label for="encrypt_content">{{ 'quarantinedOutbound.actions.outboundEncryptContent' | translate }}</label>
									</div>
									<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center; margin: 5px 0;">
										<input id="require_auth" type="checkbox"
											   [(ngModel)]="secureSend"/>
										<label for="require_auth">{{ 'quarantinedOutbound.actions.outboundRequireAuth' | translate }}</label>
									</div>
									<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center; margin: 5px 0;" *ngIf="email.type === dic.CONSTANTS.quarantinedOutboundType.recipientFailure">
										<input id="sendWithTls" type="checkbox"
											   [(ngModel)]="sendWithTls"/>
										<label for="sendWithTls">{{ 'quarantinedOutbound.sendWithTls' | translate }}</label>
									</div>
									<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center; margin: 5px 0;">
										<input id="notifySender" type="checkbox"
											   [(ngModel)]="notifySender"/>
										<label for="notifySender">{{ 'quarantinedOutbound.notifySender' | translate }}</label>
									</div>
									<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center; margin: 5px 0;">
										<input id="reportToTrustifi" type="checkbox"
											   [(ngModel)]="reportToTrustifi"/>
										<label for="reportToTrustifi">{{ 'inboundShield.trust.reportToTrustifi' | translate }} ({{ 'inboundShield.trust.recommended' | translate }})</label>
									</div>
								</div>
							</div>
							<div class="flxRow-center hSpace1">
								<btn class="large-btn" id="cancelEmailActionBtn" variant="outlined"
									 [isDisabled]="disableActionBtn"
									 (action)="actionToConfirm = '';">
									{{ 'general.cancel' | translate }}
								</btn>
								<btn class="large-btn" id="confirmEmailActionBtn"
									 [loading]="disableActionBtn"
									 (action)="doActionOnQuarantinedEmail(actionToConfirm);">
									{{ 'general.confirm' | translate }}
								</btn>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="reply-outer-container flxClmn reviewer-actions-window" style="z-index: 500;" *ngIf="gs.isMobile"
			 [ngClass]="{'active' : showLogs}">
			<div class="reply-inner-container active h100 flxClmn vSpace2"
				 *ngIf="showLogs">
				<div class="flxRow-between title-row t-color-primary" style="align-items: center;">
					<h4 style="margin: 0">{{'quarantined.reviewersActivity' | translate}}</h4>
					<a (click)="showLogs = false;">
						<i [ngClass]="gs.isMobile ? 'fa fa-arrow-right' : 'fa fa-times'" style="font-size: 1.1rem;"></i>
					</a>
				</div>
				<div class="flxClmn table-outer-container" style="flex: 1 0; overflow: auto;">
					<div class="flxClmn table-inner-container" [ngClass]="email.reviewer_actions && email.reviewer_actions.length && 'not-empty'"
						 style="flex: 1 0; overflow: auto;">
						<table class="w100 simpleDataTable t-color-primary sticky-header ellips alternated-grey reviewer-actions-table"
							   *ngIf="email.reviewer_actions && email.reviewer_actions.length" cellspacing="0">
							<thead><tr>
								<th style="width:160px; padding: 10px;"
									[ngStyle]="gs.isMobile && {'width' : '125px'}">
									{{'quarantined.reviewersActivityCreated' | translate}}
									<a *ngIf="sortCreated === 'created'" style="margin-top:1px;padding-left:5px;" [tooltip]="'Sort'" show-delay="200"
									   (click)="sortCreated = '-created'; sortReviewerActionsBy = '-created';">
										<i class="zmdi zmdi-chevron-up" style="width:10px;"></i>
									</a>
									<a *ngIf="sortCreated === '-created'" style="margin-top:1px;padding-left:5px;" [tooltip]="'Sort'"
									   (click)="sortCreated = 'created'; sortReviewerActionsBy = 'created';">
										<i class="zmdi zmdi-chevron-down" style="width:13px;"></i>
									</a>
								</th>
								<th style="width:35%;">
									{{'quarantined.reviewersActivityReviewer' | translate}}
									<a *ngIf="sortReviewer === 'reviewer'" style="margin-top:1px;padding-left:5px;" [tooltip]="'Sort'" show-delay="200"
									   (click)="sortReviewer = '-reviewer'; sortReviewerActionsBy = '-reviewer';">
										<i class="zmdi zmdi-chevron-up" style="width:10px;"></i>
									</a>
									<a *ngIf="sortReviewer === '-reviewer'" style="margin-top:1px;padding-left:5px;" [tooltip]="'Sort'"
									   (click)="sortReviewer = 'reviewer'; sortReviewerActionsBy = 'reviewer';">
										<i class="zmdi zmdi-chevron-down" style="width:13px;"></i>
									</a>
								</th>
								<th style="width:65%;">
									{{'quarantined.reviewersActivityAction' | translate}}
									<a *ngIf="sortAction === 'action'" style="margin-top:1px;padding-left:5px;" [tooltip]="'Sort'" show-delay="200"
									   (click)="sortAction = '-action'; sortReviewerActionsBy = '-action';">
										<i class="zmdi zmdi-chevron-up" style="width:10px;"></i>
									</a>
									<a *ngIf="sortAction === '-action'" style="margin-top:1px;padding-left:5px;" [tooltip]="'Sort'"
									   (click)="sortAction = 'action'; sortReviewerActionsBy = 'action';">
										<i class="zmdi zmdi-chevron-down" style="width:13px;"></i>
									</a>
								</th>
							</tr></thead>
							<tbody>
							<tr *ngFor="let actionObj of email.reviewer_actions | orderBy: sortReviewerActionsBy">
								<td style="padding: 10px">
											<span>
												{{actionObj.created | date:'MM/dd/yyyy HH:mm'}}
											</span>
								</td>
								<td>
									<span>{{actionObj.reviewer}}</span>
								</td>
								<td>
									<span>{{actionObj.action}}</span>
								</td>
							</tr>
							</tbody>
						</table>
						<div class="flxClmn-center" style="align-items: center; flex: 1 0;"
							 *ngIf="!email.reviewer_actions || !email.reviewer_actions.length">
							<span class="t-color-primary" style="opacity: 0.7;">{{'quarantined.reviewersActivityEmpty' | translate}}</span>
						</div>
					</div>
				</div>
				<div class="flxRow-center" *ngIf="!gs.isMobile">
					<btn (action)="showLogs = false;">
						{{ 'general.back' | translate }}
					</btn>
				</div>
			</div>
		</div>
	</div>

	<attachment-preview
		*ngIf="previewAttachment"
		[attachment]="previewAttachment"
		(onClose)="previewAttachment = null;">
	</attachment-preview>

</div>
