import {Component, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {dic} from '../../dictionary';
import {RouteService} from '../../services/route.service';
import {NotificationService} from '../../services/notification.service';
import {GeneralService} from '../../services/general.service';
import {TranslationService} from '../../services/translation.service';
import * as _ from 'lodash';
import {DownloadFilesService} from '../../services/download-files.service';
import {Buffer} from 'buffer';
import {TooltipDirective} from 'ng2-tooltip-directive';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import {EncryptAnimationComponent} from '../encrypt-animation/encrypt-animation.component';
import {Subject} from 'rxjs';


@Component({
	selector: 'app-quarantined-email',
	templateUrl: './quarantined-email.component.html',
	styleUrls: ['./quarantined-email.component.scss']
})
export class QuarantinedEmailComponent implements OnInit {

	@ViewChild(EncryptAnimationComponent) encryptAnimationComponent: EncryptAnimationComponent;
	@ViewChildren(TooltipDirective) tooltipDirective;

	dic = dic;
	step: string;
	stepSubject: Subject<string> = new Subject<string>();
	stepDataSubject: Subject<object> = new Subject<object>();
	contentSubject: Subject<string> = new Subject<string>();

	senderAuthMethod = this.dic.CONSTANTS.AUTH_METHOD.phone;
	showThreats = true;
	userHash: string;
	disableActionBtn = false;
	actionInProcess = false;
	logoData: any;
	activeTrackingUrl;
	threatProtection: any = {
		headers: {},
		url: {},
		file: {
			status: '',
			list: []
		}
	};
	email: any = {
		htmlContent: '',
		attachments: [],
		methods: [],
		quarantined_status: '',
		statusText: '',
		source: '',
		sender: '',
		subject: '',
		senderMethods: [],
		malicious: {},
		isRecipient: false,
		allowReviewerShowContent: false,
		reviewed_completed: false
	};

	private encryptedContent: boolean;
	encryptedContentSubject: Subject<boolean> = new Subject<boolean>();
	attachmentDownloadConfirm: any;
	actionToConfirm = '';
	isMobile: boolean;
	showLogs: boolean;
	previewAttachment;
	isIE: boolean;
	showLoader = false;
	isEmailSelected: boolean;
	senderAuthPhone = {country_code: '+1', phone_number: ''};
	addWarningLabel = false;
	releaseEmailToAllMailboxes = false;
	addSubjectText = false;
	addContactToAdmin = false;
	reportToTrustifi = true;
	senderReason = '';
	reportReason = '';
	isFullScreen = false;
	loadingEmail = true;
	reviewerActionsTarget = this.dic.CONSTANTS.quarantinedActionsTarget.email;
	sortCreated = '-created';
	sortReviewer = '-reviewer';
	sortAction = '-action';
	sortReviewerActionsBy = '-created';
	showHtml = false;
	applyToDropdown = false;
	whiteBlockListApplyToOptions = {
		globalList: 'globalList',
		personalList: 'personalList'
	}
	whiteBlockListApplyTo = this.whiteBlockListApplyToOptions.globalList;

	invalidPhoneNumberTooltipOptions = {
		'placement': 'top',
		'theme': 'light',
		'tooltip-class': 'errorTooltip',
		'show-delay': 0,
		'trigger': 'click',
		'display': false,
		'display-mobile': false
	};

	constructor(private rs: RouteService,
				private ns: NotificationService,
				private downloadFilesService: DownloadFilesService,
				private translateService: TranslationService,
				private notificationService: NotificationService,
				private gs: GeneralService) {
		this.logoData = gs.logoData;
	}

	ngOnInit() {
		this.showLogs = false;
		this.isMobile = this.gs.detectMobile() && ((window.innerWidth > 0 ? window.innerWidth < 480 : screen.width < 480) || (window.innerHeight > 0 ? window.innerHeight < 480 : screen.height < 480));
		this.isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
		this.step = dic.STEP.Authenticating;
		this.stepSubject.next(this.step);
		this.getMessage();
		this.rs.getLfSettings().then(res => {
			this.gs.initLogoData(res);
			this.gs.setMainLogo();
		}, error => {
			if (error.data && error.data.status) {
				this.ns.showMessage(error.data);
			}
		});
	}

	finishLoadingEmail() {
		setTimeout(() => {
			this.loadingEmail = false;
		}, 800); // wait a bit so loading animation will not be cleared to fast
	}

	setAccessEnc() {
		const urlSplitted = location.href.split('quarantined/');
		if (urlSplitted.length < 2) { return; }
		this.userHash = urlSplitted[1];
		const defaultHeaders = {};
		defaultHeaders[this.dic.HEADERS.xAccessEnc] = this.userHash;
		defaultHeaders[this.dic.HEADERS.xTrustifiSource] = 'miniapp';

		const localStorageFp = localStorage[this.dic.CONSTANTS.quarantinedFp];
		if (localStorageFp) {
			try {
				defaultHeaders[this.dic.HEADERS.x2FaFingerprint] = JSON.parse(localStorageFp);
			} catch (ex) {
				console.log(ex);
			}
		}

		this.rs.setDefaultHeaders(defaultHeaders);
	}

	doActionOnQuarantinedEmail(type) {
		if (this.disableActionBtn) {
			return;
		}

		if (type === dic.CONSTANTS.quarantinedActions.reportToTrustifiConfirm) {
			this.actionToConfirm = '';
			return;
		}

		this.disableActionBtn = true;
		this.actionInProcess = true;

		const actionData: any = {type: type, email: this.email.sender};

		switch (type) {
			case dic.CONSTANTS.quarantinedActions.linksWhitelist:
				if (this.email.malicious && this.email.malicious.tpResults && this.email.malicious.tpResults.resUrl &&
					this.email.malicious.tpResults.resUrl.list && this.email.malicious.tpResults.resUrl.list.length) {
					actionData.links = [];
					this.email.malicious.tpResults.resUrl.list.forEach((linkObj) => {
						if (linkObj.status !== "Safe") {
							actionData.links.push({link: linkObj.url});
						}
					});
					actionData.action = 'Add';
				}
				break;

			case dic.CONSTANTS.quarantinedActions.authSender:
				actionData.authSenderMethod = this.senderAuthMethod;
				actionData.notificationTxt = actionData.authSenderMethod;
				if (actionData.authSenderMethod === this.dic.CONSTANTS.AUTH_METHOD.phone) {
					actionData.phone = this.senderAuthPhone;
					actionData.notificationTxt = 'SMS';
				}
				break;

			case dic.CONSTANTS.quarantinedActions.release:
				actionData.skipWarningLabel = !this.addWarningLabel;
				actionData.addSubjectText = this.addSubjectText;
				actionData.addContactToAdmin = this.addContactToAdmin;
				if (!this.email.isRecipient) {
					actionData.releaseEmailToAllMailboxes = this.releaseEmailToAllMailboxes;
				}
				break;

			case dic.CONSTANTS.quarantinedActions.handled:
				actionData.enabled = !this.email.reviewed_completed;
				break;

			case dic.CONSTANTS.quarantinedActions.eml:
				actionData.action = dic.CONSTANTS.quarantinedActions.eml; // 'Download EML'
				break;

			case dic.CONSTANTS.quarantinedActions.requestedReleaseReject:
				actionData.reason = this.reportReason;
				break;
		}


		if (type === dic.CONSTANTS.quarantinedActions.whitelist || type === dic.CONSTANTS.quarantinedActions.blacklist ||
			type === dic.CONSTANTS.quarantinedActions.release || type === dic.CONSTANTS.quarantinedActions.remove) {
			actionData.action = 'Add';
			actionData.reportToTrustifi = this.reportToTrustifi;
			actionData.description = this.senderReason;
			actionData.reportReason = this.reportReason;

			if (type === dic.CONSTANTS.quarantinedActions.whitelist || type === dic.CONSTANTS.quarantinedActions.blacklist) {
				actionData.isPersonalList = this.whiteBlockListApplyTo === this.whiteBlockListApplyToOptions.personalList;
			}
		}

		this.rs.doActionOnQuarantinedEmail(actionData).then((response) => {
			switch (type) {
				case dic.CONSTANTS.quarantinedActions.remove:
					_.remove(this.email.methods, m => m === dic.CONSTANTS.quarantinedActions.remove);
					this.email.methods.push(dic.CONSTANTS.quarantinedActions.release);
					this.email.quarantined_status = dic.CONSTANTS.quarantinedActions.remove;
					break;

				case dic.CONSTANTS.quarantinedActions.release:
					_.remove(this.email.methods, m => m === dic.CONSTANTS.quarantinedActions.release);
					this.email.methods.push(dic.CONSTANTS.quarantinedActions.remove);
					this.email.quarantined_status = dic.CONSTANTS.quarantinedActions.release;
					break;

				case dic.CONSTANTS.quarantinedActions.requestedReleaseReject:
					_.remove(this.email.methods, m => m === dic.CONSTANTS.quarantinedActions.requestedReleaseReject);
					this.email.quarantined_status = dic.CONSTANTS.quarantinedActions.quarantined;
					break;

				case dic.CONSTANTS.quarantinedActions.requestedRelease:
					_.remove(this.email.methods, m => m === dic.CONSTANTS.quarantinedActions.requestedRelease);
					break;

				case dic.CONSTANTS.quarantinedActions.linksWhitelist:
					_.remove(this.email.methods, m => m === dic.CONSTANTS.quarantinedActions.linksWhitelist);
					break;

				case dic.CONSTANTS.quarantinedActions.signature:
					_.remove(this.email.methods, m => m === dic.CONSTANTS.quarantinedActions.signature);
					break;

				case dic.CONSTANTS.quarantinedActions.allowFileType:
					_.remove(this.email.methods, m => m === dic.CONSTANTS.quarantinedActions.allowFileType);
					break;

				case dic.CONSTANTS.quarantinedActions.blacklist:
					_.remove(this.email.senderMethods, m => m === dic.CONSTANTS.quarantinedActions.blacklist);
					this.email.senderMethods.push(dic.CONSTANTS.quarantinedActions.whitelist);
					break;

				case dic.CONSTANTS.quarantinedActions.whitelist:
					_.remove(this.email.senderMethods, m => m === dic.CONSTANTS.quarantinedActions.whitelist);
					this.email.senderMethods.push(dic.CONSTANTS.quarantinedActions.blacklist);
					break;

				case dic.CONSTANTS.quarantinedActions.authSender:
					_.remove(this.email.senderMethods, m => m === dic.CONSTANTS.quarantinedActions.authSender);
					break;

				case dic.CONSTANTS.quarantinedActions.eml:
					const attachment = {
						content: response.message,
						name: `${this.email.subject}.eml`,
						contentType: 'message/rfc822'
					};
					this.downloadFilesService.download(attachment.content, attachment.name, attachment.contentType);
					break;
			}

			if (this.email.reviewer_actions && response.reviewerActivity) {
				this.email.reviewer_actions.unshift({created: Date.now(), reviewer: this.email.reviewer, action: response.reviewerActivity});
			}

			this.parseEmailStatus();
			this.actionInProcess = false;
			this.actionToConfirm = '';
			this.disableActionBtn = false;
			if (type === dic.CONSTANTS.quarantinedActions.handled) {
				this.email.reviewed_completed = !this.email.reviewed_completed;
			} else {
				this.email.reviewed_completed = true;
			}
			this.reportReason = '';
			if (type === dic.CONSTANTS.quarantinedActions.authSender) {
				this.ns.showInfoMessage(dic.MESSAGES.quarantinedAuthSender);
			} else {
				this.ns.showInfoMessage(dic.MESSAGES.operationSucceeded);
			}

			if (actionData.reportToTrustifi) {
				this.actionToConfirm = dic.CONSTANTS.quarantinedActions.reportToTrustifiConfirm;
			}

		}, error => {
			this.disableActionBtn = false;
			this.actionInProcess = false;
			if (error.data) {
				this.ns.showMessage(error.data);
			}
		});
	}

	setInboundStatusStyles(status: any) {
		switch (status) {
			case dic.CONSTANTS.threatProtection.status.malicious:
				return {color: 'darkred', icon: 'fa-bug', loading: false, displayColor: 'var(--danger)'};
			case dic.CONSTANTS.threatProtection.status.suspicious:
				return {color: 'darkred', icon: 'fa-bug', loading: false, displayColor: 'var(--danger)'};
			case dic.CONSTANTS.threatProtection.status.error:
				return {color: 'darkred', icon: 'fa-exclamation-circle', loading: false, displayColor: 'var(--danger)'};
			case dic.CONSTANTS.threatProtection.status.spam:
				return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false, displayColor: 'var(--warning)'};
			case dic.CONSTANTS.threatProtection.status.grayMail:
				return {color: 'dimgray', icon: 'fa-exclamation-circle', loading: false, displayColor: 'var(--gray2)'};
			case dic.CONSTANTS.threatProtection.status.unsecured:
				return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false, displayColor: 'var(--warning)'};
			case dic.CONSTANTS.threatProtection.status.pending:
			case dic.CONSTANTS.threatProtection.status.analyzing:
			case dic.CONSTANTS.threatProtection.status.analyzingLinks:
				return {color: 'darkorange', icon: 'fa-clock', loading: true, displayColor: 'var(--warning)'};
			case dic.CONSTANTS.threatProtection.status.safe:
				return {color: 'darkgreen', icon: 'fa-check-circle', loading: false, displayColor: 'var(--success)'};
			case 'External':
				return {color: 'darkorange', icon: 'fa-external-link-alt', loading: false, displayColor: 'var(--warning)'};
			default:
				return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false, displayColor: 'var(--warning)'};
		}
	}

	downloadFile(attachment: any) {
		const isFileMalicious = this.threatProtection.file && this.threatProtection.file.list &&
			this.threatProtection.file.list.filter(item => item.name === attachment.name && item.status === 'Malicious');
		if (isFileMalicious && isFileMalicious.length) {
			if (this.email.isRecipient) {
				this.ns.showErrorMessage(dic.ERRORS.maliciousFileDownload);
				return;
			}
			this.attachmentDownloadConfirm = attachment;
			this.actionToConfirm = '';
		} else {
			this.downloadFileExecute(attachment);
		}
	}

	downloadFileExecute(attachment: any) {
		if (!attachment) {
			return;
		}

		const attachmentBuffer = Buffer.from(attachment.content, 'base64');

		if (attachmentBuffer) {
			const blob = new Blob([attachmentBuffer], {type: attachment.contentType});
			if (this.gs.detectMobile() && this.gs.isSupportedMimeType(attachment.contentType)) {
				if (this.gs.isChrome() && navigator.userAgent.match(/iPhone/i)) {
					const reader: any = new FileReader();
					reader.onload = function (e) {
						window.location.href = reader.result;
					};
					reader.readAsDataURL(blob);
				} else {
					location.href = URL.createObjectURL(blob);
				}
			} else {
				// general download
				this.downloadFilesService.download(attachmentBuffer, attachment.name, attachment.contentType);
			}
		} else {
			return; // Add Notification & Errors - Before too
		}
		this.attachmentDownloadConfirm = false;
	}

	downloadAllFiles() {
		if (this.email.content.attachments && this.email.content.attachments.length === 1) {
			this.downloadFile(this.email.content.attachments[0]);
		} else {
			if (this.threatProtection.file.status === 'Malicious') {
				this.attachmentDownloadConfirm = 'all';
			} else {
				this.downloadAllFilesExecute();
			}
		}
	}

	downloadAllFilesExecute() {
		const zip = new JSZip();
		let count = 0;
		const zipFilename = `attachments_${this.email.subject.substring(0, 50)}.zip`;
		let buffer;
		this.email.content.attachments.forEach((attachment) => {
			buffer = Buffer.from(attachment.content, 'base64');
			zip.file(attachment.name, this.gs.bufferToArrayBuffer(buffer), {binary: true});
			count++;
			if (count === this.email.content.attachments.length) {
				zip.generateAsync({type: 'blob'}).then((content) => {
					saveAs(content, zipFilename);
				});
			}
		});
		this.attachmentDownloadConfirm = false;
	}

	showEmailContent() {
		if (!this.showThreats) {
			return;
		}

		this.showThreats = false;
		this.email.isShowingContent = true;

		if (this.email.content && this.email.content.html) {
			setTimeout(() => {
				this.encryptAnimationComponent.updateIFrameContent(this.email.content.html);
			});
		}
		else {
			this.showLoader = true;
			this.rs.getQuarantinedEmailContent().then(emailInfo => {

				this.email.content = emailInfo.content;

				if (this.email.status === dic.STEP.Authenticated) {
					this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.DECRYPTED);
					this.parseEmailContent();
				}
				else {
					this.encryptedContent = true;
					this.encryptedContentSubject.next(this.encryptedContent);
				}

				setTimeout(() => {
					this.encryptAnimationComponent.updateIFrameContent();
					this.showLoader = false;
				});
			}, error => {
				this.showLoader = false;
				this.ns.showErrorMessage(error.data.message);
			});
		}
	}

	updateEncryptedContent(encryptedContent) {
		this.encryptedContent = encryptedContent;
	}

	confirmSuccessCb(response) {
		this.step = this.dic.STEP.Authenticated;
		this.stepSubject.next(this.step);
		this.setStepData(response);
	}

	confirmFailureCb = (response) => {
		if (response) {
			this.setStepData(response);
		}
	}

	applyAnimationCb() {
		if (this.encryptedContent) {
			this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.PROCESS);
		}
	}

	private getMessage() {
		this.setAccessEnc();
		this.rs.getQuarantinedEmail().then(emailInfo => {
			if (emailInfo.status !== dic.STEP.Authenticated) {
				this.encryptedContent = true;
				this.encryptedContentSubject.next(this.encryptedContent);
			}

			this.setStepData(emailInfo);
		}, error => {
			this.setStepData(error);
		});
	}

	private setStepData(stepData) {
		this.step = dic.STEP[stepData.status] || dic.STEP.Blocked;
		this.stepSubject.next(this.step);
		this.stepDataSubject.next(stepData);

		switch (stepData.status) {
			case dic.STEP.Blocked:
				break;

			case dic.STEP.Authenticated:
				this.email = stepData;
				this.email.category = this.email.malicious.status_user_reported || this.email.malicious.status;

				const tpResults = stepData.malicious?.tpResults || null;
				if (tpResults) {
					this.threatProtection.recipient = tpResults.email;
					this.threatProtection.headerStatus = tpResults.headerStatus;
					this.threatProtection.urlStatus = tpResults.urlStatus;
					this.threatProtection.hashStatus = tpResults.hashStatus;


					if (!this.email.reviewer_actions) {
						this.email.reviewer_actions = [];
					}
					this.email.reviewer_actions.unshift({created: Date.now(), reviewer: this.email.reviewer, action: 'Reviewed threat analysis'});
					this.email.reviewed_completed = true;
				}
				if (tpResults?.resHead) {
					this.threatProtection.headers = stepData.malicious.tpResults.resHead;
					this.threatProtection.AiRates = {
						threat_body_conf: stepData.malicious.tpResults.resHead.AIresults.threat_body_conf ? stepData.malicious.tpResults.resHead.AIresults.threat_body_conf > 1 ? 100 : (stepData.malicious.tpResults.resHead.AIresults.threat_body_conf * 100) : 0,
						spam_body_conf: stepData.malicious.tpResults.resHead.AIresults.spam_body_conf ? stepData.malicious.tpResults.resHead.AIresults.spam_body_conf > 1 ? 100 : (stepData.malicious.tpResults.resHead.AIresults.spam_body_conf * 100) : 0,
						graymail_body_conf: stepData.malicious.tpResults.resHead.AIresults.graymail_body_conf ? stepData.malicious.tpResults.resHead.AIresults.graymail_body_conf > 1 ? 100 : (stepData.malicious.tpResults.resHead.AIresults.graymail_body_conf * 100) : 0
					};
				}
				if (tpResults?.resUrl) {
					this.threatProtection.url = stepData.malicious.tpResults.resUrl;
				}
				if (tpResults?.resHash) {
					this.threatProtection.file = stepData.malicious.tpResults.resHash;
				}
				if (this.threatProtection.url && this.threatProtection.url.list && this.threatProtection.url.list.length) {
					this.threatProtection.url.list = _.sortBy(this.threatProtection.url.list, url => {
						return dic.CONSTANTS.statusPriority[url.status];
					});
					this.threatProtection.url.list = this.gs.filterDuplicateSafeLinks(this.threatProtection.url.list);
				} else {
					this.threatProtection.url.status = dic.CONSTANTS.threatProtection.status.safe;
					if (!this.threatProtection.url.message) {
						this.threatProtection.url.message = 'No malicious links detected.';
					}
				}
				if (this.threatProtection.file && this.threatProtection.file.list && this.threatProtection.file.list.length) {
					this.threatProtection.file.list = _.sortBy(this.threatProtection.file.list, file => {
						return dic.CONSTANTS.statusPriority[file.status];
					});
				} else {
					this.threatProtection.file.status = dic.CONSTANTS.threatProtection.status.safe;
					if (!this.threatProtection.file.message) {
						this.threatProtection.file.message = 'No malicious attachments detected.';
					}
				}

				this.parseEmailStatus();

				this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.DECRYPTED);

				if (this.email.isRecipient) {
					this.showEmailContent();
				}
		}

		this.finishLoadingEmail();
	}

	defangeUrlFromContent(content, urls, isHtml) {
		const maliciousLinks = _.filter(urls, (url) => {
			return url.status === this.dic.CONSTANTS.threatProtection.status.malicious;
		}).map((maliciousUrl) => {
			return maliciousUrl.original_url || maliciousUrl.url;
		});

		if (maliciousLinks.length) {
			try {
				return isHtml ? this.gs.defangeMaliciousLinksFromHtml(content, maliciousLinks) : this.gs.defangeMaliciousLinksFromText(content, maliciousLinks);
			}
			catch (error) {
				return content;
			}
		}
	}

	private parseEmailStatus() {
		switch (this.email.quarantined_status) {
			case this.dic.CONSTANTS.quarantinedActions.quarantined:
				this.email.statusText = 'Email is quarantined';
				break;

			case this.dic.CONSTANTS.quarantinedActions.release:
				this.email.statusText = 'Email was released';
				break;

			case this.dic.CONSTANTS.quarantinedActions.remove:
				this.email.statusText = 'Email was removed';
				break;

			case this.dic.CONSTANTS.quarantinedActions.pending:
				this.email.statusText = 'Email is pending release';
				break;
		}
	}

	private parseEmailContent() {
		try {
			if (this.email.content) {
				if (this.email.client_defang_malicious_url && this.email.malicious?.tpResults?.resUrl?.status === dic.CONSTANTS.threatProtection.status.malicious) {
					if (this.email.content.html) {
						this.email.content.html = this.defangeUrlFromContent(this.email.content.html, this.email.malicious?.tpResults?.resUrl.list, true);
					} else {
						this.email.content.text = this.defangeUrlFromContent(this.email.content.text, this.email.malicious?.tpResults?.resUrl.list, false);
					}
				}

				let emailInfo = '';
				if (this.email.content.from?.text) {
					this.email.content.from.text = this.email.content.from.text.replaceAll('<', '('); // problem to display <> in HTML
					this.email.content.from.text = this.email.content.from.text.replaceAll('>', ')');
					emailInfo += `<b>${this.translateService.getTranslationText('quarantinedOutbound.from')}</b> ${this.email.content.from.text}`;
				}
				if (this.email.content.to?.text) {
					this.email.content.to.text = this.email.content.to.text.replaceAll('<', '('); // problem to display <> in HTML
					this.email.content.to.text = this.email.content.to.text.replaceAll('>', ')');
					emailInfo += `<br><b>${this.translateService.getTranslationText('quarantinedOutbound.to')}</b> ${this.email.content.to.text}`;
				}
				if (this.email.content.cc?.text) {
					this.email.content.cc.text = this.email.content.cc.text.replaceAll('<', '('); // problem to display <> in HTML
					this.email.content.cc.text = this.email.content.cc.text.replaceAll('>', ')');
					emailInfo += `<br><b>${this.translateService.getTranslationText('quarantinedOutbound.cc')}</b> ${this.email.content.cc.text}`;
				}
				emailInfo += `<br><b>${this.translateService.getTranslationText('quarantinedOutbound.subject')}</b> ${this.email.subject}<br><br>`;

				emailInfo = emailInfo + (this.email.content.html || this.email.content.text);
				this.contentSubject.next(emailInfo);
			}

			this.showThreats = false;
		} catch (err) {
			console.error(err);
		}
	}

	getHeaders() {
		if (this.email.headers) {
			return;
		}
		this.email.loadingHeaders = true;

		this.rs.getQuarantinedEmailHeaders().then((data) => {
			this.email.headers = data.headers;
			this.email.loadingHeaders = false;
		}, (err) => {
			this.email.loadingHeaders = false;
		});
	}

	exportHeadersText() {
		if (!this.email.headers) {
			this.notificationService.showWarnMessage(dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = '';
		this.email.headers.forEach((header) => {
			csvString += `${header.name || header.key}: ${header.value}\n`;
		});

		this.gs.exportCsv(csvString, `quarantined_email_headers.txt`);
	}

	downloadEml(email) {
		if (!email) {
			return;
		}

		const type = dic.CONSTANTS.quarantinedActions.eml;
		this.doActionOnQuarantinedEmail(type);
	}

	openLocation(ll) {
		const link = 'https://www.google.com/maps/search/?api=1&query=' + ll[0] + ',' + ll[1];
		window.open(link, '_blank');
	};
}
