import {Component, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {dic} from '../../dictionary';
import {OUTBOUND_QUARANTINED_URL, ATTACHMENT_URL, WA_URL} from '../../constants';
import {RouteService} from '../../services/route.service';
import {NotificationService} from '../../services/notification.service';
import {GeneralService} from '../../services/general.service';
import * as _ from 'lodash';
import {TooltipDirective} from 'ng2-tooltip-directive';
import {EncryptAnimationComponent} from '../encrypt-animation/encrypt-animation.component';
import {DownloadFilesService} from '../../services/download-files.service';
import {Subject} from 'rxjs';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';

@Component({
  selector: 'app-outbound-quarantined',
  templateUrl: './outbound-quarantined.component.html',
  styleUrls: ['./outbound-quarantined.component.scss']
})


export class OutboundQuarantinedComponent implements OnInit {

	@ViewChild(EncryptAnimationComponent) encryptAnimationComponent: EncryptAnimationComponent;
	@ViewChildren(TooltipDirective) tooltipDirective;

	dic = dic;
	step: string;
	stepSubject: Subject<string> = new Subject<string>();
	stepDataSubject: Subject<object> = new Subject<object>();
	contentSubject: Subject<string> = new Subject<string>();

	previewAttachment;

	userHash: string;
	showThreats = false;
	disableActionBtn = false;
	encryptContent = false;
	secureSend = false;
	reportToTrustifi = false;
	sendWithTls = true;
	notifySender = false;

	email: any = {
		htmlContent: '',
		attachments: [],
		methods: [],
		quarantined_status: '',
		source: '',
		sender: '',
		subject: '',
		reviewer: '',
		reviewed_completed: false,
		allowReviewerShowContent: false
	};

	encryptedContent: boolean;
	encryptedContentSubject: Subject<boolean> = new Subject<boolean>();
	blockNote: string;
	actionToConfirm = '';
	adminPanelUrl = WA_URL + '/#/index/outbound/adminPolicyManagement';
	isFullScreen = false;
	trackingData;
	recipientTrack;
	showLogs = false;
	sortCreated = '-created';
	sortReviewer = '-reviewer';
	sortAction = '-action';
	sortReviewerActionsBy = '-created';
	getEmailContentInProcess = false;

	constructor(private rs: RouteService,
				private ns: NotificationService,
				private downloadFilesService: DownloadFilesService,
				private http: HttpClient,
				public gs: GeneralService) {
	}

	ngOnInit() {
		this.showThreats = !!this.gs.isMobile;
		this.step = dic.STEP.Authenticating;
		this.stepSubject.next(this.step);
		this.getMessage();
	}

	setAccessEnc() {
		const urlSplitted = location.href.split('outbound/');
		if (urlSplitted.length < 2) { return; }
		this.userHash = urlSplitted[1];
		const defaultHeaders = {};
		defaultHeaders[this.dic.HEADERS.xAccessEnc] = this.userHash;
		defaultHeaders[this.dic.HEADERS.xTrustifiSource] = 'miniapp';

		const localStorageFp = localStorage[this.dic.CONSTANTS.quarantinedFp];
		if (localStorageFp) {
			try {
				defaultHeaders[this.dic.HEADERS.x2FaFingerprint] = JSON.parse(localStorageFp);
			} catch (ex) {
				console.log(ex);
			}
		}

		this.rs.setDefaultHeaders(defaultHeaders);
	}

	doActionOnQuarantinedEmail(action) {
		if (this.disableActionBtn) {
			return;
		}
		this.disableActionBtn = true;
		const actionData: any = {action: action};
		if (action === dic.CONSTANTS.quarantinedOutboundAction.handled) {
			actionData.enabled = !this.email.reviewed_completed;
		}

		if (action === dic.CONSTANTS.quarantinedOutboundAction.release) {
			actionData.options = {
				methods: {
					encryptContent: this.encryptContent,
					secureSend: this.secureSend
				},
				sendWithTls: this.sendWithTls,
				notifySender: this.notifySender,
				reportToTrustifi: this.reportToTrustifi
			};
		}

		this.rs.doActionOnQuarantinedOutboundEmail(actionData).then((response) => {
			switch (action) {
				case dic.CONSTANTS.quarantinedOutboundAction.handled:
					this.email.reviewed_completed = !this.email.reviewed_completed;
					break;

				case dic.CONSTANTS.quarantinedOutboundAction.release:
					_.remove(this.email.methods, m => m === dic.CONSTANTS.quarantinedOutboundAction.release);
					_.remove(this.email.methods, m => m === dic.CONSTANTS.quarantinedOutboundAction.remove);
					this.email.quarantined_status = dic.CONSTANTS.quarantinedOutboundStatus.released;
					this.setEmailResult(this.email);
					this.email.reviewed_completed = true;
					break;

				case dic.CONSTANTS.quarantinedOutboundAction.remove:
					_.remove(this.email.methods, m => m === dic.CONSTANTS.quarantinedOutboundAction.release);
					_.remove(this.email.methods, m => m === dic.CONSTANTS.quarantinedOutboundAction.remove);
					this.email.quarantined_status = dic.CONSTANTS.quarantinedOutboundStatus.removed;
					this.setEmailResult(this.email);
					this.email.reviewed_completed = true;
			}

			if (this.email.reviewer_actions && response.log) {
				this.email.reviewer_actions.unshift({created: Date.now(), reviewer: this.email.reviewer, action: response.log});
			}

			this.ns.showInfoMessage(dic.MESSAGES.operationSucceeded);

			this.actionToConfirm = '';
			this.disableActionBtn = false;

		}, error => {
			this.disableActionBtn = false;
			if (error.data) {
				this.ns.showMessage(error.data);
			}
		});
	}

	showEmailContent() {
		if (!this.showThreats) {
			return;
		}

		this.showThreats = false;

		if (this.email.htmlContent) {
			setTimeout(() => {
				this.encryptAnimationComponent.updateIFrameContent(this.email.htmlContent);
			});
		}
		else {
			this.getQuarantinedEmailContent();
		}
	}

	getQuarantinedEmailContent = () => {
		const data = {
			action: this.dic.CONSTANTS.quarantinedOutboundAction.content,
		};
		this.getEmailContentInProcess = true;

		this.rs.doActionOnQuarantinedOutboundEmail(data).then((response) => {
			this.parseContent(response);
			setTimeout(() => {
				this.encryptAnimationComponent.updateIFrameContent(this.email.htmlContent);
			});
			 this.getEmailContentInProcess = false;
		}, () => {
			 this.getEmailContentInProcess = false;
		});
	};

	finishLoadingEmail() {
		setTimeout(() => {
			this.gs.setIsLoadingApp(false);
		}, 800); // wait a bit so loading animation will not be cleared to fast
	}

	updateEncryptedContent(encryptedContent) {
		this.encryptedContent = encryptedContent;
	}

	confirmSuccessCb(response) {
		this.step = this.dic.STEP.Authenticated;
		this.stepSubject.next(this.step);
		this.setStepData(response);
	}

	confirmFailureCb = (response) => {
		if (response) {
			this.setStepData(response);
		}
	}

	applyAnimationCb() {
		if (this.encryptedContent) {
			this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.PROCESS);
		}
	}

	private getMessage() {
		this.setAccessEnc();
		this.rs.getQuarantinedOutboundEmailInfo().then(emailInfo => {
			if (emailInfo.status !== dic.STEP.Authenticated) {
				this.encryptedContent = true;
				this.encryptedContentSubject.next(this.encryptedContent);
			}
			this.finishLoadingEmail();
			this.setStepData(emailInfo);
		}, error => {
			this.setStepData(error);
		});
	}

	private setStepData(stepData) {
		this.step = dic.STEP[stepData.status] || dic.STEP.Blocked;
		this.stepSubject.next(this.step);
		this.stepDataSubject.next(stepData);

		switch (stepData.status) {
			case dic.STEP.Blocked:
				this.blockNote = stepData.blockNote || '';
				break;

			case dic.STEP.Authenticated:
				this.email = stepData;
				this.email.attachments = stepData.contentInfo?.sent?.attachments || [];

				this.setEmailResult(stepData);
				this.getEmailTrackingData();
				this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.DECRYPTED);
				break;
		}
	}

	private parseContent(emailContent: any) {
		try {
			this.email.htmlContent = emailContent;
			this.contentSubject.next(this.email.htmlContent);
		} catch (err) {
			console.error(err);
		}
	}

	private setEmailResult(emailInfo) {
		switch (emailInfo.quarantined_status) {
			case dic.CONSTANTS.quarantinedOutboundStatus.released:
				this.email.statusText = 'Email is released';
				break;

			case dic.CONSTANTS.quarantinedOutboundStatus.pending:
				this.email.statusText = 'Email is pending release';
				break;

			case dic.CONSTANTS.quarantinedOutboundStatus.quarantined:
				this.email.statusText = 'Email is quarantined';
				break;
		}
	}

	getEmailTrackingData() {
		if (this.trackingData) {
			return;
		}

		this.rs.getQuarantinedEmailTracking()
			.then(res => {
				this.trackingData = res.tracking;
				this.recipientTrack = this.trackingData[0];
			}, err => {
				console.log('err ?', err);
			});
	}

	setPreviewAttachment(attachmentObj) {
		attachmentObj.previewLoading = true;
		this.downloadFile(attachmentObj, true, (err, attachmentData) => {
			if (err) {
				attachmentObj.previewLoading = false;
				return;
			}

			attachmentObj.previewLoading = false;
			attachmentObj.content = {data: attachmentData};
			this.previewAttachment = attachmentObj;
		});
	}

	downloadFile(attachment: any, isPreview: boolean, cb) {
		if ((attachment?.progressPercentage && attachment.progressPercentage > 0 && attachment.progressPercentage < 100) || attachment?.isInProcess) {
			return;
		}

		const fullUrl = `${ATTACHMENT_URL}/${OUTBOUND_QUARANTINED_URL}/attachment/${attachment._id}`;

		const localStorageFp = localStorage[this.dic.CONSTANTS.quarantinedFp];
		const headers = new HttpHeaders()
			.set('x-access-enc', this.userHash)
			.set('x-trustifi-source', 'miniapp')
			.set('x-trustifi-2fa-fingerprint', JSON.parse(localStorageFp));


		const req = new HttpRequest('GET', fullUrl, {
			headers,
			reportProgress: true,
			responseType: 'arraybuffer'
		});

		attachment.isInProcess = true;
		attachment.progressPercentage = 0;

		let count = 0;

		this.http.request(req).subscribe((event: any) => {
			// reporting percentages - updating local variables
			if (event.type === HttpEventType.DownloadProgress) {
				// Update progress styles once in 2 reports - improve UX
				if (count % 2 === 0 && attachment) {
					attachment.progressPercentage = ((event.loaded / (event.total || attachment.size)) * 100);
				}
				if (attachment.progressPercentage >= 100) {
					attachment.progressPercentage = 100;
				}
				count++;
			}
			else if (event.type === HttpEventType.Response) {
				if (attachment) {
					attachment.progressPercentage = 100;
				}
				attachment.isInProcess = false;
				attachment.progressPercentage = 0;

				if (isPreview) {
					return cb(null, event.body);
				}

				let contentType = attachment.type;

				if (this.gs.isChrome() && navigator.userAgent.match(/iPhone/i)) {
					if (contentType ===  'message/rfc822' || attachment.name.endsWith('.csv')) {
						contentType = 'text/plain';
					}
				}

				const blob = new Blob([event.body], {type: contentType});
				if (this.gs.detectMobile() && this.gs.isSupportedMimeType(contentType)) {
					location.href = URL.createObjectURL(blob);
				}
				else {
					this.downloadFilesService.download(blob, attachment.name, contentType);
				}
			}
		}, errResponse => {
			if (errResponse.data && errResponse.data instanceof ArrayBuffer && errResponse.data.byteLength !== undefined ||
				errResponse.error && errResponse.error instanceof ArrayBuffer && errResponse.error.byteLength !== undefined) {
				const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(errResponse.data || errResponse.error)));
				console.error(err);
			} else {
				console.error(errResponse.message);
			}
		});
	}

}

