import {RouterModule, Routes} from '@angular/router';
import {EmailTabsWrapperComponent} from './components/tabs/email-tabs-wrapper/email-tabs-wrapper.component';
import {MainComponent} from './components/main/main.component';
import {QuarantinedEmailComponent} from './components/quarantined-email/quarantined-email.component';
import {OutboundQuarantinedComponent} from './components/outbound-quarantined/outbound-quarantined.component';
import {AccountTakeoverProtectionComponent} from './components/account-takeover-protection/account-takeover-protection.component';
import {SenderComponent} from './components/sender/sender.component';
import {ReviewComponent} from './components/review/review.component';
import {SecureReplyComponent} from './components/secure-reply/secure-reply.component';
import {ArchiveComponent} from './components/archive/archive.component';

const appRoutes: Routes = [
	{path: '', component: MainComponent},
	{path: 'tracking', component: EmailTabsWrapperComponent, children: [{path: '**', component: EmailTabsWrapperComponent}]},
	{path: 'quarantined', component: QuarantinedEmailComponent, children: [{path: '**', component: QuarantinedEmailComponent}]},
	{path: 'outbound', component: OutboundQuarantinedComponent, children: [{path: '**', component: OutboundQuarantinedComponent}]},
	{path: 'acp', component: AccountTakeoverProtectionComponent, children: [{path: '**', component: AccountTakeoverProtectionComponent}]},
	{path: 'sender', component: SenderComponent, children: [{path: '**', component: SenderComponent}]},
	{path: 'reply', component: SecureReplyComponent, children: [{path: '**', component: SecureReplyComponent}]},
	{path: 'archive', component: ArchiveComponent, children: [{path: '**', component: ArchiveComponent}]},
	{path: 'review', component: ReviewComponent, children: [{path: '**', component: ReviewComponent}]},
	{path: '**', component: MainComponent}
];

/*const routerOptions: ExtraOptions = {
	// enableTracing: true,
	// initialNavigation: 'disabled',
	onSameUrlNavigation: 'reload',
	useHash: false,
	preloadingStrategy: PreloadAllModules
};*/

export const RouterModuleProvider = RouterModule.forRoot(appRoutes);
