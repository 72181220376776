import {Component, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import {RouteService} from '../../services/route.service';
import {NotificationService} from '../../services/notification.service';
import {dic} from '../../dictionary';
import {GeneralService} from '../../services/general.service';
import {TranslationService} from '../../services/translation.service';

@Component({
  selector: 'app-secure-reply',
  templateUrl: './secure-reply.component.html',
  styleUrls: ['./secure-reply.component.scss'],
})
export class SecureReplyComponent implements OnInit{
	constructor(private rs: RouteService,
				public gs: GeneralService,
				private translationService: TranslationService,
				private location: Location,
				private ns: NotificationService) {
	}

	dic = dic;
	random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
	sender: string;
	recipient: string;
	isFormError = false;
	goToReplyInProcess;
	isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
	isMobile = this.gs.detectMobile() && ((window.innerWidth > 0 ? window.innerWidth < 480 : screen.width < 480) || (window.innerHeight > 0 ? window.innerHeight < 480 : screen.height < 480));

	ngOnInit() {
		const currentUrl = this.location.path();
		const urlParts = currentUrl.split('reply/');
		if (urlParts?.length === 2) {
			const enc = urlParts[1];
			this.rs.getSecureReplyLfSettings(enc).then(res => {
				this.gs.initLogoData(res);
				this.gs.setMainLogo();
			}, error => {
				this.gs.initLogoData({});
				this.gs.setMainLogo();
			});
		}
		else {
			this.gs.initLogoData({});
			this.gs.setMainLogo();
		}

	}

	getSecureReply = () => {
		if (!this.gs.validateEmail(this.sender) || !this.gs.validateEmail(this.recipient)) {
			this.ns.showErrorMessage(this.translationService.getTranslationText('signup.emailAddressError'));
			this.isFormError = true;
			return;
		}

		this.goToReplyInProcess = true;
		this.rs.getSecureReply(this.sender.toLowerCase(), this.recipient.toLowerCase()).then(link => {
			this.removeFingerprint(link);

			window.location.href = link;

		}, error => {
			this.goToReplyInProcess = false;
			if (error.data) {
				this.ns.showMessage(error.data);
			}
		});
	}

	removeFingerprint(link) {
		try {
			const enc = link.split('#/');
			if (enc?.length === 2) {
				localStorage.removeItem(enc[1]);
			}
			localStorage.removeItem('fp'); // for smart auth
		}
		catch(ex) {
			console.error(ex);
		}
	}
}
