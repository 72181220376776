import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { dic } from '../dictionary';
import util from 'util';


@Injectable({
	providedIn: 'root'
})
export class TranslationService {
	dic = dic;

	selectedAppLang = dic.CONSTANTS.supportedLanguages.english;

	constructor(private translate: TranslateService) {}

	initLanguage = () => {
		this.translate.setDefaultLang('us');

		// this language will be used as a fallback when a translation isn't found in the current language
		const localStorageLangCode = localStorage[dic.CONSTANTS.localStorageLang];
		if (localStorageLangCode) {
			for (const langKey in dic.CONSTANTS.supportedLanguages) {
				if (dic.CONSTANTS.supportedLanguages[langKey].code === localStorageLangCode) {
					this.selectedAppLang = dic.CONSTANTS.supportedLanguages[langKey];
					break;
				}
			}
		}

		this.changeLanguageApp(this.selectedAppLang);
	}


	changeLanguageApp(lang) {
		// the lang to use, if the lang isn't available, it will use the current loader to get them
		this.selectedAppLang = lang || this.selectedAppLang;
		this.translate.use(this.selectedAppLang.code);

		// update localstorage for further use
		localStorage[dic.CONSTANTS.localStorageLang] = this.selectedAppLang.code;
	}

	getTranslatedMessage = (msg, cb) => {
		if (!msg) { return; }

		let txtMsg = msg;
		this.translate.get(`MESSAGES.${msg}`).subscribe((translatedTxt: string) => {
			if (translatedTxt && !translatedTxt.startsWith('MESSAGES.')) {
				txtMsg = translatedTxt;
			}
			cb(txtMsg);
		});
	}

	getTranslatedError = (msg, cb) => {
		if (!msg) { return; }

		let txtMsg = msg;
		this.translate.get(`ERRORS.${msg}`).subscribe((translatedTxt: string) => {
			if (translatedTxt && !translatedTxt.startsWith('ERRORS.')) {
				txtMsg = translatedTxt;
			}
			cb(txtMsg);
		});
	}

	getTranslationText = (text, ...messageParams: any[]) => {
		let translatedValue = this.translate.instant(text);
		if (messageParams) {
			// @ts-ignore
			translatedValue = util.format(translatedValue, ...messageParams);
		}
		return translatedValue;
	}
}
