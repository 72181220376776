<div class="flxClmn-evenly f-1-0 vSpace2" style="padding: 0 11%; align-items: center;">
	<h2 style="color: #486db5">{{'recipient.secureReplyTitle' | translate}}</h2>
	<span class="t-color-primary" style="text-align: center; width: 415px; line-height: 1.5;">{{'secureReply.enterAddresses' | translate}}</span>
	<div class="flxClmn-center vSpace2 w100" style="align-items: center">
		<input autocomplete="{{random}}"
			   style="text-align: center; height: 30px; width: 80%;"
			   type="text"
			   [appAutoFocus]="true"
			   (keyup.enter)="getSecureReply()"
			   (keyup)="isFormError = false;"
			   placeholder="{{ 'secureReply.sender' | translate }}"
			   [ngClass]="{'validation-error': isFormError && (!sender || !gs.validateEmail(sender))}"
			   [(ngModel)]="sender"/>
		<input autocomplete="{{random}}"
			   style="text-align: center; height: 30px; width: 80%;"
			   type="text"
			   (keyup.enter)="getSecureReply()"
			   (keyup)="isFormError = false;"
			   placeholder="{{ 'secureReply.recipient' | translate }}"
			   [ngClass]="{'validation-error': isFormError && (!recipient || !gs.validateEmail(recipient))}"
			   [(ngModel)]="recipient"/>
	</div>
	<btn class="large-btn"
		 [isDisabled]="!recipient || !sender"
		 [loading]="goToReplyInProcess"
		 (action)="getSecureReply();">
		{{'general.confirm' | translate}}
	</btn>
</div>
