import {Component, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {dic} from '../../dictionary';
import {RouteService} from '../../services/route.service';
import {NotificationService} from '../../services/notification.service';
import {GeneralService} from '../../services/general.service';
import {DownloadFilesService} from '../../services/download-files.service';
import {TooltipDirective} from 'ng2-tooltip-directive';
import {Buffer} from 'buffer';
import {Subject} from 'rxjs';
import {EncryptAnimationComponent} from '../encrypt-animation/encrypt-animation.component';
import {TranslationService} from '../../services/translation.service';


@Component({
	selector: 'app-sender',
	templateUrl: './sender.component.html',
	styleUrls: ['../quarantined-email/quarantined-email.component.scss']
})
export class SenderComponent implements OnInit {

	@ViewChild(EncryptAnimationComponent) encryptAnimationComponent: EncryptAnimationComponent;
	@ViewChildren(TooltipDirective) tooltipDirective;

	dic = dic;
	step: string;
	stepSubject: Subject<string> = new Subject<string>();
	private userHash: string;
	disableActionBtn = false;
	previewAttachment;
	email: any = {};
	contentSubject: Subject<string> = new Subject<string>();

	encryptedContentSubject: Subject<boolean> = new Subject<boolean>();
	actionToConfirm = '';
	emailActionsTooltip = '';

	constructor(private rs: RouteService,
				private ns: NotificationService,
				private downloadFilesService: DownloadFilesService,
				private translateService: TranslationService,
				public gs: GeneralService) {
	}

	ngOnInit() {
			this.step = dic.STEP.Authenticating;
		this.stepSubject.next(this.step);
		this.getMessage();

	}

	setAccessEnc() {
		const urlSplitted = location.href.split('sender/');
		if (urlSplitted.length < 2) { return; }
		this.userHash = urlSplitted[1];
		const defaultHeaders = {};
		defaultHeaders[this.dic.HEADERS.xAccessEnc] = this.userHash;

		const localStorageFp = localStorage[this.dic.CONSTANTS.quarantinedFp];
		if (localStorageFp) {
			try {
				defaultHeaders[this.dic.HEADERS.x2FaFingerprint] = JSON.parse(localStorageFp);
			} catch (ex) {
				console.log(ex);
			}
		}

		this.rs.setDefaultHeaders(defaultHeaders);
	}

	senderAuthAction(action) {
		if (this.disableActionBtn) {
			return;
		}
		this.disableActionBtn = true;

		this.rs.senderAuthAction({action}).then(() => {
			this.email.auth_sender = {status: action};

			this.actionToConfirm = '';
			this.disableActionBtn = false;
			this.ns.showInfoMessage(dic.MESSAGES.operationSucceeded);
		}, error => {
			this.disableActionBtn = false;
			if (error.data) {
				this.ns.showMessage(error.data);
			}
		});
	}

	private getMessage() {
		this.setAccessEnc();
		this.rs.getSenderAuthEmailInfo().then(emailInfo => {
			this.setStepData(emailInfo);
			this.finishLoadingEmail();
		}, error => {
			this.setStepData(error);
		});
	}

	finishLoadingEmail() {
		setTimeout(() => {
			this.gs.setIsLoadingApp(false);
		}, 800); // wait a bit so loading animation will not be cleared too fast
	}

	private setStepData(stepData) {
		this.email = stepData;
		this.step = dic.STEP.Authenticated;
		this.stepSubject.next(this.step);

		this.parseContent();

		this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.DECRYPTED);
	}

	private parseContent() {

		if (this.email.content) {
			let emailInfo = '';
			if (this.email.content.from?.text) {
				this.email.content.from.text = this.email.content.from.text.replaceAll('<', '('); // problem to display <> in HTML
				this.email.content.from.text = this.email.content.from.text.replaceAll('>', ')');
				emailInfo += `<b>${this.translateService.getTranslationText('quarantinedOutbound.from')}</b> ${this.email.content.from.text}`;
			}
			if (this.email.content.to?.text) {
				this.email.content.to.text = this.email.content.to.text.replaceAll('<', '('); // problem to display <> in HTML
				this.email.content.to.text = this.email.content.to.text.replaceAll('>', ')');
				emailInfo += `<br><b>${this.translateService.getTranslationText('quarantinedOutbound.to')}</b> ${this.email.content.to.text}`;
			}
			if (this.email.content.cc?.text) {
				this.email.content.cc.text = this.email.content.cc.text.replaceAll('<', '('); // problem to display <> in HTML
				this.email.content.cc.text = this.email.content.cc.text.replaceAll('>', ')');
				emailInfo += `<br><b>${this.translateService.getTranslationText('quarantinedOutbound.cc')}</b> ${this.email.content.cc.text}`;
			}
			emailInfo += `<br><b>${this.translateService.getTranslationText('quarantinedOutbound.subject')}</b> ${this.email.content.subject}<br><br>`;

			emailInfo = emailInfo + (this.email.content.html || this.email.content.text);
			this.contentSubject.next(emailInfo);
		}
	}

	downloadAttachment(data, name, type) {
		if (!data || !name || !type) {
			return;
		}

		const attachmentBuffer = Buffer.from(data, 'base64');

		if (attachmentBuffer) {
			const blob = new Blob([attachmentBuffer], {type});
			if (this.gs.detectMobile() && this.gs.isSupportedMimeType(type)) {
				if (this.gs.isChrome() && navigator.userAgent.match(/iPhone/i)) {
					const reader:any = new FileReader();
					reader.onload = function (e) {
						window.location.href = reader.result;
					};
					reader.readAsDataURL(blob);
				}
				else {
					location.href = URL.createObjectURL(blob);
				}
			}
			else {
				// general download
				this.downloadFilesService.download(attachmentBuffer, name, type);
			}
		} else {
			return; // Add Notification & Errors - Before too
		}
	}
}
