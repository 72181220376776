<div class="f-1-0 displayFlowAdvanced">
	<div class="flex-column gap-2 py-3 px-1 pb-5" style="min-height: fit-content; font-size: 12px;" [ngClass]="{'disabled' : userPolicy.allow_admin_policy_only.value}">

		<b class="mb-2">{{'advanced.emailSecurity.emailSecurity' | translate}}</b>

		<!--Authentication method dropdown-->
		<div class="flex-row w-100 justify-content-between gap-2 align-items-center"
			 [ngClass]="{'disabled': !methods.secure_send}" [ngStyle]="{'max-width': gs.isMobile ? 'unset' : '320px'}">
			<div class="flex-row f-1-0 align-items-center gap-1">
				<span class="text-nowrap" [ngClass]="{'opacity-50': !methods.secure_send}">{{'advanced.emailSecurity.authMethod' | translate}}:</span>
				<app-dropdown class="f-1-0" style="max-width: 120px; height: 22px; z-index: 50;"
							  [isDisabled]="!methods.secure_send"
							  [text]="_.startCase(advanced.secure?.method_2factor?.value) || ''">
					<li *ngFor="let method of dic.CONSTANTS.authenticationMethod | keyvalue: gs.returnZero"
						(click)="allowedAuthMethods.includes(method.value) ? updateAuthMethod(method.value) : $event.stopPropagation();"
						[ngClass]="{'disabled': !allowedAuthMethods.includes(method.value)}"
						[id]="'AuthMethod' + method">
						{{_.startCase(method.value)}}
						<i *ngIf="!allowedAuthMethods.includes(method.value)"
						   style="color: var(--gray); padding-left:1px; position: relative;"
						   class="zmdi zmdi-lock-outline"
						   tooltip="{{'advanced.blockedByAdmin' | translate}}">
						</i>
					</li>
				</app-dropdown>
			</div>

			<div class="flex-row align-items-center gap-1 ms-auto">
				<i *ngIf="!methods.secure_send" tooltip="{{'advanced.authToEnable' | translate}}" style="color: var(--gray); top: 2px; position: relative;" class="zmdi zmdi-lock-outline animated fadeIn"></i>
				<i style="font-weight: 400;" class="fas fa-question-circle m-0" tooltip="{{'advanced.emailSecurity.methodTooltip' | translate}}"></i>
			</div>
		</div>

		<!--Password input + hint input-->
		<div class="flex-column gap-2 ps-3" style="max-width: 264px; min-height: fit-content;"
			 [ngClass]="{'disabled': !methods.secure_send}"
			 *ngIf="advanced.secure?.method_2factor.value === dic.CONSTANTS.authenticationMethod.password">
			<div class="flex-row f-1-0 align-items-center gap-2">
				<span>{{'advanced.emailSecurity.passwordPlaceholder' | translate}}:</span>
				<div class="position-relative f-1-0">
					<input id="method_2factor_password"
						   [type]="showPassword ? 'text' : 'password'"
						   class="w-100" style="height: 22px;"
						   placeholder="{{'advanced.emailSecurity.passwordPlaceholder' | translate}}"
						   [(ngModel)]="advanced.secure.method_2factor_password.value"
						   (change)="changeAdvanced();"
						   [ngClass]="{'validation-error' :(isRequirePassword() && !advanced.secure.method_2factor_password.value && !advanced.secure.use_contact_2factor_password.value),
													   'nofill': advanced.secure.method_2factor_password.value && !showPassword}">
					<label style="opacity: 0.35; position: absolute; top: 50%; right: 0; transform: translate(-60%,-50%);" class="hidepwd cursor-pointer hvr-darken">
						<input type="checkbox" [(ngModel)]="showPassword"/>
						<i class="fa fa-eye unchecked t-color-primary"></i>
						<i class="fa fa-eye-slash checked t-color-primary"></i>
					</label>
				</div>
			</div>
			<div class="flex-row f-1-0 align-items-center gap-2" *ngIf="advanced.secure.method_2factor.value === dic.CONSTANTS.authenticationMethod.password">
				<span>{{'advanced.emailSecurity.passwordHint' | translate}}:</span>
				<div class="position-relative f-1-0">
					<input id="method_2factor_password_hint"
						   type="text"
						   class="w-100" style="height: 22px;"
						   placeholder="{{'advanced.emailSecurity.passwordHintPlaceholder' | translate}}"
						   [(ngModel)]="advanced.secure.method_2factor_password_hint.value"
						   (change)="changeAdvanced();">
				</div>
			</div>
			<ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'secure.use_contact_2factor_password.value',
                        id: 'useContact2factorPassword',
                        text: 'advanced.emailSecurity.contactPassword',
                        tooltip: 'advanced.emailSecurity.contactPasswordTooltip'}">
			</ng-container>
		</div>


		<!--2FA input-->
		<ng-container *ngTemplateOutlet="advancedOptionTemplate;
          context: {modelRef: 'secure.enable_smart_authentication.value',
                    id: 'smartAuthentication',
                    text: 'advanced.emailSecurity.smartAuth',
                    tooltip: 'advanced.emailSecurity.smartAuthTooltip',
                    isLocked: !methods.secure_send,
                    lockedTooltip: 'advanced.authToEnable',
                    isDisabled: !methods.secure_send}">
		</ng-container>

		<div class="flex-row w-100 justify-content-between gap-2 align-items-center" [ngStyle]="{'max-width': gs.isMobile ? 'unset' : '320px'}"
			 [ngClass]="{'disabled' : userPolicy.expired_enable.strict || (!methods.secure_send && !methods.encrypt_content)}">
			<div class="flex-row f-1-0 align-items-center hSpace1">
				<app-toggle-switch
					id="expiresInXdays"
					[isDisabled]="userPolicy.expired_enable.strict"
					[hideText]="true"
					[(model)]="advanced.secure.expired_enable.value"
					(modelChange)="changeAdvanced()">
				</app-toggle-switch>
				<label for="expiresInXdays" style="margin: 0; position: relative;">
					{{'advanced.emailSecurity.emailExpire' | translate}}
				</label>
				<input id="expired_days" type="number" [ngStyle]="{'opacity' : advanced.secure.expired_enable.value && !userPolicy.expired_enable.strict ? '1' : '0.6'}"
					   [disabled]="userPolicy.expired_enable.strict || !advanced.secure.expired_enable.value || (!methods.secure_send && !methods.encrypt_content)"
					   min="1" max="365"
					   class="mx-1" style="height: 22px; width: 55px; border: 1px solid #ccc; border-radius: 4px;"
					   [(ngModel)]="advanced.secure.expired_days.value"
					   (change)="changeAdvanced();"
					   placeholder="{{'advanced.emailSecurity.daysPlaceholder' | translate}}" />
			</div>

			<div class="flex-row align-items-center gap-1 ms-auto">
				<i *ngIf="userPolicy?.expired_enable.strict || (!methods.secure_send && !methods.encrypt_content)" [tooltip]="userPolicy?.expired_enable.strict ? ('compose.methods.methodLockedByAdmin' | translate) : ('advanced.encryptOrAuthToEnable' | translate)" style="color: var(--gray); top: 2px; position: relative;" class="zmdi zmdi-lock-outline animated fadeIn"></i>
				<i style="font-weight: 400;" class="fas fa-question-circle m-0" tooltip="{{'advanced.emailSecurity.emailExpireTooltip' | translate}}"></i>
			</div>
		</div>

		<!--Days until attachments expire input-->
		<div class="flex-row w-100 justify-content-between gap-2 align-items-center" [ngStyle]="{'max-width': gs.isMobile ? 'unset' : '320px'}"
			 [ngClass]="{'disabled' : ((!methods.secure_send && !methods.encrypt_content) || userPolicy.delete_attachment_enable.strict)}">
			<div class="flex-row f-1-0 align-items-center hSpace1">
					<app-toggle-switch
						id="deleteAttachmentsInXdays"
						[hideText]="true"
						[isDisabled]="((!methods.secure_send && !methods.encrypt_content) || userPolicy.delete_attachment_enable.strict)"
						[(model)]="advanced.secure.delete_attachment_enable.value"
						(modelChange)="changeAdvanced()">
					</app-toggle-switch>
				<label for="deleteAttachmentsInXdays" style="margin: 0; position: relative;">
					{{'advanced.emailSecurity.deleteAttachments' | translate}}
				</label>
				<input id="delete_attachments_days" type="number" [ngStyle]="{'opacity' : !advanced.secure.delete_attachment_enable.value || (!methods.secure_send && !methods.encrypt_content) ? '0.6' : '1'}"
					   [disabled]="(userPolicy.delete_attachment_enable.strict || !advanced.secure.delete_attachment_enable.value || (!methods.secure_send && !methods.encrypt_content))"
					   min="1" max="365"
					   class="mx-1" style="height: 22px; width: 55px; border: 1px solid #ccc; border-radius: 4px;"
					   [(ngModel)]="advanced.secure.delete_attachment_days.value"
					   (change)="changeAdvanced();"
					   placeholder="{{'advanced.emailSecurity.daysPlaceholder' | translate}}" />
			</div>

			<div class="flex-row align-items-center gap-1 ps-2 ms-auto">
				<i *ngIf="userPolicy.delete_attachment_enable.strict" [tooltip]="userPolicy.delete_attachment_enable.strict ? ('compose.methods.methodLockedByAdmin' | translate) : ('advanced.encryptToEnable' | translate)" style="color: var(--gray); top: 2px; position: relative;" class="zmdi zmdi-lock-outline animated fadeIn"></i>
				<i style="font-weight: 400;" class="fas fa-question-circle m-0" tooltip="{{'advanced.emailSecurity.deleteAttachmentsTooltip' | translate}}"></i>
			</div>
		</div>

		<!--Enforce attachment encryption checkbox/toggle-->
		<ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'secure.enforce_attachment_encryption.value',
                        id: 'enforceAttachmentEncryption',
                        text: 'advanced.emailSecurity.enforceAttachmentEncryption',
                        tooltip: 'advanced.emailSecurity.enforceAttachmentEncryptionTooltip',
                        lockedTooltip: 'advanced.general.cannotBeChanged',
                        isLocked: true,
                        isDisabled: true}">
		</ng-container>

		<!--Attachments preview mode checkbox/toggle-->
		<ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'secure.attachments_preview_mode.value',
                        id: 'attachments_preview_mode',
                        text: 'advanced.emailSecurity.attachmentsPreviewMode',
                        tooltip: 'advanced.emailSecurity.attachmentsPreviewModeTooltip',
                        isLocked: !attachmentsExist || (!methods.secure_send && !methods.encrypt_content),
                        lockedTooltip: (!methods.secure_send && !methods.encrypt_content) ? 'advanced.encryptOrAuthToEnable' : 'advanced.encryptToEnable',
                        isDisabled: !attachmentsExist || (!methods.secure_send && !methods.encrypt_content)}">
		</ng-container>

		<!--Allow print checkbox/toggle-->
		<ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'secure.enable_print.value',
                        id: 'allowPrint',
                        text: 'advanced.emailSecurity.allowPrint',
                        tooltip: 'advanced.emailSecurity.allowPrintTooltip',
                        isLocked: !methods.secure_send && !methods.encrypt_content,
                        lockedTooltip: 'advanced.encryptOrAuthToEnable',
                        isDisabled: !methods.secure_send && !methods.encrypt_content}">
		</ng-container>

		<!--Require Authentication on replies checkbox/toggle-->
		<ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'secure.secure_received.value',
                        id: 'authOnReplies',
                        text: 'advanced.emailSecurity.requireAuthReply',
                        tooltip: 'advanced.emailSecurity.requireAuthReplyTooltip',
                        isLocked: (!methods.secure_send && !methods.encrypt_content) || userPolicy.secure_received?.strict,
                        lockedTooltip: userPolicy.secure_received?.strict ? 'compose.methods.methodLockedByAdmin' : 'advanced.encryptOrAuthToEnable',
                        isDisabled: (!methods.secure_send && !methods.encrypt_content) || userPolicy.secure_received?.strict}">
		</ng-container>

		<!--Access only once checkbox/toggle-->
		<ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'secure.open_only_once.value',
                        id: 'accessOnlyOnce',
                        text: 'advanced.emailSecurity.accessOnce',
                        tooltip: 'advanced.emailSecurity.accessOnceTooltip',
                        isDisabled: !attachmentsExist && !methods.encrypt_content && !methods.secure_send}">
		</ng-container>

		<br/>
		<b class="mb-2">{{'advanced.general.general' | translate}}</b>

		<!--Notify on opened checkbox/toggle-->
		<ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'email_me.on_any_opened.value',
                        id: 'notifyEmailOpened',
                        text: 'advanced.general.notifyEmailsOpened',
                        tooltip: 'advanced.general.notifyEmailsOpenedTooltip'}">
		</ng-container>

		<!--Notify on link clicked checkbox/toggle-->
		<ng-container>
			<ng-container *ngTemplateOutlet="advancedOptionTemplate;
                  context: {modelRef: 'email_me.on_link_clicked.value',
                            id: 'notifyLinksClicked',
                            isDisabled: !advanced.general.track_links.value,
                            isLocked: !advanced.general.track_links.value,
                            lockedTooltip: 'advanced.trackLinksEnable',
                            text: 'advanced.general.notifyLinksClicked',
                            tooltip: 'advanced.general.notifyLinksClickedTooltip'}">
			</ng-container>
		</ng-container>

		<!--Notify on attachment download checkbox/toggle-->
		<ng-container>
			<ng-container *ngTemplateOutlet="advancedOptionTemplate;
                  context: {modelRef: 'email_me.on_attachment_download.value',
                            id: 'notifyFilesDownloaded',
                            isLocked: !attachmentsExist,
                            isDisabled: !attachmentsExist,
                            lockedTooltip: 'advanced.encryptToEnable',
                            text: 'advanced.general.notifyDownloadFiles',
                            tooltip: 'advanced.general.notifyDownloadFilesTooltip'}">
			</ng-container>
		</ng-container>

		<!--Reply to email input-->
		<div class="flex-row w-100 justify-content-between gap-2 align-items-center" [ngStyle]="{'max-width': gs.isMobile ? 'unset' : '320px'}">
			<div class="flxRow-start f-1-0 hSpace1 disabled" style="align-items: center;">
				<app-toggle-switch
					id="reply_to_enable"
					[hideText]="true" [isDisabled]="true"
					[(model)]="advanced.general.reply_to_enable.value"
					(modelChange)="changeAdvanced()">
				</app-toggle-switch>
				<label for="reply_to_enable" style="margin: 0; position: relative;">
					{{'advanced.general.replyTo' | translate}}
				</label>
				<input id="reply_to_email" type="text" class="m-0 ms-2 f-1-0" style="max-width: 135px; height: 22px;"
					   *ngIf="advanced.general.reply_to_enable.value"
					   [disabled]="true"
					   placeholder="{{'advanced.general.replyToMyEmail' | translate}}"
					   [ngClass]="{'validation-error' : advanced.general.reply_to_enable.value && advanced.general.reply_to_email.value ? !gs.validateEmail(advanced.general.reply_to_email.value) : false}"
					   [(ngModel)]="advanced.general.reply_to_email.value"
					   (change)="changeAdvanced();"/>
				<input type="text" class="m-0 ms-2 f-1-0" style="max-width: 135px; height: 22px;"
					   *ngIf="!advanced.general.reply_to_enable.value"
					   [disabled]="true"
					   [placeholder]="'do-not-reply@' + lfs.corpName.toLowerCase() + '.com'"/>
			</div>
			<div class="flex-row align-items-center gap-1 ps-2 ms-auto">
				<i tooltip="{{'advanced.general.cannotBeChanged' | translate}}" style="top: 0px; color: var(--gray); position: relative;" class="zmdi zmdi-lock-outline"></i>
				<i style="font-weight: 400;" class="m-0 fas fa-question-circle m-0" tooltip="{{'advanced.general.replyToToolip' | translate}}"></i>
			</div>
		</div>

		<!--Use template checkbox/toggle-->
		<ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'general.trustifi_wrapper.value',
                        id: 'useTemplate',
                        isDisabled: true,
                        lockedTooltip: 'advanced.general.cannotBeChanged',
                        isLocked: true,
                        text: 'advanced.general.useTemplate',
                        tooltip: 'advanced.general.useTemplateTooltip'}">
		</ng-container>

		<!--Track links checkbox/toggle-->
		<ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'general.track_links.value',
                        id: 'trackLinks',
                        text: 'advanced.general.trackLinks',
                        tooltip: 'advanced.general.trackLinksTooltip',
                        lockedTooltip: 'advanced.general.cannotBeChanged',
                        isLocked: true,
                        isDisabled: true}">
		</ng-container>


		<!--Track attachments checkbox/toggle-->
		<ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'general.multi_replies.value',
                        id: 'unlimitedReplies',
                        text: 'advanced.general.unlimitedReplies',
                        tooltip: 'advanced.general.unlimitedRepliesTooltip',
                        isLocked: !methods.secure_send && !methods.encrypt_content,
                        lockedTooltip: 'advanced.encryptOrAuthToEnable',
                        isDisabled: !methods.secure_send && !methods.encrypt_content}">
		</ng-container>

		<!--Enforce reply all checkbox/toggle-->
		<ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'general.enforce_reply_all.value',
                        id: 'enforceReplyAll',
                        text: 'advanced.general.enforceReplyAll',
                        tooltip: 'advanced.general.enforceReplyAllTooltip',
                        isLocked: !methods.secure_send && !methods.encrypt_content,
                        lockedTooltip: 'advanced.encryptOrAuthToEnable',
                        isDisabled: !methods.secure_send && !methods.encrypt_content}">
		</ng-container>


		<!--Allow download as EML checkbox/toggle-->
		<ng-container *ngTemplateOutlet="advancedOptionTemplate;
              context: {modelRef: 'general.allow_download_as_eml.value',
                        id: 'allowDownloadEml',
                        text: 'advanced.general.allowDownloadingAsEML',
                        tooltip: 'advanced.general.allowDownloadingAsEMLTooltip',
                        isLocked: userPolicy.allow_download_as_eml.strict || (!methods.secure_send && !methods.encrypt_content),
                        lockedTooltip: userPolicy.allow_download_as_eml.strict ? 'compose.methods.methodLockedByAdmin' : 'advanced.encryptOrAuthToEnable',
                        isDisabled: userPolicy.allow_download_as_eml.strict || (!methods.secure_send && !methods.encrypt_content)}">
		</ng-container>


		<ng-template #advancedOptionTemplate let-id="id" let-modelRef="modelRef" let-text="text" let-tooltip="tooltip" let-isLocked="isLocked" let-lockedTooltip="lockedTooltip" let-isDisabled="isDisabled">
			<!-- Toggle Version -->
			<div class="f-1-0 flex-row gap-2 align-items-center" [ngStyle]="{'max-width': gs.isMobile ? 'unset' : '320px'}" [ngClass]="{'disabled': isDisabled}">
				<app-toggle-switch
					[id]="id"
					[hideText]="true"
					[model]="_.get(advanced, modelRef)"
					(modelChange)="setAdvancedValue(modelRef, $event)">
				</app-toggle-switch>
				<label [for]="id" style="display: inline-block;">{{text | translate}}</label>
				<div class="flex-row align-items-center gap-1 ms-auto">
					<i *ngIf="isLocked" tooltip="{{lockedTooltip | translate}}" style="top: 0px; color: var(--gray); position: relative;" class="zmdi zmdi-lock-outline"></i>
					<i style="font-weight: 400; top: 0;" class="fas fa-question-circle m-0" tooltip="{{tooltip | translate}}"></i>
				</div>
			</div>
		</ng-template>
	</div>
</div>
