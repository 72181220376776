<div class="flxClmn" style="flex: 1 0 auto">

	<!--CONTENT: Blocked archive-->
	<div class="flxClmn-center vSpace2 w100" style="flex:1 0; padding:15px;" *ngIf="step === dic.STEP.Blocked">
		<!--info content block-->
		<div class="flxClmn-center vSpace5" style="flex: 4 0; align-items: center;">
			<i class="fas fa-archive blocked-archive-icon" style="font-size: 10vw; position: relative; color: lightgrey;"></i>
			<div class="flxClmn-start vSpace1 t-color-primary" style="align-items: center;">
				<span style="font-size: 1.6rem; font-family: 'Roboto',sans-serif; text-align: center; font-weight: lighter;">{{ 'archive.archiveNotAvailable' | translate }}</span>
				<br/>
				<span style="text-align: center; margin: 0 auto; opacity: 0.6;" *ngIf="blockNote">{{blockNote}}</span>
			</div>
		</div>
		<!--actions section-->
		<div class="flxClmn-center blocked-page-actions vSpace2">
			<span *ngIf="showAskAccess && !showAskAccessBlocked">{{ 'archive.askAccessInfo' | translate }}</span>
			<span class="notification-label" *ngIf="showAskAccess && showAskAccessBlocked">{{showAccessText | translate}}</span>
			<btn *ngIf="showAskAccess && !showAskAccessBlocked"
				 (action)="askForAccess();">
				{{showAccessText | translate}}
			</btn>
		</div>
	</div>

	<!--CONTENT: Authentication screen-->
	<div class="flxClmn-center vSpace2 w100" style="flex:1 0; padding:15px"
		 *ngIf="[dic.STEP.Phone, dic.STEP.Totp, dic.STEP.Email, dic.STEP.Code, dic.STEP.Password, dic.STEP.Authenticating, dic.STEP.ssoIdp, dic.STEP.SSO, dic.STEP.Empty].indexOf(step) > -1">
		<app-authentication
			style="flex: 1 0; height: 1px" class="flxClmn"
			[stepDataInput]="stepDataSubject.asObservable()"
			(confirmSuccessEmitter)="confirmSuccessCb($event)"
			(confirmFailureEmitter)="confirmFailureCb($event)"
			(applyAnimationEmitter)="applyAnimationCb()"
			[userHash]="userHash"
			[parentComponent]="dic.CONSTANTS.authParentComponents.archive">
		</app-authentication>
	</div>

	<!--CONTENT: Archive-->
	<div class="flxRow main-screen-container fancy-background" style="flex: 1 0;" *ngIf="step === dic.STEP.Authenticated">

		<!--archive list (left side)-->
		<div class="flxClmn list-outer-container t-bg-primary" style="flex: 5 0; position: relative">
			<!--list header (archive info & search)-->

			<div class="flxClmn" style="padding: 5px 10px; z-index: 8; background: linear-gradient(rgba(0,0,0,0.13), rgba(0,0,0,0.13) ), linear-gradient(0deg, var(--bs-bold-color), var(--bs-primary) ,var(--bs-bold-color) );"
				 [ngStyle]="{'color': lfs.textColor}">
				<span style="align-self: flex-start; opacity: 50%; font-size: 0.8rem;">{{ 'archive.archiveCase' | translate }}</span>
				<h4 style="align-self: center; margin: 0; font-size: 1.3rem; text-align: center; max-width: 85%;">{{caseInfo.name}}</h4>
				<a style="align-self: flex-end; font-weight: 300; font-size: 0.8rem; text-decoration: underline;" (click)="showCaseInfo = !showCaseInfo;">{{ 'archive.showCaseInfo' | translate }}</a>
			</div>

			<div class="flxRow hSpace2 search-row" style="padding: 10px; align-items: center; border-bottom: solid 1px {{lfs.color}};">
				<div class="input-holder search-input" style="width: 245px;">
					<i class="fa fa-search search-icon animated fadeIn" *ngIf="!isSearchEmailFocused"></i>
					<input id="sendersWhitelistSearchInput"
						   [ngStyle]="isSearchEmailFocused && {'padding-left' : '10px'}"
						   [placeholder]="isSearchEmailFocused ? '' : ('archive.searchEmail' | translate)"
						   (blur)="isSearchEmailFocused = false"
						   (focus)="isSearchEmailFocused = true"
						   [(ngModel)]="searchEmailText"
						   [maxLength]="dic.CONSTANTS.maxCharsInSearch"
						   (ngModelChange)="searchEmailInResults(searchEmailText)"
						   autocomplete="off"/>
					<a class="animated fas fa-times fadeIn close-icon" *ngIf="searchEmailText" (click)="searchEmailText = ''; clearSearchEmail()"></a>
				</div>

				<span class="hvr-darken3 t-color-primary" style="cursor:default; font-family: 'Roboto', sans-serif; opacity: 0.75; font-size: calc(0.75rem + 0.1vw);">{{ 'archive.total' | translate }}: {{emails.length}} {{ 'archive.emails' | translate }}</span>
			</div>

			<!--case info modal-->
			<div class="flxClmn case-info-modal t-color-primary" [ngClass]="showCaseInfo && 'shown'">
				<!--title-row-->
				<div class="flxRow-between title-row w100">
					<div class="flxRow hSpace05" style="align-items: center">
						<i style="font-size: 0.95rem; opacity: 0.5;" class="fa fa-info-circle"></i>
						<span style="font-size: 0.95rem;">{{ 'archive.caseInfo' | translate }}</span>
					</div>
					<a style="font-size: 1.2rem; padding: 0 7px;" class="close1 closeRed" (click)="showCaseInfo = false;">
						<i class="zmdi zmdi-close"></i>
					</a>
				</div>
				<!--content-->
				<div class="flxClmn vSpace2 content-wrapper" style="flex: 1 0;">
					<span style="font-size: 0.9rem"><b>{{ 'archive.caseParams' | translate }}</b></span>
					<div class="flxClmn vSpace1" style="flex: 1 0;">
						<span *ngIf="caseInfo.query.period !== dic.CONSTANTS.archiveQueryPeriods.range">{{ 'archive.paramPeriod' | translate }}: <b>{{ 'archive.periods.' + caseInfo.query.period | translate }}</b></span>
						<span *ngIf="caseInfo.query.period === dic.CONSTANTS.archiveQueryPeriods.range">{{ 'archive.paramPeriod' | translate }}:&nbsp;<b>{{ 'archive.from' | translate }}&nbsp;{{caseInfo.query.range.start | date:'MM/dd/yyyy'}}&nbsp;&nbsp;{{ 'archive.until' | translate }}&nbsp;{{caseInfo.query.range.end | date:'MM/dd/yyyy'}}</b></span>

						<span *ngIf="caseInfo.query.from">{{ 'archive.paramFrom' | translate }}: <b>{{caseInfo.query.from}}</b></span>
						<span *ngIf="caseInfo.query.rcptTo">{{ 'archive.paramRecipients' | translate }}: <b>{{caseInfo.query.rcptTo}}</b></span>
						<span *ngIf="caseInfo.query.subject">{{ 'archive.paramSubject' | translate }}: <b>{{caseInfo.query.subject}}</b></span>
						<span *ngIf="caseInfo.query.text">{{ 'archive.paramContains' | translate }}: <b>{{caseInfo.query.text}}</b></span>
						<span *ngIf="caseInfo.query.compliance?.length">{{ 'archive.paramCompliance' | translate }}: <b>{{caseInfo.query.compliance.join(', ')}}</b></span>
						<span *ngIf="caseInfo.query.scanStatus">{{ 'archive.paramScanStatus' | translate }}: <b>{{caseInfo.query.scanStatus}}</b></span>
						<span *ngIf="caseInfo.query.hasAttachments">{{ 'archive.paramAttachmentsOnly' | translate }}: <b>{{ 'archive.true' | translate }}</b></span>
					</div>
				</div>
			</div>


			<!--emails list-->
			<div class="flxClmn list-table-container" style="flex: 1 0;"
				 *ngIf="emails.length">
				<virtual-scroller class="f-1-0" style="border: solid 1px #cccccc;"
								  #archiveEmailsListScroll
								  [items]="emails"
								  [ngClass]="{'is-loading-or-empty' : !emails.length}">
					<table class="w100 mailsListTable alternated ellips">
						<tbody #container>
						<tr *ngFor="let email of archiveEmailsListScroll.viewPortItems"
							(click)="getArchiveEmailInfo(email); chosenEmailId = email._id;"
							[ngClass]="{'is-active': chosenEmailId &&  chosenEmailId === email._id}">
							<td>
								<div class="flxClmn vSpace05">
									<div class="flxRow-between">
										<div>
											<span style=" opacity: 0.8; font-size: 0.75rem;">{{email._source.from}}</span>
										</div>
										<span *ngIf="email._source.deleted" style="color: red; padding: 0 5px; flex-shrink: 0; opacity: 1; font-size: 0.7rem; font-family: 'Roboto', sans-serif;">[{{ 'archive.deleted' | translate }}]</span>
									</div>

									<div style="font-weight: 500; font-size: 0.95rem;">
										<span >{{email._source.subject}}</span>
									</div>
								</div>
							</td>
							<td style="width: 150px;">
								<div class="flxClmn-between h100 vSpace05" style="align-items: flex-end;">
									<div style="flex: 1 0">
										<span style="font-size: 0.8rem">{{ email._source.created | date:'MM/dd/yyyy HH:mm' }}</span>
									</div>
									<div class="flxRow-between hSpace05 w100" style="flex:1 0;align-items: flex-end;">
										<span style="visibility: hidden;"></span>
										<div class="hSpace05 t-color-secondary" style="font-size: 0.85rem" *ngIf="email._source.attachments.length">
											<span style="font-weight: 400;" >{{email._source.attachments.length}}</span>
											<i class="fas fa-paperclip" style=" font-size: 14px;"></i>
										</div>
										<span style="opacity: 0.5;font-size: 0.7rem;" >{{(email._source.size | formatBytes: 2)}}</span>
									</div>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</virtual-scroller>
			</div>
		</div>

		<!--selected email viewer (right side)-->
		<div class="flxClmn viewer-outer-container" style="flex: 7 0; align-items: center;"
			 [ngClass]="showEmailInMobile && 'shown-mobile'">
			<!--loading overlay-->
			<div class="flxClmn-center shinyFX-light loading-overlay" style="overflow: hidden; position: absolute"
				 [ngClass]="loadingEmailInProcess && 'shown'" >
				<div class="loading-spinner" style="border-color: var(--bs-primary); border-bottom-color: transparent; opacity: 0.75; z-index: 5;"></div>
			</div>
			<!----->
			<!--mobile navigation buttons and menu-->
			<div class="flxRow-between w100 mobile-navigation t-bg-primary t-color-primary animated fadeIn">
				<i class="fas fa-arrow-left" style="padding: 15px; font-size: 1.3rem;"
				   (click)="showEmailInMobile = false;"></i>
				<i class="fas fa-bars" style="padding: 15px; font-size: 1.3rem;"
				   [ngStyle]="!currentMessage?.content?.parsedContent && {'opacity' : '0.5', 'pointer-events' : 'none'}"
				   (click)="$event.stopPropagation(); showEmailActionsInMobile = true;"></i>
			</div>
			<!---->
			<div class="flxClmn vSpace2 w100 viewer-inner-container t-bg-primary" style="flex: 1 0;"
				 [ngStyle]="!loadingEmailInProcess && currentMessage?.content?.parsedContent && {'opacity' : '1'}"
				 [style.overflow]="gs.isMobile && 'visible'"
				 *ngIf="currentMessage?.content?.parsedContent">
				<div class="flxRow">
					<div class="flxClmn vSpace1 t-color-primary" style="font-size: calc(0.45rem + 0.4vw); flex:1 0;">
						<div style="margin-bottom: 0">
							<h2 style="margin: 0; font-size: calc(0.7rem + 0.4vw)">{{currentMessage.content.parsedContent.subject}}
								<span style="font-size: 1rem; color: red; font-weight: normal; padding: 0 10px; font-family: 'Roboto' ,sans-serif;" *ngIf="currentMessage._source.deleted">[deleted]</span>
							</h2>
						</div>

						<div class="flxRow" style="font-size: 0.8rem">
							<div class="flxClmn t-color-primary">
								<!--from-->
								<span>{{ 'archive.from' | translate }}:
											<span>{{currentMessage.content.parsedContent.from?.text || ''}}</span>
										</span>
								<!--to-->
								<div class="flxRow hSpace05" *ngIf="currentMessage.content.parsedContent.to">
									<span>{{ 'archive.to' | translate }}: </span>
									<div>
										{{currentMessage.content.parsedContent.to.text}}
									</div>
								</div>
								<!--Date-->
								<span>{{ 'archive.sent' | translate }}:
											<span>{{currentMessage.content.parsedContent.date | date:'MM/dd/yyyy HH:mm'}}</span>
										</span>
							</div>
							<div class="flxClmn">
								<!--cc-->
								<div class="flxRow hSpace05" *ngIf="currentMessage.content.parsedContent.cc">
									<span>{{ 'archive.cc' | translate }}: </span>
									<div>
										{{currentMessage.content.parsedContent.cc.text}}
									</div>
								</div>
								<!--bcc-->
								<div class="flxRow hSpace05" *ngIf="currentMessage.content.parsedContent.bcc">
									<span>{{ 'archive.bcc' | translate }}: </span>
									<div>
										{{currentMessage.content.parsedContent.bcc}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="flxClmn-center vSpace1 h100 email-actions-section t-color-secondary" style="width: 150px;cursor: pointer;"
						 [ngClass]="showEmailActionsInMobile && 'shown-mobile'"
						 (clickOutside)="showEmailActionsInMobile = false">
						<div class="flxRow-start hvr-darken3 hSpace05" style="align-items: center;"
							 (click)="downloadEml(currentMessage)">
							<i class="far fa-save" style="font-size: 1.2rem;cursor: pointer;"></i>
							<span style="opacity: 0.7;font-size: 0.7rem;">{{ 'archive.downloadEml' | translate }}</span>
						</div>
						<div class="flxRow-start hvr-darken3 hSpace05" style="align-items: center;"
							 (click)="printEmail(currentMessage)">
							<i class="fas fa-print" style="font-size: 1.2rem;cursor: pointer;"></i>
							<span style="opacity: 0.7;font-size: 0.7rem;">{{ 'archive.print' | translate }}</span>
						</div>
					</div>
				</div>
				<hr/>
				<div class="flxClmn" style="flex:1 0; margin:0; overflow: auto;">
					<iframe id="activeEmailFrame" style="border: none; max-height: 100%; background-color: white;" [ngStyle]="!gs.isMobile && {'flex' : '1 0'}"></iframe>
				</div>
				<hr *ngIf="currentMessage._source.attachments.length"/>
				<div class="flxRow hSpace2" style="margin: 0;" *ngIf="currentMessage._source.attachments.length">
					<div class="flxRow" style="flex:1 0; max-height: 66px; flex-wrap: wrap; overflow: auto">
						<attachment-c
							*ngFor="let file of currentMessage.content.parsedContent.attachments"
							[attName]="file.name"
							[attSize]="file.size"
							(preview)="previewAttachment = file;"
							(download)="downloadAttachment(file.content, file.filename, file.type)">
						</attachment-c>
					</div>
				</div>
			</div>

			<div *ngIf="(!currentMessage || !currentMessage.content || !currentMessage.content.parsedContent) && !loadingEmailInProcess" class="flxClmn-center vSpace2 w100"
				 style="align-items: center; flex: 1 0;">
				<i class="fas hvr-opacity fa-envelope-open-text" style="font-size: 4vw;  opacity: 0.1;"></i>
				<span style="opacity: 0.15; font-size: 1.2rem; font-family: 'Roboto' , sans-serif">{{ 'archive.selectEmail' | translate }}</span>
			</div>
		</div>

	</div>

	<attachment-preview
		*ngIf="previewAttachment"
		[attachment]="previewAttachment"
		(onClose)="previewAttachment = null;">
	</attachment-preview>
</div>
