import { Component, OnInit } from '@angular/core';
import {RouteService} from "../../../services/route.service";
import {GeneralService} from "../../../services/general.service";
import {Subject} from "rxjs";
import * as html2pdf from 'html2pdf.js';
import {NotificationService} from "../../../services/notification.service";
import {dic} from "../../../dictionary";

@Component({
	selector: 'app-postmark',
	templateUrl: './postmark.component.html',
	styleUrls: ['./postmark.component.scss']
})
export class PostmarkComponent implements OnInit {

	dic = dic;
	postmarkData = [];
	postmarkDataSubject: Subject<Array<object>> = new Subject<Array<object>>();
	recipientPostmark:any = {};
	companyName = '';
	loadingData: boolean;

	constructor(private rs: RouteService,
				private gs: GeneralService,
				private ns: NotificationService) {
		this.companyName = this.gs.logoData.name;
	}

	ngOnInit(): void {
		if (this.gs.postmarkData) {
			this.setPostmarkData(this.gs.postmarkData);
			return;
		}
		this.loadingData = true;
		this.rs.getEmailPostmark().then(res => {
			this.loadingData = false;
			if (!(res instanceof Array)) res = [res];
			this.gs.postmarkData = res;
			this.setPostmarkData(res);
		}, err => {
			console.log('err ?', err);
		});
	}

	setPostmarkData(data) {
		this.postmarkData = data;
		this.recipientPostmark = this.postmarkData[0];
		this.setGlobalTimes();
		setTimeout(() => {
			this.postmarkDataSubject.next(this.postmarkData);
		});
	}

	changePostmarkRecipient(recipientObj) {
		if (recipientObj && recipientObj.recipient && this.recipientPostmark.recipient.id === recipientObj.recipient.id) return;
		this.recipientPostmark = recipientObj;
		this.setGlobalTimes();
	}

	summerWinterOffset(postmarkDate) {
		const jan = new Date(postmarkDate.getFullYear(), 0, 1);
		const jul = new Date(postmarkDate.getFullYear(), 6, 1);
		const stdTimezoneOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
		return (stdTimezoneOffset - postmarkDate.getTimezoneOffset()) / 60
	}

	setGlobalTimes() {
		if (!this.recipientPostmark || !this.recipientPostmark.timestamp) return;
		let postmarkDate = new Date(this.recipientPostmark.timestamp);
		const summerWinterOffset = this.summerWinterOffset(postmarkDate);
		//calculate offset from utc, summerWinterOffset, and -5 for EST
		postmarkDate.setHours(postmarkDate.getHours() + (postmarkDate.getTimezoneOffset() / 60) - 5 + summerWinterOffset);
		this.recipientPostmark.est = postmarkDate.toISOString();
		//cst is -1 hour from est
		postmarkDate.setHours(postmarkDate.getHours() - 1);
		this.recipientPostmark.cst = postmarkDate.toISOString();
		postmarkDate.setHours(postmarkDate.getHours() - 1);
		this.recipientPostmark.mst = postmarkDate.toISOString();
		postmarkDate.setHours(postmarkDate.getHours() - 1);
		this.recipientPostmark.pst = postmarkDate.toISOString();
	}

	downloadAsPdf() {
		const element = document.getElementById('postmark-src');
		const htmldata = element.querySelector('#htmldata');
		if (htmldata && this.recipientPostmark && this.recipientPostmark.html) {
			htmldata['style']['display'] = 'block';
			htmldata.innerHTML = this.recipientPostmark.html;
		}

		const options = {
			margin: 10,
			filename: 'postmark.pdf',
			enableLinks: false,
			image: {type: 'jpeg', quality: 1},
			html2canvas: {dpi: 384, letterRendering: true, logging: false},
			jsPDF: {unit: 'pt', format: 'b5', orientation: 'p', pagesplit: true}
		};

		html2pdf().from(element).set(options).save();
	}

	verifyPostmarkReceipt() {
		if (!this.recipientPostmark.recipient) return;
		this.rs.verifyReceiptPostmark(this.recipientPostmark.recipient.id).then(res => {
			if (res.meta) {
				if (res.meta.type === dic.CONSTANTS.NotificationTypes.WARNING) {
					this.ns.showWarnMessage(res.meta.message);
				}
				else if (res.meta.type === dic.CONSTANTS.NotificationTypes.ERROR) {
					this.ns.showErrorMessage(res.meta.message);
				}
				else {
					this.ns.showInfoMessage(res.meta.message);
				}
			}
		}, err => {
		});
	}
}
