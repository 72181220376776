import {Component, ViewEncapsulation} from '@angular/core';
import {GeneralService} from '../../services/general.service';
import {TranslationService} from '../../services/translation.service';
import {dic} from '../../dictionary';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';
import {LookAndFeelService} from '../../services/lookAndFeelService';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
	dic = dic;
	showLanguageMenu = false;
	_ = _;
	isDarkMode: boolean;
	private darkModeSubscription: Subscription;


	constructor(public gs: GeneralService,
				public lfs: LookAndFeelService,
				public translateService: TranslationService) {
		this.darkModeSubscription = this.gs.isDarkMode$.subscribe(value => {
			this.isDarkMode = value;
		});
	}

	darkModeToggle() {
		this.gs.toggleDarkMode(!this.isDarkMode);
	}


	changeLanguage(langObj) {
		if (this.translateService.selectedAppLang !== langObj) {
			this.translateService.changeLanguageApp(langObj);
		}
		this.showLanguageMenu = false;
	}
}
