import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import * as _ from 'lodash';

@Pipe({name: 'safeHtml'})
export class safeHtml implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}
	transform(style) {
		return this.sanitizer.bypassSecurityTrustHtml(style);
	}
}

@Pipe({name: 'formatBytes'})
export class formatBytes implements PipeTransform {
	transform(bytes, decimals) {
		if (bytes === 0) return '0 Bytes';
		let k = 1024,
			dm = decimals || 2,
			sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
			i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}
}

@Pipe({name: 'getTrackingEvent'})
export class getTrackingEvent implements PipeTransform {
	transform(events, eventName) {
		const event: any = _.find(events, {name: eventName});
		if (event) {
			return event.status;
		}
		return 'none';
	}
}

@Pipe({name: 'orderBy'})
export class orderArrayBy implements PipeTransform {
	transform(array, propertyName) {
		// sanity
		if (!Array.isArray(array)) {
			console.log("sort pipe error : not array type");
			return array;
		}

		// order: ascending / descending
		let isDescending = false;
		if (propertyName[0] === '-') {
			isDescending = true;
			propertyName = propertyName.substring(1);
		}

		// sort
		try {
			if (isDescending) {
				return array.sort((a,b) => (a[propertyName] < b[propertyName]) ? 1 : ((b[propertyName] < a[propertyName]) ? -1 : 0));
			}
			else {
				return array.sort((a,b) => (a[propertyName] > b[propertyName]) ? 1 : ((b[propertyName] > a[propertyName]) ? -1 : 0));
			}
		}
		catch (err) {
			console.log(err.message || err);
			return array;
		}
	}
}
