<div class="attachment-preview-overlay bs-enabled" style="z-index: 500;" tabindex="0" [appAutoFocus]="true" (keyup.escape)="onClose.emit();">
	<div class="attachment-preview-container flex-column" [ngClass]="{'full-screen' : isFullScreen, 'mobile-view': gs.isMobile, 'absolute-centralize' : !gs.isMobile}">
		<div class="f-1-0 w-100 overflow-auto position-relative flex-column">
			<div class="flex-row justify-content-between w-100 align-items-center gap-4 p-3 t-bg-primary t-color-primary" style="position: sticky; top: 0; z-index: 50;">
				<div class="flex-column f-1-0 gap-1">
					<h5>Preview Attachment</h5>
					<span style="word-break: break-word; font-size: 13px;">File: {{popupData.name}}</span>
					<hr class="opacity-25 mb-0"/>
				</div>
				<i class="hvr-opacity px-2 align-self-start" style="cursor: pointer; font-size: 1.3rem;"
				   [ngClass]="gs.isMobile ? 'fas fa-arrow-left' : 'zmdi zmdi-close'"
				   (click)="closePreview();"></i>
			</div>

			<!--loader / error message-->
			<div class="d-flex centralize f-1-0" *ngIf="popupData.loading || popupData.error">
				<i *ngIf="popupData.loading" class="fa fa-spin fa-spinner"></i>
				<span *ngIf="popupData.error" class="flxClmn centralize text-danger">{{popupData.error}}</span>
			</div>

			<div class="flex-column f-1-0 w-100" [ngClass]="gs.isMobile ? 'p-1' : 'p-3 overflow-auto'" *ngIf="!popupData.loading && !popupData.error">
				<!--pdf-->
				<pdf-viewer *ngIf="fileExt === 'pdf'" class="animated fadeIn f-1-0 h-100  shadow h-100" style="background: white;"
							[ngStyle]="!gs.isMobile && {'width': '75%', 'max-width': '55vh'}"
							[ngClass]="{'m-auto' : !gs.isMobile}"
							[autoresize]="isFullScreen ? true : false"
							[src]="popupData.pdfSrc"  [render-text]="true" [original-size]="false"></pdf-viewer>

				<!--word doc-->
				<div class="animated fadeIn w-100 d-flex justify-content-center f-1-0 h-100 overflow-auto" style="background: white;" *ngIf="popupData.docUrl">
					<ngx-doc-viewer class="overflow-auto p-4 m-2 shadow" viewer="mammoth" [url]="popupData.docUrl"
									[ngStyle]="{'width': gs.isMobile ? '100%' : '75%', 'max-width': gs.isMobile ? 'unset' : '55vh'}"></ngx-doc-viewer>
				</div>

				<!--sheet-->
				<virtual-scroller *ngIf="popupData.xslRows"
								  class="f-1-0 m-2 h-100" style="background: white;"
								  #attachmentPreviewScroller [items]="popupData.xslRows"
								  [ngClass]="{'is-loading-or-empty' : !popupData.xslRows?.length}">
					<table class="simpleDataTable w-100 overflow-auto" style="table-layout: fixed; word-break: break-word;">
						<tr *ngFor="let row of attachmentPreviewScroller.viewPortItems">
							<td *ngFor="let cell of row">{{cell}}</td>
						</tr>
					</table>
				</virtual-scroller>

				<!--text-->
				<span class="animated fadeIn break-word w-100 f-1-0 h-100"
					  *ngIf="popupData.text">
					{{popupData.text}}
				</span>

				<!--image-->
				<span class="animated fadeIn overflow-auto w-100 f-1-0 h-100" *ngIf="popupData.image">
					<img class="mx-auto w-100" [src]="popupData.image"
						 onload="this.style.maxWidth = `${this.naturalWidth}px`" alt="Attachment" >
				</span>

				<!--html/eml-->
				<div *ngIf="popupData.eml" class="w-100 f-1-0 h-100" [ngClass]="{'border border-secondary-subtle flex-column overflow-auto' : !gs.isMobile}">
					<iframe class="w-100 f-1-0" style="min-height: 100%;" [srcdoc]="popupData.eml | safeHtml"  sandbox="allow-same-origin"
							onload="this.style.minHeight = this.contentDocument.documentElement.scrollHeight + 'px';"></iframe>
				</div>
			</div>

			<div class="d-flex centralize p-2 bg-white cursor-pointer hvr-darken-light opacity-75 border border-secondary-subtle"
				 *ngIf="!popupData.loading && !popupData.error && !gs.isMobile"
				 style="position: absolute; left: 0; bottom: 0;"
				 (click)="isFullScreen = !isFullScreen;">
				<i [ngClass]="isFullScreen ? 'fa fa-compress' : 'fa fa-expand'" style="font-size: 1.5rem;"></i>
			</div>
		</div>
	</div>
</div>
