import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {TranslationService} from '../../services/translation.service';


@Component({
	selector: 'summer-editor',
	template: `
		<iframe id="editorframe" src="summerlib/summer.component.html" frameborder="0"
				 style="width:100%;overflow:auto;flex:1 0; display: flex" [ngStyle]="isIE && {'height':'100%'}"></iframe>`,
})
export class SummerComponent implements OnInit {
	summerOptions: any = null;
	iframe: any = null;
	_html = '';
	isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);

	@Input()
	get html(): string {
		return this._html;
	}

	set html(html: string) {
		this._html = html;
		this.iframe && this.iframe.contentWindow.update && this.iframe.contentWindow.update(html);
	}

	@Input() minHeight?: number;
	@Output() change ? = new EventEmitter<string>();
	@Output() load ? = new EventEmitter<object>();
	@Output() focusin ? = new EventEmitter<object>();

	constructor(private translationService: TranslationService) {
		const vm = this;

		this.summerOptions = {
			focus: true,
			dialogsInBody: true,
			buttons: {
				protect: this.ProtectButton,
				rtl: this.RTLButton.bind(this, 'ltr'),
				ltr: this.RTLButton.bind(this, 'rtl')
			},
			toolbar: [
				['style', ['bold', 'italic', 'underline']],
				['fontface', ['fontname']],
				['textsize', ['fontsize']],
				['fontclr', ['color']],
				['alignment', ['paragraph', 'lineheight']],
				['insert', ['picture']]
			],
			disableResizeEditor: true,
			placeholder: this.translationService.getTranslationText('recipient.replyTextPlaceholder'),
			callbacks: {
				onChange: function (contents, $editable) {
					vm._html = contents;
					vm.change.emit(contents);
				},
				onPaste: function (e) {
					vm._html = e;
					vm.change.emit(e);
				}
			}
		};
	}

	RTLButton(direction, context) {
		const button = context.ui.button({
			contents: '<i class="fa fa-toggle-' + (direction === 'rtl' ? 'right' : 'left') + '"/> ' + direction,
			tooltip: direction === 'rtl' ? 'Right-to-Left' : 'Left-to-Right',
			container: false,
			click: function (e) {
				context.invoke('editor.justify' + (direction === 'rtl' ? 'Right' : 'Left'));

				const range = window.getSelection().getRangeAt(0);
				let startContainer = <HTMLElement>range.startContainer;
				let endContainer = range.endContainer;

				if (startContainer === range.commonAncestorContainer) {
					while (!startContainer.style || !startContainer.style.textAlign) { startContainer = <HTMLElement>startContainer.parentNode; }
					startContainer.style.direction = direction;
				} else {
					const commons = Array.prototype.slice.call(range.commonAncestorContainer.childNodes);
					while (commons.indexOf(startContainer) <= 0) { startContainer = <HTMLElement>startContainer.parentNode; }
					while (commons.indexOf(endContainer) <= 0) { endContainer = endContainer.parentNode; }

					do {
						startContainer.style.direction = direction;
						startContainer = startContainer === endContainer ? null : <HTMLElement>startContainer.nextSibling;
					} while (startContainer);
				}
			}
		});

		return button.render();
	}

	ProtectButton(context) {
		const button = context.ui.button({
			contents: '<i class="fa fa-lock"/> Protect',
			tooltip: 'Hide the content in the mailbox',
			container: false,
			click: function () {
				function createCustomLink(object) {
					const linkNode = window['$']('<code class="protect">' + (object || 'CHANGE ME') + '</code>');
					linkNode.attr('data-attribute-value', object['code']);
					return linkNode[0];
				}

				const selected = window.getSelection().toString();
				context.invoke('editor.insertNode', createCustomLink(selected));
				context.invoke('editor.pasteHTML', '&zwnj;');
			}
		});
		return button.render();
	}

	ngOnInit() {
		if (this.minHeight) {
			this.summerOptions.height = this.minHeight;
		}

		const iframe = this.iframe = window.frames['editorframe'];
		if ((iframe.contentWindow || iframe).init) {
			(iframe.contentWindow || iframe).focusinEvt = () => {
				this.focusin.emit();
			};
			(iframe.contentWindow || iframe).init(this.summerOptions);
			(iframe.contentWindow || iframe).update(this._html);
			this.load.emit(iframe.contentWindow || iframe);
		} else {
			const _timer = setInterval(() => {
				if ((iframe.contentWindow || iframe).init) {
					clearInterval(_timer);
					(iframe.contentWindow || iframe).focusinEvt = () => {
						this.focusin.emit();
					};
					(iframe.contentWindow || iframe).init(this.summerOptions);
					(iframe.contentWindow || iframe).update(this._html);
					this.load.emit(iframe.contentWindow || iframe);
				}
			}, 500);
		}
	}
}
