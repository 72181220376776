import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';
import * as $ from 'jquery';
import {dic} from '../dictionary';
import {SUPPORTED_MIMES} from '../constants';
import * as _ from 'lodash';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class GeneralService {
	dic = dic;

	logoData: any = {
		hoverColor: this.dic.CONSTANTS.trustifiDefault.hoverColor,
		boldColor: this.dic.CONSTANTS.trustifiDefault.boldColor,
		textColor: this.dic.CONSTANTS.trustifiDefault.textColor,
		color: this.dic.CONSTANTS.trustifiDefault.color,
		name: this.dic.CONSTANTS.trustifiDefault.name,
		url: this.dic.CONSTANTS.trustifiDefault.url,

		webAppUrl: this.dic.CONSTANTS.trustifiDefault.webAppUrl
	};

	leafletDefaultIcon = {
		iconUrl: 'css/images/marker-icon.png',
		shadowUrl: 'css/images/marker-shadow.png',
		iconSize: [25, 41],
		iconAnchor: [12, 41],
		popupAnchor: [1, -34],
		shadowSize: [41, 41],
	};

	leafletDangerIcon = {
		iconUrl: 'assets/images/danger-marker.png',
		shadowUrl: 'css/images/marker-shadow.png',
		iconSize: [25, 41],
		iconAnchor: [12, 41],
		popupAnchor: [1, -34],
		shadowSize: [41, 41],
	};

	private decryptionState = new BehaviorSubject('');
	decryptionStateChange = this.decryptionState.asObservable();
	postmarkData: any;

	isDarkMode = new BehaviorSubject<boolean>(false);
	isDarkMode$ = this.isDarkMode.asObservable();


	updateDecryptionState(state) {
		this.decryptionState.next(state);
	}

	constructor(private sanitizer: DomSanitizer,
				private http:HttpClient,) {
		this.logoData.data = this.sanitizer.bypassSecurityTrustResourceUrl(this.logoData.data);
		this.getInitialTheme(); // Load the initial theme when the service is constructed

	}

	defangeMaliciousLinksFromHtml = (html, maliciousLinks) => {
		// Create a temporary DOM element to parse the HTML string
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = html;

		// Iterate through each malicious link
		maliciousLinks.forEach((maliciousLink) => {
			// Defang the malicious link
			const defangedLink = maliciousLink
				.replace(/http/g, 'hxxp')
				.replace(/\./g, '[.]')
				.replace(/\//g, '[/]');

			// Find all `a` elements with the malicious link as href
			const anchorElements = tempDiv.querySelectorAll(`a[href="${maliciousLink}"]`);

			anchorElements.forEach((anchor) => {
				if (anchor.textContent.includes(maliciousLink)) {
					//replace the malicious link with the defanged link using regex
					anchor.textContent = anchor.textContent.replace(new RegExp(maliciousLink, 'g'), defangedLink);
					// Replace the `a` element with a `span` element
					const span = document.createElement('span');
					span.textContent = defangedLink;
					anchor.parentNode.replaceChild(span, anchor);
				}
				else {
					// If the text content is different, just remove the href attribute and change to span
					const span = document.createElement('span');
					span.textContent = anchor.textContent;
					anchor.parentNode.replaceChild(span, anchor);
				}
			});
		});

		return tempDiv.innerHTML;
	}

	defangeMaliciousLinksFromText = (text, maliciousLinks) => {
		// Iterate through each malicious link
		maliciousLinks.forEach((maliciousLink) => {
			// Defang the malicious link
			const defangedLink = maliciousLink
				.replace(/http/g, 'hxxp')
				.replace(/\./g, '[.]')
				.replace(/\//g, '[/]');

			// Replace the malicious link with the defanged link using regex
			text = text.replace(new RegExp(maliciousLink, 'g'), defangedLink);
		});

		return text;
	}

	hexToRgb(hex) {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? [
			parseInt(result[1], 16), // R
			parseInt(result[2], 16), // G
			parseInt(result[3], 16)  // B
		] : null;
	}

	convertHexToHSLValues(H) {
		// Convert hex to RGB first
		let r, g, b;
		if (H.length === 4) {
			r = '0x' + H[1] + H[1];
			g = '0x' + H[2] + H[2];
			b = '0x' + H[3] + H[3];
		} else if (H.length === 7) {
			r = '0x' + H[1] + H[2];
			g = '0x' + H[3] + H[4];
			b = '0x' + H[5] + H[6];
		}
		// Then to HSL
		r /= 255;
		g /= 255;
		b /= 255;
		let cmin = Math.min(r, g, b),
			cmax = Math.max(r, g, b),
			delta = cmax - cmin,
			h = 0,
			s = 0,
			l = 0;

		if (delta === 0) {
			h = 0;
		} else if (cmax === r) {
			h = ((g - b) / delta) % 6;
 } else if (cmax === g) {
			h = (b - r) / delta + 2;
 } else {
			h = (r - g) / delta + 4;
 }

		h = Math.round(h * 60);

		if (h < 0) {
			h += 360;
		}

		l = (cmax + cmin) / 2;
		s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
		s = +(s * 100).toFixed(1);
		l = +(l * 100).toFixed(1);

		return  [h, s, l] ;
	}

	// this function calculates the contrast value between two colors
	// minimal recommended contrast ratio is 4.5, or 3 for larger font-sizes. Otherwise - readability will be bad
	contrastValue(BGcolor, textColor) {
		function luminance(r, g, b) {
			const a = [r, g, b].map(function (v) {
				v /= 255;
				return v <= 0.03928
					? v / 12.92
					: Math.pow( (v + 0.055) / 1.055, 2.4 );
			});
			return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
		}
		function contrast(rgb1, rgb2) {
			const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
			const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
			const brightest = Math.max(lum1, lum2);
			const darkest = Math.min(lum1, lum2);
			return (brightest + 0.05)
				/ (darkest + 0.05);
		}
		return contrast(BGcolor, textColor);
	}

	trackByIndex(index, item) {
		return index;
	}

	initLogoData(res: any) {
		// sanity
		if (!res.lf) {
			res.lf = {
				color: this.dic.CONSTANTS.trustifiDefault.color,
				company: this.dic.CONSTANTS.trustifiDefault.name,
				url: this.dic.CONSTANTS.trustifiDefault.url
			};
		}

		let faviconSrc = this.dic.CONSTANTS.trustifiDefault.favicon;

		if (!res.lf.enabled) {
			this.logoData.data = this.sanitizer.bypassSecurityTrustResourceUrl(this.dic.CONSTANTS.trustifiDefault.logo);
			this.changeFavicon(faviconSrc);
			return;
		}

		this.logoData.color = res.lf.color || this.dic.CONSTANTS.trustifiDefault.color;
		this.logoData.name = res.lf.company || this.dic.CONSTANTS.trustifiDefault.name;
		this.logoData.url = res.lf.url || this.dic.CONSTANTS.trustifiDefault.url;

		const subDomainObj = this.dic.SUB_DOMAINS[this.logoData.name.toLowerCase()];
		if (subDomainObj) {
			this.logoData.webAppUrl = subDomainObj.webAppUrl || this.dic.CONSTANTS.trustifiDefault.webAppUrl;
		}

		// TODO: check if this file exists (__dirname +), if not take the default.
		faviconSrc = `assets/images/favicon/${this.logoData.name.toLowerCase()}/favicon.png`;

		this.logoData.data = res.lf.logo;
		if (this.logoData.data) {
			this.logoData.data = 'data:image/png;base64,' + this.logoData.data;
		}

		this.logoData.data = this.sanitizer.bypassSecurityTrustResourceUrl(this.logoData.data);

		// get color HSL and RGB values
		const colorHSL = this.convertHexToHSLValues(this.logoData.color);
		const colorRGB = this.hexToRgb(this.logoData.color);
		// set "hoverColor" and "boldColor" using the HSL's L(=Lightness)
		const lightness = colorHSL[2];
		const hoverColorLightness = Math.round(lightness + (100 - lightness) * 0.3);
		const boldColorLightness = Math.round(lightness - lightness * 0.3);

		const hoverColor = 'hsl(' + colorHSL[0] + ',' + colorHSL[1] + '%,' + hoverColorLightness + '%)';
		const boldColor = 'hsl(' + colorHSL[0] + ',' + colorHSL[1] + '%,' + boldColorLightness + '%)';
		this.logoData.hoverColor = hoverColor;
		this.logoData.boldColor = boldColor;
		//
		// If the contrast value of white color and user color is 2 or above then apply text color: white. otherwise apply black
		// (pay attention: value of 2 is less then the minimum value of readability in the WCAG standard)
		this.logoData.textColor = this.contrastValue(colorRGB, [255, 255, 255]) >= 2 ? 'white' : 'black';
		//
		this.logoData.outlinedLogoSrc = this.logoData.textColor === 'black' ? 'assets/images/smallLogoOutlineInvert.png' : 'assets/images/smallLogoOutline.png';

		this.changeFavicon(faviconSrc);
		document.head.querySelector('title').innerText = this.logoData.name + ' App';

		document.documentElement.style.setProperty('--bs-primary-h', colorHSL[0] + '');
		document.documentElement.style.setProperty('--bs-primary-s', colorHSL[1] + '%');
		document.documentElement.style.setProperty('--bs-primary-l', colorHSL[2] + '%');
		document.documentElement.style.setProperty('--bs-primary-rgb', colorRGB[0] + ', ' + colorRGB[1] + ', ' + colorRGB[2]);
		document.documentElement.style.setProperty('--bs-text-color', this.logoData.textColor);
		return true;
	}

	private updateStyles(styleElement, styleColors) {
		const rules = Array.from((styleElement.sheet as CSSStyleSheet).cssRules);
		for (let idx = 0; idx < rules.length; idx++) {
			const style = (rules[idx] as CSSStyleRule).style;


			if (style.background.indexOf('linear-gradient') >= 0) {
				style.background = styleColors[idx];
			} else {
				if (style.color) {
					style.color = styleColors[idx];
				}
				if (style.borderColor) {
					style.borderColor = styleColors[idx];
				}
				if (style.backgroundColor) {
					style.setProperty('background-color', styleColors[idx], 'important');
					style.setProperty('color', this.logoData.textColor, 'important');
				}
				// .md-checkbox only:
				if (style.border) {
					style.setProperty('border', '0.2em solid ' + styleColors[idx], 'important');
				}
				//
			}
		}
	}

	setMainLogo() {
		const isUserColorBright = (this.logoData.textColor === 'black');
		let styleColors = [
			this.logoData.color, 		// .btn, btn-rect
			this.logoData.hoverColor, 	// .btn:hover, btn-rect:hover
			this.logoData.color, 		// .btn-rect.color-inverted
			this.logoData.color, 		// .btn-rect.color-inverted span, .btn-rect.color-inverted i
			this.logoData.color,		// #loading-bar
			this.logoData.boldColor, 	// .advanced-wrapper
			this.logoData.color, 		// .track-wrapper
			this.logoData.boldColor, 	// input:focus
			this.logoData.hoverColor,	// .fa-question-circle
			this.logoData.boldColor,	// .fa-icon-style.active, a:hover
			this.logoData.hoverColor,	// .fa-icon-style:hover
			this.logoData.hoverColor,	// a:active
			this.logoData.color,		// general-actions, expand-button:hover
			this.logoData.color,		// #blueSlider
			this.logoData.color, 		// #blueSlider, #blueSliderMobile
			isUserColorBright ? this.logoData.boldColor : this.logoData.hoverColor,	// #blueSlider, #blueSliderMobile
			this.logoData.textColor, 	// .threats-email-toggle.chosen, .threats-email-toggle-mobile.chosen
			this.logoData.color, 		// .md-checkbox color
			this.logoData.textColor, 	// .md-checkbox checkmark color
			isUserColorBright ? 'black' : this.logoData.color, 	// .btn-light
			this.logoData.hoverColor	// .app-container, #blueSlider
		];

		let styleElement = document.getElementById('lookAndFeelStyle') as HTMLStyleElement;
		this.updateStyles(styleElement, styleColors);

		styleColors = [
			this.logoData.color, 		// -webkit-scrollbar-thumb
			this.logoData.hoverColor, 	// -webkit-scrollbar-thumb:hover
			this.logoData.color,		// -webkit-scrollbar-thumb:active
		];

		styleElement = document.getElementById('lookAndFeelStyle2') as HTMLStyleElement;
		this.updateStyles(styleElement, styleColors);

		styleColors = [
			'linear-gradient(to right, ' + this.logoData.color + ' 50%, ' + this.logoData.hoverColor + ' 50%, ' + this.logoData.color + ' 100%)'
		];

	}

	setSummernoteLogo(iframe: Window) {
		const styleColors = [this.logoData.color, this.logoData.hoverColor, this.logoData.color, this.logoData.color, this.logoData.hoverColor, this.logoData.color];
		const styleElement = iframe.document.getElementById('lookAndFeelStyle') as HTMLStyleElement;

		this.updateStyles(styleElement, styleColors);
	}


	validateEmail(email: string) {
		return dic.CONSTANTS.EMAIL_REGEX.test(email);
	}

	changeFavicon(src: string) {
		// random here avoids caching of old favicon
		src = src + '?=' + Math.random();
		const link = document.createElement('link'),
			oldLink = document.getElementById('dynamic-favicon');
		link.id = 'dynamic-favicon';
		link.rel = 'icon';
		link.href = src;
		if (oldLink) {
			document.head.removeChild(oldLink);
		}
		document.head.appendChild(link);
	}

	addHrefBlankTarget() {
		try {
			const emailContentFrame = (document?.getElementById('email-content-frame') as HTMLIFrameElement);
			if (!emailContentFrame || !emailContentFrame.contentDocument) {
				return;
			}
			const linksList = emailContentFrame.contentDocument.getElementsByTagName('a');
			if (!linksList || !linksList.length) {
				return;
			}
			for (let idx = 0; idx < linksList.length; idx++) {
				linksList[idx].setAttribute('target', '_blank');
				linksList[idx].setAttribute('rel', 'noopener noreferrer nofollow');
			}
		} catch (e) {
			return;
		}
	}

	stripCSSStyles(html) {
		if (!html) { return {html: '', css: []}; }

		const styles = [];
		let htmlNoCSS = html.replace(/<script[^<>]*?>[\s\S]+?<\/script>/gi, '');
		htmlNoCSS = htmlNoCSS.replace(/<style[^<>]*?>[\s\S]+?<\/style>/gi, (match) => {
			styles.push(match);
			return '';
		});

		return {html: htmlNoCSS, css: styles};
	}

	formatPhone(pinCodeData) {
		pinCodeData.phone.phone_number_after = pinCodeData.phone.phone_number.replace(/-/g, '');

		let indexNotZero = 0;
		for (; indexNotZero < pinCodeData.phone.phone_number_after.length && pinCodeData.phone.phone_number_after[indexNotZero] === '0'; indexNotZero++) {  }
		pinCodeData.phone.phone_number_after = pinCodeData.phone.phone_number_after.substr(indexNotZero);

		switch (pinCodeData.phone.country_code) {
			case '1':
				pinCodeData.phone_number_after = '(' + pinCodeData.phone.phone_number_after.substr(0, 3) + ') ' + pinCodeData.phone.phone_number_after.substr(3, 3) + '-' + pinCodeData.phone.phone_number_after.substr(6, 4);
				break;
			case '972':
				pinCodeData.phone_number_after = '(' + pinCodeData.phone.phone_number_after.substr(0, 3) + ') ' + pinCodeData.phone.phone_number_after.substr(3, 2) + '-' + pinCodeData.phone.phone_number_after.substr(5, 4);
				break;

			default:
				pinCodeData.phone_number_after = pinCodeData.phone.phone_number_after;
		}
	}

	getCountryCodeFlag(countryCode) {
		let flagIcon;
		if (!countryCode) {
			flagIcon = 'us.png';
			return flagIcon;
		}

		switch (countryCode) {
			case '1':
				flagIcon = 'us.png';
				break;

			case '31':
				flagIcon = 'nl.png';
				break;

			case '41':
				flagIcon = 'ch.png';
				break;

			case '91':
				flagIcon = 'in.png';
				break;

			case '34':
				flagIcon = 'es.png';
				break;

			case '44':
				flagIcon = 'uk.png';
				break;

			case '47':
				flagIcon = 'no.png';
				break;

			case '55':
				flagIcon = 'br.png';
				break;

			case '61':
				flagIcon = 'au.png';
				break;

			case '852':
				flagIcon = 'hk.png';
				break;

			case '972':
				flagIcon = 'il.png';
				break;

			case '64':
				flagIcon = 'nz.png';
				break;

			case '27':
				flagIcon = 'za.png';
				break;

			case '30':
				flagIcon = 'gr.png';
				break;

			case '230':
				flagIcon = 'ml.png';
				break;

			case '507':
				flagIcon = 'pa.png';
				break;
		}
		return flagIcon;
	}

	removeTooltips() {
		// delete tooltip manually from the DOM
		setTimeout(() => {
			const tooltipLeftovers = document.getElementsByTagName('tooltip');
			for (let i = 0; i < tooltipLeftovers.length; i++) {
				document.body.removeChild(tooltipLeftovers[i]);
			}
		}, 300);
	}

	detectMobile() {
		if (navigator.userAgent.match(/webOS/i)
			|| navigator.userAgent.match(/iPhone/i)
			|| navigator.userAgent.match(/iPad/i)
			|| navigator.userAgent.match(/iPod/i)
			|| navigator.userAgent.match(/BlackBerry/i)
			|| navigator.userAgent.match(/Windows Phone/i)
			|| navigator.userAgent.match(/Android/i) // no for Android
		) {
			return true;
		} else {
			return false;
		}
	}

	isChrome() {
		const isChromium = window['chrome'],
			winNav = window.navigator,
			vendorName = winNav.vendor,
			isOpera = winNav.userAgent.indexOf('OPR') > -1,
			isIEedge = winNav.userAgent.indexOf('Edge') > -1,
			isIOSChrome = winNav.userAgent.match('CriOS');

		if (isIOSChrome) {
			return true;
		} else { return isChromium !== null &&
			typeof isChromium !== 'undefined' &&
			vendorName === 'Google Inc.' &&
			isOpera === false &&
			isIEedge === false;
		}
	}

	isSupportedMimeType(mimeType) {
		return SUPPORTED_MIMES.indexOf(mimeType) > -1;
	}

	formatBytes(bytes, decimals) {
		if (bytes === 0) { return '0 Bytes'; }
		const k = 1024,
			dm = decimals || 2,
			sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
			i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	bufferToArrayBuffer(buf) {
		const arrayBuffer = new ArrayBuffer(buf.length);
		const view = new Uint8Array(arrayBuffer);
		for (let i = 0; i < buf.length; ++i) {
			view[i] = buf[i];
		}
		return arrayBuffer;
	}

	filterDuplicateSafeLinks(urlList) {
		const linksMap = {};
		urlList = _.filter(urlList, link => {
			if (link.name) {
				if (linksMap[link.name]) {
					if (link.status === dic.CONSTANTS.threatProtection.status.safe) {
						return false;
					}
				} else {
					if (link.status !== dic.CONSTANTS.threatProtection.status.safe) {
						linksMap[link.name] = link.url;
					} else {
						if (link.name === link.url &&
							_.find(urlList, l => l.status === dic.CONSTANTS.threatProtection.status.safe && l.name === link.name && l.url !== link.url)) {
							return false;
						}
					}
				}
			}
			return true;
		});
		return urlList;
	}

	toggleDarkMode(value: boolean) {
		this.isDarkMode.next(value);
		localStorage.setItem('isDarkMode', String(value));
		if (value) {
			document.body.setAttribute('data-theme', 'dark');
		} else {
			document.body.removeAttribute('data-theme');
		}
	}

	getInitialTheme() {
		const storedTheme = localStorage.getItem('isDarkMode');
		if (storedTheme) {
			this.toggleDarkMode(storedTheme === 'true');
		}
	}

	exportCsv(csvString, fileName) {
		if (!csvString) {
			return;
		}

		try {
			const blob = new Blob([csvString], {type: 'text/csv'});
			if (window.navigator['msSaveOrOpenBlob']) {
				window.navigator['msSaveBlob'](blob, fileName || 'data.csv');
			}
			else {
				const a = $('<a/>', {
					style: 'display:none',
					href: 'data:application/octet-stream;base64,' + btoa(unescape(encodeURIComponent(csvString))),
					download: fileName || 'data.csv',
				}).appendTo('body');
				a[0].click();
				a.remove();
			}
		} catch (ex) {
			console.log(ex);
		}
	}
}
