import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'app-toggle-switch',
	templateUrl: './toggle-switch.component.html',
	styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {

	constructor(private elementRef: ElementRef) {
		this.elementRef.nativeElement.removeAttribute('id');
	}

	// the main model. Represents the model of the switch's "checkbox" input
	@Input() model: any;
	@Output() modelChange = new EventEmitter();
	// an action to perform when the toggle changes
	@Output() onChange = new EventEmitter();
	// determines whether the toggle switch is disabled or not (DEFAULT: false)
	@Input() isDisabled?: boolean;
	// for QA automation or for connecting other labels to the local "checkbox" input with "for" attribute
	@Input() id?: string;

	// generally the toggle switch will come with a text indicator aside of it ("Endabled"/"Disabled"):
	// if you want to suppress this text then give "true" here (DEFAULT: false)
	@Input() hideText?: boolean;
	// if you want to change this text ("Enabled") then give other string here
	@Input() textTrue?: string;
	// if you want to change this text ("Disabled") then give other string here
	@Input() textFalse?: string;

	random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

	ngOnInit(): void {
	}

	fireClick() {
		if (this.isDisabled) {
			return;
		}

		this.modelChange.emit(this.model);
		this.onChange.emit();
	}

}
