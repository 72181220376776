<div class="flxClmn f-1-0" style="overflow: unset;">

	<!--CONTENT: Blocked email-->
	<div class="flxClmn-center vSpace2 w100" style="flex:1 0; padding:15px" *ngIf="step === dic.STEP.Blocked">
		<!--info content block-->
		<div class="flxClmn-center vSpace2" style="flex:4 0; align-items: center; color: var(--gray2);">
			<img src="assets/images/blockedEmail.png" style="width: 20vh; height: 20vh; filter:grayscale(1); opacity:0.3;" />
			<div class="flxClmn-start vSpace1" style="align-items: center">
				<span style="font-size: 1.6rem; font-family: 'Roboto',sans-serif; text-align: center; font-weight: lighter">{{ 'authentication.emailNotAvailable' | translate }}</span>
			</div>
		</div>
	</div>

	<!--CONTENT: view email-->
	<div class="flxClmn" style="flex: 1 0; height: auto; position: relative">
		<div class="content-inner-container vSpace1 h100 flxClmn">
			<!--email view-->
			<div  class="email-outer-container flxClmn" style="flex: 1 0 0; height: auto;"
				  [style.overflow]="gs.isMobile ? '' : 'auto'">

				<div *ngIf="step === dic.STEP.Authenticated"
					 class="expand-button flxClmn-center" style="z-index: 2; align-items: center"
					 (click)="gs.isFullScreen = !gs.isFullScreen">
					<i class="fas fa-expand fa-lg" [tooltip]="('general.fullScreen' | translate)" *ngIf="!gs.isFullScreen"></i>
					<i class="fas fa-compress fa-lg" [tooltip]="('general.exitFullScreen' | translate)" *ngIf="gs.isFullScreen"></i>
				</div>
				<app-encrypt-animation
					[expandInMobile] = "gs.isMobile"
					[iframeId]="'email-content-frame'"
					[encryptedContentInput]="encryptedContentSubject.asObservable()"
					[contentInput]="contentSubject.asObservable()">
				</app-encrypt-animation>
				<div class="email-view" style="flex:1 0;"
					 [ngStyle]="step !== dic.STEP.Authenticated ? {'visibility': 'hidden', 'flex' : '0 1', 'padding' : '0' , 'max-height' : '0'} : {'visibility': 'visible', 'flex' : '1 0' , 'max-height' : '100%'}">
					<div class="email">
						<iframe [ngStyle]="step !== dic.STEP.Authenticated && {'height': '0'}" style="background-color: white;"
								class="email-iframe" id="email-content-frame" src="about:blank" ></iframe>
					</div>
				</div>
			</div>

			<!--attachments-->
			<div class="attachments-body-container"
				 *ngIf="step === dic.STEP.Authenticated && email.content?.attachments?.length">

				<div class="flxRow hSpace2" style="margin: 0;">
					<div class="flxRow" style="flex:1 0; flex-wrap: wrap; overflow: auto"
						 [ngStyle]="{'max-height' : gs.isMobile ? 'none' : '66px'}">
						<attachment-c
							*ngFor="let file of email.content.attachments"
							[attName]="file.name"
							[attSize]="file.size"
							(preview)="previewAttachment = file;"
							(download)="downloadAttachment(file.content, file.filename, file.type)">
						</attachment-c>
					</div>
				</div>
			</div>

			<!--Sender Confirm/Deny Actions Section-->
			<div class="attachments-container flxClmn animated fadeIn buttons-area" style="position: relative; max-height: none; align-items: center; min-height: 235px; box-shadow: 0 0 7px #5373b794; border: dotted 1px #5373b7; border-radius: 5px; flex: 0.7 0; "
				 [ngStyle]="!gs.isMobile && {'padding': '5px 10px'}"
				 *ngIf="step === dic.STEP.Authenticated && !gs.isFullScreen">

				<!--actions body-->
				<div class="attachments-body-container t-bg-primary flxClmn" style="flex: 1 1;">
					<div class="w100 h100 flxClmn attachments-body t-bg-primary" style="flex: 1 0; padding: 10px; min-height: 0;"
						 [ngStyle]="gs.isMobile && {'padding': '5px', 'min-height':'215px'}">
						<div class="flxClmn" style="flex: 1 0;"
							 *ngIf="!email.auth_sender || email.auth_sender.status === dic.CONSTANTS.senderAuthActions.request">
							<!--ACTION SECTION CONTENT: Confirm/Deny buttons-->
							<div class="flxClmn vSpace1 animated fadeIn" style="max-width: 100%; flex: 1 0" *ngIf="!actionToConfirm">
								<div class="flxClmn-around" style="max-width: 100%; flex:1 0; align-items: center">
									<div class="flxClmn-around" style="max-width: 100%; flex: 2 0; align-items: center">
										<div class="actions-label t-color-primary" *ngIf="email" style="font-weight: bold">
											{{ 'sender.confirmIdentity' | translate }}?
											<span *ngIf="emailActionsTooltip" tooltip="{{emailActionsTooltip}}" [hide-delay]="0">
													<i class="far fa-question-circle" style="cursor:pointer;margin-right:3px;"></i>
												</span>
										</div>
										<div class="flxRow hSpace1" style="max-width: 100%;">
											<btn class="large-btn" (action)="actionToConfirm = dic.CONSTANTS.senderAuthActions.reject;">
												{{'sender.deny' | translate}}
											</btn>

											<btn class="large-btn" (action)="actionToConfirm = dic.CONSTANTS.senderAuthActions.approve;">
												{{'general.confirm' | translate}}
											</btn>

										</div>
									</div>
								</div>
							</div>
							<!--ACTION SECTION CONTENT: confirm action-->
							<div class="flxClmn-between"
								 style="flex: 1 0;" *ngIf="actionToConfirm && email.auth_sender.status === dic.CONSTANTS.senderAuthActions.request"
								 [ngStyle]="!gs.isMobile && {'padding': '10px 20px'}">

								<div class="confirm-text t-color-primary" *ngIf="actionToConfirm === dic.CONSTANTS.senderAuthActions.approve">
										<span>
											{{ 'sender.areYouSureConfirm' | translate }}
										</span>
									<ul class="action-details vSpace2">
										<li>{{ 'sender.recipientReceiveConfirmation' | translate }}</li>
									</ul>
								</div>

								<div class="confirm-text t-color-primary" *ngIf="actionToConfirm === dic.CONSTANTS.senderAuthActions.reject">
										<span>
											{{ 'sender.areYouSureDeny' | translate }}
										</span>
									<ul class="action-details vSpace2">
										<li>{{ 'sender.recipientNotified' | translate }}</li>
										<li>{{ 'sender.notSendTheEmail' | translate }}</li>
									</ul>
								</div>

								<div class="flxRow-center hSpace1">
									<btn class="large-btn" (action)="actionToConfirm = '';" variant="outlined">
										{{'general.cancel' | translate}}
									</btn>
									<btn class="large-btn" (action)="senderAuthAction(actionToConfirm);"
										 [isDisabled]="disableActionBtn">
										{{'general.confirm' | translate}}
									</btn>
								</div>
							</div>
						</div>
						<!--ACTION SECTION CONTENT: "Done!" message-->
						<div class="flxClmn-center"
							 style="flex: 1 0; align-items: center" *ngIf="!actionToConfirm && email.auth_sender?.status !== dic.CONSTANTS.senderAuthActions.request"
							 [ngStyle]="!gs.isMobile && {'padding': '10px 20px'}">
							<h3 style="opacity: 0.8; background: linear-gradient( 45deg, transparent , #f0f0f0, transparent); padding: 33px 0; width: 100%; text-align: center;">
								{{email.auth_sender?.status === dic.CONSTANTS.senderAuthActions.approve ? ('sender.senderConfirmedIdentity' | translate) : ('sender.senderDeniedIdentity' | translate)}}
							</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<attachment-preview
		*ngIf="previewAttachment"
		[attachment]="previewAttachment"
		(onClose)="previewAttachment = null;">
	</attachment-preview>
</div>


