import _ from 'lodash';
import {Component, HostListener, OnInit} from '@angular/core';
import fontawesome from '@fortawesome/fontawesome';
import faTrashAlt from '@fortawesome/fontawesome-free-regular/';
import {dic} from './dictionary';
import {GeneralService} from './services/general.service';
import {TranslationService} from './services/translation.service';
import {Router} from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent{
	dic = dic;
	_ = _;

	timeoutIdle;
	isTimeout = false;

	@HostListener('document:mousemove', ['$event'])
	onMouseMove(e) {
		this.resetTimer();
	}

	@HostListener('document:keypress', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		this.resetTimer();
	}

	constructor(public gs: GeneralService,
				public router: Router,
				private translationService: TranslationService) {
		// Init fontawesome
		fontawesome.library.add(faTrashAlt);

		translationService.initLanguage();

		this.resetTimer();
	}

	resetTimer() {
		if (this.isTimeout) {
			return;
		}
		clearTimeout(this.timeoutIdle);
		this.timeoutIdle = setTimeout(() => {
			this.isTimeout = true;
		}, 15 * 60 * 1000); // 15 minutes
	}

	reloadPage() {
		window.location.reload();
	}
}
