import {Component, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import {RouteService} from '../../services/route.service';
import {NotificationService} from '../../services/notification.service';
import {dic} from '../../dictionary';
import {GeneralService} from '../../services/general.service';
import {TranslationService} from '../../services/translation.service';

@Component({
  selector: 'app-secure-reply',
  templateUrl: './secure-reply.component.html',
})
export class SecureReplyComponent implements OnInit{
	constructor(private rs: RouteService,
				public gs: GeneralService,
				private translationService: TranslationService,
				private location: Location,
				private ns: NotificationService) {
	}

	dic = dic;
	random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
	sender: string;
	recipient: string;
	isFormError = false;
	goToReplyInProcess;

	ngOnInit() {
		this.gs.setIsLoadingApp(false);
	}

	getSecureReply = () => {
		if (!this.gs.validateEmail(this.sender) || !this.gs.validateEmail(this.recipient)) {
			this.ns.showErrorMessage(this.translationService.getTranslationText('signup.emailAddressError'));
			this.isFormError = true;
			return;
		}

		this.goToReplyInProcess = true;
		this.rs.getSecureReply(this.sender.toLowerCase(), this.recipient.toLowerCase()).then(link => {
			this.removeFingerprint(link);

			window.location.href = link;

		}, error => {
			this.goToReplyInProcess = false;
			if (error.data) {
				this.ns.showMessage(error.data);
			}
		});
	}

	removeFingerprint(link) {
		try {
			const enc = link.split('#/');
			if (enc?.length === 2) {
				localStorage.removeItem(enc[1]);
			}
			localStorage.removeItem('fp'); // for smart auth
		}
		catch(ex) {
			console.error(ex);
		}
	}
}
