import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {dic} from '../../dictionary';
import {GeneralService} from '../../services/general.service';
import {RouteService} from '../../services/route.service';
import {NotificationService} from '../../services/notification.service';
import {Observable, Subscription} from 'rxjs';
import * as $ from 'jquery';

@Component({
	selector: 'app-encrypt-animation',
	templateUrl: './encrypt-animation.component.html',
	styleUrls: ['./encrypt-animation.component.scss']
})
export class EncryptAnimationComponent implements OnInit, OnDestroy {

	dic = dic;
	logoData: any;
	@Input() expandInMobile: boolean;
	@Input() iframeId: string;
	private encryptedContentSubscription: Subscription;
	@Input() encryptedContentInput: Observable<boolean>;
	encryptedContent: boolean;
	@Output() encryptedContentEmitter?: EventEmitter<boolean> = new EventEmitter();

	private decryptionSubscription: Subscription;
	decryptionInProcess = false;
	decryptionStatus = dic.CONSTANTS.CONTENT_STATUS.INITIAL;

	step: string;
	isMobile: boolean;

	private contentSubscription: Subscription;
	@Input() contentInput: Observable<string>;
	content: string;


	constructor(private rs: RouteService,
				private notificationService: NotificationService,
				private gs: GeneralService) {
		this.logoData = gs.logoData;
	}

	ngOnInit(): void {
		this.encryptedContentSubscription = this.encryptedContentInput.subscribe((value) => {
			this.encryptedContent = value;
		});

		this.contentSubscription = this.contentInput.subscribe((value) => {
			this.content = value;
		});

		this.decryptionSubscription = this.gs.decryptionStateChange.subscribe(state => {
			this.decryptionStatus = dic.CONSTANTS.CONTENT_STATUS.INITIAL;
			this.decryptionInProcess = false;
		});


		this.isMobile = this.gs.detectMobile() && ((window.innerWidth > 0 ? window.innerWidth < 480 : screen.width < 480) || (window.innerHeight > 0 ? window.innerHeight < 480 : screen.height < 480));
		// ^ (checks also landscape) ^
	}

	ngOnDestroy() {
		this.encryptedContentSubscription.unsubscribe();
		this.contentSubscription.unsubscribe();
		this.decryptionSubscription.unsubscribe();
	}

	decryptionProcess(status) {
		if (this.encryptedContent) {
			if (status === dic.CONSTANTS.CONTENT_STATUS.PROCESS) {
				this.decryptionInProcess = true;
				this.decryptionStatus = dic.CONSTANTS.CONTENT_STATUS.PROCESS;
			}
			else {
				if (status === dic.CONSTANTS.CONTENT_STATUS.DECRYPTED) {
					setTimeout(() => {
						this.decryptionStatus = dic.CONSTANTS.CONTENT_STATUS.DECRYPTED;
					}, 1500);
					setTimeout(() => {
						this.decryptData();
					}, 2500);
				}
			}
		}
		else {
			if (status === dic.CONSTANTS.CONTENT_STATUS.DECRYPTED) {
				this.decryptData();
			}
		}
	};

	decryptData(content?) {
		this.decryptionStatus = dic.CONSTANTS.CONTENT_STATUS.INITIAL;
		this.decryptionInProcess = false;
		this.encryptedContent = false;
		if (this.encryptedContentEmitter) {
			this.encryptedContentEmitter.emit(this.encryptedContent);
		}
		setTimeout(() => {
			this.updateIFrameContent(content);
		});
	}

	updateIFrameContent(content?) {
		if (!this.content && !content) {
			return;
		}
		if (content) {
			this.content = content;
		}
		const marginPStyle = `
			p { margin: 0 !important; }
			body {
				word-break: break-word;
				height: auto;
			}
		`;
		const scrollBarStyle = `::-webkit-scrollbar {
                      width: 10px;
                      height: 10px;
                    }
                    ::-webkit-scrollbar-button {
                        width: 0px;
                        height: 0px;
                    }
                    ::-webkit-scrollbar-thumb {
                        background: ${this.logoData.color};
                        border: 1px solid rgba(0, 0, 0, 0.5);
                        border-radius: 50px;
                    }
                    ::-webkit-scrollbar-thumb:hover {
                        background: ${this.logoData.hoverColor};
                    }
                    ::-webkit-scrollbar-thumb:active {
                        background: ${this.logoData.color};
                    }
                    ::-webkit-scrollbar-track {
                        background: #ffffff;
                        border: 1px solid rgba(0,0,0,0.15);
                        border-radius: 50px;
                    }
                    ::-webkit-scrollbar-track:hover {
                        background: #ffffff;
                    }
                    ::-webkit-scrollbar-track:active {
                        background: #ffffff;
                    }
                    ::-webkit-scrollbar-corner {
                        background: transparent;
                    }
                    body {
                    	overflow: auto;
                    }`;

		const iframe = window.frames[this.iframeId];
		if (!iframe) {
			return
		}
		const iframeDoc = iframe.contentDocument || iframe.contentWindow && iframe.contentWindow.document || iframe.document;
		if (iframeDoc) {

			iframeDoc.open();
			iframeDoc.write(this.content);

			// add style (the above rules)
			let style = iframeDoc.createElement('style');
			style.type = 'text/css';
			style.appendChild(iframeDoc.createTextNode(marginPStyle));
			style.appendChild(iframeDoc.createTextNode(scrollBarStyle));
			iframeDoc.head.appendChild(style);
			//end

			iframeDoc.close();

			iframeDoc.addEventListener('load', () => {
				this.adjustIframeHeight(iframe, iframeDoc);
			}, true);

			// apparently the 'load' event is not fired in some IOS devices. this is a fix for them
			if (this.isMobile) {
				setTimeout(() => {
					this.adjustIframeHeight(iframe, iframeDoc);
				});
			}
		}

		this.gs.addHrefBlankTarget();
	}

	adjustIframeHeight = (iframe, iframeDoc) => {
		const body = iframeDoc.body,
			documentEl = iframeDoc.documentElement,
			html = iframeDoc.getElementsByTagName('HTML')[0];

		body.style['overflowY'] = 'hidden';

		let fixHeight = Math.max( body.scrollHeight, body.offsetHeight,
			html.clientHeight, html.scrollHeight, html.offsetHeight, documentEl.clientHeight, documentEl.scrollHeight, documentEl.offsetHeight );

		iframe.style['height'] = fixHeight.toString() + "px";
	}

}
