<div class="attachment-block-container hvr-opacity flxRow hSpace1">
	<div (click)="onDownload();" class="file-icon-container flxRow-center">
		<img style="width: 100%;" [src]="fileIcon" alt="file icon">
	</div>

	<div style="flex: 1 0; position: relative; gap: 3px;" class="flxClmn-center ellipsis">
		<div class="flxRow">
			<span (click)="onDownload();" class="file-name ellipsis">{{ attName || 'Untitled' }}</span>

			<i (click)="previewAttachment();" *ngIf="!isPreviewLoading" class="fas fa-eye" style="margin-left: auto; opacity: 0.7; width: 18px; text-align: end;" [tooltip]="('recipient.attachmentsPreview' | translate)"></i>
			<i class="fa fa-spin fa-spinner" *ngIf="isPreviewLoading" style="margin-left: auto; opacity: 0.7; width: 18px; text-align: end;"></i>
		</div>
		<div class="flxRow" style="height: 16px;" (click)="onDownload();">
			<span *ngIf="attSize" class="file-size">{{ attSize | formatBytes: 2 }}</span>
			<i *ngIf="!isPreviewMode && (downloadProgress ? downloadProgress >= 100 : true)"
			   style="margin-left: auto;" class="fas fa-download"
			   [tooltip]="('recipient.attachmentsDownloadOne' | translate)"></i>
			<div style="height: 16px; margin-left: auto;" *ngIf="downloadProgress && downloadProgress < 100">
				<div class="radial-progress-bar" style="width: 14px; height: 14px; animation-delay: 1s; transition: max-width 0.5s 1.5s; right: 2px;">
					<div class="p-bar" [ngStyle]="{'background' : 'conic-gradient(royalblue 0deg ' + (downloadProgress / 100 * 365) +'deg, #d7d7d7 ' + (downloadProgress / 100 * 365) + 'deg 365deg)'}"></div>
				</div>
			</div>
		</div>
	</div>
</div>
