import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {dic} from "../../../dictionary";
import * as _ from "lodash";
import {NotificationService} from "../../../services/notification.service";
import {RouteService} from "../../../services/route.service";
import {Subject} from "rxjs";


@Component({
	selector: 'app-tracking',
	templateUrl: './tracking.component.html',
	styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnChanges {
	@Input() recipientTrack;
	@Input() emailInfo;

	trackingData = [];

	ngOnChanges(changes: SimpleChanges) {
		if (changes.recipientTrack) {
			this.trackingData = [];
			this.initEvents();
		}
	}

	openLocation(ll) {
		const link = "https://www.google.com/maps/search/?api=1&query=" + ll[0] + "," + ll[1];
		window.open(link, '_blank');
	};

	initEvents = () => {
		this.addEvent('Sent', 'fas fa-envelope', 'Sent');

		if (this.emailInfo.methods?.postmark) {
			this.addEvent('Postmarked', 'fas fa-stamp', 'Postmark');
		}

		this.addEvent('Delivery', 'fas fa-check-circle', 'Delivered');

		this.addEvent('Opened', 'fas fa-envelope-open', !_.find<any>(this.recipientTrack.events_status,{name : 'Read'}) && this.emailInfo.methods?.encrypt_content ? 'Opened' : 'Read');

		if (this.emailInfo.methods?.secure_reply) {
			this.addEvent('Reply Received', 'fas fa-reply', 'Replied');
		}

		if (this.recipientTrack.is_blocked) {
			this.addEvent('Blocked', 'fas fa-ban', 'Blocked');
		}
	}

	addEvent = (name, icon, label) => {
		this.trackingData.push({ name, icon, label});
	}

	getTrackingEventStatus = (eventName: string) => {
		const status = this.recipientTrack.events_status.find(event => event.name === eventName)?.status;
		return status || 'none'; // Return 'none' if status is not found
	}

}
