import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'btn',
    templateUrl: './btn.component.html',
    styleUrls: ['./btn.component.scss']
})
export class BtnComponent implements OnInit {
    @Input() variant: string;
    @Input() isDisabled: boolean;
    @Input() startIcon: string;
    @Input() endIcon: string;
    @Input() color: string;
    @Output() action: EventEmitter<object> = new EventEmitter();
    @Input() loading: any; // {text? : string, flag: boolean, dotAnimation?: boolean} | boolean;
    @Input() btnTooltip: string;
	@Input() btnTooltipDirection: string;

    colorClass = '';
    specialColor = '';

    ngOnInit() {
        if (this.color) {
            if (['success', 'danger', 'warning', 'info'].indexOf(this.color) > -1) {
                this.colorClass = this.color + '-btn';
            } else {
                this.specialColor = this.color;
                this.colorClass = 'color-btn';
            }
        }
    }

    fireClick() {
        if (this.isDisabled || (this.loading === true) || (this.loading && this.loading.flag)) {
            return;
        }

        if (this.action.observed) {
            this.action.emit();
        }
    }
}
