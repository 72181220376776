import {Component, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {dic} from '../../dictionary';
import {WA_URL} from '../../constants';
import {RouteService} from '../../services/route.service';
import {NotificationService} from '../../services/notification.service';
import {GeneralService} from '../../services/general.service';
import * as _ from 'lodash';
import {TooltipDirective} from 'ng2-tooltip-directive';
import {EncryptAnimationComponent} from '../encrypt-animation/encrypt-animation.component';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-outbound-quarantined',
  templateUrl: './outbound-quarantined.component.html',
  styleUrls: ['./outbound-quarantined.component.scss']
})


export class OutboundQuarantinedComponent implements OnInit {

	@ViewChild(EncryptAnimationComponent) encryptAnimationComponent: EncryptAnimationComponent;
	@ViewChildren(TooltipDirective) tooltipDirective;

	dic = dic;
	step: string;
	stepSubject: Subject<string> = new Subject<string>();
	stepDataSubject: Subject<object> = new Subject<object>();
	contentSubject: Subject<string> = new Subject<string>();

	userHash: string;
	showThreats = false;
	disableActionBtn = false;
	encryptContent = false;
	secureSend = false;
	reportToTrustifi = false;
	sendWithTls = true;
	notifySender = false;
	logoData: any;

	email: any = {
		htmlContent: '',
		attachments: [],
		methods: [],
		quarantined_status: '',
		source: '',
		sender: '',
		subject: '',
		reviewer: '',
		reviewed_completed: false
	};

	encryptedContent: boolean;
	encryptedContentSubject: Subject<boolean> = new Subject<boolean>();
	blockNote: string;
	actionToConfirm = '';
	isMobile: boolean;
	emailActionsTooltip = '';
	adminPanelUrl = WA_URL + '/#/index/outbound/adminPolicyManagement';
	showLoader = false;
	isFullScreen = false;
	isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
	loadingEmail = true;
	trackingData;
	recipientTrack;
	showLogs = false;
	sortCreated = '-created';
	sortReviewer = '-reviewer';
	sortAction = '-action';
	sortReviewerActionsBy = '-created';

	invalidPhoneNumberTooltipOptions = {
		'placement': 'top',
		'theme': 'light',
		'tooltip-class': 'errorTooltip',
		'show-delay': 0,
		'trigger': 'click',
		'display': false,
		'display-mobile': false
	};

	constructor(private rs: RouteService,
				private ns: NotificationService,
				public gs: GeneralService) {
		this.logoData = gs.logoData;
	}

	ngOnInit() {
		this.isMobile = this.gs.detectMobile();
		this.showThreats = !!this.isMobile;
		this.step = dic.STEP.Authenticating;
		this.stepSubject.next(this.step);
		this.getMessage();
		this.getEmailTrackingData();
		this.rs.getLfSettings().then(res => {
			this.gs.initLogoData(res);
			this.gs.setMainLogo();
		}, error => {
			if (error.data && error.data.status) {
				this.ns.showMessage(error.data);
			}
		});
	}

	setAccessEnc() {
		const urlSplitted = location.href.split('outbound/');
		if (urlSplitted.length < 2) { return; }
		this.userHash = urlSplitted[1];
		const defaultHeaders = {};
		defaultHeaders[this.dic.HEADERS.xAccessEnc] = this.userHash;
		defaultHeaders[this.dic.HEADERS.xTrustifiSource] = 'miniapp';

		const localStorageFp = localStorage[this.dic.CONSTANTS.quarantinedFp];
		if (localStorageFp) {
			try {
				defaultHeaders[this.dic.HEADERS.x2FaFingerprint] = JSON.parse(localStorageFp);
			} catch (ex) {
				console.log(ex);
			}
		}

		this.rs.setDefaultHeaders(defaultHeaders);
	}

	doActionOnQuarantinedEmail(action) {
		if (this.disableActionBtn) {
			return;
		}
		this.disableActionBtn = true;
		const actionData: any = {action: action};
		if (action === dic.CONSTANTS.quarantinedOutboundAction.handled) {
			actionData.enabled = !this.email.reviewed_completed;
		}

		if (action === dic.CONSTANTS.quarantinedOutboundAction.release) {
			actionData.options = {
				methods: {
					encryptContent: this.encryptContent,
					secureSend: this.secureSend
				},
				sendWithTls: this.sendWithTls,
				notifySender: this.notifySender,
				reportToTrustifi: this.reportToTrustifi
			};
		}

		this.rs.doActionOnQuarantinedOutboundEmail(actionData).then((response) => {
			switch (action) {
				case dic.CONSTANTS.quarantinedOutboundAction.handled:
					this.email.reviewed_completed = !this.email.reviewed_completed;
					break;

				case dic.CONSTANTS.quarantinedOutboundAction.release:
					_.remove(this.email.methods, m => m === dic.CONSTANTS.quarantinedOutboundAction.release);
					_.remove(this.email.methods, m => m === dic.CONSTANTS.quarantinedOutboundAction.remove);
					this.email.quarantined_status = dic.CONSTANTS.quarantinedOutboundStatus.released;
					this.setEmailResult(this.email);
					this.email.reviewed_completed = true;
					break;

				case dic.CONSTANTS.quarantinedOutboundAction.remove:
					_.remove(this.email.methods, m => m === dic.CONSTANTS.quarantinedOutboundAction.release);
					_.remove(this.email.methods, m => m === dic.CONSTANTS.quarantinedOutboundAction.remove);
					this.email.quarantined_status = dic.CONSTANTS.quarantinedOutboundStatus.removed;
					this.setEmailResult(this.email);
					this.email.reviewed_completed = true;
			}

			if (this.email.reviewer_actions && response.log) {
				this.email.reviewer_actions.unshift({created: Date.now(), reviewer: this.email.reviewer, action: response.log});
			}

			this.ns.showInfoMessage(dic.MESSAGES.operationSucceeded);

			this.actionToConfirm = '';
			this.disableActionBtn = false;

		}, error => {
			this.disableActionBtn = false;
			if (error.data) {
				this.ns.showMessage(error.data);
			}
		});
	}

	showEmailContent() {
		if (!this.showThreats) {
			return;
		}

		this.showThreats = false;
		setTimeout(() => {
			this.encryptAnimationComponent.updateIFrameContent(this.email.htmlContent);
		});
	}

	finishLoadingEmail() {
		setTimeout(() => {
			this.loadingEmail = false;
		}, 800); // wait a bit so loading animation will not be cleared to fast
	}

	updateEncryptedContent(encryptedContent) {
		this.encryptedContent = encryptedContent;
	}

	confirmSuccessCb(response) {
		this.step = this.dic.STEP.Authenticated;
		this.stepSubject.next(this.step);
		this.setStepData(response);
	}

	confirmFailureCb = (response) => {
		if (response) {
			this.setStepData(response);
		}
	}

	applyAnimationCb() {
		if (this.encryptedContent) {
			this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.PROCESS);
		}
	}

	private getMessage() {
		this.setAccessEnc();
		this.rs.getQuarantinedOutboundEmailInfo().then(emailInfo => {
			if (emailInfo.status !== dic.STEP.Authenticated) {
				this.encryptedContent = true;
				this.encryptedContentSubject.next(this.encryptedContent);
			}
			this.finishLoadingEmail();
			this.setStepData(emailInfo);
		}, error => {
			this.setStepData(error);
		});
	}

	private setStepData(stepData) {
		this.step = dic.STEP[stepData.status] || dic.STEP.Blocked;
		this.stepSubject.next(this.step);
		this.stepDataSubject.next(stepData);

		switch (stepData.status) {
			case dic.STEP.Blocked:
				this.blockNote = stepData.blockNote || '';
				break;

			case dic.STEP.Authenticated:
				this.email = stepData;
				this.parseContent(stepData);
				this.setEmailResult(stepData);
				this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.DECRYPTED);
		}
	}

	private parseContent(stepData: any) {
		this.email.htmlContent = '';
		this.email.attachments = [];

		if (!stepData.contentInfo) {
			return;
		}

		try {
			this.email.htmlContent = stepData.contentInfo.content;
			this.email.attachments = stepData.contentInfo.sent.attachments;
			this.contentSubject.next(this.email.htmlContent);
		} catch (err) {
			console.error(err);
		}
	}

	private setEmailResult(emailInfo) {
		switch (emailInfo.quarantined_status) {
			case dic.CONSTANTS.quarantinedOutboundStatus.released:
				this.email.statusText = 'Email is released';
				break;

			case dic.CONSTANTS.quarantinedOutboundStatus.pending:
				this.email.statusText = 'Email is pending release';
				break;

			case dic.CONSTANTS.quarantinedOutboundStatus.quarantined:
				this.email.statusText = 'Email is quarantined';
				break;
		}
	}

	getEmailTrackingData() {
		if (this.trackingData) {
			return;
		}

		this.rs.getQuarantinedEmailTracking()
			.then(res => {
				this.trackingData = res.tracking;
				this.recipientTrack = this.trackingData[0];
			}, err => {
				console.log('err ?', err);
			});
	}
}

