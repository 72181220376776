<div *ngIf="!isTimeout" class="app-container">
	<router-outlet></router-outlet>
</div>


<div *ngIf="isTimeout" class="app-container session-expired">
	<div class="content-page-container" [ngClass]="{'mobile' : isMobile}">
		<div class="border-effect flxClmn" [ngStyle]="isIE && {'flex' : '1 0 auto'}" style="flex: 1 0;">
			<div class="content-container flxClmn-center t-bg-primary" style="flex: 1 0 auto; align-items: center; text-align: center; font-family: 'Roboto', sans-serif;">
				<!--Logo header-->
				<app-header class="logo-container"></app-header>

				<img src="assets/images/sessionTimeout.png" style="width: 240px; margin: 0; image-rendering: -webkit-optimize-contrast;">
				<h2 class="t-color-primary" style="font-size: clamp(16px,2.2rem,7vw); font-weight: bold; margin-bottom: 0; ">{{'general.sessionExpired' | translate}}</h2>
				<h3 style="font-size: clamp(14px,1.2rem,5vw); color: var(--gray2);">{{'general.sessionExpiredInactivity' | translate}}</h3>
				<h3 style="font-size: clamp(14px,1.2rem,5vw); color: var(--gray2); font-weight: 400;">{{'general.pleaseRefreshPage' | translate}}</h3>
				<br/>
				<btn style="font-size: 1rem;" (action)="reloadPage();">
					{{ 'general.refresh' | translate }}
				</btn>
			</div>
		</div>
	</div>
</div>
