import { Component, OnInit } from '@angular/core';
import {dic} from '../../../dictionary';
import {RouteService} from '../../../services/route.service';
import {GeneralService} from '../../../services/general.service';

@Component({
  selector: 'app-email-tabs-wrapper',
  templateUrl: './email-tabs-wrapper.component.html',
  styleUrls: ['./email-tabs-wrapper.component.scss']
})
export class EmailTabsWrapperComponent implements OnInit {

	userHash: string;
	dic = dic;
	emailInfo: any;
	userPolicy: any;
	trackingData: any;
	recipientTrack: any;
	currentTab: string;
	secureMethods;

	constructor(private rs: RouteService,
				private gs: GeneralService) {
	}

	ngOnInit() {
		const urlSplitted = location.href.split('tracking/');
		if (urlSplitted.length < 2) { return; }
		this.userHash = urlSplitted[1];
		const defaultHeaders = {};
		defaultHeaders[this.dic.HEADERS.xAccessEnc] = this.userHash;
		defaultHeaders[this.dic.HEADERS.xTrustifiSource] = 'miniapp';

		this.currentTab = this.dic.TAB.Tracking;

		// set x-access-enc for this session
		this.rs.setDefaultHeaders(defaultHeaders);
		// read email track data with the enc
		this.getEmailTrackingData();

		this.rs.getLfSettings().then(res => {
			this.secureMethods = res.allowed_auth_methods || Object.values(dic.CONSTANTS.AUTH_METHOD);
			this.gs.initLogoData(res);
			this.gs.setMainLogo();
		});
	}

	getEmailTrackingData() {
		this.rs.getEmailTracking()
		.then(res => {
			this.emailInfo = res.email;
			this.userPolicy = res.policy;
			this.trackingData = res.tracking;
			this.recipientTrack = this.trackingData[0];

		}, err => {
			console.log('err ?', err);
		});
	}

}
