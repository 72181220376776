import {Component, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {dic} from '../../dictionary';
import {RouteService} from '../../services/route.service';
import {NotificationService} from '../../services/notification.service';
import {GeneralService} from '../../services/general.service';
import {TooltipDirective} from 'ng2-tooltip-directive';
import {EncryptAnimationComponent} from '../encrypt-animation/encrypt-animation.component';
import {Subject} from 'rxjs';
import _ from 'lodash';
import {DownloadFilesService} from '../../services/download-files.service';
import {ACCOUNT_TAKEOVER_PROTECTION_URL, ATTACHMENT_URL} from '../../constants';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {LookAndFeelService} from '../../services/lookAndFeelService';


@Component({
	selector: 'app-account-takeover-protection',
	templateUrl: './account-takeover-protection.component.html',
	styleUrls: ['./account-takeover-protection.component.scss']
})
export class AccountTakeoverProtectionComponent implements OnInit {

	@ViewChild(EncryptAnimationComponent) encryptAnimationComponent: EncryptAnimationComponent;
	@ViewChildren(TooltipDirective) tooltipDirective;

	dic = dic;
	step: string;
	stepSubject: Subject<string> = new Subject<string>();
	stepDataSubject: Subject<object> = new Subject<object>();
	contentSubject: Subject<string> = new Subject<string>();

	userHash: string;
	disableActionBtn = false;

	private encryptedContent: boolean;
	encryptedContentSubject: Subject<boolean> = new Subject<boolean>();
	_ = _;

	isFullScreen = false;
	incidentObj: any;
	showMore = {
		to: false,
		cc: false,
		bcc: false
	}
	getEmailContentInProcess;
	allowViewContent = false;

	actionToConfirm = '';
	setStatusPopup: any;
	previewAttachment;
	accessOnce = false;

	incidentsActionsDic = {
		block24: {display: 'User was blocked for 24 hours', color: 'danger', icon: 'fa fa-ban', name: 'block24'},
		block: {display: 'User was blocked', color: 'danger', icon: 'fa fa-ban', name: 'block'},
		resetPassword: {display: 'User password was reset', color: 'warning', icon: 'fa fa-ban', name: 'resetPassword'},
		disableAccount: {display: 'User account was disabled', color: 'danger', icon: 'fa fa-ban', name: 'disableAccount'},
		removeRestrictions: {display: 'Removed restrictions for user', color: 'success', icon: 'fa fa-check-circle', name: 'removeRestrictions'},
		risky: {display: 'User marked as risky for 24 hours', color: 'warning', icon: 'fa fa-exclamation-triangle', name: 'risky'},
		approved: {display: 'Updated incident status to "Approved"', color: 'success', icon: 'fa fa-check-circle', name: this.dic.CONSTANTS.compromisedIncidentStatus.approved},
		suspicious: {display: 'Updated incident status to "Suspicious"', color: 'warning', icon: 'fa fa-exclamation-triangle', name: this.dic.CONSTANTS.compromisedIncidentStatus.suspicious},
		compromised: {display: 'Updated incident status to "Compromised"', color: 'danger', icon: 'fa fa-times-circle', name: this.dic.CONSTANTS.compromisedIncidentStatus.compromised},
	};

	showIncidentDescription = true;

	constructor(private rs: RouteService,
				private ns: NotificationService,
				private downloadFilesService: DownloadFilesService,
				private http: HttpClient,
				private lfs: LookAndFeelService,
				public gs: GeneralService) {
	}

	ngOnInit() {
			this.step = dic.STEP.Authenticating;
		this.stepSubject.next(this.step);
		this.getMessage();

	}

	setAccessEnc() {
		const urlSplitted = location.href.split('acp/');
		if (urlSplitted.length < 2) { return; }
		this.userHash = urlSplitted[1];
		const defaultHeaders = {};
		defaultHeaders[this.dic.HEADERS.xAccessEnc] = this.userHash;
		defaultHeaders[this.dic.HEADERS.xTrustifiSource] = 'miniapp';

		const localStorageFp = localStorage[this.dic.CONSTANTS.quarantinedFp];
		if (localStorageFp) {
			try {
				defaultHeaders[this.dic.HEADERS.x2FaFingerprint] = JSON.parse(localStorageFp);
			} catch (ex) {
				console.log(ex);
			}
		}

		this.rs.setDefaultHeaders(defaultHeaders);
	}

	confirmSuccessCb(response) {
		this.step = this.dic.STEP.Authenticated;
		this.stepSubject.next(this.step);
		this.setStepData(response);
	}

	confirmFailureCb = (response) => {
		if (response) {
			this.setStepData(response);
		}
	}

	updateEncryptedContent(encryptedContent) {
		this.encryptedContent = encryptedContent;
	}

	applyAnimationCb() {
		if (this.encryptedContent) {
			this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.PROCESS);
		}
	}

	private getMessage() {
		this.setAccessEnc();
		this.rs.getAcpIncident().then(emailInfo => {
			if (emailInfo.status !== dic.STEP.Authenticated) {
				this.encryptedContent = true;
				this.encryptedContentSubject.next(this.encryptedContent);
			}

			this.setStepData(emailInfo);
		}, error => {
			this.setStepData(error);
		});
	}

	private setStepData(stepData) {
		this.step = dic.STEP[stepData.status] || dic.STEP.Blocked;
		this.stepSubject.next(this.step);
		this.stepDataSubject.next(stepData);

		switch (stepData.status) {
			case dic.STEP.Blocked:
				break;

			case dic.STEP.Authenticated:
				if (stepData.incidentObj) {
					this.incidentObj = stepData.incidentObj;
					this.getUserIncidentInfo(stepData, stepData.allowReviewerShowContent);
					this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.DECRYPTED);
				}
				break;

			default:
				this.gs.setIsLoadingApp(false);
				break;
		}
	}

	getUserIncidentInfo(res, isUserAllowedToViewEmailContent=false) {
		if (['suspicious_location_change', 'suspicious_device_change', 'email_breach'].includes(res.incidentObj.incident_type)) {
			isUserAllowedToViewEmailContent = false;
		}
		this.allowViewContent = isUserAllowedToViewEmailContent;

		if (!this.allowViewContent) {
			this.showIncidentDescription = true;
		}

		res.incidentObj.reviewer = res.reviewer;

		if (res.incidentObj.incident_type === 'suspicious_location_change' && res.data) {
			this.prepareIncidentLocationInfo(res.incidentObj, res.data);
		}

		if (res.incidentObj.incident_type === 'suspicious_device_change' && res.data) {
			this.prepareIncidentDeviceInfo(res.incidentObj, res.data);
		}

		if (res.incidentObj.incident_type === 'sending_unusual_amount_of_emails' && res.data?.emails) {
			res.incidentObj.incident_info.emails = [];
			res.data.emails.forEach((emailObj) => {
				emailObj.recipients = emailObj.recipientsIDs.map(itm => itm.email).join(', ');
				res.incidentObj.incident_info.emails.push(emailObj);
			});
		}

		this.gs.setIsLoadingApp(false);
	}

	getIncidentEmailContent = () => {
		// prevent multiple requests
		if (this.incidentObj.emailInfo) {
			this.updateIframeContent(this.incidentObj.emailInfo);
			return;
		}

		this.getEmailContentInProcess = true;

		this.rs.getIncidentEmailContent().then(response => {
			this.incidentObj.emailInfo = response;
			this.updateIframeContent(response);
			this.getEmailContentInProcess = false;
		}, () => {
			this.getEmailContentInProcess = undefined;
		});
	}

	updateIframeContent = (emailData) => {
		const css = `::-webkit-scrollbar {
                      width: 10px;
                      height: 10px;
                    }
                    ::-webkit-scrollbar-button {
                        width: 0px;
                        height: 0px;
                    }
                    ::-webkit-scrollbar-thumb {
                        background: ${this.lfs.color};
                        border: 1px solid rgba(0, 0, 0, 0.5);
                        border-radius: 50px;
                    }
                    ::-webkit-scrollbar-thumb:hover {
                        background: ${this.lfs.hoverColor};
                    }
                    ::-webkit-scrollbar-thumb:active {
                        background: ${this.lfs.color};
                    }
                    ::-webkit-scrollbar-track {
                        background: #ffffff;
                        border: 1px solid rgba(0,0,0,0.15);
                        border-radius: 50px;
                    }
                    ::-webkit-scrollbar-track:hover {
                        background: #ffffff;
                    }
                    ::-webkit-scrollbar-track:active {
                        background: #ffffff;
                    }
                    ::-webkit-scrollbar-corner {
                        background: transparent;
                    }`;

		const style:any = document.createElement('style');
		style.type = 'text/css';
		style.appendChild(document.createTextNode(css));

		setTimeout( () => {
			const iframe:any = document.getElementById('previewframe');
			iframe.src = 'about:blank';
			const iframedoc = iframe.contentDocument || iframe.contentWindow && iframe.contentWindow.document || iframe.document;
			if (iframedoc) {
				iframedoc.open();
				iframedoc.write(emailData.content);
				iframedoc.close();

				iframedoc.head.appendChild(style);
			} else {
				this.ns.showWarnMessage(this.dic.ERRORS.browserNotSupported);
			}
		}, 250);

	}

	prepareIncidentLocationInfo(incidentObj, data) {
		incidentObj.incident_info.locations = [];
		data.devices.forEach((deviceObj) => {
			deviceObj.agent = deviceObj.agent || {};
			if (deviceObj.agent.raw) {
				deviceObj.agent.raw = JSON.parse(deviceObj.agent.raw);
			}
			deviceObj.location.forEach((locationObj) => {
				if (locationObj.ll.length === 2) {
					const marker = {
						icon: _.isEqual(locationObj.ll, incidentObj.incident_info.location[0].ll) ? this.gs.leafletDangerIcon : this.gs.leafletDefaultIcon,
						lat: locationObj.ll[0],
						lng: locationObj.ll[1],
						// message: title,
						// title: title,
						draggable: false
					};

					incidentObj.incident_info.locations.push(marker);
				}
			});
		});

		incidentObj.incident_info.center_location = {
			lat: incidentObj.incident_info.location[0].ll[0],
			lng: incidentObj.incident_info.location[0].ll[1],
			zoom: 6
		};
	}

	prepareIncidentDeviceInfo(incidentObj, data) {
		incidentObj.devices = [];
		data.devices.forEach((deviceObj) => {
			deviceObj.agent = deviceObj.agent || {};
			if (deviceObj.agent.raw) {
				deviceObj.agent.raw = JSON.parse(deviceObj.agent.raw);
			}
			incidentObj.devices.push(deviceObj)
		});
	}

	applyIncidentAction() {
		switch (this.actionToConfirm) {
			case dic.CONSTANTS.accountCompromisedIncidentActions.status.value:
				this.setStatusConfirm();
				break;

			case dic.CONSTANTS.accountCompromisedIncidentActions.blockUser.value:
				this.blockUser();
				break;

			case dic.CONSTANTS.accountCompromisedIncidentActions.removeRestrictions.value:
				this.removeRestrictionsUser();
				break;

			case dic.CONSTANTS.accountCompromisedIncidentActions.blockUser24hours.value:
				this.blockUser24hoursExecute();
				break;

			case dic.CONSTANTS.accountCompromisedIncidentActions.riskyUser24hours.value:
				this.riskyUser24hoursExecute();
				break;

			case dic.CONSTANTS.accountCompromisedIncidentActions.disableUserMailServer.value:
				this.disableUserMailServerExecute();
				break;

			case dic.CONSTANTS.accountCompromisedIncidentActions.resetPasswordMailServer.value:
				this.resetPasswordMailServerExecute();
				break;
		}
	}

	setStatusConfirm() {
		const incidentInfo = {
			incidentId: this.incidentObj._id,
			status: this.setStatusPopup.incident.curStatus.toLowerCase(),
			action: this.dic.CONSTANTS.accountCompromisedIncidentActions.status.value
		};

		this.rs.doActionOnIncidentFingerprint(incidentInfo).then(response => {
			this.actionToConfirm = '';
			this.setStatusPopup = null;
			this.incidentObj.status = incidentInfo.status;
			this.ns.showInfoMessage(dic.MESSAGES.operationSucceeded);

			this.addIncidentAction(incidentInfo.status);
		}, (err) => {
			this.ns.showErrorMessage(err.data?.message);
			this.actionToConfirm = '';
		});
	}

	blockUser() {
		const actionInfo = {
			action: this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser.display,
			incidentId: this.incidentObj._id
		};
		this.rs.doActionOnIncidentFingerprint(actionInfo).then(() => {
			this.ns.showInfoMessage(dic.MESSAGES.operationSucceeded);
			this.actionToConfirm = '';

			this.addIncidentAction('block');
		}, (err) => {
			this.ns.showErrorMessage(err.data?.message);
			this.actionToConfirm = '';
		});
	}

	blockUser24hoursExecute() {
		const actionInfo = {
			action: this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser24hours.display,
			incidentId: this.incidentObj._id
		};
		this.rs.doActionOnIncidentFingerprint(actionInfo).then(() => {
			this.ns.showInfoMessage(dic.MESSAGES.operationSucceeded);
			this.actionToConfirm = '';

			this.addIncidentAction('block24');
		}, (err) => {
			this.ns.showErrorMessage(err.data?.message);
			this.actionToConfirm = '';
		});
	}

	riskyUser24hoursExecute() {
		const actionInfo = {
			action: this.dic.CONSTANTS.accountCompromisedIncidentActions.riskyUser24hours.display,
			incidentId: this.incidentObj._id
		};
		this.rs.doActionOnIncidentFingerprint(actionInfo).then(() => {
			this.ns.showInfoMessage(dic.MESSAGES.operationSucceeded);
			this.actionToConfirm = '';

			this.addIncidentAction('risky');
		}, (err) => {
			this.ns.showErrorMessage(err.data?.message);
			this.actionToConfirm = '';
		});
	}

	disableUserMailServerExecute() {
		const actionInfo = {
			action: this.dic.CONSTANTS.accountCompromisedIncidentActions.disableUserMailServer.display,
			incidentId: this.incidentObj._id
		};
		this.rs.doActionOnIncidentFingerprint(actionInfo).then(() => {
			this.ns.showInfoMessage(dic.MESSAGES.operationSucceeded);
			this.actionToConfirm = '';

		}, (err) => {
			this.ns.showErrorMessage(err.data?.message);
			this.actionToConfirm = '';
		});
	}

	resetPasswordMailServerExecute() {
		const actionInfo = {
			action: this.dic.CONSTANTS.accountCompromisedIncidentActions.resetPasswordMailServer.display,
			incidentId: this.incidentObj._id
		};
		this.rs.doActionOnIncidentFingerprint(actionInfo).then((data) => {
			this.ns.showInfoMessage(dic.MESSAGES.operationSucceeded);
			this.actionToConfirm = '';
		}, (err) => {
			this.ns.showErrorMessage(err.data?.message);
			this.actionToConfirm = '';
		});
	}

	removeRestrictionsUser() {
		const actionInfo = {
			action: this.dic.CONSTANTS.accountCompromisedIncidentActions.removeRestrictions.display,
			incidentId: this.incidentObj._id
		};
		this.rs.doActionOnIncidentFingerprint(actionInfo).then(() => {
			this.ns.showInfoMessage(dic.MESSAGES.operationSucceeded);
			this.actionToConfirm = '';

		}, (err) => {
			this.ns.showErrorMessage(err.data?.message);
			this.actionToConfirm = '';
		});
	}

	addIncidentAction(actionName) {
		const actionData = {
			name: actionName,
			created: (new Date()).toISOString(),
			admin: this.incidentObj.reviewer
		};
		if (this.incidentObj.actions) {
			this.incidentObj.actions.push(actionData)
		}
		else {
			this.incidentObj.actions = [actionData];
		}
	}

	changeIncidentStatus() {
		const curIncident = this.incidentObj;
		curIncident.curStatus = this.incidentObj.status[0].toUpperCase() + this.incidentObj.status.slice(1); // capitalize
		 this.setStatusPopup =  {
			incident: curIncident,
			show: true
		};
	}

	downloadFile(attachment: any, isPreview: boolean, cb) {
		if ((attachment?.progressPercentage && attachment.progressPercentage > 0 && attachment.progressPercentage < 100) || attachment?.isInProcess) {
			return;
		}

		const fullUrl = `${ATTACHMENT_URL}/${ACCOUNT_TAKEOVER_PROTECTION_URL}/attachment/${attachment._id}`;

		const localStorageFp = localStorage[this.dic.CONSTANTS.quarantinedFp];
		const headers = new HttpHeaders()
			.set('x-access-enc', this.userHash)
			.set('x-trustifi-source', 'miniapp')
			.set('x-trustifi-2fa-fingerprint', JSON.parse(localStorageFp));


		const req = new HttpRequest('GET', fullUrl, {
			headers,
			reportProgress: true,
			responseType: 'arraybuffer'
		});

		attachment.isInProcess = true;
		attachment.progressPercentage = 0;

		let count = 0;

		this.http.request(req).subscribe((event: any) => {
			// reporting percentages - updating local variables
			if (event.type === HttpEventType.DownloadProgress) {
				// Update progress styles once in 2 reports - improve UX
				if (count % 2 === 0 && attachment) {
					attachment.progressPercentage = ((event.loaded / (event.total || attachment.size)) * 100);
				}
				if (attachment.progressPercentage >= 100) {
					attachment.progressPercentage = 100;
				}
				count++;
			}
			else if (event.type === HttpEventType.Response) {
				if (attachment) {
					attachment.progressPercentage = 100;
				}
				attachment.isInProcess = false;
				attachment.progressPercentage = 0;

				if (isPreview) {
					return cb(null, event.body);
				}

				let contentType = attachment.type;

				if (this.gs.isChrome() && navigator.userAgent.match(/iPhone/i)) {
					if (contentType ===  'message/rfc822' || attachment.name.endsWith('.csv')) {
						contentType = 'text/plain';
					}
				}

				const blob = new Blob([event.body], {type: contentType});
				if (this.gs.detectMobile() && this.gs.isSupportedMimeType(contentType)) {
					location.href = URL.createObjectURL(blob);
				}
				else {
					this.downloadFilesService.download(blob, attachment.name, contentType);
				}
			}
		}, errResponse => {
			if (errResponse.data && errResponse.data instanceof ArrayBuffer && errResponse.data.byteLength !== undefined ||
				errResponse.error && errResponse.error instanceof ArrayBuffer && errResponse.error.byteLength !== undefined) {
				const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(errResponse.data || errResponse.error)));
				console.error(err);
			} else {
				console.error(errResponse.message);
			}
		});
	}

	setPreviewAttachment(attachmentObj) {
		attachmentObj.previewLoading = true;
		this.downloadFile(attachmentObj, true, (err, attachmentData) => {
			if (err) {
				attachmentObj.previewLoading = false;
				return;
			}

			attachmentObj.previewLoading = false;
			attachmentObj.content = {data: attachmentData};
			this.previewAttachment = attachmentObj;
		});
	}

	openLocation(ll) {
		const link = 'https://www.google.com/maps/search/?api=1&query=' + ll[0] + ',' + ll[1];
		window.open(link, '_blank');
	};
}
