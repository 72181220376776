
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Restangular, RestangularModule} from 'ngx-restangular';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {ngfModule} from 'angular-file';
import {TooltipModule, TooltipOptions} from 'ng2-tooltip-directive';
import {MomentModule} from 'ngx-moment';
import {ClickOutsideModule} from 'ng4-click-outside';
import {NgKnifeModule} from 'ng-knife';
import {InternationalPhoneNumberModule} from 'ngx-international-phone-number';
import {ClipboardModule} from 'ngx-clipboard';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppComponent} from './app.component';
import {RecipientComponent} from './components/recipient/recipient.component';
import {HeaderComponent} from './components/header/header.component';
import {SummerComponent} from './components/summer/summer.component';
import {RouteService} from './services/route.service';
import {DownloadFilesService} from './services/download-files.service';
import {GeneralService} from './services/general.service';
import {TranslationService} from './services/translation.service';
import {NotificationService} from './services/notification.service';
import {ATTACHMENT_URL, BE_URL, Restangular_Attachments} from './constants';
import {formatBytes, getTrackingEvent, safeHtml, orderArrayBy} from './pipes';
import {RouterModuleProvider} from './routes';
import {MainComponent} from './components/main/main.component';
import {EmailTabsWrapperComponent} from './components/tabs/email-tabs-wrapper/email-tabs-wrapper.component';
import {TrackingComponent} from './components/tabs/tracking/tracking.component';
import {AdvancedComponent} from './components/tabs/advanced/advanced.component';
import { QuarantinedEmailComponent } from './components/quarantined-email/quarantined-email.component';
import { SenderComponent } from './components/sender/sender.component';
import { ReviewComponent } from './components/review/review.component';
import { EncryptAnimationComponent } from './components/encrypt-animation/encrypt-animation.component';
import { SearchDropdownComponent } from './components/search-dropdown/search-dropdown.component';
import { PostmarkComponent } from './components/tabs/postmark/postmark.component';
import { OutboundQuarantinedComponent } from './components/outbound-quarantined/outbound-quarantined.component';
import {AuthenticationComponent} from './components/authentication/authentication.component';
import { LoadingAnimationComponent } from './components/loading-animation/loading-animation.component';
import { ArchiveComponent } from './components/archive/archive.component';
import { SecureReplyComponent } from './components/secure-reply/secure-reply.component';
import { ToggleSwitchComponent } from './components/uiComponents/toggle-switch/toggle-switch.component';
import { AttachmentPreviewComponent } from './components/attachment-preview/attachment-preview.component';
import { AttachmentBoxComponent } from './components/attachment-box/attachment-box.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CodeInputModule } from 'angular-code-input';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {BtnComponent} from './components/uiComponents/btn/btn.component';
import { AccountTakeoverProtectionComponent } from './components/account-takeover-protection/account-takeover-protection.component';
import {TrustifiTableComponent} from "./components/uiComponents/trustifi-table/trustifi-table.component";
import {DropdownComponent} from "./components/uiComponents/dropdown/dropdown.component";
import {NgInit, AutoFocusDirective, ScrollToTopFlagDirective, SelectAllText, ShownDirective} from "./directives/directives";
import {LeafletComponent} from "./components/uiComponents/leaflet/leaflet.component";


const defaultTooltipOptions: TooltipOptions = {
	'hide-delay': 0
};

export function RestangularAttachmentsFactory(restangular: Restangular) {
	return restangular.withConfig((RestangularConfigurer) => {
		RestangularConfigurer.setBaseUrl(ATTACHMENT_URL);
	});
}


export function RestangularConfigFactory(RestangularProvider) {
	RestangularProvider.setBaseUrl(BE_URL);
	RestangularProvider.setPlainByDefault(true);
	// RestangularProvider.setDefaultHeaders({timeout: 10000});
}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		RecipientComponent,
		HeaderComponent,
		SummerComponent,
		TrackingComponent,
		MainComponent,
		safeHtml,
		formatBytes,
		getTrackingEvent,
		orderArrayBy,
		EmailTabsWrapperComponent,
		AdvancedComponent,
		QuarantinedEmailComponent,
		SenderComponent,
		ReviewComponent,
		EncryptAnimationComponent,
		AuthenticationComponent,
		SearchDropdownComponent,
		PostmarkComponent,
		OutboundQuarantinedComponent,
		LoadingAnimationComponent,
		ArchiveComponent,
  		ToggleSwitchComponent,
    	SecureReplyComponent,
		BtnComponent,
		AttachmentPreviewComponent,
		AttachmentBoxComponent,
		TrustifiTableComponent,
		DropdownComponent,
		ShownDirective,
		SelectAllText,
		NgInit,
		AutoFocusDirective,
		ScrollToTopFlagDirective,
		LeafletComponent,
  		AccountTakeoverProtectionComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		CodeInputModule,
		RestangularModule.forRoot(RestangularConfigFactory),
		HttpClientModule,
		VirtualScrollerModule,
		ngfModule,
		PdfViewerModule,
		InternationalPhoneNumberModule,
		TooltipModule.forRoot(defaultTooltipOptions as TooltipOptions),
		RouterModuleProvider,
		MomentModule,
		ClickOutsideModule,
		NgKnifeModule,
		BrowserAnimationsModule, // required animations module
		ToastrModule.forRoot(), // ToastrModule added
		ClipboardModule,
		NgxDocViewerModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			},
			defaultLanguage: 'us'
		})
	],
	providers: [
		RouteService,
		DownloadFilesService,
		TranslationService,
		GeneralService,
		NotificationService,
		{ provide: Restangular_Attachments, useFactory:  RestangularAttachmentsFactory, deps: [Restangular] },
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
