import {Component, Input, OnInit, AfterViewInit} from '@angular/core';
import {dic} from '../../../dictionary';
import {RouteService} from '../../../services/route.service';
import {GeneralService} from '../../../services/general.service';
import {NotificationService} from '../../../services/notification.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-advanced',
  templateUrl: './advanced.component.html',
  styleUrls: ['./advanced.component.scss']
})
export class AdvancedComponent implements OnInit {

	@Input()
	set trackingDataInput(value) {
		this.trackingData = value;
	}

	@Input()
	set emailInfoInput(value) {
		this.emailInfo = value;
	}

	@Input()
	set userPolicyInput(value) {
		this.userPolicy = value;
	}

	@Input()
	set secureMethodsInput(methods) {
		this.secureMethods = methods;
	}

	dic = dic;
	trackingData: any;
	emailInfo: any;
	userPolicy: any;
	originalAdvanced: any;

	ddsComposeMethod: boolean;
 	showPassword: boolean;
	isDirty: boolean;
	replyToEMailValidation = true;
	isCustomized = false;
	logoData: any;
	attachmentExist = false;
	secureMethods;

	constructor(private gs: GeneralService,
				private rs: RouteService,
				private ns: NotificationService) {
		this.logoData = gs.logoData;
	}

	ngOnInit() {
		this.originalAdvanced = _.cloneDeep(this.emailInfo.advanced);
  	}

	updateAuthMethod(method, event) {
		if (!this.secureMethods.includes(method)) {
			event.stopPropagation();
			return;
		}

		this.emailInfo.advanced.secure.method_2factor = method;
		this.updateAdvanced();
	}

	validateEmail(email) {
		return this.gs.validateEmail(email);
	}

	forceExpirationMinMax() {
		if (this.emailInfo.advanced.secure.expired_days < dic.CONSTANTS.EXPIRED_DAYS_VALUES.MIN) {
			this.emailInfo.advanced.secure.expired_days = dic.CONSTANTS.EXPIRED_DAYS_VALUES.MIN;
		}
		if (this.emailInfo.advanced.secure.expired_days > dic.CONSTANTS.EXPIRED_DAYS_VALUES.MAX) {
			this.emailInfo.advanced.secure.expired_days = dic.CONSTANTS.EXPIRED_DAYS_VALUES.MAX;
		}
	}

	updateAdvanced() {
		if (_.isEqual(this.originalAdvanced, this.emailInfo.advanced)) { return; }

		this.rs.updateAdvanced(this.emailInfo.advanced).then(res => {
			this.ns.showInfoMessage(dic.MESSAGES.updateAdvancedSuccess);
			this.isDirty = true;
			this.originalAdvanced = _.cloneDeep(this.emailInfo.advanced);
		}, err => {
			console.log('err ?', err);
			if (err.data || err.error) {
				this.ns.showErrorMessage(err.data.message || err.error.message);
			}
		});
	}

}
