import { Component, OnInit } from '@angular/core';
import {dic} from '../../../dictionary';
import {RouteService} from '../../../services/route.service';
import {GeneralService} from '../../../services/general.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-email-tabs-wrapper',
  templateUrl: './email-tabs-wrapper.component.html',
})
export class EmailTabsWrapperComponent implements OnInit {
	dic = dic;
	emailInfo: any;
	userPolicy: any;
	trackingData: any;
	recipientTrack: any;
	currentTab = dic.CONSTANTS.trackingPageTabs.tracking;
	secureMethods;
	getTrackingDataInProcess;
	_ = _;
	advanced;

	constructor(private rs: RouteService,
				public gs: GeneralService) {

	}

	ngOnInit() {
		const userHash = location.href.split('tracking/')[1];
		if (!userHash) {
			return;
		}
		const defaultHeaders = {
			[this.dic.HEADERS.xAccessEnc]: userHash,
			[this.dic.HEADERS.xTrustifiSource]: 'miniapp'
		};

		// set x-access-enc for this session
		this.rs.setDefaultHeaders(defaultHeaders);
		// read email track data with the enc
		this.getEmailTrackingData();

	}

	getEmailTrackingData() {
		this.getTrackingDataInProcess = true;

		this.rs.getEmailTracking().then(res => {
			this.emailInfo = res.email;
			this.userPolicy = res.policy;
			this.trackingData = res.tracking;
			this.advanced = this.gs.deflateAdvanced(this.emailInfo.advanced);
			this.recipientTrack = _.find(res.tracking, {name: this.recipientTrack?.name || ''}) || res.tracking[0];

			this.gs.setIsLoadingApp(false);
			this.getTrackingDataInProcess = false;

		}, err => {
			this.gs.isLoadingApp = false;
			this.getTrackingDataInProcess = false;
		});
	}
}
