import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'attachment-c',
	templateUrl: './attachment-box.component.html',
})
export class AttachmentBoxComponent implements OnInit {
	@Input() attName;
	@Input() attSize;
	@Input() downloadProgress;
	@Output() download: EventEmitter<void> = new EventEmitter<void>();
	@Output() preview: EventEmitter<void> = new EventEmitter<void>();
	@Input() isPreviewMode;
	@Input() isPreviewLoading;

	fileIcon;

	ngOnInit() {
		const fileExt = this.attName?.substring(this.attName?.lastIndexOf('.')+1).toLowerCase() || '';
		switch (fileExt) {
			case 'csv':
			case 'txt':
			case 'pdf':
			case 'png':
			case 'svg':
			case 'html':
			case 'zip':
			case 'json':
			case 'gif':
			case 'xml':
			case 'eml':
				this.fileIcon = `assets/images/file-icons/${fileExt}.png`;
				break;

			case 'xls':
			case 'xlsx':
				this.fileIcon = 'assets/images/file-icons/excel.png';
				break;

			case 'jpg':
			case 'jpeg':
				this.fileIcon = 'assets/images/file-icons/jpg.png';
				break;

			case 'doc':
			case 'docx':
				this.fileIcon = 'assets/images/file-icons/word.png';
				break;

			case 'ppt':
			case 'pptx':
				this.fileIcon = 'assets/images/file-icons/pptx.png';
				break;

			default:
				this.fileIcon = 'assets/images/file-icons/file.png';
				break;
		}
	}

	onDownload() {
		if (this.isPreviewMode) {
			this.previewAttachment();
			return;
		}

		this.download.emit();
	}

	previewAttachment() {
		this.preview.emit();
	}
}
