import { Component, OnInit } from '@angular/core';
import {RouteService} from '../../services/route.service';
import {dic} from '../../dictionary';
import {GeneralService} from '../../services/general.service';
import {NotificationService} from '../../services/notification.service';
import * as _ from 'lodash';
import {DownloadFilesService} from '../../services/download-files.service';
import {Buffer} from 'buffer';
import {Subject} from 'rxjs';
import {LookAndFeelService} from '../../services/lookAndFeelService';


@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {

	blockNote: string;
	showAskAccess = false;
	showAccessText: string;
	showAskAccessBlocked = false;
	dic = dic;
	step: string;
	stepSubject: Subject<string> = new Subject<string>();
	stepDataSubject: Subject<object> = new Subject<object>();
	userHash: string;
	searchEmailText = '';
	emails = [] ;
	emailsOriginal = [] ;
	previewAttachment;
	currentMessage: any;
	loadingArchive = true;
	isSearchEmailFocused = false;
	caseInfo = {};
	chosenEmailId = null; // used for highlighting chosen email item
	loadingEmailInProcess = false;
	showCaseInfo = false;
	showEmailInMobile = false; // flag for navigation between email list and email viewer screens, in mobile view
	showEmailActionsInMobile = false;

	constructor(
		private rs: RouteService,
		public gs: GeneralService,
		public lfs: LookAndFeelService,
		private ns: NotificationService,
		private downloadFilesService: DownloadFilesService) {
  }

  ngOnInit(): void {
		this.step = dic.STEP.Authenticating;
	  this.stepSubject.next(this.step);
	  this.getMessage();

	  // hide email action menu in mobile when clicking on email's iframe (since "click-outside" won't catch it)
	  window.addEventListener('blur', () => {
		  setTimeout(() => {
			  if (document.activeElement.tagName === 'IFRAME') {
				  this.showEmailActionsInMobile = false
			  }
		  });
	  });

	  this.gs.setIsLoadingApp(false);
  }

	private getMessage() {
		this.setAccessEnc();
		this.rs.getArchiveCaseInfo().then(archiveInfo => {
			this.setStepData(archiveInfo);
		}, error => {
			this.setStepData(error);
			this.loadingArchive = false;
		});
	}

	private setStepData(stepData) {
		this.step = dic.STEP[stepData.status] || dic.STEP.Blocked;
		this.stepSubject.next(this.step);
		this.stepDataSubject.next(stepData);
		this.loadingArchive = false;

		switch (stepData.status) {
			case dic.STEP.Blocked:
				this.blockNote = stepData.blockNote || '';
				if (stepData.allowAskAccess) {
					this.showAskAccess = true;
					this.showAskAccessBlocked = false;
					this.showAccessText = 'compose.recipients.askSenderAccess';
				}
				else {
					if (stepData.reason === 'blocked') {
						this.showAskAccess = true;
						this.showAskAccessBlocked = true;
						this.showAccessText = 'compose.recipients.senderNotified';
					}
				}
				break;

			case dic.STEP.Authenticated:
				this.caseInfo = stepData.caseInfo;

				this.emails = stepData.searchResults.emails;
				this.emails = _.orderBy(this.emails, [(item1) => item1._source?.created], ['desc'])
				this.emailsOriginal = _.clone(this.emails);

				if (stepData.searchResults.scrollId) {
					this.applyCaseBatch(stepData.searchResults.scrollId);
				}
				break;
		}
	}

	applyCaseBatch = (scrollId) => {
		this.rs.getArchiveCaseInfoWithScroll({scrollId}).then((response) => {
			if (response.searchResults.emails?.length) {
				this.emails = this.emails.concat(response.searchResults.emails || []);
				this.emails = _.orderBy(this.emails, [(item1) => item1._source?.created], ['desc'])
				this.emailsOriginal = _.clone(this.emails);

				this.searchEmailInResults(this.searchEmailText);
			}

			if (response.searchResults.scrollId) {
				this.applyCaseBatch(response.searchResults.scrollId);
			}
		},(err)=>{
		});
	}


	applyAnimationCb() {

	}

	confirmSuccessCb(response) {
		this.setStepData(response);
	}

	confirmFailureCb = (response) => {
		if (response) {
			this.setStepData(response);
		}
	}

	getArchiveEmailInfo(emailObj) {
		this.loadingEmailInProcess = true;
		this.showEmailInMobile = true;

		this.currentMessage = emailObj;

		if (emailObj.content) {
			this.loadingEmailInProcess = false;
			setTimeout(() => this.updateEmailFrame());
			return;
		}
		this.rs.getArchiveEmailInfo(emailObj._id).then(archiveInfo => {
			emailObj.content = archiveInfo;
			this.loadingEmailInProcess = false;

			if (emailObj.content.parsedContent.attachments?.length) {
				emailObj.content.parsedContent.attachments = emailObj.content.parsedContent.attachments.filter(itm => itm.contentDisposition === 'attachment');
			}

			setTimeout(() => this.updateEmailFrame());
		}, error => {
			this.loadingEmailInProcess = true;
		});
	}

	setAccessEnc() {
		const urlSplitted = location.href.split('archive/');
		if (urlSplitted.length < 2) { return; }
		this.userHash = urlSplitted[1];
		const defaultHeaders = {};
		defaultHeaders[this.dic.HEADERS.xAccessEnc] = this.userHash;
		defaultHeaders[this.dic.HEADERS.xTrustifiSource] = 'miniapp';

		const localStorageFp = localStorage[this.dic.CONSTANTS.localStorageFp];
		if (localStorageFp) {
			try {
				defaultHeaders[this.dic.HEADERS.x2FaFingerprint] = JSON.parse(localStorageFp);
			} catch (ex) {
				console.log(ex);
			}
		}

		this.rs.setDefaultHeaders(defaultHeaders);
	}

	searchEmailInResults(searchTerm) {
		if (!searchTerm) {
			this.emails = _.clone(this.emailsOriginal);
			this.searchEmailText = null;
			return;
		}

		searchTerm = searchTerm.toLowerCase();

		this.emails = _.filter(this.emailsOriginal, (email) => {
			const isFound = this.searchTextExecute(email, searchTerm);
			if (isFound) {
				return true;
			}
			return false;
		});
	}

	searchTextExecute(email, searchTerm) {
		if (email._source.rcptTo) {
			for (let i = 0; i < email._source.rcptTo.length; i++) {
				if (email._source.rcptTo[i].indexOf(searchTerm) > -1) {
					return true;
				}
			}
		}
		return (email._source.subject.toLowerCase().indexOf(searchTerm) > -1 ||
			email._source.from.toLowerCase().indexOf(searchTerm) > -1);
	}

	clearSearchEmail() {
		this.emails = _.clone(this.emailsOriginal);
		this.searchEmailText = null;
	}

	downloadEml = (email) => {
		if (!email) {
			return;
		}

		const attachment = {
			content: email.content.rawMail,
			name: `${email.content.parsedContent.subject}.eml`,
			contentType: 'message/rfc822'
		};
		this.downloadFilesService.download(attachment.content, attachment.name, attachment.contentType);
	}

	printEmail(email) {
		if (!email) {
			return;
		}

		const emailData = email.content.parsedContent;
		const getBasicHeadersHtml = function () {
			let headers = `<div><b>${emailData.from && emailData.from.text || ''}</b></div>
                <hr style="border-width: 1px; border-color: black">
                <br><div>
                <span style="padding-right: 54px;">From: </span>${email.content.parsedContent.from && email.content.parsedContent.from.text || ''}</div><div>
                <span style="padding-right: 60px;">Sent: </span>${email._source.created && (new Date(email._source.created)).toUTCString()}</div><div>
                ${email.content.parsedContent.to && email.content.parsedContent.to.text ? `<span style="padding-right: 72px;">To:   </span>${email.content.parsedContent.to.text}</div><div>` : ''}
                ${email.content.parsedContent.cc && email.content.parsedContent.cc.text ? `<span style="padding-right: 72px;">Cc:   </span>${email.content.parsedContent.cc.text}</div><div>` : ''}
                ${email.content.parsedContent.bcc ? `<span style="padding-right: 72px;">Bcc:   </span>${email.content.parsedContent.bcc}</div><div>` : ''}
                <span style="padding-right: 42px;">Subject: </span>${emailData.subject}</div>`;

			if (email._source.attachments && email._source.attachments.length) {
				headers += `<div><span style="padding-right: 10px;">Attachments: </span>${email._source.attachments.join(', ')}</div>`;
			}
			headers += '<br><br>';
			return headers;
		};

		const printTab = window.open();
		printTab.document.write(getBasicHeadersHtml() + (emailData.html || emailData.text || ''));
		printTab.document.close();
		printTab.print();
	}

	downloadAttachment(data, name, type) {
		if (!data || !name || !type) {
			return;
		}

		const attachmentBuffer = Buffer.from(data, 'base64');

		if (attachmentBuffer) {
			const blob = new Blob([attachmentBuffer], {type});
			if (this.gs.detectMobile() && this.gs.isSupportedMimeType(type)) {
				if (this.gs.isChrome() && navigator.userAgent.match(/iPhone/i)) {
					const reader:any = new FileReader();
					reader.onload = function (e) {
						window.location.href = reader.result;
					};
					reader.readAsDataURL(blob);
				}
				else {
					location.href = URL.createObjectURL(blob);
				}
			}
			else {
				// general download
				this.downloadFilesService.download(attachmentBuffer, name, type);
			}
		} else {
			return; // Add Notification & Errors - Before too
		}
	}

	updateEmailFrame() {
		const iframe = window.frames['activeEmailFrame'];
		if (!iframe) {
			return;
		}
		const iframeDocument = iframe.contentDocument || iframe.contentWindow && iframe.contentWindow.document || iframe.document;

		/*let iframeDocument = document.querySelector('#activeEmailFrame');
		if (!iframeDocument || !iframeDocument.contentWindow) return;

		iframeDocument = iframeDocument.contentWindow.document;*/
		let content = this.currentMessage.content.parsedContent.html || this.currentMessage.content.parsedContent.text || '';
		let protectStyle = '';
		if (!content) {
			content = '';
		}
		if (content.includes('<code class="protect">')) {
			protectStyle = `<style>
                                code.protect { color: darkred; visibility: hidden; }
                                code.protect:before { visibility: visible; content: '[PROTECTED] ' }
                                code.protect:hover { visibility: visible; color:limegreen; content: unset; }
                            </style>`;
		}
		const marginStyles = `<style>p {margin:0;padding:0;} html { padding: 0 3% !important;}</style>`;

		const color = this.lfs.color;
		const hoverColor = this.lfs.hoverColor;
		const boldColor = this.lfs.boldColor;

		const scrollBarStyle = `<style>
                                ::-webkit-scrollbar  { width: 10px; height: 10px; }
                                ::-webkit-scrollbar-button { width: 0; height: 0; }
                                ::-webkit-scrollbar-thumb { border: 1px solid rgba(0, 0, 0, 0.5); border-radius: 50px;}
                                ::-webkit-scrollbar-track { background: #ffffff;  border: 1px solid rgba(0,0,0,0.15); border-radius: 50px; }
                                ::-webkit-scrollbar-track:hover { background: #ffffff; }
                                ::-webkit-scrollbar-track:active { background: #ffffff; }
                                ::-webkit-scrollbar-corner {  background: transparent; }
                                ::-webkit-scrollbar-thumb {  background-color: ${color}; }
                                ::-webkit-scrollbar-thumb:hover { background-color: ${hoverColor}; }
                                ::-webkit-scrollbar-thumb:active { background: ${boldColor}; }
                              </style>`;

		iframeDocument.open('text/html', 'replace');
		iframeDocument.write(marginStyles + scrollBarStyle + protectStyle + content);
		iframeDocument.close();

		if (this.gs.isMobile) { // on mobile: display full email content on screen with scroll (no max-height)
			const body = iframeDocument.body,
				documentEl = iframeDocument.documentElement,
				html = iframeDocument.getElementsByTagName('HTML')[0];

			body.style['overflowY'] = 'hidden';

			let fixHeight = Math.max( body.scrollHeight, body.offsetHeight,
				html.clientHeight, html.scrollHeight, html.offsetHeight, documentEl.clientHeight, documentEl.scrollHeight, documentEl.offsetHeight );
			fixHeight = fixHeight + 15;

			iframe.style['height'] = fixHeight.toString() + 'px';

			// listen to screen orientation and recalculate iframe height on change
			   /*$(window).on( "orientationchange", function( event ) {

				fixHeight = Math.max( body.scrollHeight, body.offsetHeight,
					html.clientHeight, html.scrollHeight, html.offsetHeight );
				fixHeight = fixHeight + 15;

				iframe.style['height'] = fixHeight.toString() + "px";

				console.log(fixHeight);*/

				// safest way to check orientation:
				/*const portrait = (window.innerHeight && window.innerWidth) ? (window.innerHeight > window.innerWidth) : (screen.height > screen.width);
				const landscape = (window.innerHeight && window.innerWidth) ? (window.innerHeight < window.innerWidth) : (screen.height < screen.width);
				if (portrait) {

				}
				else if (landscape) {

				}
			});*/
		}

	}

	askForAccess() {

	}
}
