<div class="track-wrapper t-bg-primary t-color-primary" *ngIf="trackingData">
	<app-header class="desktop-header"></app-header>
	<app-header class="mobile-header"></app-header>
	<div class="title-and-tabs">
		<div class="title">{{'mailbox.tracking.emailInformation' | translate }}:
			<span class="email-subject">{{emailInfo.sent.title}}</span>
		</div>
		<div class="tabs" [ngClass]="{'with-postmark': emailInfo.methods.postmark}">
			<div class="tab">
				<a [ngClass]="{'active' : currentTab === dic.TAB.Tracking}"
				   (click)="currentTab = dic.TAB.Tracking">{{'mailbox.general.tabs.tracking' | translate}}</a>
			</div>
			<div class="tab">
				<a [ngClass]="{'active' : currentTab === dic.TAB.Advanced}"
				   (click)="currentTab = dic.TAB.Advanced">{{'mailbox.general.tabs.advanced' | translate}}</a>
			</div>
			<div class="tab" *ngIf="emailInfo.methods.postmark">
				<a [ngClass]="{'active' : currentTab === dic.TAB.Postmark}"
				   (click)="currentTab = dic.TAB.Postmark">{{'mailbox.general.tabs.postmark' | translate}}</a>
			</div>
		</div>
		<div class="tab-wrapper">
			<app-tracking *ngIf="currentTab === dic.TAB.Tracking"
						  [trackingDataInput]="trackingData"
						  [recipientTrackInput]="recipientTrack"
						  [emailInfoInput]="emailInfo">
			</app-tracking>
			<app-advanced *ngIf="currentTab === dic.TAB.Advanced"
						  [trackingDataInput]="trackingData"
						  [emailInfoInput]="emailInfo"
						  [userPolicyInput]="userPolicy"
						  [secureMethodsInput]="secureMethods">
			</app-advanced>
			<app-postmark *ngIf="currentTab === dic.TAB.Postmark">
			</app-postmark>
		</div>
	</div>
</div>
