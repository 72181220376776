import {Injectable} from '@angular/core';
import {dic} from '../dictionary';
import {RouteService} from './route.service';
import {NotificationService} from './notification.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class LookAndFeelService {

	constructor(private rs:RouteService,
				private http:HttpClient,
				private route:Router,
				private ns:NotificationService) {
		this.applyColor(this.color);
	}

	// default L&F values
	corpName = dic.CONSTANTS.trustifiDefault.name;
	corpUrl = dic.CONSTANTS.trustifiDefault.url;
	webAppUrl = dic.CONSTANTS.trustifiDefault.webAppUrl;
	color = dic.CONSTANTS.trustifiDefault.color;
	logo = dic.CONSTANTS.trustifiDefault.logo;
	helpUrl = 'https://docs.trustifi.com/docs/opening-a-trustifi-encrypted-email';

	isLfEnabled;

	hoverColor;
	textColor;
	opaqueColorLo;
	boldColor;

	getLfSettings() {
		// secure reply page lf settings
		if (this.route.url.split('/')[1] === dic.CONSTANTS.appstates.reply && !!this.route.url.split('/')[2]) {
			this.rs.getSecureReplyLfSettings(this.route.url.split('/')[2]).then(res => {
				this.processLfSettings(res);
			}, err => {
				if (err.data?.status) {
					this.ns.showMessage(err.data);
				}
			});
		}

		// normal lf settings
		else {
			this.rs.getLfSettings().then(res => {
				this.processLfSettings(res);
			}, err => {
				if (err.data?.status) {
					this.ns.showMessage(err.data);
				}
			});
		}
	}

	processLfSettings = (res) => {
		if (res?.lf?.enabled) {
			this.isLfEnabled = true;

			this.corpName = res.lf.company														|| this.corpName;
			this.corpUrl = res.lf.url															|| this.corpUrl;
			this.webAppUrl = dic.SUB_DOMAINS[(res.lf.company || '').toLowerCase()]?.webAppUrl	|| this.webAppUrl;
			this.color = res.lf.color															|| this.color;
			this.logo = res.lf.logo ? 'data:image/png;base64,' + res.lf.logo					: this.logo;
			this.helpUrl = res.lf.ma_auth_help_url || this.helpUrl;

			if (res.lf.company) {
				// app title
				document.head.querySelector('title').innerText = res.lf.company + ' App';

				// favicon
				// const faviconSrc = `assets/images/favicon/${res.lf.company.toLowerCase()}/favicon.png`;
				// this.changeFavicon(faviconSrc);
			}

			// lf color
			this.applyColor(this.color);
		}
	}

	applyColor(color) {
		// get color HSL and RGB values
		const colorHSL = this.convertHexToHSLValues(color);
		const isDarkMode = document.body.getAttribute('data-theme') === 'dark';
		colorHSL[1] = isDarkMode ? Math.min(colorHSL[1], 33) : colorHSL[1];
		colorHSL[2] = isDarkMode ? Math.max(colorHSL[2], 55) : colorHSL[2];

		const colorRGB = parseHexColor(hslToHex(colorHSL[0], colorHSL[1], colorHSL[2]));

		this.color = color;

		// If the contrast value of white color and user color is 2 or above then apply text color: white. otherwise apply black
		// (NOTE: value of 2 is less then the minimum value of readability in the WCAG standard)
		const textColor = contrastValue(colorRGB,[255,255,255]) >= 2 ? "white" : "black";
		this.textColor = textColor;

		this.hoverColor = "hsl(" + colorHSL[0] + "," + colorHSL[1] + "%," + (colorHSL[2] + 15) + "%)";
		this.boldColor = "hsl(" + colorHSL[0] + "," + colorHSL[1] + "%," + (colorHSL[2] - 15) + "%)";
		this.opaqueColorLo = "hsl(" + colorHSL[0] + "," + colorHSL[1] + "%," + colorHSL[2] + "%,"+ 90+"%)"; // reduction of 10% in opacity

		document.documentElement.style.setProperty('--bs-primary-h', colorHSL[0] + '');
		document.documentElement.style.setProperty('--bs-primary-s', colorHSL[1] + '%');
		document.documentElement.style.setProperty('--bs-primary-l', colorHSL[2] + '%');
		document.documentElement.style.setProperty('--bs-primary-rgb', colorRGB[0] + ', ' + colorRGB[1] + ', ' + colorRGB[2]);
		document.documentElement.style.setProperty('--bs-text-color', textColor);
	}

	changeFavicon(src: string) {
		this.checkFileExists(src).then(isExist => {
			if (isExist) {
				console.log('file found!');
				// random here avoids caching of old favicon
				src = src + '?=' + Math.random();
				const link = document.createElement('link');
				const oldLink = document.getElementById('dynamic-favicon');
				link.id = 'dynamic-favicon';
				link.rel = 'icon';
				link.href = src;
				if (oldLink) {
					document.head.removeChild(oldLink);
				}
				document.head.appendChild(link);
			}
		});
	}

	checkFileExists(filePath: string): Promise<boolean> {
		return new Promise((resolve) => {
			this.http.head(filePath, { observe: 'response' }).subscribe({
				next: () => resolve(true),
				error: () => resolve(false)
			});
		});
	}

	convertHexToHSLValues(H) {
		// Convert hex to RGB first
		let r = 0, g = 0, b = 0;
		if (H.length === 4) {
			r = parseInt("0x" + H[1] + H[1], 16);
			g = parseInt("0x" + H[2] + H[2], 16);
			b = parseInt("0x" + H[3] + H[3], 16);
		} else if (H.length === 7) {
			r = parseInt("0x" + H[1] + H[2], 16);
			g = parseInt("0x" + H[3] + H[4], 16);
			b = parseInt("0x" + H[5] + H[6], 16);
		}
		// Then to HSL
		r /= 255;
		g /= 255;
		b /= 255;
		let cmin = Math.min(r,g,b),
			cmax = Math.max(r,g,b),
			delta = cmax - cmin,
			h = 0,
			s = 0,
			l = 0;

		if (delta === 0)
			h = 0;
		else if (cmax === r)
			h = ((g - b) / delta) % 6;
		else if (cmax === g)
			h = (b - r) / delta + 2;
		else
			h = (r - g) / delta + 4;

		h = Math.round(h * 60);

		if (h < 0)
			h += 360;

		l = (cmax + cmin) / 2;
		s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
		s = +(s * 100).toFixed(1);
		l = +(l * 100).toFixed(1);

		return  [h, s, l] ;
	}
}

// this function calculates the contrast value between two colors
// minimal recommended contrast ratio is 4.5, or 3 for larger font-sizes. Otherwise - readability will be bad
function contrastValue(BGcolor,textColor) {
	return contrast(BGcolor,textColor)
}

function luminance(r, g, b) {
	const a = [r, g, b].map((v) => {
		v /= 255;
		return v <= 0.03928
			? v / 12.92
			: Math.pow( (v + 0.055) / 1.055, 2.4 );
	});
	return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}
function contrast(rgb1, rgb2) {
	const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
	const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
	const brightest = Math.max(lum1, lum2);
	const darkest = Math.min(lum1, lum2);
	return (brightest + 0.05) / (darkest + 0.05);
}

function parseHexColor(col) {
	let num = parseInt(col.slice(1),16);
	return [num >> 16, (num >> 8) & 0x00FF, num & 0x0000FF];    //RGB
}

function hslToHex(h, s, l) {
	l /= 100;
	const a = s * Math.min(l, 1 - l) / 100;
	const f = n => {
		const k = (n + h / 30) % 12;
		const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
		return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
	};
	return `#${f(0)}${f(8)}${f(4)}`;
}
