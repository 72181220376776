import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
	selector: 'loader',
	template: '<div class="trustifi-loader" [ngStyle]="{\'height\' : height ? height + \'px\' : \'\', \'width\' : width ? width + \'px\' : \'\' }">\n' +
		'         <div class="trustifi-loader-animation"></div>\n' +
		'         <svg xmlns="http://www.w3.org/2000/svg" class="center trustifi-logo-loader" style="isolation:isolate" viewBox="0 0 175 175" filter="brightness(1.0)"><defs><clipPath id="_clipPath_MsIBpX8VoDKCS77dL1mgFIQQnD6QIQFQ"><rect width="175" height="175"></rect></clipPath></defs><g><path d="M 86.82 81.975 L 163.643 35.136 L 163.643 24.645 L 87.5 11.169 L 11.357 24.645 L 11.357 35.696 L 86.82 81.975 Z"  ></path><path d="M 163.643 56.01 L 119.502 82.924 L 152.178 112.394 C 159.549 102.348 163.643 90.325 163.643 77.828 L 163.643 56.01 Z"  ></path><path d="M 106.036 95.038 L 116.423 84.801 L 86.792 102.868 L 58.869 85.743 L 68.3 95.038 L 35.137 124.947 C 35.414 125.159 35.677 125.383 35.959 125.592 L 87.5 163.831 L 139.042 125.592 C 139.202 125.474 139.35 125.345 139.509 125.225 L 106.036 95.038 Z"  ></path><path d="M 11.357 56.607 L 11.357 77.828 C 11.357 90.174 15.358 102.057 22.561 112.03 L 54.606 83.129 L 11.357 56.607 Z"  ></path></g></svg>\n' +
		'      </div>',
})
export class LoaderComponent implements OnInit{
	@Input() height?;
	@Input() width?;
	@Input() spinnerWidth?;

	constructor(private elRef: ElementRef) {
	}

	ngOnInit() {
		const ratio = 16.666666666666668;

		this.height = this.height || this.elRef.nativeElement.parentElement.clientHeight;
		this.width = this.width || this.height;
		this.spinnerWidth = this.width / ratio;
		if ( this.spinnerWidth > 9 ) {
			this.spinnerWidth = 9;
		}
	}
}
