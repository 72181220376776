import {Component, EventEmitter, Input, OnDestroy, NgZone, OnInit, Output, ViewChild, ViewChildren} from '@angular/core';
import {dic} from '../../dictionary';
import {PinCode} from '../../types';
import {Observable, Subscription} from 'rxjs';
import {GeneralService} from '../../services/general.service';
import {NotificationService} from '../../services/notification.service';
import {RouteService} from '../../services/route.service';
import {TooltipDirective} from 'ng2-tooltip-directive';
import {
	EMAIL_ACCESS_URL,
	OUTBOUND_QUARANTINED_URL,
	QUARANTINED_URL,
	REVIEW_URL,
	ARCHIVE_URL,
	ACCOUNT_TAKEOVER_PROTECTION_URL
} from '../../constants';
import {AuthService} from '../../services/auth.service';
import {TranslationService} from '../../services/translation.service';
import {LookAndFeelService} from '../../services/lookAndFeelService';

@Component({
	selector: 'app-authentication',
	templateUrl: './authentication.component.html',
	styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit, OnDestroy {

	@ViewChildren(TooltipDirective) tooltipDirective;
	@ViewChild('onlySMS') onlySMS;

	private flagIcon: string;
	isVoiceSupported: boolean;
	private stepDataSubscription: Subscription;
	@Input() stepDataInput: Observable<object>;
	stepData: any;

	private smartAuthenticationSubscription: Subscription;
	@Input() smartAuthenticationInput?: Observable<object>;
	useSmartAuthentication = false;
	localMetrics: any;

	private confirmButtonSubscription: Subscription;
	@Input() confirmButtonInput?: Observable<string>;
	confirmButtonTxt = 'Authenticate';

	@Input() userHash: string;
	@Input() parentComponent: string;

	@Output() confirmSuccessEmitter?: EventEmitter<object> = new EventEmitter();
	@Output() confirmFailureEmitter?: EventEmitter<object> = new EventEmitter();
	@Output() applyAnimationEmitter?: EventEmitter<string> = new EventEmitter();
	@Output() setEmailResultEmitter?: EventEmitter<object> = new EventEmitter();

	pinCodeData: PinCode = {
		phone: {country_code: '', phone_number: '', phone_number_after: ''},
		type: 'text',
		resendType: 'text',
		phone_number_after: '',
		pinCode: ''
	};

	dic = dic;
	step = dic.STEP.Authenticating;
	validationAnimationState = '';
	errorMsg: string;
	emailHint: string;
	phoneHint: string;
	passwordHint: string;
	authPinCodeMethod: string;
	emailAuth: string;
	ssoType: string;
	enableResend = false;
	showWrongPhoneBtn = true;
	showAuthChangeBtn = true;
	disableConfirmAuth = false;
	password: string;
	totpCode: string;
	isOriginalSender = false;
	showPassword: boolean;
	localStorageKey: string;
	requestHeader: string;
	requestUrl: string;

	constructor(private gs: GeneralService,
				private notificationService: NotificationService,
				private translateService: TranslationService,
				private rs: RouteService,
				public lfs: LookAndFeelService,
				private authService: AuthService,
				private ngZone: NgZone) {
	}

	ngOnInit() {
		this.setComponentHeaderAndKey();

		this.stepDataSubscription = this.stepDataInput.subscribe((stepData: any) => {
			this.isOriginalSender = stepData.original_sender;
			this.setStepData(stepData);
		});
		if (this.smartAuthenticationInput) {
			this.smartAuthenticationSubscription = this.smartAuthenticationInput.subscribe((options: any) => {
				this.useSmartAuthentication = options && options.use;
				this.localMetrics = options && options.metrics;
			});
		}
		if (this.confirmButtonInput) {
			this.confirmButtonSubscription = this.confirmButtonInput.subscribe((value) => {
				this.confirmButtonTxt = value;
			});
		}
	}

	ngOnDestroy() {
		this.stepDataSubscription.unsubscribe();
		if (this.smartAuthenticationInput) {
			this.smartAuthenticationSubscription.unsubscribe();
		}
		if (this.confirmButtonInput) {
			this.confirmButtonSubscription.unsubscribe();
		}
	}

	setStepData(stepData) {
		this.stepData = stepData;
		this.step = dic.STEP[this.stepData.status] || dic.STEP.Blocked;

		switch (this.stepData.status) {
			case dic.STEP.Phone:
				if (this.stepData.hint) {
					this.phoneHint = this.stepData.hint;
				}
				this.pinCodeData.phone.country_code = '+' + this.stepData.country_code;
				this.flagIcon = this.gs.getCountryCodeFlag(this.stepData.country_code);
				this.isVoiceSupported = this.stepData.isVoiceSupported;
				break;

			// code can be received via phone or email
			case dic.STEP.Code:
				this.authPinCodeMethod = this.stepData.auth;
				this.emailAuth = this.stepData.recipientEmail;
				if (this.stepData.auth === dic.STEP.Phone) {
					this.isVoiceSupported = this.stepData.isVoiceSupported;
					this.pinCodeData.phone = this.stepData.phone;
					this.gs.formatPhone(this.pinCodeData);
				}
				this.pinCodeData.pinCode = '';
				setTimeout(() => {
					this.enableResend = true;
				}, this.dic.CONSTANTS.RESEND_TIMEOUT);
				break;

			case dic.STEP.Password:
				if (this.stepData.hint) {
					this.passwordHint = this.stepData.hint;
				}
				break;

			case dic.STEP.Email:
				if (this.stepData.hint) {
					this.emailHint = this.stepData.hint;
				}
				break;

			case dic.STEP.SSO:
				this.ssoType = this.stepData.ssoType;
				this.emailAuth = this.stepData.recipientEmail;
				break;

			case dic.STEP.ssoIdp:
				this.emailAuth = this.stepData.recipientEmail;
				break;
		}
		this.validationAnimationState = 'appear';
	}

	setAuthenticationStep = (step: string) => {
		this.step = step;
	}

	wrongPhone() {
		if (!this.showWrongPhoneBtn) {
			return;
		}
		this.showWrongPhoneBtn = false;

		this.rs.doAuthAction(dic.CONSTANTS.recipientAuthAction.wrongPhone).then(response => {
			this.notificationService.showMessage(response);
			this.showWrongPhoneBtn = false;
		});
	}

	authenticationChangeRequest() {
		if (!this.showAuthChangeBtn) {
			return;
		}

		this.rs.doAuthAction(dic.CONSTANTS.recipientAuthAction.changeAuthentication).then(response => {
			this.notificationService.showMessage(response);
			this.showAuthChangeBtn = false;
		}, (err) => {
			this.notificationService.showMessage(err.data);
		});
	}

	getPinCode(resend) {
		if ((!resend && (!this.pinCodeData.phone || this.disableConfirmAuth)) || (resend && !this.enableResend)) {
			return;
		}

		// check if the phone number contains letters
		if (!(Math.floor(Number(this.pinCodeData.phone.phone_number)) === Number(this.pinCodeData.phone.phone_number))) {
			this.notificationService.showWarnMessage(dic.ERRORS.invalidPhoneNumber);
			return;
		}
		this.disableConfirmAuth = true;
		const type = resend ? this.pinCodeData.resendType : this.pinCodeData.type;

		this.gs.formatPhone(this.pinCodeData);

		const data = {
			action: dic.STEP.Phone,
			phone: this.pinCodeData.phone,
			resend: resend,
			codeType: type
		};
		this.rs.doAuthentication2Fa(this.requestUrl, data).then(stepData => {
				this.validationAnimationState = 'disappear';
				setTimeout(() => { // wait for animation
					this.gs.removeTooltips();
					this.setStepData(stepData);
					this.disableConfirmAuth = false;
					this.enableResend = false;

					if (resend) {
						this.notificationService.showInfoMessage(dic.MESSAGES.resendPinCode);
						this.pinCodeData.type = this.pinCodeData.resendType;
					} else {
						this.notificationService.showMessage(stepData.data);
					}
				}, 200);
			},
			error => {
				this.confirmFailure(error, this.dic.STEP.Phone);
			});
	}

	confirmPinCode() {
		if (this.disableConfirmAuth || !this.pinCodeData.pinCode) {
			return;
		}

		this.disableConfirmAuth = true;
		this.applyAnimationEmitter.emit(null);

		const data = {
			action: dic.STEP.Code,
			code: parseInt(this.pinCodeData.pinCode),
			metrics: this.localMetrics
		};

		this.rs.doAuthentication2Fa(this.requestUrl, data).then(response => {
			this.confirmSuccess(response);
		}, error => {
			this.confirmFailure(error, this.dic.STEP.Code);
		});
	}

	confirmEmail(resend) {
		if ((!resend && (!this.emailAuth || this.disableConfirmAuth)) || (resend && !this.enableResend)) {
			return;
		}

		this.disableConfirmAuth = true;
		this.enableResend = false;

		const data = {
			action: dic.STEP.Email,
			email: this.emailAuth.trim()
		};

		this.rs.doAuthentication2Fa(this.requestUrl, data).then(response => {
			if (resend) {
				this.notificationService.showInfoMessage(dic.MESSAGES.resendPinCode);
			}
			this.gs.removeTooltips();
			this.validationAnimationState = 'disappear';
			setTimeout(() => { // wait for animation
				this.setStepData(response);
				this.disableConfirmAuth = false;
			}, 200);
		}, error => {
			this.confirmFailure(error, this.dic.STEP.Email);
		});
	}

	confirmPassword() {
		if (this.disableConfirmAuth || !this.password) {
			return;
		}

		this.disableConfirmAuth = true;
		this.applyAnimationEmitter.emit(null);

		const data = {
			action: dic.STEP.Password,
			password: this.password.trim(),
			metrics: this.localMetrics
		};
		this.rs.doAuthentication2Fa(this.requestUrl, data).then(response => {
			this.confirmSuccess(response);
		}, error => {
			this.confirmFailure(error, this.dic.STEP.Password);
		});
	}

	confirmTotp() {
		if (this.disableConfirmAuth || !this.totpCode) {
			return;
		}

		this.disableConfirmAuth = true;
		this.applyAnimationEmitter.emit(null);

		this.rs.doAuthentication2Fa(this.requestUrl, {
			action: this.dic.STEP.Totp,
			password: this.totpCode
		}).then(response => {
			this.confirmSuccess(response);

			if (this.setEmailResultEmitter) {
				this.setEmailResultEmitter.emit(response);
			}
		}, error => {
			this.confirmFailure(error, this.dic.STEP.Totp);
		});
	}

	confirmSuccess(response) {
		this.gs.removeTooltips();
		this.step = this.dic.STEP.Authenticated;
		this.disableConfirmAuth = false;

		if (response.fingerprint) {
			if (this.useSmartAuthentication || this.parentComponent !== dic.CONSTANTS.authParentComponents.recipient) {
				localStorage[this.localStorageKey] = JSON.stringify(response.fingerprint);
			} else {
				localStorage[this.userHash] = JSON.stringify(response.fingerprint);
			}
		}

		this.rs.addDefaultHeader(this.requestHeader, response.fingerprint);
		this.confirmSuccessEmitter.emit(response);
		this.notificationService.showMessage(response.data);
	}

	confirmFailure(error, currentStatus) {
		this.disableConfirmAuth = false;
		this.errorMsg = error.data.message;

		if (error.data.status === currentStatus) {
			if (error.data.remain_attempts) {
				const msg = error.data.remain_attempts > 1 ? this.translateService.getTranslationText('authentication.multiAttemptRemain', error.data.remain_attempts) : this.translateService.getTranslationText('authentication.oneAttemptRemain');

				this.notificationService.showWarnMessage(msg);
			}
			this.confirmFailureEmitter.emit(null);
		} else {
			this.setStepData(error.data);
			this.confirmFailureEmitter.emit(error.data);
		}

		this.gs.updateDecryptionState(false);

		this.notificationService.showMessage(error.data);
	}

	// Available providers: trustifi-microsoft, trustifi-yahoo, google-oauth2
	// Alternative provider: trustificorp-azuread
	socialLogin(provider) {
		this.authService.socialLogin(this.emailAuth, provider, (accessToken, connection) => {
			if (accessToken) {
				this.disableConfirmAuth = true;
				this.applyAnimationEmitter.emit(null);

				this.rs.doAuthentication2Fa(this.requestUrl, {
					action: this.dic.STEP.SSO,
					metrics: this.localMetrics,
					accessToken: accessToken,
					provider: connection
				}).then(response => {
					this.confirmSuccess(response);

					if (this.setEmailResultEmitter) {
						this.setEmailResultEmitter.emit(response);
					}
				}, error => {
					this.confirmFailure(error, this.dic.STEP.SSO);
				});
			} else {
				this.notificationService.showErrorMessage(dic.ERRORS.SSOAuthFailed);
			}
		});
	}

	ssoIdpAuthentication() {
		this.authService.IDPLogin(this.emailAuth, (accessToken, connection) => {
			if (accessToken) {
				this.disableConfirmAuth = true;
				this.applyAnimationEmitter.emit(null);

				this.rs.doAuthentication2Fa(this.requestUrl, {
					action: this.dic.STEP.ssoIdp,
					metrics: this.localMetrics,
					accessToken: accessToken,
					provider: connection
				}).then(response => {
					this.ngZone.run(() => {
						this.confirmSuccess(response);

						if (this.setEmailResultEmitter) {
							this.setEmailResultEmitter.emit(response);
						}
					});
				}, error => {
					this.ngZone.run(() => {
						this.confirmFailure(error, this.dic.STEP.ssoIdp);
					});
				});
			}
		});
	}

	private setComponentHeaderAndKey = () => {
		switch (this.parentComponent) {
			case dic.CONSTANTS.authParentComponents.recipient:
				this.localStorageKey = this.dic.CONSTANTS.localStorageFp;
				this.requestHeader = this.dic.HEADERS.xFingerprint;
				this.requestUrl = EMAIL_ACCESS_URL;
				break;

			case dic.CONSTANTS.authParentComponents.quarantined:
				this.localStorageKey = this.dic.CONSTANTS.quarantinedFp;
				this.requestHeader = this.dic.HEADERS.x2FaFingerprint;
				this.requestUrl = QUARANTINED_URL;
				break;

			case dic.CONSTANTS.authParentComponents.acp:
				this.localStorageKey = this.dic.CONSTANTS.quarantinedFp;
				this.requestHeader = this.dic.HEADERS.x2FaFingerprint;
				this.requestUrl = ACCOUNT_TAKEOVER_PROTECTION_URL;
				break;

			case dic.CONSTANTS.authParentComponents.outboundQuarantined:
				this.localStorageKey = this.dic.CONSTANTS.quarantinedFp;
				this.requestHeader = this.dic.HEADERS.x2FaFingerprint;
				this.requestUrl = OUTBOUND_QUARANTINED_URL;
				break;

			case dic.CONSTANTS.authParentComponents.archive:
				this.localStorageKey = this.dic.CONSTANTS.localStorageFp;
				this.requestHeader = this.dic.HEADERS.x2FaFingerprint;
				this.requestUrl = ARCHIVE_URL;
				break;

			case dic.CONSTANTS.authParentComponents.review:
				this.localStorageKey = this.dic.CONSTANTS.quarantinedFp;
				this.requestHeader = this.dic.HEADERS.x2FaFingerprint;
				this.requestUrl = REVIEW_URL;
				break;
		}
	}
}
