<div class="header-wrapper">
	<div class="logo t-bg-secondary">
		<!--<img *ngIf="logoData.data.changingThisBreaksApplicationSecurity" [src]="logoData.data" alt="Logo"/>-->
		<img *ngIf="lfs.logo" [src]="lfs.logo" alt="Logo"/>
	</div>

	<div [title]="showLanguageMenu ? '' : ('general.darkMode' | translate)"
		 class="dark-mode-div t-bg-primary t-color-primary"
		 [ngStyle]="{'right': gs.isMobile ? '39px' : '57px'}"
		 (click)="darkModeToggle()">
		<i class="fas fa-adjust" style="font-size: 15px; transform: rotate(180deg);"></i>
	</div>
	<a class="language-select drop-menu-container t-bg-primary t-color-primary"
	   id="selectLanguageMenuTrigger"
	   [title]="showLanguageMenu ? '' : ('general.language' | translate)"
	   [ngStyle]="{'border-color' : showLanguageMenu ? 'transparent' : 'var(--light-gray)', 'min-width' : gs.isMobile ? '32px' : '50px'}"
	   (click)="showLanguageMenu = !showLanguageMenu;">
		<span [ngClass]="{'hSpace05' : !gs.isMobile}">
			<i class="fa fa-globe" style="opacity: 0.5;" *ngIf="!gs.isMobile"></i>
			<span>{{this.translateService.selectedAppLang.shortened}}</span>
		</span>
		<div class="drop-menu"
			 (clickOutside)="showLanguageMenu = false;"
			 [exclude]="'#selectLanguageMenuTrigger'"
			 [ngClass]="{'active' : showLanguageMenu}">
			<ul>
				<li *ngFor="let langObj of _.values(dic.CONSTANTS.supportedLanguages)"
					(click)="changeLanguage(langObj); $event.stopPropagation()">
					<span class="t-color-primary" [ngStyle]="{'font-weight' : this.translateService.selectedAppLang.code === langObj.code ? 'bold' : 'normal'}">{{langObj.labelLocal}}</span>
				</li>
			</ul>
		</div>
	</a>
</div>
