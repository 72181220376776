import {
	Component,
	OnInit,
	ChangeDetectorRef,
	NgZone,
	ViewChild
} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Subject} from 'rxjs';
import * as _ from 'lodash';
import * as $ from 'jquery';
import * as Fingerprint2 from 'fingerprintjs2';

import {EMAIL_ACCESS_URL, ATTACHMENT_URL} from '../../constants';
import {dic} from '../../dictionary';
import {RouteService} from '../../services/route.service';
import {DownloadFilesService} from '../../services/download-files.service';
import {NotificationService} from '../../services/notification.service';
import {GeneralService} from '../../services/general.service';
import {TranslationService} from '../../services/translation.service';
import {EncryptAnimationComponent} from '../encrypt-animation/encrypt-animation.component';

@Component({
	selector: 'app-recipient',
	templateUrl: './recipient.component.html',
	styleUrls: ['./recipient.component.scss']
})


export class RecipientComponent implements OnInit {

	@ViewChild(EncryptAnimationComponent) encryptAnimationComponent: EncryptAnimationComponent;
	@ViewChild('noAttachments') noAttachments;

	dic = dic;

	localMetrics = false;
	useSmartAuthentication = false;
	useSmartAuthenticationSubject: Subject<object> = new Subject<object>();
	step: string;
	stepSubject: Subject<string> = new Subject<string>();
	stepDataSubject: Subject<object> = new Subject<object>();
	msgId: string;
	isEmlDownload = false;
	enableReply = true;
	enforceReplyAll = false;
	isReplySent = false;
	isReplyMinimized = false;
	initSummernote = true;
	isEditorFullScreen = false;
	disableReplySendBtn = false;
	mouseOverAttachment = false;
	isFullScreen = false;
	previewAttachment;
	isMobile: boolean;
	errorMessage: string;
	message: string;
	repliedSuccessMsg = '';
	userHash: string;
	innerContent = '';
	showHelpUrl = 'https://docs.trustifi.com/docs/opening-a-trustifi-encrypted-email';
	innerContentSubject: Subject<string> = new Subject<string>();
	html: string;
	recipientSignature: string = '';
	emailInfo: any;
	uploadedFiles: number;
	encryptedContent: boolean;
	encryptedContentSubject: Subject<boolean> = new Subject<boolean>();
	confirmButtonSubject: Subject<string> = new Subject<string>();
	blockNote: string;
	accessOnce = false;
	multipleReply = true;
	uploadSubscribers = {};
	showAskAccess = false;
	showAccessText: string;
	showAskAccessBlocked = false;
	isRecipientTrustifiUser = false;
	hidePostReplyMessage = false;
	recipientsDropdown = false;
	recipientsCCDropdown = false;
	firstRecipient = '';
	firstCCRecipient = '';
	showUploadedAttachmentsPopup = false;
	replyMessage = {attachments: []};
	files: any;
	logoData: any;
	loadingEmail = true;
	allowDownloadEml = false;
	attachmentsPreviewMode = false;
	downloadAllAttachments = {progressPercentage: 0, isInProcess: false};
	isDownloadAll;

	constructor(private rs: RouteService,
				private http: HttpClient,
				private downloadFilesService: DownloadFilesService,
				private notificationService: NotificationService,
				private gs: GeneralService,
				private chRef: ChangeDetectorRef,
				private ngZone: NgZone,
				private translationService: TranslationService) {
		this.logoData = gs.logoData;
	}

	ngOnInit() {
		this.step = dic.STEP.Authenticating;
		this.stepSubject.next(this.step);
		this.message = _.clone(sessionStorage.message);
		sessionStorage.setItem('message', null);

		this.isMobile = this.gs.detectMobile() && ((window.innerWidth > 0 ? window.innerWidth < 480 : screen.width < 480) || (window.innerHeight > 0 ? window.innerHeight < 480 : screen.height < 480));
		// ^ (checks also landscape) ^

		this.getMessage(location.hash, true);

		this.uploadedFiles = 0;
		this.getLfSettings();
	}

	getLfSettings() {
		this.rs.getLfSettings().then(res => {
			this.gs.initLogoData(res);
			this.logoData.signUpUrl = this.logoData.webAppUrl + '/#/signup/';
			this.gs.setMainLogo();
			this.hidePostReplyMessage = res.lf && res.lf.enabled;

			if (res.lf && res.lf.enabled) {
				this.showHelpUrl = '';
			}
			if (res.lf && res.lf.ma_auth_help_url) {
				this.showHelpUrl = res.lf.ma_auth_help_url;
			}
		}, error => {
			this.gs.initLogoData({});
			this.logoData.signUpUrl = this.logoData.webAppUrl + '/#/signup/';
			this.gs.setMainLogo();

			if (error.data && error.data.status) {
				this.notificationService.showMessage(error.data);
			}
		});
	}

	encTokenChanged(tokenId) {
		if (tokenId && (
			tokenId.length === 636 || tokenId.length === 546 || // with/without slash
			tokenId.length === 790 || tokenId.length === 682)) { // BC
			this.getMessage(tokenId, false);
			this.getLfSettings();
		}
	}

	getMessage(msgId, strip) {
		if (this.setAccessEnc(msgId, strip)) {
			this.getAuthStatus(true, null);
		}
		else {
			this.step = dic.STEP.Empty;
			this.finishLoadingEmail();
			this.stepSubject.next(this.step);
		}
	}

	finishLoadingEmail() {
		setTimeout(() => {
			this.loadingEmail = false;
		}, 800); // wait a bit so loading animation will not be cleared to fast
	}

	updateSummernoteLogo(iframe: Window) {
		this.gs.setSummernoteLogo(iframe);
	}

	setAccessEnc(enc: string, strip: boolean) {
		if (enc && enc.length > 0) {
			this.userHash = strip ? location.hash.substring(2) : enc;

			const defaultHeaders = {};
			defaultHeaders[dic.HEADERS.xAccessEnc] = this.userHash;
			defaultHeaders[dic.HEADERS.xTrustifiSource] = 'miniapp';

			const localStorageFp = this.useSmartAuthentication ? localStorage[dic.CONSTANTS.localStorageFp] : localStorage[this.userHash];
			if (localStorageFp) {
				defaultHeaders[dic.HEADERS.xFingerprint] = JSON.parse(localStorageFp);
			}

			this.rs.setDefaultHeaders(defaultHeaders);
			return true;
		}

		return false;
	}


	updateEmailContent(content: string) {
		if (this.initSummernote) {
			this.html = '';
			this.initSummernote = false;

			this.rs.getRecipientSignature().then(response => {
				if (response?.html) {
					this.recipientSignature = response.html;
					this.updateEmailContent(response.html);
				}
			}, (err) => {

			});

		} else {
			this.html = content;
		}
		this.chRef.detectChanges();
	}

	downloadFile(url: string, attachment: any, isPreview: boolean, cb) {
		if ((attachment?.progressPercentage && attachment.progressPercentage > 0 && attachment.progressPercentage < 100) || attachment?.isInProcess) {
			return;
		}
		if (!attachment && (this.downloadAllAttachments.progressPercentage || this.downloadAllAttachments.isInProcess)) {
			return;
		}

		let fullUrl = ATTACHMENT_URL + '/' + EMAIL_ACCESS_URL + url;
		let size = 0;
		// for single attachment download
		if (attachment && (!this.accessOnce || isPreview)) {
			fullUrl += `/${attachment._id}`;
		}
		else {
			_.each(this.emailInfo.sent.attachments, a => size += a.size);
		}

		let localStorageFp, method, body = {};
		if (this.useSmartAuthentication) {
			localStorageFp = localStorage[dic.CONSTANTS.localStorageFp];
			method = 'POST';
			body['metrics'] = this.localMetrics;
		} else {
			localStorageFp = localStorage[this.userHash];
			method = 'GET';
		}

		const headers = new HttpHeaders()
		.set('x-access-enc', this.userHash)
		.set('x-trustifi-source', 'miniapp')
		.set('x-trustifi-fingerprint', localStorageFp ? JSON.parse(localStorageFp) : '');


		const req = new HttpRequest(method, fullUrl, body, {
			headers,
			reportProgress: true,
			responseType: 'arraybuffer'
		});

		if (!attachment || this.accessOnce) {
			this.isDownloadAll = true;
			attachment = this.downloadAllAttachments;
		}
		attachment.isInProcess = true;
		attachment.progressPercentage = 0;

		let count = 0;

		this.http.request(req).subscribe((event: any) => {
			// reporting percentages - updating local variables
			if (event.type === HttpEventType.DownloadProgress) {
				// Update progress styles once in 2 reports - improve UX
				if (count % 2 === 0 && attachment) {
					attachment.progressPercentage = ((event.loaded / (event.total || size)) * 100);
				}
				if (attachment.progressPercentage >= 100) {
					attachment.progressPercentage = 100;
				}
				count++;
			}
			else if (event.type === HttpEventType.Response) {
				if (attachment) {
					attachment.progressPercentage = 100;
				}
				attachment.isInProcess = false;
				attachment.progressPercentage = 0;

				if (isPreview) {
					return cb(null, event.body);
				}

				let filename;
				if (this.emailInfo.sent.attachments.length === 1) {
					filename = this.emailInfo.sent.attachments[0].name;
				}
				else {
					if (this.isDownloadAll) {
						filename = 'secured_attachments.zip';
					}
					else {
						filename = attachment.name;
					}
				}
				let contentType = event.headers['content-type'];
				if (!contentType || contentType === 'bytes') {
					if (attachment) {
						contentType = attachment.type;
					}
					else if (this.emailInfo.sent.attachments.length === 1) {
						contentType = this.checkMimeType(event.body) || this.emailInfo.sent.attachments[0].type;
					}
					else {
						contentType = 'application/zip';
					}
				}

				if (this.isEmlDownload) {
					filename = this.emailInfo.sent.title + '.eml';
					contentType = 'message/rfc822';
				}

				if (this.gs.isChrome() && navigator.userAgent.match(/iPhone/i)) {
					if (contentType ===  'message/rfc822' || filename.endsWith('.csv')) {
						contentType = 'text/plain';
					}
				}

				const blob = new Blob([event.body], {type: contentType});
				if (this.gs.detectMobile() && this.gs.isSupportedMimeType(contentType)) {
					location.href = URL.createObjectURL(blob);
				}
				else {
					this.downloadFilesService.download(blob, filename, contentType);
				}

				this.isEmlDownload = false;
				this.isDownloadAll = false;
			}
		}, errResponse => {
			if (errResponse.data && errResponse.data instanceof ArrayBuffer && errResponse.data.byteLength !== undefined ||
				errResponse.error && errResponse.error instanceof ArrayBuffer && errResponse.error.byteLength !== undefined) {
				const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(errResponse.data || errResponse.error)));
				console.error(err);
				if (err.status === dic.CONSTANTS.EMAIL_STATUS.BLOCKED) {
					this.notificationService.showWarnMessage(dic.ERRORS.downloadingNotAllowed);
				} else {
					this.notificationService.showWarnMessage(err.message);
				}
			} else {
				console.error(errResponse.message);
				this.notificationService.showMessage(errResponse.message);
			}
			this.isDownloadAll = false;
			this.isEmlDownload = false;
		});
	}


	deleteAttachment(index) {
		const attachmentRemove = this.replyMessage.attachments[index];
		if (!attachmentRemove) {
			return;
		}

		if (attachmentRemove.id) {
			this.rs.replyDeleteAttachment(attachmentRemove.id.toString());
		}

		this.replyMessage.attachments.splice(index, 1);
		if (this.uploadedFiles > 0) {
			this.uploadedFiles--;
		}

		if (this.uploadSubscribers[attachmentRemove.name]) {
			this.uploadSubscribers[attachmentRemove.name].unsubscribe();
			delete this.uploadSubscribers[attachmentRemove.name];
		}
		this.gs.removeTooltips();
	}

	replyMessageExecute() {
		if (this.disableReplySendBtn) {
			return;
		}
		this.disableReplySendBtn = true;

		let totalAttachmentsSize = 0;
		const attachmentIds = [], attachmentNames = [];
		_.each(this.replyMessage.attachments, attachment => {
			attachmentIds.push(attachment.id);
			attachmentNames.push(attachment.name);
			totalAttachmentsSize += attachment.size || 0;
		});

		const recipients = _.map(_.filter(this.emailInfo.replyRecipients, 'selected'), 'email');
		const ccRecipients = _.map(_.filter(this.emailInfo.replyRecipientsCC, 'selected'), 'email');

		// Combine recipients and ccRecipients
		const allRecipients = recipients.concat(ccRecipients);
		if (!allRecipients?.length) {
			this.notificationService.showWarnMessage(dic.ERRORS.replyWithOneRecipient);
			this.disableReplySendBtn = false;
			return;
		}

		// Create toReplay and ccReply arrays in the specified format
		const toReply = recipients.map(email => ({ address: email }));
		const ccReply = ccRecipients.map(email => ({ address: email }));

		this.rs.replyMessage({
			html: this.html,
			attachments: attachmentIds,
			recipients: allRecipients,
			to: toReply,
			cc: ccReply,
			metrics: this.localMetrics,
			totalAttachmentsSize: totalAttachmentsSize
		}).then(response => {

			this.translationService.getTranslatedMessage(dic.MESSAGES.repliedSuccess, (txt) => {
				this.repliedSuccessMsg = txt;
				this.replyMessage.attachments = [];
				setTimeout(() => {
					this.disableReplySendBtn = false;
					this.message = txt;
					this.isRecipientTrustifiUser = response.isRecipientTrustifiUser;
					this.step = dic.STEP.Authenticated;
					this.enableGlobalScroll(true);
					this.stepSubject.next(this.step);
					this.addReplyContent(this.html, toReply, ccReply, attachmentNames);
					if (!this.multipleReply) {
						this.enableReply = false;
					}
					this.isReplySent = true;
				}, 1000);
			});
		}, error => {
			this.errorMessage = error.data.message;
			this.notificationService.showMessage(error.data);
			this.disableReplySendBtn = false;
		});
	}

	addReplyContent(replyContent, toReply, ccReply, attachmentNames) {
		let receivedAttachments = [];
		if (this.emailInfo.sent.attachments?.length) {
			receivedAttachments = this.emailInfo.sent.attachments.map((itm) => itm.name);
		}

		let replyContentHtml = `
			  <div>
				${toReply?.length ? `<div>To: ${toReply.map(itm => itm.address).join(', ')}</div>` : ''}
				${ccReply?.length ? `<div>Cc: ${ccReply.map(itm => itm.address).join(', ')}</div>` : ''}
				${attachmentNames?.length ? `<div>Attachments: ${attachmentNames.join(', ')}</div>` : ''}
			  </div>
			  <br>${replyContent}
			  <br><br><hr style="border-color: black">
			  <br>`;

		if (!this.isReplySent) {
			replyContentHtml += `
			  <div>
				${this.emailInfo.to?.length ? `<div>To: ${this.emailInfo.to.join(', ')}</div>` : ''}
				${this.emailInfo.cc?.length ? `<div>Cc: ${this.emailInfo.cc.join(', ')}</div>` : ''}
				${receivedAttachments.length ? `<div>Attachments: ${receivedAttachments.join(', ')}</div>` : ''}
			  </div>
			  <br/>`;

		}

		this.emailInfo.sent.html = replyContentHtml + this.emailInfo.sent.html;
		this.encryptAnimationComponent.decryptData(this.emailInfo.sent.html);
		if (!this.emailInfo.originalSender) {
			this.emailInfo.originalSender = this.emailInfo.sender;
		}
		this.emailInfo.sender = this.emailInfo.recipient || '';
		this.initSummernote = true;
	}

	uploadFiles() {
		if (!this.files) { return; }
		this.files = _.filter(this.files, f => f !== null);

		if (this.files.length > dic.CONSTANTS.secureReplyMaxAttachments
			|| this.replyMessage.attachments.length + this.files.length > dic.CONSTANTS.secureReplyMaxAttachments) {
			this.notificationService.showWarnMessage(dic.ERRORS.recipAuthReplyTooManyAttachments);
			// empty files array so next upload will have a clean array
			this.files = [];
			return;
		}
		let totalSize = 0;
		let filesForUpload = [];
		for (let i = 0; i < this.files.length; i++) {
			if (_.find(this.replyMessage.attachments, {name: this.files[i].name, size: this.files[i].size})) {
				this.notificationService.showWarnMessage(dic.ERRORS.attachmentAlreadyUploaded, this.files[i].name);
				// empty files array so next upload will have a clean array
				this.files = [];
				return;
			}
			totalSize += this.files[i].size;
		}

		for (let i = 0; i < this.files.length; i++) {
			const currentAttachment: any = {
				name: this.files[i].name,
				size: this.files[i].size,
				finished: false,
				progressPercentage: 0,
				upload: {},
				fileData: this.files[i]
			};
			this.replyMessage.attachments.push(currentAttachment);
			filesForUpload.push(currentAttachment);
		}

		const isValid = this.checkUploadFileSize(totalSize, 'Current upload attachments');
		if (totalSize === 0 || !isValid) { return; }

		const localStorageFp = this.useSmartAuthentication ? localStorage[dic.CONSTANTS.localStorageFp] : localStorage[this.userHash];
		const headers = new HttpHeaders()
		.set('x-access-enc', this.userHash)
		.set('x-trustifi-source', 'miniapp')
		.set('x-trustifi-fingerprint', localStorageFp ? JSON.parse(localStorageFp) : '');

		this.uploadedFiles += this.files.length;
		this.uploadMultipleFiles(filesForUpload, headers);

		// empty files array so next upload will have a clean array
		this.files = [];
	}

	uploadMultipleFiles(files, headers) {
		if (files && files.length) {
			const file = files[0];
			this.uploadFilesExecute(file, headers, () => {
				files.splice(0, 1);
				this.uploadMultipleFiles(files, headers);
			});
		}
	}

	uploadFilesExecute(fileObj, headers, cb) {
		if (!fileObj || !fileObj.fileData) {
			return cb();
		}

		const fd = new FormData();
		fd.append('file', fileObj.fileData);

		if (this.useSmartAuthentication) {
			fd.append('metrics', JSON.stringify(this.localMetrics));
		}

		const req = new HttpRequest('POST', ATTACHMENT_URL + '/' + EMAIL_ACCESS_URL + '/Attachment', fd, {
			headers,
			reportProgress: true
		});

		this.uploadSubscribers[fileObj.name] = this.http.request(req).subscribe(event => {
			// reporting percentages - updating local variables
			if (event.type === HttpEventType.UploadProgress) {
				fileObj.progressPercentage = 100.0 * event.loaded / event.total;
			} else if (event.type === HttpEventType.Response) {
				this.uploadedFiles--;
				// update finished state and new id for this attachment
				fileObj.finished = true;
				fileObj.id = event.body[0];
				fileObj.fileData = null;
				cb();
			}
		}, err => {
			if (err.error && err.error.message) {
				this.notificationService.showErrorMessage(err.error.message);
			}
			_.remove(this.replyMessage.attachments, attachment => {
				return fileObj.name === attachment.name;
			});

			if (this.uploadedFiles > 0) {
				this.uploadedFiles--;
			}
			cb();
		});
	}

	downloadAsEml() {
		if (this.isEmlDownload) { return; }
		this.isEmlDownload = true;
		this.downloadFile('/downloadEml', null, false, () => {});
	}

	printContent() {
		if (this.encryptedContent) { return; }

		const getBasicHeadersHtml = () => {
			return `<div><b>${this.emailInfo.sender}</b></div>
			<hr style="border-width: 1px; border-color: black">
			<br><div>
			<span style="padding-right: 30px;">From: </span>${this.emailInfo.sender}</div><div>
			<span style="padding-right: 36px;">Sent: </span>${this.emailInfo.created && (new Date(this.emailInfo.created)).toString().split(' GMT')[0]}</div><div>
			${this.emailInfo.to && this.emailInfo.to.length ? `<span style="padding-right: 48px;">To:   </span>${this.emailInfo.to.join(', ')}</div><div>` : ''}
			${this.emailInfo.cc && this.emailInfo.cc.length ? `<span style="padding-right: 45px;">CC:   </span>${this.emailInfo.cc.join(', ')}</div><div>` : ''}
			<span style="padding-right: 17px;">Subject: </span>${this.emailInfo.sent.title}</div>
			<br><br>
			<style>body {word-break: break-all;}</style>`;
		};

		const getAttachmentMetadata = () => {
			let innerHTML = '';
			if (this.emailInfo.sent.attachments && this.emailInfo.sent.attachments.length) {
				let attachmentsContent = '<br/><div>Attachments:</div>';
				this.emailInfo.sent.attachments.forEach(attachment => {
					attachmentsContent += `<div>${attachment.name} (${this.gs.formatBytes(attachment.size, 2)})</div>`;
				});
				innerHTML += `<span style="font-family: Times New Roman; font-size: medium;">${attachmentsContent}</span><br/>`;
			}

			return innerHTML;
		};

		const printTab = window.open();
		printTab.document.write(getBasicHeadersHtml() + this.emailInfo.sent.html.replace('page:WordSection1;', '') + getAttachmentMetadata());
		printTab.document.close();
		printTab.print();
	}

	askForAccess() {
		if (this.showAskAccessBlocked) {
			return;
		}
		this.showAskAccessBlocked = true;
		this.rs.doAuthAction(dic.CONSTANTS.recipientAuthAction.askAccess).then(() => {
			this.notificationService.showInfoMessage(dic.MESSAGES.askAccessSuccess);
			this.showAskAccess = true;
			this.showAskAccessBlocked = true;
			this.showAccessText = 'compose.recipients.senderNotified';
		})
		.catch(err => {
			console.error(err);
			this.showAskAccessBlocked = false;
		});
	}

	getDropdownHeader(dropdown: string = null) {
		const updateDropdownHeader = (items, selectedCount) => {
			if (items.length > 1 && selectedCount === items.length) {
				return this.translationService.getTranslationText('recipient.all');
			}
			else if (items.length === 1 || selectedCount < items.length) {
				const firstSelected = items.find(item => item.selected);
				if (firstSelected) {
					let header = firstSelected.email;
					if (selectedCount > 1) {
						header += ` +${selectedCount - 1}`;
					}
					return header;
				}
				else {
					return 'Choose';
				}
			}
			else {
				return 'Choose';
			}
		};

		if (dropdown === null || dropdown === 'to') {
			const selectedRecipients = this.emailInfo.replyRecipients.filter(r => r.selected).length;
			this.firstRecipient = updateDropdownHeader(this.emailInfo.replyRecipients, selectedRecipients);
		}

		if (dropdown === null || dropdown === 'cc') {
			const selectedCCRecipients = this.emailInfo.replyRecipientsCC.filter(cc => cc.selected).length;
			this.firstCCRecipient = updateDropdownHeader(this.emailInfo.replyRecipientsCC, selectedCCRecipients);
		}
	}

	openSecureReply(isReplyAll) {
		if (isReplyAll) {
			this.emailInfo.replyRecipients = _.map(this.emailInfo.replyRecipients, r => {
				r.selected = true;
				return r;
			});
		} else {
			this.emailInfo.replyRecipients = _.map(this.emailInfo.replyRecipients, (r, index: number) => {
				r.selected = index === 0 || r.email === this.emailInfo.sender || r.email === this.emailInfo.originalSender;
				return r;
			});
		}

		const clonedRecipients = _.cloneDeep(this.emailInfo.replyRecipients);

		this.emailInfo.replyRecipientsCC = clonedRecipients.map(r => {
			r.enforce && delete r.enforce;
			r.selected = false;
			return r;
		});

		this.getDropdownHeader();
		this.step = dic.STEP.Reply;
		this.isReplyMinimized = false;
		this.enableGlobalScroll(false);
	}

	setPreviewAttachment(attachmentObj) {
		attachmentObj.previewLoading = true;
		this.downloadFile('/Download', attachmentObj, true, (err, attachmentData) => {
			if (err) {
				attachmentObj.previewLoading = false;
				return;
			}

			attachmentObj.previewLoading = false;
			attachmentObj.content = {data: attachmentData};
			this.previewAttachment = attachmentObj;
		});
	}

	// for mobile view
	enableGlobalScroll(enable) {
		if (!this.isMobile) {
			return;
		}
		if (enable) {
			$('body').css({'overflow': 'auto'});
		} else {
			$('body').css({'overflow': 'hidden'});
		}
	}

	updateEncryptedContent(encryptedContent) {
		this.encryptedContent = encryptedContent;
	}

	confirmSuccessCb(response) {
		this.step = dic.STEP.Authenticated;
		this.stepSubject.next(this.step);

		if (response.sent) {
			if (this.encryptedContent) {
				this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.DECRYPTED);
			}
			this.emailInfo.sent.html = response.sent.html;
			this.emailInfo.recipient = response.recipient;
			this.innerContent = this.emailInfo.sent.html;
			this.innerContentSubject.next(this.innerContent);
			this.emailInfo.sent.edited_timestamp = response.sent.edited_timestamp;
			this.emailInfo.enable_print = response.enable_print;
		}
	}

	confirmFailureCb = (response) => {
		if (response) {
			this.setStepData(response);
		}
	}

	applyAnimationCb() {
		if (this.encryptedContent) {
			this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.PROCESS);
		}
	}

	private checkMimeType(array) {
		const arr = (new Uint8Array(array)).subarray(0, 4);
		let header = '';
		for (let i = 0; i < arr.length; i++) {
			header += arr[i].toString(16);
		}

		switch (header) {
			// 504b34 docx
			case '89504e47':
				return 'image/png';
			case '47494638':
				return 'image/gif';
			case 'ffd8ffe0':
			case 'ffd8ffe1':
			case 'ffd8ffe2':
				return 'image/jpeg';
			case '25504446':
				return 'application/pdf';
			case '504b0304':
				return 'application/zip';
			default:
				return false;
		}
	}

	private getAuthStatus(isFirst, localMetrics) {
		this.loadingEmail = true;
		this.rs.getAuthStatus(localMetrics).then(response => {
			if (!response) {
				this.step = dic.STEP.Empty;
				this.stepSubject.next(this.step);
				this.finishLoadingEmail();
				return;
			}

			if (isFirst && response.status !== dic.STEP.Authenticated && response.smart_authentication) {
				this.useSmartAuthentication = true;
				this.setAccessEnc(location.hash, true);
				this.createLocalMetricsFingerprint((localMetrics) => {
					this.useSmartAuthenticationSubject.next({use: this.useSmartAuthentication, metrics: localMetrics});

					this.ngZone.run(() => {
						console.log('localMetrics ?', localMetrics);
						this.localMetrics = localMetrics;
						this.getAuthStatus(false, this.localMetrics);
					});
				});
				return;
			}

			if (response.email_info) {
				this.finishLoadingEmail();
				this.emailInfo = response.email_info;
				this.innerContent = this.emailInfo.sent.html;
				this.innerContentSubject.next(this.innerContent);
				if (this.emailInfo.is_content_encrypted) {
					this.encryptedContent = true;
					this.encryptedContentSubject.next(this.encryptedContent);
					if (this.emailInfo.is_content_decrypted) {
						this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.PROCESS);
						this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.DECRYPTED);
					}
				} else {
					this.encryptAnimationComponent.decryptionProcess(dic.CONSTANTS.CONTENT_STATUS.DECRYPTED);
				}
			}

			if (response.open_only_once) {
				this.accessOnce = true;
				this.notificationService.showInfoMessage(dic.MESSAGES.accessOnce);
			}
			if (response.enforce_reply_all) {
				this.enforceReplyAll = true;
			}
			this.multipleReply = response.multi_replies;
			this.allowDownloadEml = response.allow_download_as_eml;
			this.attachmentsPreviewMode = response.attachments_preview_mode;

			this.setStepData(response);
			this.notificationService.showMessage(response.data);

		}, error => {
			this.finishLoadingEmail();
			if (error.data && error.data.status) {
				this.setStepData(error.data);
				this.notificationService.showMessage(error.data);
			} else {
				console.error(error);
				this.setStepData({status: dic.STEP.networkError});
			}
		});
	}

	private setStepData(stepData) {
		this.step = dic.STEP[stepData.status] || dic.STEP.Blocked;
		this.stepSubject.next(this.step);
		this.stepDataSubject.next(stepData);

		switch (stepData.status) {
			case dic.STEP.Blocked:
				this.blockNote = stepData.blockNote || '';
				if (stepData.allowAskAccess) {
					this.showAskAccess = true;
					this.showAskAccessBlocked = false;
					this.showAccessText = 'compose.recipients.askSenderAccess';
				} else {
					if (stepData.reason === 'blocked') {
						this.showAskAccess = true;
						this.showAskAccessBlocked = true;
						this.showAccessText = 'compose.recipients.senderNotified';
					} else if (stepData.reason === 'deleted') {
						this.showAskAccess = false;
					}
				}
				break;

			case dic.STEP.networkError:
				this.blockNote = 'We could not get the requested data, the user who sent the email may have been deleted or blocked. Also make sure you have a stable internet connection and you are not connected via proxy or VPN and try again in a few minutes.';
				break;
		}
	}

	private checkUploadFileSize(fileSize, fileName) {
		if (fileSize <= 0) {
			this.notificationService.showWarnMessage(dic.ERRORS.emptyFile, fileName);
			return false;
		}

		const fileSizeInMB = fileSize * 0.000001;
		if (fileSizeInMB > 250) {
			this.notificationService.showWarnMessage(dic.ERRORS.largeFile, fileName);
			return false;
		}
		return true;
	}

	private createLocalMetricsFingerprint(cb) {
		let fpData = [];
	  	fixedFP(function () {
	  		cb(fpData);
/*			 networkFP(function () {
				 cb(fpData);
			});*/
	  	});

	  	function fixedFP(cb) {
	  		const options = {};
			Fingerprint2.get(options, function (components) {
				fpData = components || [];
				cb();
			}, err => {
				console.error(err);
				cb();
			});
		}

	  	function networkFP(cb) {
			try {
				const RTCPeerConnection = window['RTCPeerConnection'] || window['webkitRTCPeerConnection'] || window['mozRTCPeerConnection'];
				if (RTCPeerConnection) { (function () {
					const pc = new RTCPeerConnection({iceServers: []}), noop = function () {};
					pc.createDataChannel('');
					pc.createOffer(pc.setLocalDescription.bind(pc), noop);
					pc.onicecandidate = function (ice) {
						if (!ice || !ice.candidate || !ice.candidate.candidate) { return; }
						const myIP = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/.exec(ice.candidate.candidate);
						if (myIP) {
							fpData.push({key: 'lanIp', value: myIP[1]});
							pc.onicecandidate = noop;
						}
						cb();
					};
				})();
				} else {
					cb();
				}
			} catch (err) {
				cb();
			}
		}


	  	function batteryFP(cb) {
			const readBattery = function (battery) {
				fpData.push({key: 'battery_charging', value: battery.level * 100 + '%'});
				fpData.push({key: 'battery_percentage', value: battery.charging ? 'Yes' : 'No'});
				cb();
			};

			if (navigator['battery']) {
				readBattery(navigator['battery']);
			} else if (navigator['getBattery']) {
				navigator['getBattery']().then(function (battery) {
					fpData.push({key: 'battery_charging', value: battery.level * 100 + '%'});
					fpData.push({key: 'battery_percentage', value: battery.charging ? 'Yes' : 'No'});
					cb();
				});
			} else {
				cb();
			}
	  	}
	}

	private addAttachmentContent(element, attachments) {
		let attachmentsContent = '<br><div>Attachments:</div>';
		_.each(attachments, attachment => {
			attachmentsContent += `<div>${attachment.name} (${this.gs.formatBytes(attachment.size, 2)})</div>`;
		});
		element.innerHTML += `<span style="font-family: Times New Roman; font-size: medium;">${attachmentsContent}</span><br>`;
	}

	fileOver(isFileOver: any) {
		this.mouseOverAttachment = isFileOver;
	}

	toggleMobileAttachments() {
		this.showUploadedAttachmentsPopup = !this.showUploadedAttachmentsPopup;
		if (this.replyMessage.attachments.length) {
			this.recipientsDropdown = false;
		}
	}
}
