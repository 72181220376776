import {Component, HostListener} from '@angular/core';
import fontawesome from '@fortawesome/fontawesome';
import faTrashAlt from '@fortawesome/fontawesome-free-regular/';
import {dic} from './dictionary';
import {GeneralService} from './services/general.service';
import {TranslationService} from './services/translation.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	dic = dic;

	timeoutIdle;
	isTimeout = false;
	isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
	isMobile = this.gs.detectMobile() && ((window.innerWidth > 0 ? window.innerWidth < 480 : screen.width < 480) || (window.innerHeight > 0 ? window.innerHeight < 480 : screen.height < 480));


	@HostListener('document:mousemove', ['$event'])
	onMouseMove(e) {
		this.resetTimer();
	}

	@HostListener('document:keypress', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		this.resetTimer();
	}

	constructor(private gs: GeneralService,
				private translationService: TranslationService) {
		// Init fontawesome
		fontawesome.library.add(faTrashAlt);

		translationService.initLanguage();

		this.resetTimer();
	}

	resetTimer() {
		if (this.isTimeout) {
			return;
		}
		clearTimeout(this.timeoutIdle);
		this.timeoutIdle = setTimeout(() => {
			this.isTimeout = true;
		}, 15 * 60 * 1000); // 15 minutes
	}

	reloadPage() {
		window.location.reload();
	}
}
