import {Inject, Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {
	ACCOUNT_TAKEOVER_PROTECTION_URL,
	ARCHIVE_URL,
	EMAIL_ACCESS_URL,
	EMAIL_URL,
	OUTBOUND_QUARANTINED_URL,
	QUARANTINED_URL,
	REPLY_URL,
	Restangular_Attachments,
	REVIEW_URL,
	SENDER_URL
} from '../constants';
import {dic} from '../dictionary';


@Injectable({
	providedIn: 'root'
})
export class RouteService {

	constructor(private restangular: Restangular,
				@Inject(Restangular_Attachments) public RestangularAttachments) {
	}

	setDefaultHeaders(headers: any) {
		headers = {
			[dic.HEADERS.xTrustifiSource]: 'miniapp',
			... headers
		};
		this.restangular.configuration.defaultHeaders = headers;
		this.RestangularAttachments.configuration.defaultHeaders = headers;
	}

	addDefaultHeader(headerKey, headerValue) {
		this.restangular.configuration.defaultHeaders[headerKey] = headerValue;
	}

	getDefaultHeaders() {
		return this.restangular.configuration.defaultHeaders;
	}

	/* API declarations */

	getAuthStatus = metricsData => {
		let promise;
		if (metricsData) {
			promise = this.restangular.all(EMAIL_ACCESS_URL).all('authStatus').post({metrics: metricsData}).toPromise();
		}
		else {
			promise = this.restangular.all(EMAIL_ACCESS_URL).all('authStatus').get('').toPromise();
		}
		return promise;
	};

	reportThisEmail = (data) => this.restangular.all(EMAIL_ACCESS_URL).all('reportEmail').post(data).toPromise();

	exchangeTokenOpenID = (data) => this.restangular.all('openid').all('token').post(data).toPromise();

	// EMAIL_ACCESS_URL
	doAuthentication2Fa = (url, data) => this.restangular.all(url).all('2fa').post(data).toPromise();

	getLfSettings = () => this.restangular.all(EMAIL_ACCESS_URL).all('settings').get('').toPromise();

	getRecipientSignature = () => this.restangular.all(EMAIL_ACCESS_URL).all('signature').get('').toPromise();

	replyMessage = data => this.restangular.all(EMAIL_ACCESS_URL).all('Reply').post(data).toPromise();

	replyDeleteAttachment = attachmentId => this.restangular.all(EMAIL_ACCESS_URL).one('ReplyAttachmentRemove', attachmentId).post('').toPromise();

	doAuthAction = (action) => this.restangular.all(EMAIL_ACCESS_URL).all('authAction').one(action).post('').toPromise();

	getEmailTracking = () => this.restangular.all(EMAIL_URL).all('tracking').get('').toPromise();

	getEmailPostmark = () => this.restangular.all(EMAIL_URL).all('postmark').get('').toPromise();

	verifyReceiptPostmark = (recipId) => this.restangular.all(EMAIL_URL).all('postmark').all('verify').one(recipId).get('').toPromise();

	updateAdvanced = data => this.restangular.all(EMAIL_URL).all('tracking').customPUT(data).toPromise();


	/* quarantined section */
	getQuarantinedEmail = () => this.RestangularAttachments.all(QUARANTINED_URL).get('').toPromise();

	doActionOnQuarantinedEmail = (data) => this.restangular.all(QUARANTINED_URL).customPUT(data).toPromise();

	getQuarantinedEmailContent = () => this.restangular.all(QUARANTINED_URL).get('preview').toPromise();

	getQuarantinedEmailHeaders = () => this.restangular.all(QUARANTINED_URL).get('headers').toPromise();

	/* account takeover protection section */
	getAcpIncident = () => this.RestangularAttachments.all(ACCOUNT_TAKEOVER_PROTECTION_URL).get('').toPromise();

	getIncidentEmailContent = () => this.RestangularAttachments.all(ACCOUNT_TAKEOVER_PROTECTION_URL).all('content').get('').toPromise();

	doActionOnIncidentFingerprint = (data) => this.restangular.all(ACCOUNT_TAKEOVER_PROTECTION_URL).customPUT(data).toPromise();


	/* outbound quarantined section */
	getQuarantinedOutboundEmailInfo = () => this.restangular.all(OUTBOUND_QUARANTINED_URL).get('').toPromise();

	doActionOnQuarantinedOutboundEmail = (data) => this.restangular.all(OUTBOUND_QUARANTINED_URL).customPUT(data).toPromise();

	getQuarantinedEmailTracking = () => this.restangular.all(OUTBOUND_QUARANTINED_URL).get('tracking').toPromise();


	/* sender authentication section */
	getSenderAuthEmailInfo = () => this.restangular.all(SENDER_URL).get('').toPromise();

	senderAuthAction = (data) => this.restangular.all(SENDER_URL).customPUT(data).toPromise();

	/* review email section */
	getReviewEmailInfo = () => this.RestangularAttachments.all(REVIEW_URL).get('').toPromise();


	doActionOnReviewEmail = (data) => this.RestangularAttachments.all(REVIEW_URL).customPUT(data).toPromise();

	/* archive section */
	getArchiveCaseInfo = () => this.restangular.all(ARCHIVE_URL).get('').toPromise();
	getArchiveCaseInfoWithScroll = (params) => this.restangular.all(ARCHIVE_URL).post(params).toPromise();

	getArchiveEmailInfo = (messageId) => this.restangular.all(ARCHIVE_URL).all('info').one(messageId).get('').toPromise();


	/* secure reply */
	getSecureReply = (sender, recipient) => this.restangular.all(REPLY_URL).all(sender).one(recipient).get('').toPromise();

	getSecureReplyLfSettings = (planEnc) => this.restangular.all(REPLY_URL).all('settings').one(planEnc).get('').toPromise();
}
