<div class="flxClmn f-1-0" style="overflow: auto;">
	<!--"Handled by a reviewer" label-->
	<div class="flxClmn vSpace05" style="overflow: hidden; position: absolute; top: 0; right: 0; direction: rtl;" *ngIf="gs.isMobile && step === dic.STEP.Authenticated">
				<span class="flxRow-center animated fadeIn handled-text text-reveal t-color-primary"
					  style="font-family: 'Roboto', sans-serif; letter-spacing: 0.45px; max-width: 40px; cursor: default;  background: forestgreen; padding: 7px; width: 500px; box-shadow:  0 0 8px rgba(0,255,0,0.2); font-size: 0.85rem; visibility: visible;"
					  *ngIf="email.is_handled">
					<span style="position: absolute; right: 0; padding: 0 10px;">Email was handled by a reviewer</span>
					<i class="icon fas fa-check-circle" style="font-size: 1.2rem;"></i>
				</span>
		<span class="flxRow-center animated fadeIn text-reveal followed-text t-color-primary"
			  style="font-family: 'Roboto', sans-serif; letter-spacing: 0.45px; max-width: 40px; cursor: default; background: deepskyblue; padding: 7px; width: 500px; box-shadow:  0 0 8px rgba(0,255,255,0.2); font-size: 0.85rem; visibility: visible;"
			  *ngIf="email.mark_for_follow">
					<span style="position: absolute; right: 0; padding: 0 10px;">Followed</span>
					<i class="icon fas fa-eye" style="font-size: 1.2rem;"></i>
				</span>
	</div>
	<!--handle/follow buttons - mobile-->
	<div class="handle-action-btns flxRow animated fadeIn" style="position: fixed; top: 0; left: 0; font-size: 1.2rem; z-index: 2500; border: solid 1px darkgrey;"
		 *ngIf="gs.isMobile && step === dic.STEP.Authenticated">
		<i class="handle-btn fas fa-check-circle"
		   [ngStyle]="{'color': email.is_handled ? 'green' : 'grey'}"
		   (click)="email.is_handled ? doActionOnEmail(dic.CONSTANTS.reviewerEmailAction.updateHandled) : actionToConfirm = dic.CONSTANTS.reviewerEmailAction.updateHandled">
		</i>
		<i class="follow-btn fas fa-eye"
		   [ngStyle]="{'color': email.mark_for_follow ? '#486db5' : 'grey'}"
		   (click)="email.mark_for_follow ? doActionOnEmail(dic.CONSTANTS.reviewerEmailAction.updateFollow) : actionToConfirm = dic.CONSTANTS.reviewerEmailAction.updateFollow"></i>
	</div>


	<!--CONTENT: Blocked email-->
	<div class="flxClmn-center vSpace2 w100" style="flex:1 0; padding:15px" *ngIf="step === dic.STEP.Blocked">
		<!--info content block-->
		<div class="flxClmn-center vSpace2 t-color-secondary" style="flex:4 0; align-items: center;">
			<img src="assets/images/blockedEmail.png" style="width: 20vh; height: 20vh; filter:grayscale(1); opacity:0.3;" />
			<div class="flxClmn-start vSpace1" style="align-items: center">
				<span style="font-size: 1.6rem; font-family: 'Roboto',sans-serif; text-align: center; font-weight: lighter">This email is no more available</span>
			</div>
		</div>
		<span style="flex: 1 0; text-align: center; max-width: 80%; margin: 0 auto;" *ngIf="blockNote">{{blockNote}}</span>
	</div>

	<!--CONTENT: authenticated viewed email - NEW-->
	<div class="h100 w100 flxClmn" style="font-size: 0.75rem; overflow: auto;" *ngIf="step !== dic.STEP.Blocked">
		<app-encrypt-animation
			[expandInMobile] = "gs.isMobile"
			[iframeId]="'email-content-frame'"
			[encryptedContentInput]="encryptedContentSubject.asObservable()"
			[contentInput]="contentSubject.asObservable()"
			(encryptedContentEmitter)="updateEncryptedContent($event)">
		</app-encrypt-animation>
		<app-authentication
			style="flex: 1 0; height: 1px" class="flxClmn"
			*ngIf="[dic.STEP.Phone, dic.STEP.Totp, dic.STEP.Email, dic.STEP.Code, dic.STEP.Password, dic.STEP.Authenticating, dic.STEP.ssoIdp, dic.STEP.SSO, dic.STEP.Empty].indexOf(step) > -1"
			[stepDataInput]="stepDataSubject.asObservable()"
			(confirmSuccessEmitter)="confirmSuccessCb($event)"
			(confirmFailureEmitter)="confirmFailureCb($event)"
			(applyAnimationEmitter)="applyAnimationCb()"
			[userHash]="userHash"
			[parentComponent]="dic.CONSTANTS.authParentComponents.review">
		</app-authentication>
		<!--Popup header-->
		<div class="flxClmn-around tp-popup-header" style="flex: 1 0; background: linear-gradient(90deg, var(--bg-primary), var(--bg-primary) 58%, var(--light-gray-at)), url('assets/images/headerBG.jpg'); background-size: cover;"
			 *ngIf="step === dic.STEP.Authenticated">
			<div class="flxRow-between" [ngStyle]="{'margin' : gs.isMobile ? '15px 0' : '0'}">
				<div class="flxRow hSpace2 ellipsis" style="align-items: center; padding-right: 5%;">
					<img src="assets/images/favicon/favicon-32x32.png" style="width: 20px; margin: 0; image-rendering: -webkit-optimize-contrast;">
					<h3 class="t-color-primary ellipsis">{{email.subject}}</h3>
				</div>
			</div>

			<div [ngClass]="gs.isMobile ? 'flxClmn vSpace05' : 'flxRow hSpace2'"
				 [ngStyle]="{'align-items' : gs.isMobile ? 'flex-start' : 'center'}" class="t-color-primary">
				<!--quarantinedCategories: ['Malicious', 'Suspicious', 'Spam', 'Graymail', 'External', 'Safe'],-->
				<div class="flxRow-center hSpace1" style="border-radius: 250px; align-items: center; font-weight: 500; background: transparent; border: solid 2px; padding: 4px 15px;"
					 [ngStyle]="{'border-color': setInboundStatusStyles(email.category).displayColor, 'margin-left' : gs.isMobile ? '0' : '30px'}">
					<i class="fas" [ngStyle]="{'color' : setInboundStatusStyles(email.category).displayColor}"
					   [ngClass]="email.category === 'Safe' ? 'fa-check' : email.category === 'External' ? 'fa-external-link-alt' : (email.category === 'Spam' || email.category === 'Graymail') ? 'fa-exclamation-circle' : 'fa-biohazard'">
					</i>
					<span style="font-size: 0.9rem; font-weight: bold;"
						  [ngStyle]="{'color' : setInboundStatusStyles(email.category).displayColor, 'font-size' : gs.isMobile ? '12px' : '0.9rem'}">
								{{email.category}}
							</span>
				</div>
				<!--"quarantine status" details-->
				<div [ngClass]="gs.isMobile ? 'flxClmn vSpace05' : 'flxRow hSpace2 h100'"
					 [ngStyle]="{'align-items' : gs.isMobile ? 'flex-start' : 'center'}">
					<div class="vertical-separator" style="border-width: 2px; margin: 0 1.5vw; border-color: darkgrey; border-left: none;" *ngIf="!gs.isMobile"></div>
					<div class="flxRow hSpace1" style="font-size: 1rem; align-items: center;" [ngStyle]="gs.isMobile && {'font-size' : '14px'}">
						<span style="font-weight: bold;">Quarantine Status:</span>
						<span style="max-width: 400px;" class="ellipsis">{{email.quarantined_id && email.quarantined_id.status || 'Not Quarantined (Report-Only)'}}</span>
					</div>
				</div>
				<!---->
				<!--"reported by" details-->
				<div [ngClass]="gs.isMobile ? 'flxClmn vSpace05' : 'flxRow hSpace2 h100'"
					 [ngStyle]="{'align-items' : gs.isMobile ? 'flex-start' : 'center'}">
					<div class="vertical-separator" style="border-width: 2px; margin: 0 1.5vw; border-color: darkgrey; border-left: none;" *ngIf="!gs.isMobile"></div>
					<span style="font-size: 1rem;" [ngStyle]="gs.isMobile && {'font-size' : '14px'}">
								<b [ngStyle]="{'color' : email.report_info.type === 'report' ? 'red' : 'green'}">{{email.report_info.type | titlecase}}</b>: {{email.report_info_text}}
							</span>
					<div class="vertical-separator" style="border-width: 2px; margin: 0 1.5vw; border-color: darkgrey; border-left: none;" *ngIf="!gs.isMobile"></div>
					<div class="flxRow hSpace1" style="font-size: 1rem; align-items: center;" [ngStyle]="gs.isMobile && {'font-size' : '14px'}">
						<span style="font-weight: bold;">Reported by:</span>
						<span style="max-width: 400px;" class="ellipsis">{{email.reported_by}}</span>
					</div>
				</div>
				<!---->
			</div>
		</div>

		<div class="tp-below-header-container" style="flex: 8 0; overflow: hidden;"
			 *ngIf="step === dic.STEP.Authenticated">
			<!--Sidebar-->
			<div class="flxClmn tp-popup-sidebar">
				<div class="flxClmn"
					 [ngStyle]="{'flex' : !gs.isMobile ? '2 0' : 'unset',
						 				 'padding' : gs.isMobile ? '25px max(1.2vw,15px) 30px' : 'max(1.2vw,15px)'}">
					<!--Analysis Summary-->
					<div class="flxClmn vSpace1 t-color-primary">
						<div class="flxRow-between" style="margin-bottom: min(15px,1vh);">
							<div class="flxClmn w100 vSpace05">
								<span style="font-family: 'Roboto', sans-serif; font-size: 1.1rem;">Summary</span>
								<div style="background: linear-gradient(90deg, #9b9b9b, transparent); width: 100%; height: 1px;"></div>
							</div>
						</div>
						<div class="flxRow hSpace1" style="align-items: flex-start;"
							 *ngIf="threatProtection.resHead.message"
							 [ngStyle]="{'color': setInboundStatusStyles(threatProtection.resHead.status).displayColor}">
							<i class="fa fa-user" style="color: inherit; font-size: 0.9rem; flex-basis: 15px;"></i>
							<div class="flxClmn vSpace05" style="color: initial;">
								<span class="t-color-primary">{{threatProtection.resHead.message}}</span>
								<span class="t-color-primary" style="word-break: break-word;"
									  *ngIf="threatProtection.resHead.from && threatProtection.resHead.from.highlight"
									  [innerHTML]="threatProtection.resHead.from.highlight"></span>
							</div>
						</div>
						<div class="flxRow hSpace1" style="align-items: flex-start;"
							 *ngIf="threatProtection.resUrl.message"
							 [ngStyle]="{'color': setInboundStatusStyles(threatProtection.resUrl.status).displayColor}">
							<i class="fa fa-link" style="color: inherit; font-size: 0.9rem; flex-basis: 15px;"></i>
							<div class="flxClmn vSpace05" style="color: initial;">
								<span class="t-color-primary">{{threatProtection.resUrl.message}}</span>
								<span class="t-color-primary" style="word-break: break-word;"
									  *ngIf="threatProtection.resUrl.highlight"
									  [innerHTML]="threatProtection.resUrl.highlight"></span>
							</div>
						</div>
						<div class="flxRow hSpace1" style="align-items: flex-start;"
							 *ngIf="threatProtection.resHash.message"
							 [ngStyle]="{'color': setInboundStatusStyles(threatProtection.resHash.status).displayColor}">
							<i class="fa fa-file" style="color: inherit; font-size: 0.9rem; flex-basis: 15px;"></i>
							<div class="flxClmn vSpace05" style="color: initial;">
								<span class="t-color-primary">{{threatProtection.resHash.message}}</span>
								<span class="t-color-primary" style="word-break: break-word;"
									  *ngIf="threatProtection.resHash.highlight"
									  [innerHTML]="threatProtection.resHash.highlight"></span>
							</div>
						</div>
					</div>
				</div>
				<hr style="margin: 0" *ngIf="!gs.isMobile"/>
				<!--Actions-->
				<div class="flxClmn-between" style="flex: 4 0;">
					<div class="flxClmn animated fadeIn" style="padding: max(1.2vw,15px); position: relative;">
						<!--handle/follow buttons - desktop-->
						<div class="handle-action-btns flxRow animated fadeIn" style="position: absolute; top: 0; right: 0; font-size: 1.2rem;"
							 *ngIf="!gs.isMobile">
							<i class="handle-btn fas fa-check-circle"
							   [tooltip]="'Set as ' + (email.is_handled ? 'not ' : '') + 'handled'"
							   [ngStyle]="{'color': email.is_handled ? 'green' : 'grey'}"
							   (click)="email.is_handled ? doActionOnEmail(dic.CONSTANTS.reviewerEmailAction.updateHandled) : actionToConfirm = dic.CONSTANTS.reviewerEmailAction.updateHandled">
							</i>
							<i class="follow-btn fas fa-eye"
							   [tooltip]="email.mark_for_follow ? 'Unfollow ' : 'Follow'"
							   [ngStyle]="{'color': email.mark_for_follow ? '#486db5' : 'grey'}"
							   (click)="email.mark_for_follow ? doActionOnEmail(dic.CONSTANTS.reviewerEmailAction.updateFollow) : actionToConfirm = dic.CONSTANTS.reviewerEmailAction.updateFollow"></i>
						</div>
						<!--email actions-->
						<div class="flxClmn">
							<div class="flxClmn w100 vSpace05">
								<span class="t-color-primary" style="font-family: 'Roboto', sans-serif; font-size: 1.1rem;">Email actions</span>
								<div style="background: linear-gradient(90deg, #9b9b9b, transparent); width: 100%; height: 1px; margin-bottom: min(15px,1vh);"></div>
							</div>

							<div class="flxRow animated fadeIn" style="flex-wrap: wrap;">
								<btn style="flex: 1 0; max-width: 44%; min-width: 38% !important; margin: 5px;"
									 id="createIncidentBtn"
									 *ngIf="email.quarantined_id"
									 (action)="actionToConfirm = dic.CONSTANTS.reviewerEmailAction.createIncident">
									Create Incident
								</btn>
								<btn style="flex: 1 0; max-width: 44%; min-width: 38% !important; margin: 5px;"
									 id="rescanEmailBtn"
									 (action)="actionToConfirm = dic.CONSTANTS.reviewerEmailAction.rescanEmail">
									Rescan Email
								</btn>
								<btn style="flex: 1 0; max-width: 44%; min-width: 38% !important; margin: 5px;"
									 *ngIf="email.report_info.attachments?.length"
									 id="hashActionBtn"
									 (action)="actionToConfirm = dic.CONSTANTS.reviewerEmailAction.attachmentsHash">
									Hash
								</btn>
							</div>
						</div>

						<!--plan actions-->
						<div class="flxClmn" style="margin-top: 3vh;">
							<div class="flxClmn w100 vSpace05">
								<span class="t-color-primary" style="font-family: 'Roboto', sans-serif; font-size: 1.1rem;">Plan actions</span>
								<div style="background: linear-gradient(90deg, #9b9b9b, transparent); width: 100%; height: 1px; margin-bottom: min(15px,1vh);"></div>
							</div>
							<!--global actions-->
							<div class="flxRow animated fadeIn" style="flex-wrap: wrap;">
								<btn style="flex: 1 0; max-width: 44%; min-width: 38% !important; margin: 5px;"
									 *ngIf="email.report_info.links && email.report_info.links.length"
									 id="linksPlanActionBtn"
									 (action)="actionToConfirm = dic.CONSTANTS.reviewerEmailAction.planLinks">
									Links
								</btn>
								<btn style="flex: 1 0; max-width: 44%; min-width: 38% !important; margin: 5px;"
									 *ngIf="email.report_info.attachments && email.report_info.attachments.length"
									 id="attachmentsPlanActionBtn"
									 (action)="actionToConfirm = dic.CONSTANTS.reviewerEmailAction.planAttachments">
									Attachments
								</btn>
								<btn style="flex: 1 0; max-width: 44%; min-width: 38% !important; margin: 5px;"
									 id="senderPlanActionBtn"
									 (action)="actionToConfirm = dic.CONSTANTS.reviewerEmailAction.planSender">
									Sender
								</btn>
								<btn style="flex: 1 0; max-width: 44%; min-width: 38% !important; margin: 5px;"
									 *ngIf="email.threat_protection.resHead?.signature"
									 id="signSourcePlanActionBtn"
									 (action)="actionToConfirm = dic.CONSTANTS.reviewerEmailAction.planSignSource">
									Sign Source
								</btn>
							</div>
						</div>

						<!--security reviewer actions-->
						<div class="flxClmn" style="margin-top: 3vh;">
							<div class="flxClmn w100 vSpace05">
								<span class="t-color-primary" style="font-family: 'Roboto', sans-serif; font-size: 1.1rem;">Security Reviewer actions</span>
								<div style="background: linear-gradient(90deg, #9b9b9b, transparent); width: 100%; height: 1px; margin-bottom: min(15px,1vh);"></div>
							</div>
							<div class="flxRow animated fadeIn" style="flex-wrap: wrap;">
								<btn style="flex: 1 0; max-width: 44%; min-width: 38% !important; margin: 5px;"
									 id="senderActionBtn"
									 (action)="actionToConfirm = dic.CONSTANTS.reviewerEmailAction.globalSender">
									Sender
								</btn>
								<btn style="flex: 1 0; max-width: 44%; min-width: 38% !important; margin: 5px;"
									 id="linksActionBtn"
									 *ngIf="email.report_info.links && email.report_info.links.length"
									 (action)="actionToConfirm = dic.CONSTANTS.reviewerEmailAction.globalLinks">
									Links
								</btn>
								<btn style="flex: 1 0; max-width: 44%; min-width: 38% !important; margin: 5px;"
									 id="attachmentsActionBtn"
									 *ngIf="email.report_info.attachments && email.report_info.attachments.length"
									 (action)="actionToConfirm = dic.CONSTANTS.reviewerEmailAction.globalAttachments">
									Attachments
								</btn>
							</div>
						</div>

					</div>
					<div class="flxClmn-center" style="margin-top: -5px; padding: 0 4% 2vh; align-items: center; min-height: 45px;">
						<div class="flxRow" style="opacity: 0.95; font-size: 0.95rem; justify-content: flex-end"
							 *ngIf="!email.isRecipient && gs.isMobile"
							 [ngStyle]="gs.isMobile && {'font-size' : '0.85rem', 'margin' : '10px', 'justify-content' : 'center'}">
							<a [tooltip]="'Review the history of actions that were taken by reviewers'" show-delay="400"
							   [displayTouchscreen]="false"
							   (click)="showLogs = true;" class="hSpace05">
								<i class="fa fa-list-ul t-color-primary" style="opacity: 0.85; font-size: 0.8rem;"></i>
								<span class="t-color-primary">Reviewers activity</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<!--view threats / email toggle button | Mobile-->
			<div class="flxRow-center hSpace1 threats-email-toggle-container-mobile t-color-primary" style="align-items: center; margin: 10px 0 5px; height: 50px;"
				 *ngIf="gs.isMobile && threatProtection.resHead">
				<span>Toggle view:</span>
				<div class="flxRow-center" style="position: relative; overflow: hidden; border-radius: 5px; height: 30px;">
					<div class="flxRow-center hSpace05 threats-email-toggle-mobile" style="align-items: center; border-bottom-right-radius: 0; border-top-right-radius: 0;"
						 [ngClass]="showThreats && 'chosen'"
						 (click)="showThreats = true;">
						<i class="fas fa-exclamation-triangle"></i>
						<span>
								Threats
							</span>
					</div>
					<div class="flxRow-center threats-email-toggle-mobile" style="align-items: center;border-bottom-left-radius: 0; border-top-left-radius: 0; margin-left: -1px;"
						 [ngClass]="!showThreats && 'chosen'"
						 (click)="showEmailContent()">
						<div *ngIf="showThreats || !showLoader" class="hSpace05">
							<i class="fa fa-envelope"></i>
							<span class="animated fadeIn" *ngIf="showThreats || !showLoader">
									Email
								</span>
						</div>
						<i *ngIf="!showThreats && showLoader" class="fa fa-spinner fa-spin"></i>
					</div>
					<div id="blueSliderMobile" style="left: 0; width: 28vw; position: absolute; transition: margin 0.2s;height:100%"
						 [ngStyle]="showThreats ? {'margin-left':'0'} : {'margin-left':'28vw'}"></div>
				</div>
			</div>
			<!---->
			<div class="flxClmn" style="flex: 1 0; position: relative; overflow: auto;">
				<div class="threat-protection-grid h100" style="flex: 1 0; overflow: auto; padding: 0 0.75vw;">
					<!--Message Analysis Data Card-->
					<div class="threat-protection-card flxClmn ro18 co7 md-co12" style="min-height: 770px; max-height: 75vh;" *ngIf="!(gs.isMobile && !showThreats)">
						<div class="card-header flxRow-between" style="align-items: center;">
									<span class="card-title t-color-primary"
										  [ngStyle]="{'color' : setInboundStatusStyles(threatProtection.resHead.status).color === 'darkred' ? 'var(--danger)' : ''}">
										Message Analysis
									</span>
							<div class="flxRow hSpace1">
								<i class="fa hvr-opacity animated fadeIn" style="font-size: 1rem;"
								   [ngClass]="setInboundStatusStyles(threatProtection.resHead.status).icon === 'fa-bug' ? 'fa-times-circle' : setInboundStatusStyles(threatProtection.resHead.status).icon"
								   [ngStyle]="{'color' : setInboundStatusStyles(threatProtection.resHead.status).displayColor}"
								   [tooltip]="threatProtection.resHead.message || 'Some headers were found malicious or suspicious'"></i>
								<i class="fas fa-spinner fa-spin animated fadeIn" style="opacity: 0.4; font-size: 1rem; animation-duration: 1s;" *ngIf="_.isEmpty(threatProtection.resHead)"></i>
							</div>
						</div>
						<div class="flxClmn animated fadeIn t-bg-primary" style="flex: 1 0; overflow: auto;" *ngIf="!_.isEmpty(threatProtection.resHead)">
							<div class="flxClmn vSpace05" style="padding: 15px 10px">
								<span class="t-color-primary" style="text-decoration: underline;">Sender Identities</span>
								<table class="simpleDataTable t-color-primary w100 message-analysis-table middle" style="table-layout: fixed;">
									<tr>
										<th style="width: 100px; padding: 0;"></th>
										<th style="padding: 0;"></th>
										<th style="width: 30px; padding: 0;"></th>
									</tr>
									<tr *ngIf="threatProtection.resHead.from.senderMail && threatProtection.resHead.from.senderMail !== threatProtection.resHead.from.senderMailFrom">
										<td>
											<span><b>Appears As</b></span>
										</td>
										<td>
											<span style="padding: 0; white-space: pre-wrap; word-break: break-word;">{{threatProtection.resHead.from.senderMail}}</span>
										</td>
										<td>
											<i *ngIf="!threatProtection.resHead.from.senderMailFromFlag" [tooltip]="'Sender email address'" title-direction="right" class="fas fa-check" ></i>
											<a *ngIf="threatProtection.resHead.from.senderMailFromFlag" [tooltip]="'Sender email address is blacklisted'" title-direction="right"><i class="fas fa-times-circle"></i></a>
										</td>
									</tr>
									<tr *ngIf="threatProtection.resHead.from.senderMailFrom">
										<td>
											<span><b>Sender</b></span>
										</td>
										<td>
											<span style="padding: 0; white-space: pre-wrap; word-break: break-word;">{{threatProtection.resHead.from.senderMailFrom}}</span>
										</td>
										<td>
											<i *ngIf="!threatProtection.resHead.from.senderMailFromFlag" [tooltip]="'Sender email address'" title-direction="right" class="fas fa-check" ></i>
											<a *ngIf="threatProtection.resHead.from.senderMailFromFlag" [tooltip]="'Sender email address is blacklisted'" title-direction="right"><i class="fas fa-times-circle"></i></a>
										</td>
									</tr>
									<tr *ngIf="threatProtection.resHead && threatProtection.resHead.relationshipLevel">
										<td><b>Relationship</b></td>
										<td>
											<div class="flxRow hSpace1">
												<div style="flex: 1 0; background-color: #f1f1f1; border: 1px solid #ccc; border-radius: 5px;">
													<div class="widthAnimation" style="border-radius: 3px; text-align: center; word-break: keep-all; height: 100%;"
														 [ngStyle]="{ 'width': (threatProtection.resHead.relationshipLevel && threatProtection.resHead.relationshipLevel.total ? (threatProtection.resHead.relationshipLevel.total * 0.95 + 5)  : 5) + '%', 'background-color': threatProtection.resHead.relationshipLevel && threatProtection.resHead.relationshipLevel.total >= 70 ? 'darkgreen' : threatProtection.resHead.relationshipLevel && threatProtection.resHead.relationshipLevel.total >= 50 ? 'var(--warning)' : 'var(--danger)'}"></div>
												</div>
												<span>{{threatProtection.resHead.relationshipLevel && threatProtection.resHead.relationshipLevel.total || 0}}%</span>
											</div>
										</td>
										<td>
											<i style="font-weight: 400;" class="fas fa-question-circle checkmark" tooltip="Sender's relationship level with the user and organisation.<br/>This score is calculated at the time the email was received and does not reflect changes that were made after that time."></i>
										</td>
									</tr>
									<tr *ngIf="threatProtection.resHead.from.replyTo">
										<td>
											<span><b>Reply To</b></span>
										</td>
										<td>
											<span style="padding: 0; white-space: pre-wrap; word-break: break-word;">{{threatProtection.resHead.from.replyTo}}</span>
										</td>
										<td>
											<i *ngIf="!threatProtection.resHead.from.replyToFlag" [tooltip]="'Reply-To address'" title-direction="right" class="fas fa-check"></i>
											<a *ngIf="threatProtection.resHead.from.replyToFlag" [tooltip]="'Reply-To address is blacklisted'" title-direction="right"><i class="fas fa-times-circle"></i></a>
										</td>
									</tr>
									<tr *ngIf="threatProtection.resHead.from.returnPath">
										<td>
											<span><b>Return Path</b></span>
										</td>
										<td>
											<span style="padding: 0; white-space: pre-wrap; word-break: break-word;">{{threatProtection.resHead.from.returnPath}}</span>
										</td>
										<td>
											<i *ngIf="!threatProtection.resHead.from.returnPathFlag" [tooltip]="'Return-Path address'" title-direction="right" class="fas fa-check"></i>
											<a *ngIf="threatProtection.resHead.from.returnPathFlag" [tooltip]="'Return-Path address is blacklisted'" title-direction="right"><i class="fas fa-times-circle"></i></a>
										</td>
									</tr>
									<tr *ngIf="threatProtection.resHead.from.messageID">
										<td>
											<span><b>Message ID</b></span>
										</td>
										<td>
											<span style="word-break: break-all;">{{threatProtection.resHead.from.messageID}}</span>
										</td>
										<td>
											<i *ngIf="!threatProtection.resHead.from.messageIDFlag" [tooltip]="'Message-ID address'" title-direction="right" class="fas fa-check"></i>
											<a *ngIf="threatProtection.resHead.from.messageIDFlag" [tooltip]="'Message-ID address is blacklisted'" title-direction="right"><i class="fas fa-times-circle"></i></a>
										</td>
									</tr>
									<tr *ngIf="threatProtection.resHead.from.sendingMTA.domain">
										<td>
											<span><b>MTA Domain</b></span>
										</td>
										<td>
											<span style="padding: 0; white-space: pre-wrap; word-break: break-word;">{{threatProtection.resHead.from.sendingMTAFull || threatProtection.resHead.from.sendingMTA.domain}}</span>
										</td>
										<td>
											<i *ngIf="!threatProtection.resHead.from.senderEmailServerFlag" [tooltip]="'MTA domain'" title-direction="right" class="fas fa-check"></i>
											<a *ngIf="threatProtection.resHead.from.senderEmailServerFlag" [tooltip]="'MTA domain'" title-direction="right"><i class="fas fa-times-circle"></i></a>
										</td>
									</tr>
									<tr *ngIf="threatProtection.resHead.from.ipAddresses && threatProtection.resHead.from.ipAddresses.length">
										<td>
											<span><b>IP Addresses</b></span>
										</td>
										<td>
											<span>{{threatProtection.resHead.from.ipAddresses.join(', ')}}</span>
										</td>
										<td>
											<i *ngIf="!threatProtection.resHead.from.senderIPAddressFlag" [tooltip]="'IP address'" title-direction="right" class="fas fa-check"></i>
											<a *ngIf="threatProtection.resHead.from.senderIPAddressFlag" [tooltip]="'IP address'" title-direction="right"><i class="fas fa-times-circle"></i></a>
										</td>
									</tr>
								</table>
							</div>
							<div class="flxClmn vSpace05" style="padding: 15px 10px">
								<span class="t-color-primary" style="text-decoration: underline;">AI Confidence</span>
								<table class="simpleDataTable t-color-primary w100 message-analysis-table middle" style="table-layout: fixed;">
									<tr>
										<th style="width: 100px; padding: 0;"></th>
										<th style="padding: 0;"></th>
										<th style="width: 30px; padding: 0;"></th>
									</tr>
									<tr>
										<td>
											<span><b>AI Decision</b></span>
										</td>
										<td>
                                                       <span style="text-transform: capitalize;"
															 *ngIf="!threatProtection.resHead.AIresults.skip">
                                                           {{!threatProtection.resHead.AIresults.decision ? 'Safe' : threatProtection.resHead.AIresults.decision === 'threat' ? 'Business Email Compromise (BEC)' : threatProtection.resHead.AIresults.decision}}
                                                       </span>
											<span style="text-transform: capitalize;" *ngIf="threatProtection.resHead.AIresults.skip">
														{{threatProtection.resHead.AIresults.decision ? threatProtection.resHead.AIresults.decision : 'Internal metrics decision'}}
													</span>
										</td>
										<td>
											<i style="font-weight: 400;" class="fas fa-question-circle checkmark" tooltip="The final AI decision for this email, based on the AI score for BEC, Spam, and Graymail.<br/> The final email status may differ from the AI decision, due to link and attachment analysis."></i>
										</td>
									</tr>
									<tr *ngIf="threatProtection.AiRates">
										<td>
											<span><b>- BEC AI</b></span>
										</td>
										<td>
											<div class="flxRow hSpace1">
												<div style="flex: 1 0; background-color: #f1f1f1; border: 1px solid #ccc; border-radius: 5px;">
													<div class="widthAnimation" style="border-radius: 3px; text-align: center; word-break: keep-all; height: 100%;"
														 [ngStyle]="{ 'width': threatProtection.AiRates.threat_body_conf + '%', 'background-color' : threatProtection.AiRates.threat_body_conf <= 50 ? 'var(--success)' : threatProtection.AiRates.threat_body_conf <= 70 ? 'var(--warning)' : 'var(--danger)'}"></div>
												</div>
												<span style="flex-basis: 32px;">{{threatProtection.AiRates.threat_body_conf | number: 0}}%</span>
											</div>
										</td>
										<td>
										</td>
									</tr>
									<tr *ngIf="threatProtection.AiRates">
										<td>
											<span><b>- Spam AI</b></span>
										</td>
										<td>
											<div class="flxRow hSpace1">
												<div style="flex: 1 0; background-color: #f1f1f1; border: 1px solid #ccc; border-radius: 5px;">
													<div class="widthAnimation" style="border-radius: 3px; text-align: center; word-break: keep-all; height: 100%;"
														 [ngStyle]="{ 'width': threatProtection.AiRates.spam_body_conf + '%', 'background-color' : threatProtection.AiRates.spam_body_conf <= 50 ? 'var(--success)' : threatProtection.AiRates.spam_body_conf <= 70 ? 'var(--warning)' : 'var(--danger)'}"></div>
												</div>
												<span style="flex-basis: 32px;">{{threatProtection.AiRates.spam_body_conf | number: 0}}%</span>
											</div>
										</td>
										<td>
										</td>
									</tr>
									<tr *ngIf="threatProtection.AiRates">
										<td>
											<span><b>- Graymail AI</b></span>
										</td>
										<td>
											<div class="flxRow hSpace1">
												<div style="flex: 1 0; background-color: #f1f1f1; border: 1px solid #ccc; border-radius: 5px;">
													<div class="widthAnimation" style="border-radius: 3px; text-align: center; word-break: keep-all; height: 100%;"
														 [ngStyle]="{ 'width': threatProtection.AiRates.graymail_body_conf + '%', 'background-color' : threatProtection.AiRates.graymail_body_conf <= 50 ? 'var(--success)' : threatProtection.AiRates.graymail_body_conf <= 70 ? 'var(--warning)' : 'var(--danger)'}"></div>
												</div>
												<span style="flex-basis: 32px;">{{threatProtection.AiRates.graymail_body_conf | number: 0}}%</span>
											</div>
										</td>
										<td>
										</td>
									</tr>
								</table>
							</div>
							<div class="flxClmn vSpace05" style="padding: 15px 10px">
								<span class="t-color-primary" style="text-decoration: underline;">Authentication Standards</span>
								<table class="simpleDataTable t-color-primary w100 message-analysis-table middle" style="table-layout: fixed;">
									<tr>
										<th style="width: 100px; padding: 0;"></th>
										<th style="padding: 0;"></th>
										<th style="width: 30px; padding: 0;"></th>
									</tr>
									<tr *ngIf="threatProtection.resHead.spf">
										<td>
											<span><b>SPF</b></span>
										</td>
										<td>
											<span class="ellipsis" style="padding: 0;">{{threatProtection.resHead.spf.message ? threatProtection.resHead.spf.message : (threatProtection.resHead.from.isInternal ? 'SPF is Internal' : 'No SPF was sent.')}}</span>
										</td>
										<td>
											<i *ngIf="!threatProtection.resHead.spf.message" class="fas" [ngClass]="threatProtection.resHead.from.isInternal ? 'fa-check' : 'fa-exclamation-circle'"></i>
											<i class="fas" *ngIf="threatProtection.resHead.spf.message" [ngClass]="threatProtection.resHead.spf.score === 2 ? 'fa-exclamation-circle' : threatProtection.resHead.spf.score > 1 ? 'fa-check' : 'fa-times-circle'"></i>
										</td>
									</tr>
									<tr *ngIf="threatProtection.resHead.arc">
										<td>
											<span><b>ARC</b></span>
										</td>
										<td>
											<span class="ellipsis" style="padding: 0;">{{threatProtection.resHead.arc.message ? threatProtection.resHead.arc.message : (threatProtection.resHead.from.isInternal ? 'ARC is Internal' : 'No ARC was sent.')}}</span>
										</td>
										<td>
											<i *ngIf="!threatProtection.resHead.arc.message" class="fas" [ngClass]="threatProtection.resHead.from.isInternal ? 'fa-check' : 'fa-exclamation-circle'"></i>
											<i class="fas" *ngIf="threatProtection.resHead.arc.message" [ngClass]="threatProtection.resHead.arc.score === 2 ? 'fa-exclamation-circle' : threatProtection.resHead.arc.score > 1 ? 'fa-check' : 'fa-times-circle'"></i>
										</td>
									</tr>
									<tr *ngIf="threatProtection.resHead.dkim">
										<td><b>DKIM</b></td>
										<td>
											<span class="ellipsis" style="padding: 0;">{{threatProtection.resHead.dkim.message ? threatProtection.resHead.dkim.message : (threatProtection.resHead.from.isInternal ? 'DKIM is Internal' : 'No DKIM was sent')}}</span>
										</td>
										<td>
											<i *ngIf="!threatProtection.resHead.dkim.message" class="fas" [ngClass]="threatProtection.resHead.from.isInternal ? 'fa-check' : 'fa-exclamation-circle'"></i>
											<i class="fas" *ngIf="threatProtection.resHead.dkim.message" [ngClass]="threatProtection.resHead.dkim.score === 2 ? 'fa-exclamation-circle' : threatProtection.resHead.dkim.score > 1 ? 'fa-check' : 'fa-times-circle'"></i>
										</td>
									</tr>
									<tr *ngIf="threatProtection.resHead.dmarc">
										<td><b>DMARC</b></td>
										<td>
											<span class="ellipsis" style="padding: 0;">{{threatProtection.resHead.dmarc.message ? threatProtection.resHead.dmarc.message : (threatProtection.resHead.from.isInternal ? 'DMARC is Internal' : 'No DMARC was sent.')}}</span>
										</td>
										<td>
											<i *ngIf="!threatProtection.resHead.dmarc.message" class="fas" [ngClass]="threatProtection.resHead.from.isInternal ? 'fa-check' : 'fa-exclamation-circle'"></i>
											<i class="fas" *ngIf="threatProtection.resHead.dmarc.message" [ngClass]="threatProtection.resHead.dmarc.score === 2 ? 'fa-exclamation-circle' : threatProtection.resHead.dmarc.score > 1 ? 'fa-check' : 'fa-times-circle'"></i>
										</td>
									</tr>
									<tr *ngIf="threatProtection.resHead.compauth">
										<td><b>CompAuth</b></td>
										<td>
											<span class="ellipsis" style="padding: 0;">{{threatProtection.resHead.compauth.message ? threatProtection.resHead.compauth.message : (threatProtection.resHead.from.isInternal ? 'CompAuth is Internal' : 'No CompAuth was sent.')}}</span>
										</td>
										<td>
											<i *ngIf="!threatProtection.resHead.compauth.message" class="fas" [ngClass]="threatProtection.resHead.from.isInternal ? 'fa-check' : 'fa-exclamation-circle'"></i>
											<i class="fas" *ngIf="threatProtection.resHead.compauth.message" [ngClass]="threatProtection.resHead.compauth.score === 2 ? 'fa-exclamation-circle' : threatProtection.resHead.compauth.score > 1 ? 'fa-check' : 'fa-times-circle'"></i>
										</td>
									</tr>
									<tr *ngIf="threatProtection.resHead.from && threatProtection.resHead.from.tls && (threatProtection.resHead.from.tls.message || threatProtection.resHead.from.tls.recipient.message || threatProtection.resHead.from.tls.external.message || threatProtection.resHead.from.tls.sender.message)">
										<td>
											<span><b>TLS</b></span>
										</td>
										<td>
											<div class="flxClmn vSpace05">
												<span>{{threatProtection.resHead.from.tls.message}}</span>
												<span *ngIf="threatProtection.resHead.from.tls.recipient.message">{{threatProtection.resHead.from.tls.recipient.message}}</span>
												<span *ngIf="threatProtection.resHead.from.tls.external.message">{{threatProtection.resHead.from.tls.external.message}}</span>
												<span *ngIf="threatProtection.resHead.from.tls.sender.message">{{threatProtection.resHead.from.tls.sender.message}}</span>
											</div>
										</td>
										<td>
											<div class="flxClmn" style="align-items: flex-start">
												<i class="fas" [ngClass]="threatProtection.resHead.from.tls.status === false ? 'fa-times-circle' : 'fa-check'"></i>
											</div>
										</td>
									</tr>
								</table>
							</div>
						</div>
						<div class="flxClmn-center" style="align-items: center; flex: 1 0;" *ngIf="_.isEmpty(threatProtection.resHead)">
							<span style="opacity: 0.5;">Loading</span>
						</div>
					</div>
					<div class="flxClmn ro18 co7 md-co12"
						 [ngStyle]="{'min-height' : gs.isMobile ? '0' : '770px'}"
						 [ngClass]="gs.isMobile && 'md-co24'"
						 *ngIf="!(gs.isMobile && !showThreats)">
						<div class="threat-protection-card flxClmn" style="flex: 1 0;">
							<div class="card-header flxRow-between" style="align-items: center;">
										<span class="card-title t-color-primary" [ngStyle]="{'color' : setInboundStatusStyles(threatProtection.resUrl.status).color === 'darkred' ? 'var(--danger)' : ''}">
											Links Analysis {{threatProtection.resUrl && threatProtection.resUrl.list && threatProtection.resUrl.list.length ? '(' + threatProtection.resUrl.list.length + ')' : ''}}
										</span>
								<div class="flxRow hSpace1">
									<i class="fa hvr-opacity" style="font-size: 1rem;" *ngIf="!_.isEmpty(threatProtection.resHead)"
									   [tooltip]="threatProtection.resUrl.status"
									   [ngClass]="setInboundStatusStyles(threatProtection.resUrl.status).icon === 'fa-bug' ? 'fa-times-circle' : setInboundStatusStyles(threatProtection.resUrl.status).icon"
									   [ngStyle]="{'color' : setInboundStatusStyles(threatProtection.resUrl.status).displayColor}">
									</i>
									<i class="fas fa-spinner fa-spin animated fadeIn" style="opacity: 0.4; font-size: 1rem; animation-duration: 1s;" *ngIf="_.isEmpty(threatProtection.resHead)"></i>
								</div>
							</div>
							<div class="flxClmn animated fadeIn t-bg-primary" style="align-items: center; flex: 1 0; padding: 10px; overflow: auto;"
								 *ngIf="!_.isEmpty(threatProtection.resHead)"
								 [ngStyle]="{'justify-content' : !threatProtection.resUrl.list || !threatProtection.resUrl.list.length ? 'center' : ''}">
								<div class="w100" style="flex: 1 0;" *ngIf="threatProtection.resUrl.list && threatProtection.resUrl.list.length">
									<table class="simpleDataTable t-color-primary w100 message-analysis-table middle" style="table-layout: fixed;">
										<tr>
											<th style="padding: 0;"></th>
										</tr>
										<tr *ngFor="let url of threatProtection.resUrl.list; let i = index">
											<td>
												<div class="flxClmn vSpace1 ellipsis" style="padding: 10px 0;">
													<div class="flxRow" style="align-items: center;">
														<div style="flex: 1 0; padding-right: 15px;" class="ellipsis flxRow hSpace05">
															<b style="flex-basis: 40px">Name:</b>
															<span class="ellipsis" style="flex: 1 0;">{{url.name ? url.name : url.domain ? url.domain : url.url}}</span>
														</div>
														<div class="flxRow" style="align-items: center;">
															<i class="fas" *ngIf="!url.category" [ngClass]="setInboundStatusStyles(url.status).icon === 'fa-check-circle' ? 'fa-check' : setInboundStatusStyles(url.status).icon === 'fa-bug' ? 'fa-times-circle' : setInboundStatusStyles(url.status).icon"
															   [ngStyle]="{'color': setInboundStatusStyles(url.status).displayColor}" title-direction="right" [tooltip]="url.status"></i>
															<div class="flxRow hSpace05" style="background: var(--bg-primary); width: fit-content; border: solid 2px; border-radius: 3px; padding: 2px 7px; align-items: center;"
																 *ngIf="url.category"
																 [ngStyle]="{'border-color': setInboundStatusStyles(url.status).displayColor,
                                                                                    'color': setInboundStatusStyles(url.status).displayColor}">
																<i class="fa" style="color: inherit; font-size: 10px;" [ngClass]="setInboundStatusStyles(url.status).icon"></i>
																<span style="color: inherit; font-size: 11px;">{{url.category}}</span>
															</div>
														</div>
													</div>
													<div class="flxRow" style="align-items: flex-end;" *ngIf="url.original_url || url.url">
														<div class="flxRow hSpace05 ellipsis" style="padding-right: 45px; font-weight: 300; flex: 1 0;">
															<b style="flex-basis: 40px;">URL:</b>
															<span class="ellipsis" style="flex: 1 0;">{{url.original_url || url.url}}</span>
														</div>
														<a ngxClipboard [cbContent]="url.original_url || url.url" (click)="$event.stopPropagation();">
															<i class="fas fa-copy" style="opacity: 0.7;" [tooltip]="'Copy full URL'"></i>
														</a>
													</div>
													<div class="flxRow" style="align-items: flex-end;" *ngIf="url.url && url.original_url && (url.url !== url.original_url || url.redirect_url && url.original_url !== url.redirect_url)">
														<div class="flxRow hSpace05 ellipsis" style="padding-right: 45px; font-weight: 300; flex: 1 0;">
															<b style="flex-basis: 40px;">Redirect:</b>
															<span class="ellipsis" style="flex: 1 0;">{{url.redirect_url || url.url}}</span>
														</div>
														<a ngxClipboard [cbContent]="url.redirect_url || url.url" (click)="$event.stopPropagation();">
															<i class="fas fa-copy" style="opacity: 0.7;" [tooltip]="'Copy full redirect URL'"></i>
														</a>
													</div>
													<div class="flxRow" style="align-items: flex-end;" *ngIf="url.malware_type">
																<span class="ellipsis" style="display: block; font-weight: 300; padding-right: 45px; flex: 1 0;" >
																	<b>Malware Type</b>: <span [tooltip]="url.malware_type">{{url.malware_type}}</span>
																</span>
														<a ngxClipboard [cbContent]="url.malware_type" (click)="$event.stopPropagation();">
															<i class="fas fa-copy" style="opacity: 0.7;" [tooltip]="'Copy malware type'"></i>
														</a>
													</div>
													<div class="flxRow" style="align-items: flex-end;" *ngIf="url.domain_age">
																<span class="ellipsis" style="display: block; font-weight: 300; padding-right: 45px; flex: 1 0;" >
																	<b>Domain Age</b>: <span>{{url.domain_age | date:'MM/dd/yyyy'}}</span>
																</span>
													</div>
												</div>
											</td>
										</tr>
									</table>
								</div>
								<span *ngIf="!threatProtection.resUrl.list || !threatProtection.resUrl.list.length">No links in email</span>
							</div>
							<div class="flxClmn-center" style="align-items: center; flex: 1 0;" *ngIf="_.isEmpty(threatProtection.resHead)">
								<span style="opacity: 0.5;">Loading</span>
							</div>
						</div>
						<div class="threat-protection-card flxClmn" style="flex: 1 0;">
							<div class="card-header flxRow-between" style="align-items: center;">
										<span class="card-title t-color-primary" [ngStyle]="{'color' : setInboundStatusStyles(threatProtection.resHash.status).color === 'darkred' ? 'var(--danger)' : ''}">
											Attachments Analysis {{threatProtection.resHash && threatProtection.resHash.list && threatProtection.resHash.list.length ? '(' + threatProtection.resHash.list.length + ')' : ''}}
										</span>
								<div class="flxRow hSpace1">
									<i class="fa hvr-opacity" style="font-size: 1rem;" *ngIf="!_.isEmpty(threatProtection.resHead)"
									   [tooltip]="threatProtection.resHash.status"
									   [ngClass]="setInboundStatusStyles(threatProtection.resHash.status).icon === 'fa-bug' ? 'fa-times-circle' : setInboundStatusStyles(threatProtection.resHash.status).icon"
									   [ngStyle]="{'color' : setInboundStatusStyles(threatProtection.resHash.status).displayColor}">
									</i>
									<i class="fas fa-spinner fa-spin animated fadeIn" style="opacity: 0.4; font-size: 1rem; animation-duration: 1s;" *ngIf="_.isEmpty(threatProtection.resHead)"></i>
								</div>
							</div>
							<div class="flxClmn animated fadeIn t-bg-primary" style="align-items: center; flex: 1 0; padding: 10px; overflow: auto;"
								 *ngIf="!_.isEmpty(threatProtection.resHead)"
								 [ngStyle]="{'justify-content' : !threatProtection.resHash.list || !threatProtection.resHash.list.length ? 'center' : ''}">
								<div style="flex: 1 0;" *ngIf="threatProtection.resHash.list && threatProtection.resHash.list.length">
									<table class="w100 simpleDataTable t-color-primary message-analysis-table middle" style="table-layout: fixed;">
										<tr>
											<th style="padding: 0;"></th>
										</tr>
										<tr *ngFor="let file of threatProtection.resHash.list; let i = index">
											<td>
												<div class="flxClmn vSpace1 ellipsis" style="padding: 10px 0;">
													<div class="flxRow" style="align-items: center;">
																<span style="font-weight: 500; flex: 1 0; padding-right: 15px;" class="ellipsis">
																	{{file.name}}
																</span>
														<div class="flxRow" style="align-items: center;">
															<i class="fas" *ngIf="!file.category" [ngClass]="setInboundStatusStyles(file.status).icon === 'fa-check-circle' ? 'fa-check' : setInboundStatusStyles(file.status).icon === 'fa-bug' ? 'fa-times-circle' : setInboundStatusStyles(file.status).icon"
															   [ngStyle]="{'color': setInboundStatusStyles(file.status).displayColor}" title-direction="right" [tooltip]="file.status"></i>
															<div class="flxRow hSpace05" style="background: var(--bg-primary); width: fit-content; border: solid 2px; border-radius: 3px; padding: 2px 7px; align-items: center;"
																 *ngIf="file.category"
																 [ngStyle]="{'border-color': setInboundStatusStyles(file.status).displayColor,
                                                                                    'color': setInboundStatusStyles(file.status).displayColor}">
																<i class="fa" style="color: inherit; font-size: 10px;" [ngClass]="setInboundStatusStyles(file.status).icon"></i>
																<span style="color: inherit; font-size: 11px;">{{file.category}}</span>
															</div>
														</div>
													</div>
													<div class="flxRow" style="align-items: flex-end;">
																<span class="ellipsis" style="display: block; font-weight: 300; padding-right: 45px; flex: 1 0;" *ngIf="file.type">
																	<b>Type</b>: {{file.type}} ({{file.name && file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase()}})
																</span>
													</div>
													<div class="flxRow" style="align-items: flex-end;">
																<span class="ellipsis" style="display: block; font-weight: 300; padding-right: 45px; flex: 1 0;" *ngIf="file.hash">
																	<b>Hash</b>: {{file.hash}}
																</span>
														<a ngxClipboard [cbContent]="file.hash" (click)="$event.stopPropagation();" *ngIf="file.hash">
															<i class="fas fa-copy" style="opacity: 0.7;" [tooltip]="'Copy file hash'"></i>
														</a>
													</div>
													<div class="flxRow" style="align-items: flex-end;" *ngIf="file.malware_type">
																<span class="ellipsis" style="display: block; font-weight: 300; padding-right: 45px; flex: 1 0;" >
																	<b>Malware Type</b>: <span [tooltip]="file.malware_type">{{file.malware_type}}</span>
																</span>
														<a ngxClipboard [cbContent]="file.malware_type" (click)="$event.stopPropagation();">
															<i class="fas fa-copy" style="opacity: 0.7;" [tooltip]="'Copy malware type'"></i>
														</a>
													</div>
												</div>
											</td>
										</tr>
									</table>
								</div>
								<span class="t-color-primary" *ngIf="!threatProtection.resHash.list || !threatProtection.resHash.list.length">No attachments in email</span>
							</div>
							<div class="flxClmn-center" style="align-items: center; flex: 1 0;" *ngIf="_.isEmpty(threatProtection.resHead)">
								<span class="t-color-primary" style="opacity: 0.5;">Loading</span>
							</div>
						</div>
					</div>
					<div class="email-content-card ro15 co10 md-co24" style="position: relative; margin: 0.75vw;"
						 [ngClass]="(email.isShowingContent || email.htmlContent) && !gs.isMobile && 'is-showing-content'"
						 [ngStyle]="{'max-height' : gs.isMobile ? 'none !important' : '75vh', 'height' : gs.isMobile ? 'auto !important' : ''}"
						 *ngIf="!(gs.isMobile && showThreats)">
						<div class="threat-protection-card flxClmn w100 h100" style="position: relative; z-index: 3; margin: 0;">
							<div class="card-header flxRow-between" style="align-items: center;">
										<span class="card-title t-color-primary">
											Email Content
										</span>
								<div class="flxRow hSpace1" [ngClass]="{'disabled' : !email.htmlContent}">
									<a>
										<i class="fa fa-solid fa-code t-color-primary" style="font-size: 1rem" [tooltip]="Mode"
										   (click)="showHtml = !showHtml"></i>
									</a>
									<a>
										<i class="fa fa-download t-color-primary" style="font-size: 1rem" [tooltip]="EML"
										   (click)="downloadRawMail()"></i>
									</a>
								</div>
							</div>

							<div *ngIf="email.htmlContent"
								 class="input-wrap flxClmn t-bg-secondary" style="flex: 1 0; overflow: auto; padding: 15px 20px 10px;"
								 [ngStyle]="gs.isMobile && {'padding' : '0'}">

								<div [hidden]="showHtml" style="flex: 1 0; flex-direction: column;" [ngStyle]="{'display': showHtml ? 'none' : 'flex'}">
									<div class="email-view animated fadeIn t-bg-primary" style="flex: 1 0; padding: 10px;"
										 [ngStyle]="step !== dic.STEP.Authenticated || (gs.isMobile && showThreats) ? {'visibility': 'hidden', 'flex' : '0 1', 'padding' : '0' , 'max-height' : '0'} : {'visibility': 'visible', 'flex' : '1 0' , 'max-height' : '100%'}">
										<div class="email" style="flex: 1 0;">
											<iframe [ngStyle]="step !== dic.STEP.Authenticated && {'height': '0'}" style="background-color: white;"
													class="email-iframe" id="email-content-frame" src="about:blank" ></iframe>
										</div>
									</div>

									<div id="quarantinedAttachmentsContainer" *ngIf="email.content.attachments?.length">
										<hr style="width: 80%; margin: 10px auto;"/>
										<div style="margin: 0;" [ngClass]="gs.isMobile ? 'flxClmn vSpace1' : 'flxRow hSpace1'" [ngStyle]="gs.isMobile && {'padding' : '10px'}">
											<div class="flxRow" style="flex:1 0; flex-wrap: wrap; overflow: auto"
												 [ngStyle]="{'max-height' : gs.isMobile ? 'none' : '66px'}">
												<attachment-c
													*ngFor="let file of email.content.attachments"
													[attName]="file.name"
													[attSize]="file.size"
													(preview)="previewAttachment = file;"
													(download)="downloadFile(file)">
												</attachment-c>
											</div>
										</div>
									</div>
								</div>

								<div *ngIf="showHtml" class="flxClmn t-color-primary" style="flex: 1 0">
									<pre>{{email.content.html || email.content.text}}</pre>
								</div>
							</div>

							<!--"show content" button for large size-->
							<div class="flxClmn-center t-bg-primary" style="align-items: center; flex: 1 0;"
								 [ngClass]="showLoader && 'shinyFX'"
								 [ngStyle]="{'overflow' : showLoader ? 'hidden' : 'auto'}"
								 *ngIf="!email.htmlContent">
								<btn class="show-email-large-btn"
									 [loading]="showLoader"
									 (action)="showEmailContent();">
									Show Content
								</btn>
							</div>
						</div>
						<!--label "show content" button for laptop size-->
						<a class="hSpace1 blueLink flxRow-center show-email-medium-btn t-color-primary"
						   style="padding: 10px; font-size: 0.9rem; font-weight: 500; align-items: center; position: relative; z-index: 5;"
						   *ngIf="!gs.isMobile && !email.isShowingContent && !email.htmlContent"
						   (click)="showEmailContent();">
							<span>Show Email Content</span>
							<i class="fa fa-chevron-down animated flash infinite" style="font-size: 0.6rem; margin-top: 0; animation-duration: 2.5s;"></i>
						</a>
					</div>
					<div class="threat-protection-card flxClmn ro15 co24 md-co24" style="min-height: 350px;" *ngIf="!email.isRecipient && !gs.isMobile">
						<div class="card-header flxRow-between" style="align-items: center;">
                                    <span class="card-title t-color-primary">
                                        Reviewers Activity {{email.reviewer_actions && email.reviewer_actions.length ? '(' + email.reviewer_actions.length + ')' : ''}}
                                    </span>
						</div>
						<div class="flxClmn t-bg-primary" style="flex: 1 0; align-items: center; padding: 15px; overflow: auto;"
							 [ngStyle]="{'justify-content' : !email.reviewer_actions || !email.reviewer_actions.length ? 'center' : ''}">
							<table style="text-align: left; width:100%; table-layout: fixed;" class="simpleDataTable t-color-primary" *ngIf="email.reviewer_actions && email.reviewer_actions.length">
								<tr>
									<th style="width: 20%;">Created</th>
									<th style="width: 25%">Reviewer</th>
									<th style="width: 50%">Action</th>
								</tr>
								<tr *ngFor="let actionObj of email.reviewer_actions; let i = index">
									<td>
										{{actionObj.created | date:'MM/dd/yyyy HH:mm'}}
									</td>
									<td style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
										{{actionObj.reviewer}}
									</td>
									<td style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
										<span style="display: block; width: fit-content; max-width: 100%;" [tooltip]="actionObj.action">{{actionObj.action}}</span>
									</td>
								</tr>
							</table>
							<span class="t-color-primary" style="opacity: 0.7;" *ngIf="!email.reviewer_actions || !email.reviewer_actions.length">
										No activity by reviewers
									</span>
						</div>
					</div>
				</div>
				<!--actions details container-->
				<div class="flxClmn actions-details-container" style="flex: 1 0; z-index: 20;"
					 [ngClass]="{'active' : actionToConfirm || attachmentDownloadConfirm}">
					<!--PADDER-->
					<div style="flex: 1 0;"></div>
					<!---->
					<div class="shader" style="transition: all 0.2s;" [ngClass]="{'active': actionToConfirm || attachmentDownloadConfirm}"></div>
					<div class="attachments-container t-color-primary flxRow-center" style="flex: 2 0; max-height: none; padding: 15px; margin: calc(2vw + 23px) 0 0;">
						<!--ACTION SECTION CONTENT: confirm action-->
						<div class="flxClmn-between h100"
							 style="flex: 1 0; max-width: -webkit-fill-available;" *ngIf="actionToConfirm && !attachmentDownloadConfirm"
							 [ngStyle]="!gs.isMobile && {'padding': '10px 20px'}">
							<div class="confirm-text">
								<span *ngIf="actionToConfirm === dic.CONSTANTS.reviewerEmailAction.spam">Email content will be added to spam model</span>

								<div class="flxClmn vSpace2" *ngIf="actionToConfirm === dic.CONSTANTS.reviewerEmailAction.globalLinks || actionToConfirm === dic.CONSTANTS.reviewerEmailAction.planLinks">
												<span *ngIf="actionToConfirm === dic.CONSTANTS.reviewerEmailAction.globalLinks">
													Choose which links to be marked as <b>{{email.report_info.type === dic.CONSTANTS.reportType.trust ? 'trusted' : 'malicious'}} (globally)</b>
												</span>
									<span *ngIf="actionToConfirm === dic.CONSTANTS.reviewerEmailAction.planLinks">
													Choose which links to be added to <b>{{email.report_info.type === dic.CONSTANTS.reportType.trust ? 'allowlist' : 'block list'}} (plan)</b>
												</span>
									<div *ngIf="email.report_info.links && email.report_info.links.length">
										<div class="dropdown"
											 (clickOutside)="linksDropdown = false;"
											 [ngClass]="{'active': linksDropdown}"
											 (click)="linksDropdown = !linksDropdown"
											 (focus)="linksDropdown = true"
											 (blur)="linksDropdown = false">
											<div class="select">
												<div class="ellipsis t-color-primary" style="font-size: 0.85rem; padding-left: 5px; line-height: 18px;">Choose</div>
												<i class="fa fa-chevron-left"></i>
											</div>
											<ul class="dropdown-menu" style="max-height: 250px"
												[ngClass]="{'active': linksDropdown}"
												[style.height.px]="linksDropdown ? 36 * email.report_info.links.length : 0">
												<li style="padding: 0 8px;border:0;position: relative;height: 36px;"
													*ngFor="let link of email.report_info.links"
													(click)="$event.stopPropagation()">
													<label [ngClass]="{'disabled': link.enforce}">
														<input type="checkbox"
															   [(ngModel)]="link.selected"
															   [checked]="link.selected"/>
														<div class="label-value ellipsis" style="font-size: 0.85rem">{{link.link}}</div>
													</label>
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div class="flxClmn vSpace2" *ngIf="actionToConfirm === dic.CONSTANTS.reviewerEmailAction.globalAttachments || actionToConfirm === dic.CONSTANTS.reviewerEmailAction.planAttachments">
												<span *ngIf="actionToConfirm === dic.CONSTANTS.reviewerEmailAction.globalAttachments">
													Choose which attachments to be marked as <b>{{email.report_info.type === dic.CONSTANTS.reportType.trust ? 'trusted' : 'malicious'}} (globally)</b>
												</span>
									<span *ngIf="actionToConfirm === dic.CONSTANTS.reviewerEmailAction.planAttachments">
													Choose which attachments to be added to <b>{{email.report_info.type === dic.CONSTANTS.reportType.trust ? 'allowlist' : 'block list'}} (plan)</b>
												</span>
									<div *ngIf="email.report_info.attachments && email.report_info.attachments.length">
										<div class="dropdown review"
											 (clickOutside)="linksDropdown = false;"
											 [ngClass]="{'active': linksDropdown}"
											 (click)="linksDropdown = !linksDropdown"
											 (focus)="linksDropdown = true"
											 (blur)="linksDropdown = false">
											<div class="select">
												<div class="ellipsis" style="font-size: 0.85rem; padding-left: 5px; line-height: 18px;">Choose</div>
												<i class="fa fa-chevron-left"></i>
											</div>
											<ul class="dropdown-menu" style="max-height: 250px"
												[ngClass]="{'active': linksDropdown}"
												[style.height.px]="linksDropdown ? 36 * email.report_info.attachments.length : 0">
												<li style="padding: 0 8px;border:0;position: relative;height: 36px;"
													*ngFor="let attachment of email.report_info.attachments"
													(click)="$event.stopPropagation()">
													<label [ngClass]="{'disabled': attachment.enforce}">
														<input type="checkbox"
															   [(ngModel)]="attachment.selected"
															   [checked]="attachment.selected"/>
														<div class="label-value ellipsis" style="font-size: 0.85rem">{{attachment.name}}</div>
													</label>
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div *ngIf="actionToConfirm === dic.CONSTANTS.reviewerEmailAction.planSender || actionToConfirm === dic.CONSTANTS.reviewerEmailAction.globalSender">
									Choose which items to be added to <b>{{email.report_info.type === dic.CONSTANTS.reportType.trust ? 'allowlist' : 'blocklist'}} ({{actionToConfirm === dic.CONSTANTS.reviewerEmailAction.planSender ? 'Plan' : 'Global'}})</b>
									<div class="flxRow-start w100 hSpace1 md-checkbox" style="align-items: center; margin: 5px 0;" *ngIf="threatProtection.resHead.from.senderMail && threatProtection.resHead.from.senderMail !== threatProtection.resHead.from.senderMailFrom">
										<input type="checkbox"
											   id="senderAppearsAs"
											   [(ngModel)]="senderEmailObj.senderEmailAppearsAs"/>
										<label style="word-break: break-all;" for="senderAppearsAs">Sender appears as: {{threatProtection.resHead.from.senderMail}}</label>
									</div>
									<div class="flxRow-start w100 hSpace1 md-checkbox" style="align-items: center; margin: 5px 0;">
										<input type="checkbox"
											   id="senderEmail"
											   [(ngModel)]="senderEmailObj.senderEmail"/>
										<label style="word-break: break-all;" for="senderEmail">Sender email: {{threatProtection.resHead.from.senderMailFrom}}</label><span *ngIf="email.report_info.type === dic.CONSTANTS.reportType.trust && threatProtection.resHead && threatProtection.resHead.from">(will be signed)</span>
									</div>
									<div class="flxRow-start w100 hSpace1 md-checkbox" style="align-items: center; margin: 5px 0;">
										<input type="checkbox"
											   id="senderDomain"
											   [(ngModel)]="senderEmailObj.senderDomain"/>
										<label style="word-break: break-all;" for="senderDomain">Sender domain: {{getEmailDomain(email.sender_info.email)}}</label>
									</div>
									<div class="flxRow-start w100 hSpace1 md-checkbox" style="align-items: center; margin: 5px 0;"
										 *ngIf="threatProtection.resHead && threatProtection.resHead.from">
										<input type="checkbox"
											   id="replyToEmail"
											   [(ngModel)]="senderEmailObj.replyToEmail"/>
										<label style="word-break: break-all;" for="replyToEmail">Reply-to email: {{threatProtection.resHead.from.replyTo}}</label>
									</div>
									<div class="flxRow-start w100 hSpace1 md-checkbox" style="align-items: center; margin: 5px 0;"
										 *ngIf="threatProtection.resHead && threatProtection.resHead.from">
										<input type="checkbox"
											   id="replyToDomain"
											   [(ngModel)]="senderEmailObj.replyToDomain"/>
										<label style="word-break: break-all;" for="replyToDomain">Reply-to domain: {{getEmailDomain(threatProtection.resHead.from.replyTo)}}</label>
									</div>
									<div class="flxRow-start w100 hSpace1 md-checkbox" style="align-items: center; margin: 5px 0;"
										 *ngIf="threatProtection.resHead && threatProtection.resHead.from">
										<input type="checkbox"
											   id="messageIdDomain"
											   [(ngModel)]="senderEmailObj.messageIdDomain"/>
										<label style="word-break: break-all;" for="messageIdDomain">Message-ID domain: {{getEmailDomain(threatProtection.resHead.from.messageID)}}</label>
									</div>
									<div class="flxRow-start w100 hSpace1 md-checkbox" style="align-items: center; margin: 5px 0;"
										 *ngIf="threatProtection.resHead && threatProtection.resHead.from">
										<input type="checkbox"
											   id="returnPathDomain"
											   [(ngModel)]="senderEmailObj.returnPathDomain"/>
										<label style="white-space: pre-wrap; word-break: break-all;" for="returnPathDomain">Return path domain: {{getEmailDomain(threatProtection.resHead.from.returnPath)}}</label>
									</div>
								</div>

								<div class="flxClmn vSpace2" *ngIf="actionToConfirm === dic.CONSTANTS.reviewerEmailAction.attachmentsHash">
									<span>Click on attachment to get their <b>hash</b></span>
									<div *ngIf="email.report_info.attachments && email.report_info.attachments.length">
										<div class="dropdown review"
											 (clickOutside)="linksDropdown = false;"
											 [ngClass]="{'active': linksDropdown}"
											 (click)="linksDropdown = !linksDropdown"
											 (focus)="linksDropdown = true"
											 (blur)="linksDropdown = false">
											<div class="select">
												<div class="ellipsis" style="font-size: 0.85rem; padding-left: 5px; line-height: 18px;">Choose</div>
												<i class="fa fa-chevron-left"></i>
											</div>
											<ul class="dropdown-menu" style="max-height: 250px"
												[ngClass]="{'active': linksDropdown}"
												[style.height.px]="linksDropdown ? 36 * email.report_info.attachments.length : 0">
												<li style="padding: 0 8px;border:0;position: relative;height: 36px;"
													*ngFor="let attachment of email.report_info.attachments"
													ngxClipboard [cbContent]="attachment.hash"
													(cbOnSuccess)="hashCopied(attachment)"
													(click)="attachment.animateCopy = true; attachment.firstCopy = true; $event.stopPropagation()"
													[ngStyle]="attachment.firstCopy && {'color':'darkgreen'}"
													[ngClass]="attachment.animateCopy && 'animated flash'">
													<label>
														<div class="ellipsis" style="font-size: 0.85rem" tooltip="Copy to clipboard">{{attachment.name}}</div>
													</label>
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div class="flxClmn vSpace2" *ngIf="actionToConfirm === dic.CONSTANTS.reviewerEmailAction.createIncident">
									<span>An email will be sent to all reviewers with details about this email. You can add your questions to the reviewers below.</span>
									<span>Security team comments:</span>
									<div class="flxRow-start w100 hSpace1 md-checkbox" style="align-items: center; margin: 5px 0;">
										<input id="incidentText" placeholder="information" style="min-width: 350px;"
											   [(ngModel)]="securityTeamComments"/>
									</div>
								</div>

								<div class="flxClmn vSpace2" *ngIf="actionToConfirm === dic.CONSTANTS.reviewerEmailAction.rescanEmail">
									<span>The process will only take a few moments to update the threat analysis scan results. Allow/Block lists will be ignored.</span>
									<div class="flxRow-start w100 hSpace1 md-checkbox" style="align-items: center; margin: 5px 0;">
										<input type="checkbox"
											   id="useOpenAI"
											   [(ngModel)]="useOpenAI"/>
										<label style="white-space: nowrap" for="useOpenAI">Scan content with OpenAI</label>
									</div>
								</div>

								<div class="flxClmn vSpace2" *ngIf="actionToConfirm === dic.CONSTANTS.reviewerEmailAction.planSignSource">
									<span style="word-break: break-all">The source will be {{removeDomainSignature ? 'removed' : 'signed and considered safe and valid'}} for domain {{getEmailDomain(email.sender_info.email)}}</span>
									<div class="flxRow-start w100 hSpace1 md-checkbox" style="align-items: center; margin: 5px 0;">
										<input type="checkbox"
											   id="senderDomainType"
											   [(ngModel)]="removeDomainSignature"/>
										<label style="white-space: nowrap" for="senderDomainType">Remove</label>
									</div>
								</div>

								<div class="flxClmn vSpace2" *ngIf="actionToConfirm === dic.CONSTANTS.reviewerEmailAction.updateHandled">
									<span>Please add a short description before setting this email as handled (optional):</span>
									<div style="margin: 10px; width: 90%;">
										<textarea style="resize: none; width: 100%; height: 2.7rem; font-family: 'Roboto' , sans-serif;" [(ngModel)]="handledDescription"></textarea>
									</div>
								</div>

								<div class="flxClmn vSpace2" *ngIf="actionToConfirm === dic.CONSTANTS.reviewerEmailAction.updateFollow">
									<span>Please add a short description before marking this email as followed (optional):</span>
									<div style="margin: 10px; width: 90%;">
										<textarea style="resize: none; width: 100%; height: 2.7rem; font-family: 'Roboto' , sans-serif;" [(ngModel)]="followDescription"></textarea>
									</div>
								</div>
							</div>
							<div class="flxRow-center hSpace1">
								<btn id="cancelEmailActionBtn" variant="outlined"
									 (action)="actionToConfirm = '';">
									Cancel
								</btn>
								<btn id="confirmEmailActionBtn"
									 *ngIf="actionToConfirm !== dic.CONSTANTS.reviewerEmailAction.attachmentsHash"
									 [loading]="disableActionBtn"
									 (action)="doActionOnEmail(actionToConfirm);">
									Confirm
								</btn>
							</div>
						</div>
						<!--ACTION SECTION CONTENT: confirm malicious file download-->
						<div class="flxClmn-between vSpace1 h100 animated fadeIn" style="max-width: 100%; flex: 1 0; padding: 10px 20px;" *ngIf="attachmentDownloadConfirm">
							<div class="confirm-text">
								<div *ngIf="attachmentDownloadConfirm === 'all'">Are you sure you want to download all attachments?</div>
								<div *ngIf="attachmentDownloadConfirm !== 'all'">Are you sure you want to download this attachment?</div>
								<ul class="action-details">
									<li>{{attachmentDownloadConfirm === 'all' ? 'One or more of these attachments has been detected as malicious' : 'This attachment has been detected as malicious'}}</li>
								</ul>
							</div>
							<div class="flxRow-center hSpace1">
								<btn variant="outlined"
									 (action)="attachmentDownloadConfirm = false;">
									Cancel
								</btn>
								<btn [isDisabled]="disableActionBtn"
									 (action)="attachmentDownloadConfirm === 'all' ? downloadAllFilesExecute() : downloadFileExecute(attachmentDownloadConfirm)">
									Download
								</btn>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="reply-outer-container flxClmn reviewer-actions-window" style="z-index: 500;" *ngIf="gs.isMobile"
			 [ngClass]="{'active' : showLogs}">
			<div class="reply-inner-container active h100 flxClmn vSpace2"
				 *ngIf="showLogs">
				<div class="flxRow-between title-row t-color-primary" style="align-items: center;">
					<h4 style="margin: 0">Reviewers Activity Logs</h4>
					<a (click)="showLogs = false;">
						<i [ngClass]="gs.isMobile ? 'fa fa-arrow-right' : 'fa fa-times'" style="font-size: 1.1rem;"></i>
					</a>
				</div>
				<div class="flxClmn table-outer-container" style="flex: 1 0; overflow: auto;">
					<div class="flxClmn table-inner-container" [ngClass]="email.reviewer_actions && email.reviewer_actions.length && 'not-empty'"
						 style="flex: 1 0; overflow: auto;">
						<table class="w100 simpleDataTable t-color-primary sticky-header ellips alternated-grey reviewer-actions-table"
							   *ngIf="email.reviewer_actions && email.reviewer_actions.length" cellspacing="0">
							<thead><tr>
								<th style="width:160px; padding: 10px;"
									[ngStyle]="gs.isMobile && {'width' : '125px'}">
									Created
									<a *ngIf="sortCreated === 'created'" style="margin-top:1px;padding-left:5px;" [tooltip]="'Sort'" show-delay="200"
									   (click)="sortCreated = '-created'; sortReviewerActionsBy = '-created';">
										<i class="zmdi zmdi-chevron-up" style="width:10px;"></i>
									</a>
									<a *ngIf="sortCreated === '-created'" style="margin-top:1px;padding-left:5px;" [tooltip]="'Sort'"
									   (click)="sortCreated = 'created'; sortReviewerActionsBy = 'created';">
										<i class="zmdi zmdi-chevron-down" style="width:13px;"></i>
									</a>
								</th>
								<th style="width:35%;">
									Reviewer
									<a *ngIf="sortReviewer === 'reviewer'" style="margin-top:1px;padding-left:5px;" [tooltip]="'Sort'" show-delay="200"
									   (click)="sortReviewer = '-reviewer'; sortReviewerActionsBy = '-reviewer';">
										<i class="zmdi zmdi-chevron-up" style="width:10px;"></i>
									</a>
									<a *ngIf="sortReviewer === '-reviewer'" style="margin-top:1px;padding-left:5px;" [tooltip]="'Sort'"
									   (click)="sortReviewer = 'reviewer'; sortReviewerActionsBy = 'reviewer';">
										<i class="zmdi zmdi-chevron-down" style="width:13px;"></i>
									</a>
								</th>
								<th style="width:65%;">
									Action
									<a *ngIf="sortAction === 'action'" style="margin-top:1px;padding-left:5px;" [tooltip]="'Sort'" show-delay="200"
									   (click)="sortAction = '-action'; sortReviewerActionsBy = '-action';">
										<i class="zmdi zmdi-chevron-up" style="width:10px;"></i>
									</a>
									<a *ngIf="sortAction === '-action'" style="margin-top:1px;padding-left:5px;" [tooltip]="'Sort'"
									   (click)="sortAction = 'action'; sortReviewerActionsBy = 'action';">
										<i class="zmdi zmdi-chevron-down" style="width:13px;"></i>
									</a>
								</th>
							</tr></thead>
							<tbody>
							<tr *ngFor="let actionObj of email.reviewer_actions | orderBy: sortReviewerActionsBy">
								<td style="padding: 10px">
											<span>
												{{actionObj.created | date:'MM/dd/yyyy HH:mm'}}
											</span>
								</td>
								<td>
									<span>{{actionObj.reviewer}}</span>
								</td>
								<td>
									<span>{{actionObj.action}}</span>
								</td>
							</tr>
							</tbody>
						</table>
						<div class="flxClmn-center" style="align-items: center; flex: 1 0;"
							 *ngIf="!email.reviewer_actions || !email.reviewer_actions.length">
							<span class="t-color-primary" style="opacity: 0.7;">No actions taken by reviewers for this email</span>
						</div>
					</div>
				</div>
				<div class="flxRow-center" *ngIf="!gs.isMobile">
					<btn (action)="showLogs = false;">
						Back
					</btn>
				</div>
			</div>
		</div>
	</div>
	<attachment-preview
		*ngIf="previewAttachment"
		[attachment]="previewAttachment"
		(onClose)="previewAttachment = null;">
	</attachment-preview>
</div>
