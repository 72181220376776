import {Component, Input, OnInit} from '@angular/core';
import {dic} from "../../../dictionary";
import * as _ from "lodash";
import {NotificationService} from "../../../services/notification.service";
import {RouteService} from "../../../services/route.service";
import {Subject} from "rxjs";


@Component({
	selector: 'app-tracking',
	templateUrl: './tracking.component.html',
	styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
	@Input()
	set trackingDataInput(value) {
		this.trackingData = value;
	};

	@Input()
	set recipientTrackInput(value) {
		this.recipientTrack = value;
	};

	@Input()
	set emailInfoInput(value) {
		this.emailInfo = value;
	};

	@Input()
	set maxTrackRefreshInput(value) {
		this.maxTrackRefresh = value;
	};

	@Input() originComponent? : string;

	dic = dic;
	trackingData: any;
	trackingDataSubject: Subject<Array<object>> = new Subject<Array<object>>();
	trackEventShow = this.dic.CONSTANTS.DROPDOWN_STATE.CLOSE;
	recipientTrack: any;
	emailInfo: any;
	maxTrackRefresh: number;
	intervalId: undefined | ReturnType<typeof setInterval>;

	constructor(private notificationService: NotificationService,
				private rs: RouteService) {}

	ngOnInit() {
		this.maxTrackRefresh = this.dic.CONSTANTS.MAX_REFRESH_COUNT;
		if (this.intervalId) {
			clearInterval(this.intervalId);
			this.intervalId = undefined;
		}


		// outbound quarantined page has its own route to get tracking data
		if (this.originComponent && this.originComponent === 'outboundQuarantined') {
			setTimeout(() => {
				this.trackingDataSubject.next(this.trackingData);
			});
			return;
		}

		this.intervalId = setInterval(() => {
			this.getEmailTracking(this.recipientTrack);
		}, 10000);
		setTimeout(() => {
			this.trackingDataSubject.next(this.trackingData);
		});
	}

	getEmailTracking(prevRecipient) {
		if (this.maxTrackRefresh) {
			this.rs.getEmailTracking()
			.then(res => {
				if (!(res instanceof Array)) res = [res];
				this.trackingData = res;
				this.trackingDataSubject.next(this.trackingData);
				if (prevRecipient) {
					for (let i = 0; i < this.trackingData.length; i++) {
						if (prevRecipient.id === this.trackingData[i].id) {
							this.recipientTrack = this.trackingData[i];
							break;
						}
					}
					for (let i = 0; i < this.recipientTrack.track_events.length; i++) {
						if (this.recipientTrack.track_events[i] && prevRecipient.track_events[i]) {
							this.recipientTrack.track_events[i].opened = prevRecipient.track_events[i].opened;
						}
					}
				}
				else {
					this.recipientTrack = this.trackingData[0];
				}
			}, err => {
				console.log('err ?', err);
			});
			this.maxTrackRefresh--;

		}
	};

	showReadEvent(events) {
		return _.find(events,{name : 'Read'});
	};

	expandAllTrackEvents() {
		this.recipientTrack.track_events = _.map(this.recipientTrack.track_events, evt => {
			evt.opened = true;
			return evt;
		});
		this.trackEventShow = this.dic.CONSTANTS.DROPDOWN_STATE.OPEN;
	};

	collapseAllTrackEvents() {
		this.recipientTrack.track_events = _.map(this.recipientTrack.track_events, evt => {
			evt.opened = false;
			return evt;
		});
		this.trackEventShow = this.dic.CONSTANTS.DROPDOWN_STATE.CLOSE;
	};

	toggleTrackEvent = trackEvent => {
		trackEvent.opened = !trackEvent.opened;
		const isAllOpened = _.every(this.recipientTrack.track_events, te => te.opened);
		if (isAllOpened) {
			this.trackEventShow = this.dic.CONSTANTS.DROPDOWN_STATE.OPEN;
			return;
		}
		const isAllClosed = _.every(this.recipientTrack.track_events, te => !te.opened);
		if (isAllClosed) {
			this.trackEventShow = this.dic.CONSTANTS.DROPDOWN_STATE.CLOSE;
		}
	}

	openLocation(ll) {
		const link = "https://www.google.com/maps/search/?api=1&query=" + ll[0] + "," + ll[1];
		window.open(link, '_blank');
	};

	changeTrackingRecipient(track) {
		if (this.recipientTrack.id === track.id) return;
		this.recipientTrack = track;
		this.collapseAllTrackEvents();
	}

	refresh() {
		this.ngOnInit();
	}
}
