<div class="flxClmn f-1-0" [ngStyle]="{'overflow' : gs.isMobile ? '' : 'auto'}">
	<div class="shader" [ngClass]="{'active':(step === dic.STEP.Reply && !isReplyMinimized)}"></div>

	<!--CONTENT: enter token-->
	<div class="flxClmn-center vSpace4" style="flex: 1 0; padding: 0 11%"
		 *ngIf="step === dic.STEP.Empty">
		<input autocomplete="off"
			   style="text-align: center; min-height: 31px; border: solid 2px lightgrey;"
			   type="text"
			   appAutoFocus
			   (input)="encTokenChanged(msgId)"
			   placeholder="{{ 'recipient.pasteEnc' | translate }}"
			   [(ngModel)]="msgId"/>
	</div>

	<!--CONTENT: Blocked email-->
	<div class="flxClmn-center vSpace2 w100" style="flex:1 0; padding:15px" *ngIf="step === dic.STEP.Blocked">
		<!--info content block-->
		<div class="flxClmn-center vSpace2" style="flex:4 0; align-items: center; color: dimgrey;">
			<img src="assets/images/blockedEmail.png" style="width: 20vh; height: 20vh; filter:grayscale(1); opacity:0.3;" />
			<div class="flxClmn-start vSpace1" style="align-items: center">
				<span style="font-size: 1.6rem; font-family: 'Roboto',sans-serif; text-align: center; font-weight: lighter">{{ 'authentication.emailNotAvailable' | translate }}</span>
			</div>
		</div>
		<!--actions section-->
		<div class="flxClmn-center blocked-page-actions vSpace2">
			<span *ngIf="showAskAccess && !showAskAccessBlocked">{{ 'authentication.askSenderAccess' | translate }}</span>
			<span class="notification-label" *ngIf="showAskAccess && showAskAccessBlocked">{{showAccessText | translate}}</span>
			<btn *ngIf="showAskAccess && !showAskAccessBlocked"
				 (action)="askForAccess();">
				{{showAccessText | translate}}
			</btn>
		</div>
	</div>

	<div class="flxClmn-center vSpace2 w100" style="flex:1 0; padding:15px" *ngIf="step === dic.STEP.networkError">
		<!--info content block-->
		<div class="flxClmn-center vSpace2 t-color-secondary" style="flex:4 0; align-items: center;">
			<img src="assets/images/blockedEmail.png" style="width: 20vh; height: 20vh; filter:grayscale(1); opacity:0.3;" />
			<div class="flxClmn-start vSpace1" style="align-items: center">
				<span style="font-size: 1.6rem; font-family: 'Roboto',sans-serif; text-align: center; max-width: 800px; font-weight: lighter">{{ blockNote }}</span>
			</div>
		</div>
	</div>

	<!--CONTENT: authenticated viewed email-->
	<div class="flxClmn" style="flex: 1 0; height: auto; position: relative"
		 [ngStyle]="[dic.STEP.Authenticated, dic.STEP.Reply, dic.STEP.Phone, dic.STEP.Email, dic.STEP.Code, dic.STEP.Password, dic.STEP.Authenticating, dic.STEP.ssoIdp, dic.STEP.SSO].indexOf(step) === -1 ? {'visibility': 'hidden', 'flex' : 'inherit' , 'max-height' : '0'} : {'visibility': 'visible', 'flex' : '1 0' , 'max-height' : '100%'}">
		<div class="content-inner-container vSpace1 h100 flxClmn">
			<!--Subject & Sender & Date-->
			<div *ngIf="emailInfo" class="subject-row w100">
				<div [ngClass]="gs.isMobile ? 'flxClmn-end vSpace05' : 'flxRow-between hSpace05'">
					<div class="flxClmn-end vSpace05"  style="align-items: flex-start; flex: 1; overflow: hidden; padding-right: 15px">
						<span *ngIf="emailInfo.sent && emailInfo.sent.title" class="mail-title t-color-primary" [tooltip]="emailInfo.sent.title" style="white-space: nowrap;overflow: hidden;width: 100%; text-overflow: ellipsis">{{emailInfo.sent.title}}</span>
						<span *ngIf="emailInfo.sender" class="small-title">{{ 'recipient.sender' | translate }}: {{emailInfo.sender}}</span>
					</div>
					<div class="flxClmn-end vSpace05" [ngStyle]="{'align-items' : gs.isMobile ? 'flex-start' : 'flex-end'}"  style="flex: 0 1; white-space: nowrap">
						<span *ngIf="emailInfo.created" class="small-title">{{ 'recipient.sent' | translate }}: {{emailInfo.created | date:'MM/dd/yyyy HH:mm'}}</span>
						<span *ngIf="emailInfo.sent.edited_timestamp" class="small-title">{{ 'recipient.edited' | translate}}: {{emailInfo && emailInfo.sent.edited_timestamp | date:'MM/dd/yyyy HH:mm'}}</span>
						<span *ngIf="emailInfo.expiration_date" class="small-title">{{ 'recipient.expires' | translate}}: {{emailInfo.expiration_date | amDateFormat:'MM/DD/YYYY'}}</span>
					</div>
				</div>
			</div>

			<!--email view-->
			<div class="email-outer-container flxClmn" style="flex: 1 0 0; height: auto;"
				 [style.overflow]="gs.isMobile ? '' : 'auto'">
				<div *ngIf="emailInfo && emailInfo.sent.html && [dic.STEP.Authenticated, dic.STEP.Reply].indexOf(step) > -1"
					 class="expand-button flxClmn-center" style="align-items: center; z-index: 1;"
					 (click)="gs.isFullScreen = !gs.isFullScreen">
					<i class="fas fa-expand fa-lg" [tooltip]="('general.fullScreen' | translate)" *ngIf="!gs.isFullScreen"></i>
					<i class="fas fa-compress fa-lg" [tooltip]="('general.exitFullScreen' | translate)" *ngIf="gs.isFullScreen"></i>
				</div>
				<app-encrypt-animation
					[expandInMobile] = "gs.isMobile"
					[iframeId]="'email-content-frame'"
					[encryptedContentInput]="encryptedContentSubject.asObservable()"
					[contentInput]="innerContentSubject.asObservable()"
					(encryptedContentEmitter)="updateEncryptedContent($event)">
				</app-encrypt-animation>
				<app-authentication
					style="flex: 1 0; height: 1px" class="flxClmn"
					*ngIf="[dic.STEP.Phone, dic.STEP.Email, dic.STEP.Code, dic.STEP.Password, dic.STEP.Authenticating, dic.STEP.ssoIdp, dic.STEP.SSO, dic.STEP.Empty].indexOf(step) > -1"
					[stepDataInput]="stepDataSubject.asObservable()"
					[smartAuthenticationInput]="useSmartAuthenticationSubject.asObservable()"
					[confirmButtonInput]="confirmButtonSubject.asObservable()"
					(confirmSuccessEmitter)="confirmSuccessCb($event)"
					(confirmFailureEmitter)="confirmFailureCb($event)"
					(applyAnimationEmitter)="applyAnimationCb()"
					[userHash]="userHash"
					[parentComponent]="dic.CONSTANTS.authParentComponents.recipient">
				</app-authentication>
				<div class="email-view" style="flex:1 0;"
					 [ngStyle]="[dic.STEP.Phone, dic.STEP.Email, dic.STEP.Code, dic.STEP.Password, dic.STEP.Authenticating, dic.STEP.ssoIdp, dic.STEP.SSO].indexOf(step) > -1 ? {'visibility': 'hidden', 'flex' : '0 1', 'padding' : '0' , 'max-height' : '0'} : {'visibility': 'visible', 'flex' : '1 0' , 'max-height' : '100%'}">
					<div class="email t-bg-primary">
						<iframe [ngStyle]="[dic.STEP.Phone, dic.STEP.Email, dic.STEP.Code, dic.STEP.Password, dic.STEP.Authenticating, dic.STEP.ssoIdp, dic.STEP.SSO].indexOf(step) > -1 && {'height': '0'}"
								class="email-iframe" style="background-color: white;" id="email-content-frame" src="about:blank" ></iframe>
					</div>
				</div>
			</div>

			<!--attachments-->
			<div class="attachments-container flxClmn" style="align-items: center; flex:1 0"
				 *ngIf=" (step === dic.STEP.Authenticated || step === dic.STEP.Reply) && emailInfo && emailInfo.sent.numAttachments">

				<!--attachments title row-->
				<div class="w100 flxRow-between attachments-title-row" style="color: #868686; font-size: 0.75rem; flex-grow: 0; flex-shrink: 0">
					<div class="hover-link">
						<span>{{'recipient.attachments' | translate}} ({{emailInfo.sent.numAttachments}})</span>
					</div>
					<span *ngIf="!attachmentsPreviewMode" class="hover-link clickable" [ngStyle]="downloadAllAttachments.isInProcess && {'cursor':'not-allowed', 'opacity': '0.85'}"
						  (click)="downloadFile('/Download',null)" style="opacity: 0.85; cursor: pointer">{{'recipient.attachmentDownloadAll' | translate}}</span>
				</div>

				<!--attachments body-->
				<div class="attachments-body-container" style="flex: 1 1;"
					 *ngIf="emailInfo?.sent?.numAttachments && [dic.STEP.Authenticated, dic.STEP.Reply].indexOf(step) > -1">

					<div class="flxRow hSpace2" style="margin: 0;" *ngIf="emailInfo.sent.attachments && [dic.STEP.Authenticated, dic.STEP.Reply].indexOf(step) > -1">
						<div class="flxRow" style="flex:1 0; max-height: 66px; flex-wrap: wrap; overflow: auto">
							<attachment-c
								*ngFor="let file of emailInfo.sent.attachments"
								[attName]="file.name"
								[attSize]="file.size"
								[downloadProgress]="file.progressPercentage"
								[isPreviewMode]="attachmentsPreviewMode"
								[isPreviewLoading]="file.previewLoading"
								(preview)="setPreviewAttachment(file)"
								(download)="downloadFile('/Download', file, false, null)">
							</attachment-c>
						</div>
					</div>
				</div>
			</div>

			<!--buttons area-->
			<div *ngIf="emailInfo?.sent?.html && [dic.STEP.Authenticated, dic.STEP.Reply].indexOf(step) > -1 && !gs.isFullScreen"
				 class="buttons-area animated fadeIn flxRow-center vSpace1" style="align-content: center; margin: 1rem 0;">

				<div class="flxClmn-start vSpace2" style="align-items: center">
					<div class="flxRow-center hSpace1" style="align-items: center">
						<btn class="large-btn"
							 *ngIf="emailInfo.methods.indexOf('Reply') >= 0 && enableReply && !enforceReplyAll"
							 (action)="openSecureReply(false);">
							<div class="flxRow-center hSpace05" style="align-items: center;">
								<img src="./assets/images/smallLogoOutline.png" style="height: 22px;"
									 [ngStyle]="{'filter' : lfs.textColor === 'black' ? 'brightness(0)' : 'none'}"/>
								<span class="flxClmn-center">{{'recipient.reply' | translate}}</span>
							</div>
						</btn>
						<btn class="large-btn"
							 *ngIf="emailInfo.methods.indexOf('Reply') >= 0 && enableReply && (emailInfo.replyRecipients.length > 1 || enforceReplyAll)"
							 (action)="openSecureReply(true);">
							<div class="flxRow-center hSpace05" style="align-items: center;">
								<img src="./assets/images/smallLogoOutline.png" style="height: 22px;"
									 [ngStyle]="{'filter' : lfs.textColor === 'black' ? 'brightness(0)' : 'none'}"/>
								<span class="flxClmn-center">{{'recipient.replyAll' | translate}}</span>
							</div>
						</btn>
					</div>
					<!--"replyed successfully" msg / "join Trustifi" msg-->
					<div class="flxClmn-start vSpace05" style="align-items: center" *ngIf="message === repliedSuccessMsg">
						<span class="hover-brighten" style="color:green;font-weight: bold; cursor: default"> <i style="margin:0 5px" class="fas fa-check"></i> {{message}} </span>
						<a *ngIf="!isRecipientTrustifiUser && !hidePostReplyMessage" style="text-align: center; margin-left: 5px; padding: 10px; text-decoration: none; font-size: 0.9rem; color: var(--bs-primary)" [href]="lfs.webAppUrl + '/signup/'" target="_blank">
							<span>
								{{ 'recipient.replyingWasEasy' | translate }}?
							</span>
							<span style="text-decoration: underline; white-space: nowrap">
								{{ 'recipient.tryTrustifiNow' | translate }}
							</span>
						</a>
					</div>
				</div>
			</div>

			<!--"need help" (mobile)-->
			<div *ngIf="gs.isMobile" class="flxClmn vSpace05 t-color-secondary" style="margin: 10px; text-align: center">
							<span style="font-size: calc(0.5rem + 0.5vw);">
								{{ 'recipient.notSureMsg' | translate}}
							</span>
				<span  style="font-size: calc(0.5rem + 0.5vw);">
					{{ 'recipient.needHelp' | translate}}?
					<a href="{{gs.showHelpUrl}}" target="_blank">
						{{ 'recipient.clickHere' | translate}}
					</a>
				</span>
			</div>

		</div>

		<!--Reply Section-->
		<div class="reply-outer-container t-bg-primary flxClmn overflow-auto"
			 *ngIf="[dic.STEP.Authenticated, dic.STEP.Reply].indexOf(step) > -1"
			 [ngClass]="{'active' : step === dic.STEP.Reply , 'minimized' : isReplyMinimized}">
			<div class="reply-inner-container active h100 flxClmn vSpace1"
				 *ngIf="step === dic.STEP.Reply">
				<!--logo row-->
				<div class="flxRow-between" style=" margin-bottom: 15px;" [ngStyle]="{'align-items' : isReplyMinimized ? 'center' : 'flex-start'}">
					<!--<img src="assets/images/secureReply.png" class="reply-header-image">-->
					<div class="flxClmn vSpace05 reply-design-title">
						<h1 class="t-color-primary">
							{{ 'recipient.secureReplyTitle' | translate}}
						</h1>
						<span>
										{{ 'recipient.secureReplyDesc' | translate}}
									</span>
					</div>
					<div class="t-color-primary" style="padding: 10px">
						<i tooltip="minimize" style="cursor: pointer;font-weight: normal;" class="fa fa-window-minimize"
						   *ngIf="!isReplyMinimized && !gs.isMobile" (click)="isReplyMinimized = true;"></i>
						<i class="fas fa-arrow-left" style="cursor: pointer;"
						   *ngIf="!isReplyMinimized && gs.isMobile" (click)="isReplyMinimized = true; enableGlobalScroll(true)"></i>
					</div>
				</div>

				<!--email edit section-->
				<div class="mail-edit-section flxClmn-start vSpace2"
					 *ngIf="!isEditorFullScreen">
					<!--details-->
					<table style="font-size: 0.75rem; white-space: nowrap;" class="simpleDataTableShrink t-color-primary t-bg-primary">
						<tr>
							<td style="width: 1%; font-weight: bold; text-transform: capitalize;">
								<span>{{ 'recipient.from' | translate }}: </span>
							</td>
							<td>
								<span style="padding-left: 7px">{{emailInfo.recipient}}</span>
							</td>
						</tr>
						<tr *ngIf="emailInfo.replyRecipients && emailInfo.replyRecipients.length">
							<td style="width: 1%; font-weight: bold; text-transform: capitalize;">
								<span>{{ 'recipient.replyTo' | translate }}: </span>
							</td>
							<td>
								<div style="display: flex; align-items: center; max-width: 100%">
									<div class="dropdown" style="width: 100%;"
										 (clickOutside)="recipientsDropdown = false;"
										 [ngClass]="{'active': recipientsDropdown}"
										 (click)="recipientsDropdown = !recipientsDropdown"
										 (focus)="recipientsDropdown = true"
										 (blur)="recipientsDropdown = false">
										<div class="select">
											<div class="ellipsis" style="font-size: 0.75rem; padding-right: 10px; padding-left: 5px; line-height: 18px;">{{firstRecipient}}</div>
											<i class="fa fa-chevron-left"></i>
										</div>
										<ul class="dropdown-menu" style="max-height: none"
											[ngClass]="{'active': recipientsDropdown}"
											[style.height]="recipientsDropdown ? 'auto' : '0'">
											<li style="padding: 0 8px;border:0;position: relative;height: 36px;"
												*ngFor="let recipient of emailInfo.replyRecipients"
												(click)="$event.stopPropagation()">
												<label [ngClass]="{'disabled': recipient.enforce}">
													<input type="checkbox"
														   (change)="getDropdownHeader('to'); $event.stopPropagation()"
														   [(ngModel)]="recipient.selected"
														   [checked]="recipient.selected"/>
													<div class="label-value ellipsis" style="font-size: 12px">{{recipient.email}}</div>
												</label>
											</li>
										</ul>
									</div>
								</div>
							</td>
						</tr>
						<tr *ngIf="emailInfo.replyRecipients && emailInfo.replyRecipients.length && emailInfo.replyRecipients.length !== 1">
							<td style="width: 1%; font-weight: bold; text-transform: capitalize;">
								<span>{{ 'recipient.cc' | translate }}: </span>
							</td>
							<td>
								<div style="display: flex; align-items: center; max-width: 100%">
									<div class="dropdown" style="width: 100%; z-index: 4;"
										 (clickOutside)="recipientsCCDropdown = false;"
										 [ngClass]="{'active': recipientsCCDropdown}"
										 (click)="recipientsCCDropdown = !recipientsCCDropdown"
										 (focus)="recipientsCCDropdown = true"
										 (blur)="recipientsCCDropdown = false">
										<div class="select">
											<div class="ellipsis" style="font-size: 0.75rem; padding-right: 10px; padding-left: 5px; line-height: 18px;">{{firstCCRecipient}}</div>
											<i class="fa fa-chevron-left"></i>
										</div>
										<ul class="dropdown-menu" style="max-height: none"
											[ngClass]="{'active': recipientsCCDropdown}"
											[style.height]="recipientsCCDropdown ? 'auto' : '0'">
											<li style="padding: 0 8px;border:0;position: relative;height: 36px;"
												*ngFor="let cc of emailInfo.replyRecipientsCC"
												(click)="$event.stopPropagation()">
												<label [ngClass]="{'disabled': cc.enforce}">
													<input type="checkbox"
														   (change)="getDropdownHeader('cc'); $event.stopPropagation()"
														   [(ngModel)]="cc.selected"
														   [checked]="cc.selected"/>
													<div class="label-value ellipsis" style="font-size: 12px">{{cc.email}}</div>
												</label>
											</li>
										</ul>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td style="width: 1%; font-weight: bold; text-transform: capitalize;">
								<span>{{ 'recipient.attachments' | translate }}: </span>
							</td>
							<td>
								<div class="attachment-wrap flxRow-between">
									<div class="attachment-row" style="flex:1 0"
										 [ngClass]="{'hover': mouseOverAttachment, 'drag-over': mouseOverAttachment}"
										 ngfSelect multiple="1"
										 ngfDrop
										 [(files)]="files"
										 [(validDrag)]="mouseOverAttachment"
										 (fileOver)="fileOver($event)"
										 (fileChange)="uploadFiles(files)">
										<div class="attachment-popup">
											<a class="opener" tooltip="Add attachment"><i class="zmdi zmdi-attachment-alt">&nbsp;</i>{{ 'compose.files.attachFiles' | translate }}</a>
										</div>
										<span id="mobile-resp" class="title" style="color: black;">{{ 'compose.files.dragDrop' | translate }}</span>
									</div>
								</div>
							</td>
						</tr>
					</table>
				</div>

				<div class="attachment-items"
					 *ngIf="!isEditorFullScreen"
					 (mouseover)="gs.isMobile ? '' : showUploadedAttachmentsPopup = true; recipientsDropdown = false;"
					 (mouseleave)="gs.isMobile ? '' : showUploadedAttachmentsPopup = false">
					<ul class="attachment-list">
						<li *ngFor="let attachment of replyMessage.attachments; let i = index"
							[ngClass]="{'finished' : attachment.finished}">
							<div class="item" style="flex:1 0;">
								<span class="name t-color-primary" style="font-size: 12px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;padding-right: 15px;width: 90%;">{{attachment.name}}</span>
								<span class="size t-color-primary" style="font-size: 11px; white-space: nowrap">{{attachment.size | formatBytes: 2}}</span>
								<span class="after" [style.width.%]="attachment.progressPercentage"></span>
							</div>
							<!--									<i (click)="previewAttachment = attachment;" class="fas fa-eye cursor-pointer" style="margin-left: auto; color: white; opacity: 0.7;" tooltip="Preview"></i>-->
							<a class="close area-l" (click)="deleteAttachment(i)">
								<i class="zmdi zmdi-close"></i>
							</a>
						</li>
					</ul>
				</div>

				<!-- Summernote -->
				<div style="flex: 1 0 0; position: relative" class="flxClmn">
					<div class="expand-button flxClmn-center" style="right: 0; top: 0; left: auto; margin: 0; align-items: center; z-index:1;"
						 (click)="isEditorFullScreen = !isEditorFullScreen">
						<i class="fas fa-expand fa-lg" *ngIf="!isEditorFullScreen"></i>
						<i class="fas fa-compress fa-lg" *ngIf="isEditorFullScreen"></i>
					</div>
					<summer-editor [html]="recipientSignature" style="flex:1 0; display: flex; flex-direction: column"
								   (change)="updateEmailContent($event)"
								   (load)="updateSummernoteLogo($event)">
					</summer-editor>
				</div>

				<div class="flxRow-between" style="padding: 15px 0; min-height: 66px; align-items: center">
					<btn class="large-btn" variant="outlined"
						 (action)="initSummernote = true; step = dic.STEP.Authenticated; replyMessage.attachments = []; enableGlobalScroll(true)">
						{{ 'general.cancel' | translate}}
					</btn>
					<div class="flxRow hSpace2" style="align-items: center">
						<div class="loading-dots" style="font-style: italic; text-align: center; color: var(--gray); font-size: 0.8rem"
							 [hidden]="!uploadedFiles"
							 (mouseover)="gs.isMobile ? '' : showUploadedAttachmentsPopup = true; replyMessage.attachments.length ? recipientsDropdown = false : ''"
							 (mouseleave)="gs.isMobile ? '' : showUploadedAttachmentsPopup = false"
							 (click)="gs.isMobile ? toggleMobileAttachments() : ''">
							{{ 'recipient.uploading' | translate }}
						</div>
						<btn class="large-btn" id="replyMessageBtn"
							 [isDisabled]="!(replyMessage.attachments.length || html) || !!uploadedFiles"
							 [loading]="disableReplySendBtn"
							 (action)="replyMessageExecute()">
							{{ 'recipient.send' | translate}}
						</btn>
					</div>
				</div>
			</div>
		</div>

		<!-- window bottom row -->
		<div class="bottom-row flxRow-between hSpace05 w100"
			 *ngIf="!gs.isFullScreen && !gs.isMobile && [dic.STEP.Authenticated, dic.STEP.Reply].indexOf(step) > -1">
			<div class="flxRow">
				<a *ngIf="emailInfo && emailInfo.enable_print"
				   [ngStyle]="encryptedContent && {'cursor':'not-allowed'}"
				   (click)="printContent()" class="general-actions" >
					<i class="fas fa-print"></i>
					{{ 'recipient.printEmail' | translate }}
				</a>

				<a *ngIf="emailInfo && allowDownloadEml"
				   [ngStyle]="(encryptedContent || isEmlDownload || downloadAllAttachments.isInProcess) && {'cursor':'not-allowed'}"
				   [ngClass]="{'loading-dots': isEmlDownload}"
				   (click)="downloadAsEml()" class="general-actions" style="margin-left: 10px;">
					<i class="fas fa-download"></i>
					{{ 'recipient.downloadAsEml' | translate }}
				</a>
			</div>

			<div class="flxRow">
				<!-- Progress Bar -->
				<div class="flxRow hSpace05" style="align-items: center"
					 *ngIf="downloadAllAttachments.progressPercentage">
								<span style="text-transform: capitalize; color: var(--gray)">
									{{ 'general.downloading' | translate }}
								</span>
					<progress
						style="height:11px"
						value="{{downloadAllAttachments.progressPercentage}}" max="100"></progress>
				</div>
			</div>
		</div>

	</div>

	<attachment-preview
		*ngIf="previewAttachment"
		[attachment]="previewAttachment"
		(onClose)="previewAttachment = null;">
	</attachment-preview>

</div>
