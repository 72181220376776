<div class="app-container animated fadeIn">
	<div class="content-page-container flxClmn-center" [ngClass]="{'full-screen' : gs.isFullScreen, 'is-mobile' : gs.isMobile}">
		<div class="border-effect flxClmn" style="flex: 1 0"
			 [ngStyle]="{'max-width' : !isTimeout && gs.globalWidth || '', 'max-height' : !isTimeout && gs.globalHeight || ''}"
			 [ngClass]="isTimeout ? 'sm' : {
						 'sm': currentAppState === dic.CONSTANTS.appstates.reply,
						 'md' : !_.values(dic.CONSTANTS.appstates).includes(currentAppState)
						  		|| [dic.CONSTANTS.appstates.sender, dic.CONSTANTS.appstates.tracking].includes(currentAppState),
			 			 'lg' : currentAppState === dic.CONSTANTS.appstates.outbound}">
			<div class="content-container flxClmn t-bg-primary" style="flex: 1 0 auto">
				<app-loading-animation [hideCondition]="!gs.isLoadingApp" ></app-loading-animation>

				<ng-container *ngIf="!isTimeout">
					<!--Logo header-->
					<app-header class="logo-container"></app-header>

					<router-outlet></router-outlet>
				</ng-container>

				<div class="flxClmn f-1-0 centralize" *ngIf="isTimeout">
					<img src="assets/images/sessionTimeout.png" style="width: 240px; margin: 0; image-rendering: -webkit-optimize-contrast;">
					<h2 class="t-color-primary" style="font-size: clamp(16px,2.2rem,7vw); font-weight: bold; margin-bottom: 0; ">{{'general.sessionExpired' | translate}}</h2>
					<h3 style="font-size: clamp(14px,1.2rem,5vw); color: var(--gray2);">{{'general.sessionExpiredInactivity' | translate}}</h3>
					<h3 style="font-size: clamp(14px,1.2rem,5vw); color: var(--gray2); font-weight: 400;">{{'general.pleaseRefreshPage' | translate}}</h3>
					<br/>
					<btn style="font-size: 1rem;" (action)="reloadPage();">
						{{ 'general.refresh' | translate }}
					</btn>
				</div>
			</div>
		</div>

		<!--"need help" (recipient page only)-->
		<ng-container *ngIf="!isTimeout && !_.values(dic.CONSTANTS.appstates).includes(currentAppState)">
			<div *ngIf="!gs.isFullScreen"
				 class="flxClmn vSpace05 bs-enabled" style="color: white; z-index: 2; font-size: 0.75rem; margin-top: 15px; text-align: center;"
				 [ngStyle]="{'padding' : gs.isMobile ? '0 20px 15px' : ''}">
				<span style="opacity: 0.8;">
					{{'recipient.notSureMsg' | translate}}
				</span>
				<div class="flex-row gap-2 justify-content-center text-decoration-underline">
					<a class="text-white hvr-opacity"
					   [href]="lfs.helpUrl" target="_blank">
						{{'recipient.clickHereForHelp' | translate}}
					</a>
					<div class="vertical-separator border-white h-auto"></div>
					<span [tooltip]="isEmailReported ? ('recipient.userReportedEmail' | translate) : null">
						<a class="text-white hvr-opacity" target="_blank"
						   [ngClass]="{'disabled' : !gs.isContentDisplayed || isEmailReported}"
						   (click)="openReportEmailPopup();">
							{{'recipient.reportThisEmail' | translate}}
						</a>
					</span>
				</div>
			</div>

			<div class="report-email-outer-container bs-enabled" [ngClass]="{'active' : reportEmailPopup?.show}">
				<div class="report-email-inner-container flex-column h-100 t-color-primary t-bg-primary">
					<div class="border-bottom border-secondary-subtle flex-row align-items-center justify-content-between">
						<div class="flex-row p-3 pb-2 gap-2 align-items-center">
							<i class="fa fa-exclamation-triangle opacity-25" style="font-size: 1rem;"></i>
							<h5 class="m-0">{{'recipient.reportEmailTitle' | translate}}</h5>
						</div>
						<i class="fas fa-arrow-left p-3 pb-2 pe-4" style="cursor: pointer; font-size: 1.2rem;"
						   *ngIf="gs.isMobile"
						   (click)="enableGlobalScroll(true); reportEmailPopup = null;"></i>
					</div>
					<div class="p-4 flex-column gap-3 f-1-0">
						<ng-container *ngIf="reportEmailPopup?.show">
							<div class="flex-row align-items-center gap-2">
								<span style="flex-basis: 110px; flex-shrink: 0;">{{'recipient.selectReason' | translate}}: </span>
								<app-dropdown class="f-1-0" style="max-width: calc(100% - 110px - 0.5rem);"
											  [text]="reportEmailPopup.selectedCategory">
									<li *ngFor="let category of reportEmailPopup.categories"
										(click)="reportEmailPopup.selectedCategory = category">
										{{category}}
									</li>
								</app-dropdown>
							</div>
							<div class="flex-row f-1-0 pt-1 gap-2">
								<span style="flex-basis: 110px; flex-shrink: 0;">{{'recipient.comment' | translate}}</span>
								<textarea class="f-1-0" style="resize: none; max-height: 200px; padding: 8px; font-family: 'Roboto', sans-serif;"
										  [(ngModel)]="reportEmailPopup.comment"></textarea>
							</div>
						</ng-container>
					</div>
					<div class="flex-row gap-2 align-items-center justify-content-end p-4">
						<btn class="large-btn" (action)="reportEmailPopup = null;" variant="outlined">
							{{'general.cancel' | translate}}
						</btn>
						<btn class="large-btn" (action)="reportThisEmail();" [loading]="reportEmailPopup?.reportEmailInProcess">
							{{'general.confirm' | translate}}
						</btn>
					</div>
				</div>
			</div>

		</ng-container>
	</div>
</div>
