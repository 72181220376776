import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';
import * as $ from 'jquery';
import {dic} from '../dictionary';
import {SUPPORTED_MIMES} from '../constants';
import * as _ from 'lodash';
import {HttpClient} from "@angular/common/http";
import {LookAndFeelService} from './lookAndFeelService';

@Injectable()
export class GeneralService {
	dic = dic;
	isLoadingApp = true;

	leafletDefaultIcon = {
		iconUrl: 'css/images/marker-icon.png',
		shadowUrl: 'css/images/marker-shadow.png',
		iconSize: [25, 41],
		iconAnchor: [12, 41],
		popupAnchor: [1, -34],
		shadowSize: [41, 41],
	};

	leafletDangerIcon = {
		iconUrl: 'assets/images/danger-marker.png',
		shadowUrl: 'css/images/marker-shadow.png',
		iconSize: [25, 41],
		iconAnchor: [12, 41],
		popupAnchor: [1, -34],
		shadowSize: [41, 41],
	};

	private decryptionState = new BehaviorSubject('');
	decryptionStateChange = this.decryptionState.asObservable();
	postmarkData: any;

	isDarkMode = new BehaviorSubject<boolean>(false);
	isDarkMode$ = this.isDarkMode.asObservable();

	isFullScreen;
	globalHeight;
	globalWidth;
	isMobile = this.detectMobile() && ((window.innerWidth > 0 ? window.innerWidth < 480 : screen.width < 480) || (window.innerHeight > 0 ? window.innerHeight < 480 : screen.height < 480));
	showHelpUrl;

	setIsLoadingApp(value) {
		this.isLoadingApp = value;
	}

	updateDecryptionState(state) {
		this.decryptionState.next(state);
	}

	constructor(private sanitizer: DomSanitizer,
				private lfs:LookAndFeelService,
				private http:HttpClient,) {
		this.getInitialTheme(); // Load the initial theme when the service is constructed
	}

	defangeMaliciousLinksFromHtml = (html, maliciousLinks) => {
		// Create a temporary DOM element to parse the HTML string
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = html;

		// Iterate through each malicious link
		maliciousLinks.forEach((maliciousLink) => {
			// Defang the malicious link
			const defangedLink = maliciousLink
				.replace(/http/g, 'hxxp')
				.replace(/\./g, '[.]')
				.replace(/\//g, '[/]');

			// Find all `a` elements with the malicious link as href
			const anchorElements = tempDiv.querySelectorAll(`a[href="${maliciousLink}"]`);

			anchorElements.forEach((anchor) => {
				if (anchor.textContent.includes(maliciousLink)) {
					//replace the malicious link with the defanged link using regex
					anchor.textContent = anchor.textContent.replace(new RegExp(maliciousLink, 'g'), defangedLink);
					// Replace the `a` element with a `span` element
					const span = document.createElement('span');
					span.textContent = defangedLink;
					anchor.parentNode.replaceChild(span, anchor);
				}
				else {
					// If the text content is different, just remove the href attribute and change to span
					const span = document.createElement('span');
					span.textContent = anchor.textContent;
					anchor.parentNode.replaceChild(span, anchor);
				}
			});
		});

		return tempDiv.innerHTML;
	}

	defangeMaliciousLinksFromText = (text, maliciousLinks) => {
		// Iterate through each malicious link
		maliciousLinks.forEach((maliciousLink) => {
			// Defang the malicious link
			const defangedLink = maliciousLink
				.replace(/http/g, 'hxxp')
				.replace(/\./g, '[.]')
				.replace(/\//g, '[/]');

			// Replace the malicious link with the defanged link using regex
			text = text.replace(new RegExp(maliciousLink, 'g'), defangedLink);
		});

		return text;
	}

	trackByIndex(index, item) {
		return index;
	}
	returnZero() {
		return 0
	}

	private updateStyles(styleElement, styleColors) {
		const rules = Array.from((styleElement.sheet as CSSStyleSheet).cssRules);
		for (let idx = 0; idx < rules.length; idx++) {
			const style = (rules[idx] as CSSStyleRule).style;


			if (style.background.indexOf('linear-gradient') >= 0) {
				style.background = styleColors[idx];
			} else {
				if (style.color) {
					style.color = styleColors[idx];
				}
				if (style.borderColor) {
					style.borderColor = styleColors[idx];
				}
				if (style.backgroundColor) {
					style.setProperty('background-color', styleColors[idx], 'important');
					style.setProperty('color', this.lfs.textColor, 'important');
				}
				// .md-checkbox only:
				if (style.border) {
					style.setProperty('border', '0.2em solid ' + styleColors[idx], 'important');
				}
				//
			}
		}
	}


	setSummernoteLogo(iframe: Window) {
		const styleColors = [this.lfs.color, this.lfs.hoverColor, this.lfs.color, this.lfs.color, this.lfs.hoverColor, this.lfs.color];
		const styleElement = iframe.document.getElementById('lookAndFeelStyle') as HTMLStyleElement;

		this.updateStyles(styleElement, styleColors);
	}


	validateEmail(email: string) {
		return dic.CONSTANTS.EMAIL_REGEX.test(email);
	}

	addHrefBlankTarget() {
		try {
			const emailContentFrame = (document?.getElementById('email-content-frame') as HTMLIFrameElement);
			if (!emailContentFrame || !emailContentFrame.contentDocument) {
				return;
			}
			const linksList = emailContentFrame.contentDocument.getElementsByTagName('a');
			if (!linksList || !linksList.length) {
				return;
			}
			for (let idx = 0; idx < linksList.length; idx++) {
				linksList[idx].setAttribute('target', '_blank');
				linksList[idx].setAttribute('rel', 'noopener noreferrer nofollow');
			}
		} catch (e) {
			return;
		}
	}

	stripCSSStyles(html) {
		if (!html) { return {html: '', css: []}; }

		const styles = [];
		let htmlNoCSS = html.replace(/<script[^<>]*?>[\s\S]+?<\/script>/gi, '');
		htmlNoCSS = htmlNoCSS.replace(/<style[^<>]*?>[\s\S]+?<\/style>/gi, (match) => {
			styles.push(match);
			return '';
		});

		return {html: htmlNoCSS, css: styles};
	}

	formatPhone(pinCodeData) {
		pinCodeData.phone.phone_number_after = pinCodeData.phone.phone_number.replace(/-/g, '');

		let indexNotZero = 0;
		for (; indexNotZero < pinCodeData.phone.phone_number_after.length && pinCodeData.phone.phone_number_after[indexNotZero] === '0'; indexNotZero++) {  }
		pinCodeData.phone.phone_number_after = pinCodeData.phone.phone_number_after.substr(indexNotZero);

		switch (pinCodeData.phone.country_code) {
			case '1':
				pinCodeData.phone_number_after = '(' + pinCodeData.phone.phone_number_after.substr(0, 3) + ') ' + pinCodeData.phone.phone_number_after.substr(3, 3) + '-' + pinCodeData.phone.phone_number_after.substr(6, 4);
				break;
			case '972':
				pinCodeData.phone_number_after = '(' + pinCodeData.phone.phone_number_after.substr(0, 3) + ') ' + pinCodeData.phone.phone_number_after.substr(3, 2) + '-' + pinCodeData.phone.phone_number_after.substr(5, 4);
				break;

			default:
				pinCodeData.phone_number_after = pinCodeData.phone.phone_number_after;
		}
	}

	flatAdvanced(defaultAdvanced) {
		const flatAdvancedObj = {
			general: {},
			email_me: {},
			secure: {},
			postmark: {}
		};

		if (defaultAdvanced.general) {
			Object.keys(defaultAdvanced.general).forEach((key) => {
				flatAdvancedObj.general[key] = defaultAdvanced.general[key].value;
			});
		}

		if (defaultAdvanced.email_me) {
			Object.keys(defaultAdvanced.email_me).forEach((key) => {
				flatAdvancedObj.email_me[key] = defaultAdvanced.email_me[key].value;
			});
		}

		if (defaultAdvanced.secure) {
			Object.keys(defaultAdvanced.secure).forEach((key) => {
				flatAdvancedObj.secure[key] = defaultAdvanced.secure[key].value;
			});
		}

		if (defaultAdvanced.postmark) {
			Object.keys(defaultAdvanced.postmark).forEach((key) => {
				flatAdvancedObj.postmark[key] = defaultAdvanced.postmark[key].value;
			});
		}

		return flatAdvancedObj;
	}

	deflateAdvanced(advanced) {
		const deflateAdvancedObj: any = {
			general: {},
			email_me: {},
			secure: {},
			postmark: {}
		};

		if (advanced.general) {
			Object.keys(advanced.general).forEach((key) => {
				deflateAdvancedObj.general[key] = {value: advanced.general[key]};
			});
		}

		if (advanced.email_me) {
			Object.keys(advanced.email_me).forEach((key) => {
				deflateAdvancedObj.email_me[key] = {value: advanced.email_me[key]};
			});
		}

		if (advanced.secure) {
			if (!advanced.secure.hasOwnProperty('delete_attachment_enable')) {
				advanced.secure.delete_attachment_enable = false;
				advanced.secure.delete_attachment_days = 30;
			}
			Object.keys(advanced.secure).forEach((key) => {
				deflateAdvancedObj.secure[key] = {value: advanced.secure[key]};
			});

			if (_.isUndefined(deflateAdvancedObj.secure.secure_received)) {
				deflateAdvancedObj.secure.secure_received = {value: false};
			}
		}

		if (advanced.postmark) {
			Object.keys(advanced.postmark).forEach((key) => {
				deflateAdvancedObj.postmark[key] = {value: advanced.postmark[key]};
			});
		}

		return deflateAdvancedObj;
	}

	getCountryCodeFlag(countryCode) {
		let flagIcon;
		if (!countryCode) {
			flagIcon = 'us.png';
			return flagIcon;
		}

		switch (countryCode) {
			case '1':
				flagIcon = 'us.png';
				break;

			case '31':
				flagIcon = 'nl.png';
				break;

			case '41':
				flagIcon = 'ch.png';
				break;

			case '91':
				flagIcon = 'in.png';
				break;

			case '34':
				flagIcon = 'es.png';
				break;

			case '44':
				flagIcon = 'uk.png';
				break;

			case '47':
				flagIcon = 'no.png';
				break;

			case '55':
				flagIcon = 'br.png';
				break;

			case '61':
				flagIcon = 'au.png';
				break;

			case '852':
				flagIcon = 'hk.png';
				break;

			case '972':
				flagIcon = 'il.png';
				break;

			case '64':
				flagIcon = 'nz.png';
				break;

			case '27':
				flagIcon = 'za.png';
				break;

			case '30':
				flagIcon = 'gr.png';
				break;

			case '230':
				flagIcon = 'ml.png';
				break;

			case '507':
				flagIcon = 'pa.png';
				break;
		}
		return flagIcon;
	}

	removeTooltips() {
		// delete tooltip manually from the DOM
		setTimeout(() => {
			const tooltipLeftovers = document.getElementsByTagName('tooltip');
			for (let i = 0; i < tooltipLeftovers.length; i++) {
				document.body.removeChild(tooltipLeftovers[i]);
			}
		}, 300);
	}

	detectMobile() {
		if (navigator.userAgent.match(/webOS/i)
			|| navigator.userAgent.match(/iPhone/i)
			|| navigator.userAgent.match(/iPad/i)
			|| navigator.userAgent.match(/iPod/i)
			|| navigator.userAgent.match(/BlackBerry/i)
			|| navigator.userAgent.match(/Windows Phone/i)
			|| navigator.userAgent.match(/Android/i) // no for Android
		) {
			return true;
		} else {
			return false;
		}
	}

	isChrome() {
		const isChromium = window['chrome'],
			winNav = window.navigator,
			vendorName = winNav.vendor,
			isOpera = winNav.userAgent.indexOf('OPR') > -1,
			isIEedge = winNav.userAgent.indexOf('Edge') > -1,
			isIOSChrome = winNav.userAgent.match('CriOS');

		if (isIOSChrome) {
			return true;
		} else { return isChromium !== null &&
			typeof isChromium !== 'undefined' &&
			vendorName === 'Google Inc.' &&
			isOpera === false &&
			isIEedge === false;
		}
	}

	isSupportedMimeType(mimeType) {
		return SUPPORTED_MIMES.indexOf(mimeType) > -1;
	}

	formatBytes(bytes, decimals) {
		if (bytes === 0) { return '0 Bytes'; }
		const k = 1024,
			dm = decimals || 2,
			sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
			i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	bufferToArrayBuffer(buf) {
		const arrayBuffer = new ArrayBuffer(buf.length);
		const view = new Uint8Array(arrayBuffer);
		for (let i = 0; i < buf.length; ++i) {
			view[i] = buf[i];
		}
		return arrayBuffer;
	}

	filterDuplicateSafeLinks(urlList) {
		const linksMap = {};
		urlList = _.filter(urlList, link => {
			if (link.name) {
				if (linksMap[link.name]) {
					if (link.status === dic.CONSTANTS.threatProtection.status.safe) {
						return false;
					}
				} else {
					if (link.status !== dic.CONSTANTS.threatProtection.status.safe) {
						linksMap[link.name] = link.url;
					} else {
						if (link.name === link.url &&
							_.find(urlList, l => l.status === dic.CONSTANTS.threatProtection.status.safe && l.name === link.name && l.url !== link.url)) {
							return false;
						}
					}
				}
			}
			return true;
		});
		return urlList;
	}

	toggleDarkMode(value: boolean) {
		this.isDarkMode.next(value);
		localStorage.setItem('isDarkMode', String(value));
		if (value) {
			document.body.setAttribute('data-theme', 'dark');
		} else {
			document.body.removeAttribute('data-theme');
		}

		setTimeout(() => {
			// reduce saturation for main color since saturation change in DM
			this.lfs.applyColor(this.lfs.color);
		});
	}

	getInitialTheme() {
		const storedTheme = localStorage.getItem('isDarkMode');
		if (storedTheme) {
			this.toggleDarkMode(storedTheme === 'true');
		}
	}

	exportCsv(csvString, fileName) {
		if (!csvString) {
			return;
		}

		try {
			const blob = new Blob([csvString], {type: 'text/csv'});
			if (window.navigator['msSaveOrOpenBlob']) {
				window.navigator['msSaveBlob'](blob, fileName || 'data.csv');
			}
			else {
				const a = $('<a/>', {
					style: 'display:none',
					href: 'data:application/octet-stream;base64,' + btoa(unescape(encodeURIComponent(csvString))),
					download: fileName || 'data.csv',
				}).appendTo('body');
				a[0].click();
				a.remove();
			}
		} catch (ex) {
			console.log(ex);
		}
	}
}
