import {Component, ViewEncapsulation} from '@angular/core';
import {GeneralService} from '../../services/general.service';
import {TranslationService} from '../../services/translation.service';
import {dic} from '../../dictionary';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
	logoData: any;
	dic = dic;
	showLanguageMenu = false;
	_ = _;
	isMobile = this.gs.detectMobile() && ((window.innerWidth > 0 ? window.innerWidth < 480 : screen.width < 480) || (window.innerHeight > 0 ? window.innerHeight < 480 : screen.height < 480));
	isDarkMode: boolean;
	private darkModeSubscription: Subscription;


	constructor(private gs: GeneralService, public translateService: TranslationService) {
		this.logoData = gs.logoData;
		this.darkModeSubscription = this.gs.isDarkMode$.subscribe(value => {
			this.isDarkMode = value;
		});
	}

	darkModeToggle() {
		this.gs.toggleDarkMode(!this.isDarkMode);
	}


	changeLanguage(langObj) {
		if (this.translateService.selectedAppLang !== langObj) {
			this.translateService.changeLanguageApp(langObj);
		}
		this.showLanguageMenu = false;
	}
}
