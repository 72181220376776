import {Component, Input, OnInit} from '@angular/core';
import {dic} from '../../../dictionary';
import {RouteService} from '../../../services/route.service';
import {GeneralService} from '../../../services/general.service';
import {NotificationService} from '../../../services/notification.service';
import * as _ from 'lodash';
import {LookAndFeelService} from '../../../services/lookAndFeelService';

@Component({
  selector: 'app-advanced',
  templateUrl: './advanced.component.html',
  styleUrls: ['./advanced.component.scss']
})
export class AdvancedComponent implements OnInit {

	dic = dic;
	_ = _;
	showPassword = false;

	@Input() methods: any;
	@Input() advanced: any;
	@Input() userPolicy: any;
	@Input() attachmentsExist: boolean;

	allowedAuthMethods;

	constructor(public gs: GeneralService,
				private rs: RouteService,
				public lfs: LookAndFeelService,
				private ns: NotificationService) {
	}

	ngOnInit() {
		this.allowedAuthMethods = Object.values(dic.CONSTANTS.authenticationMethod);
		if (this.userPolicy.allowed_auth_methods?.length) {
			this.allowedAuthMethods = this.userPolicy.allowed_auth_methods;

			if (!this.userPolicy.allowed_auth_methods.includes(this.advanced.secure.method_2factor.value)) {
				this.advanced.secure.method_2factor = {value: this.allowedAuthMethods[0], strict: false};
			}
		}
	}

	changeAdvanced() {
		if (this.advanced.email_me.on_link_clicked.value) {
			this.advanced.general.track_links.value = true;
		}
		const data = this.advanced;
		const flat = this.gs.flatAdvanced(data);

		this.rs.updateAdvanced(flat).then(res => {
			this.ns.showInfoMessage(dic.MESSAGES.updateAdvancedSuccess);
		}, err => {
			console.log('err ?', err);
			if (err.data || err.error) {
				this.ns.showErrorMessage(err.data.message || err.error.message);
			}
		});
	}

	updateAuthMethod(selectedMethod: string, event?: Event) {
		if (this.allowedAuthMethods && !this.allowedAuthMethods.includes(selectedMethod)) {
			event?.stopPropagation();
			return;
		}

		this.advanced.secure.method_2factor.value = selectedMethod;
		this.changeAdvanced();
	}

	setAdvancedValue(modelRef: string, value: any) {
		_.set(this.advanced, modelRef, value);
		this.changeAdvanced();
	}

	isRequirePassword() {
		return (
			this.advanced?.secure?.method_2factor &&
			this.advanced.secure.method_2factor.value === dic.CONSTANTS.authenticationMethod.password &&
			this.methods?.secure_send.value
		);
	}
}
