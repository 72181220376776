
import {InjectionToken} from '@angular/core';

let baseUrl, attachmentUrl, waUrl, appId, connection;

// const config:string = 'staging';
const config = location.origin.indexOf('localhost') >= 0 ? 'development' :
	location.origin.indexOf('stage1') >= 0 ? 'staging1' :
		location.origin.indexOf('stage') >= 0 ? 'staging' :
			'production';

switch (config) {
	case 'development':
		baseUrl = 'http://localhost:8080/api/o/v1';
		waUrl = 'http://localhost:5000/';
		attachmentUrl = 'http://localhost:8080/api/o/v1';
		appId = 'V5Dk3W3f8S26jxCNkCrkIaHbEtbA12Xq';
		connection = 'Username-Password-Authentication';
		break;

	case 'staging1':
		baseUrl = 'https://stage1trustifibackend.herokuapp.com/api/o/v1';
		waUrl = 'https://stage1trustifiwebapp.herokuapp.com/';
		attachmentUrl = 'https://stage1trustifibackend.herokuapp.com/api/o/v1';
		appId = 'V5Dk3W3f8S26jxCNkCrkIaHbEtbA12Xq';
		connection = 'Username-Password-Authentication';
		break;

	case 'staging':
		baseUrl = 'https://stagebe.trustifi.com/api/o/v1';
		waUrl = 'https://stageapp.trustifi.com/';
		attachmentUrl = 'https://stagetrustifiattachmentbackend.herokuapp.com/api/o/v1';
		appId = 'V5Dk3W3f8S26jxCNkCrkIaHbEtbA12Xq';
		connection = 'Username-Password-Authentication';
		break;

	default:
		baseUrl = 'https://be.trustifi.com/api/o/v1';
		waUrl = 'https://app.trustifi.com/';
		attachmentUrl = 'https://attachments.trustifi.com/api/o/v1';
		appId = 'wo3WLDFKhoRVAd8aQKswNXGjeeyr6CYK';
		connection = 'prod-users';
}

console.log('Back-End URI: ', baseUrl);
export const BE_URL = baseUrl;
export const WA_URL = waUrl;
export const ATTACHMENT_URL = attachmentUrl;
export const EMAIL_ACCESS_URL = 'emailAccess';
export const EMAIL_URL = 'email';
export const QUARANTINED_URL = 'quarantined';

export const ACCOUNT_TAKEOVER_PROTECTION_URL = 'acp';
export const OUTBOUND_QUARANTINED_URL = 'outbound';
export const ARCHIVE_URL = 'archive';
export const SENDER_URL = 'sender';
export const REVIEW_URL = 'review';
export const REPLY_URL = 'reply';


export const SUPPORTED_MIMES = [
	'audio/x-aiff',
	'audio/basic',
	'text/plain',
	'image/gif',
	'text/html',
	'image/jpeg',
	'video/mpeg',
	'image/png',
	'image/tiff',
	'image/bmp',
	'application/pdf',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // docx
];


export const AUTH0_CONSTS = {
	domain: 'trustifi.auth0.com',
	clientId: appId,
	connection: connection,
	auth0RedirectUrl: 'https://trustifi.auth0.com/login/callback'
};

export const Restangular_Attachments = new InjectionToken<any>('RestangularAttachments');
