import {Component, Input, OnInit} from '@angular/core';
import {GeneralService} from '../../services/general.service';

@Component({
  selector: 'app-loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss']
})
export class LoadingAnimationComponent implements OnInit {

	@Input() hideCondition: boolean;
	logoData: any;

	constructor(private gs: GeneralService) {
		this.logoData = gs.logoData;
	}

	ngOnInit(): void {
	}

}
