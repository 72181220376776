import { Injectable } from '@angular/core';
import {RouteService} from "./route.service";
import {TranslationService} from "./translation.service";
import {AUTH0_CONSTS} from "../constants";
import * as auth0 from 'auth0-js';
import {BE_URL} from '../constants';
import {dic} from '../dictionary';
import util from 'util';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	dic = dic;
	Auth0Client = new auth0.WebAuth({
		domain: AUTH0_CONSTS.domain,
		clientID: AUTH0_CONSTS.clientId,
		redirectUri: AUTH0_CONSTS.auth0RedirectUrl,
		responseType: 'token',
		leeway : 60
	});

	constructor(private rs: RouteService, private translationService: TranslationService) { }

	socialLogin(username, provider, cb) {
		this.Auth0Client.popup.authorize({
			owp: true,
			connection: provider,
			login_hint: username,
			prompt: provider.includes('yahoo') ? 'login' : 'select_account',
		}, (err, res) => {
			if (err) {
				console.error(err);
				return cb();
			}

			this.Auth0Client.validateAuthenticationResponse({},{
				id_token: res.id_token,
				access_token: res.access_token,
				state: res.state,
				expires_in: res.expiresIn
			}, (err, authResult) => {
				if (err) {
					console.error(err);
					return cb();
				}

				if (authResult.idTokenPayload) {
					authResult.email = authResult.idTokenPayload.email;
				}

				this.parseAuthenticationResponse(authResult);
				cb(authResult.accessToken, provider);
			})
		});
	}

	IDPLogin(username, callback) {
		const url = `${BE_URL}/callback/idp/${encodeURIComponent(window.location.origin)}/${username}`;

		this.authenticateWithPopup(url, (err, tokens) => {
			//the result can be returned twice because of the behavior of "window.open"
			if (err) {
				if (err !== true) {
					console.error(err);
				}
				return callback();
			}

			try {
				const access_token = (tokens.split('&').find(itm => itm.includes('access_token')) || '').split('=')[1];
				const saml_token = (tokens.split('&').find(itm => itm.includes('saml_token')) || '').split('=')[1];

				if (access_token) {
					this.rs.exchangeTokenOpenID({token: access_token}).then(res => {
						const authResult = {
							accessToken: res.oToken,
							connection: 'openid',
							expiresIn: 1800 * 1000 + Date.now()
						};

						this.parseAuthenticationResponse(authResult);
						callback(authResult.accessToken, authResult.connection);
					}).catch(err => {
						console.error(err.message);
						callback();
					});
				}
				else if (saml_token) {
					const authResult = {
						accessToken: saml_token,
						connection: 'saml',
						expiresIn: 1800 * 1000 + Date.now()
					};

					this.parseAuthenticationResponse(authResult);
					callback(authResult.accessToken, authResult.connection);
				}
			}
			catch(ex) {
				console.error(ex.message);
				callback();
			}
		});
	}

	authenticateWithPopup(url, callback) {
		let authwnd = window.open(url, '_blank', 'location=no,width=750,height=600,scrollbars=no,resizable=no');

		try {
			authwnd.focus();
			authwnd.onunload = () => callback(true);
		}
		catch (e) {
			console.error(e);
			return callback(this.translationService.getTranslationText(this.dic.ERRORS.popupblocker));
		}

		window['trustifi_authwnd_handler'] = (event) => {
			let eqSign = event.data.indexOf('=');
			let apiparam = eqSign < 0 ? event.data : event.data.substring(0, eqSign);
			let apivalue = eqSign < 0 ? '' : event.data.substring(eqSign+1);

			try {authwnd.close()} catch(e) {}

			if (apiparam.indexOf('error') >= 0 || apivalue && apivalue.indexOf('error_description=') >= 0) {
				if (apivalue) {
					apivalue = decodeURIComponent(apivalue.replace('}}', ''));
					if(apivalue === 'cancel' || apivalue.indexOf('AADSTS65004') >= 0) {
						apivalue = true;
					}
					else if (apivalue.indexOf('ServerError') === 0) {
						apivalue = util.format(this.translationService.getTranslationText(this.dic.ERRORS.adminConnectError));
					}
					else if (apivalue.match(/^AADSTS\d+:/)) {
						apivalue = util.format(this.translationService.getTranslationText(this.dic.ERRORS.ssoError));
					}
					else {
						if(apivalue.indexOf('error_description=') >= 0) {
							apivalue = apivalue.substring(apivalue.indexOf('error_description=') + 18).replace(/\+/g, ' ');
						}
						apivalue = 'Sign in failed due to: ' + apivalue;
					}
				}
				else {
					console.error(apiparam);
				}

				return callback(apivalue || this.translationService.getTranslationText(this.dic.ERRORS.authSignInError));
			}

			callback(null, event.data);
		};
	}

	private parseAuthenticationResponse = (authResult) => {
		const connection = authResult.connection || authResult.idTokenPayload && authResult.idTokenPayload.identities && authResult.idTokenPayload.identities[0].connection;

		if (authResult.accessToken && connection) {
			// this.rs.setDefaultHeaders({"x-access-token": authResult.accessToken, "x-trustifi-source": 'miniapp'});
			localStorage.access_token = authResult.accessToken;
			localStorage.expires_at = authResult.expiresIn * 1000 + Date.now();
		}
	}
}
