<div class="flxClmn bs-enabled f-1-0 vSpace2 t-bg-primary t-color-primary overflow-auto" style="padding: 1rem; font-size: 13px;"
	 *ngIf="emailInfo">
	<span style="font-size: 15px;">{{'mailbox.tracking.emailInformation' | translate }}: <b>{{emailInfo.sent.title}}</b></span>

	<!--Tabs-->
	<div class="md-tabs" style="--md-tabs-width : 100px;">
		<ng-container *ngFor="let tab of dic.CONSTANTS.trackingPageTabs | keyvalue: gs.returnZero">
			<div *ngIf="tab.value !== dic.CONSTANTS.trackingPageTabs.postmark || emailInfo.methods?.postmark"
				 [ngClass]="{'selected' : currentTab === tab.value}"
				 (click)="currentTab = tab.value">
				<input type="radio" name="tabs" id="{{tab.key}}Tab" [checked]="currentTab === tab.value">
				<label for="{{tab.key}}Tab">{{'mailbox.general.tabs.' + tab.value | translate}}</label>
			</div>
		</ng-container>

		<div class="slide" [ngStyle]="{'left' : 100 * _.values(dic.CONSTANTS.trackingPageTabs).indexOf(currentTab) + 'px' || 0}"></div>
	</div>
	<!---->

	<div class="flex-column f-1-0 p-2 m-0 overflow-auto">
		<ng-container [ngSwitch]="currentTab">

			<div class="flex-column f-1-0 gap-4" *ngSwitchCase="dic.CONSTANTS.trackingPageTabs.tracking">
				<div class="flex-row gap-2 align-items-center mb-3 t-color-primary">
					<span>{{'mailbox.tracking.viewTrackingFor' | translate }}:</span>
					<app-dropdown style="width: 250px;"
								  [text]="recipientTrack.name || 'Select'"
								  [enableSearch]="true"
								  [isDisabled]="getTrackingDataInProcess"
								  itemDisplayKey="name"
								  [items]="trackingData"
								  (onItemClicked)="recipientTrack = $event.item"></app-dropdown>
					<i class="fa fa-sync-alt cursor-pointer hvr-opacity ms-2" style="font-size: 15px;"
					   *ngIf="!getTrackingDataInProcess"
					   (click)="getEmailTrackingData();"
					   tooltip="Refresh data"></i>
				</div>
				<div class="flex-column centralize" *ngIf="getTrackingDataInProcess">
					<loader height="100" width="100" style="opacity: 0.8;"></loader>
				</div>
				<app-tracking
					*ngIf="!getTrackingDataInProcess"
					[recipientTrack]="recipientTrack"
					[emailInfo]="emailInfo">
				</app-tracking>
			</div>

			<app-advanced *ngIf="currentTab === dic.CONSTANTS.trackingPageTabs.advanced && emailInfo && userPolicy && advanced" class="bs-enabled"
						  [userPolicy]="userPolicy"
						  [advanced]="advanced"
						  [attachmentsExist]="emailInfo.sent.attachments && emailInfo.sent.attachments.length"
						  [methods]="emailInfo.methods">
			</app-advanced>

			<app-postmark *ngSwitchCase="dic.CONSTANTS.trackingPageTabs.postmark">
			</app-postmark>

		</ng-container>
	</div>
</div>

