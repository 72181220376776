import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {dic} from '../../dictionary';
import {DomSanitizer} from '@angular/platform-browser';
import * as XLSX from 'xlsx';
import * as $ from 'jquery';
import _ from 'lodash';
import {NotificationService} from '../../services/notification.service';
import {GeneralService} from '../../services/general.service';
import PostalMime from 'postal-mime';

@Component({
	selector: 'attachment-preview',
	templateUrl: './attachment-preview.component.html',
	styleUrls: ['./attachment-preview.component.scss']
})
export class AttachmentPreviewComponent implements OnInit {
	@Input() attachment;
	@Input() url;
	@Output() onClose = new EventEmitter<any>;

	constructor(private ns: NotificationService,
				public gs: GeneralService,
				private sanitizer: DomSanitizer) {
	}
	dic = dic;
	popupData:any;
	fileExt;
	isFullScreen = false;
	isMobile;

	ngOnInit(): void {
		this.isMobile = this.gs.detectMobile() && ((window.innerWidth > 0 ? window.innerWidth < 480 : screen.width < 480) || (window.innerHeight > 0 ? window.innerHeight < 480 : screen.height < 480));
		this.enableGlobalScroll(false);
		this.fileExt = this.attachment.name?.substring(this.attachment.name?.lastIndexOf('.')+1).toLowerCase() || '';

		if (!this.dic.CONSTANTS.SUPPORTED_FILES.includes(this.fileExt)) {
			this.ns.showWarnMessage(this.dic.ERRORS.attachmentPreviewNotAvail);
			this.onClose.emit();
			return;
		}

		this.popupData = {
			name: this.attachment?.name || 'Unnamed File',
			loading: true,
			error: '',
		};

		const uintArray = typeof this.attachment.content === 'string' ? base64ToUint8Array(this.attachment.content) :
			(new Uint8Array(this.attachment.content.data).buffer);
		this.displayPreviewExecute(this.attachment.contentType || this.attachment.type, uintArray);
	}

	displayPreviewExecute = (contentType, arrayBuffer) => {
		switch (this.fileExt) {
			case 'xls':
			case 'xlsx':
			case 'csv':
				this.handleExcelFiles(arrayBuffer);
				break;

			case 'xml':
				let text = String.fromCharCode(...new Uint8Array(arrayBuffer));
				text = text.replace(/.*?(?=<\?xml)/s, '');
				this.popupData.text = text;
				break;

			case 'json':
			case 'txt':
				this.popupData.text = String.fromCharCode(...new Uint8Array(arrayBuffer));
				break;

			case 'pdf':
				this.popupData.pdfSrc = getBlobUrl(arrayBuffer, contentType);
				break;

			case 'png':
			case 'jpg':
			case 'jpeg':
			case 'svg':
			case 'gif':
			case 'webp':
				this.popupData.image = this.sanitizer.bypassSecurityTrustUrl(getBlobUrl(arrayBuffer, contentType));

				break;

			case 'docx':
				this.popupData.docUrl = getBlobUrl(arrayBuffer, contentType);
				break;

			case 'eml':
				this.previewEml(arrayBuffer);
				break;

			case 'html':
				const htmlString = new TextDecoder().decode(arrayBuffer);
				this.popupData.eml = htmlString;
				break;

			default:
				this.popupData.error = `File type not supported`;
				break;
		}

		this.popupData.loading = false;
	}

	handleExcelFiles = (arrayBuffer) => {
		try {
			const data = new Uint8Array(arrayBuffer);
			const workbook = XLSX.read(data, { type: 'array' });
			const firstSheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[firstSheetName];
			const xslRows: any = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
			const maxLength = _.maxBy(xslRows, (row: any[]) => row.length)?.length || 0;

			this.popupData.xslRows = this.padRows(xslRows, maxLength);
		}
		catch (err) {
			this.popupData.error = err;
			console.error(err);
		}
	}

	padRows = (rows, maxLength) =>  {
		return rows.map(row => {
			while (row.length < maxLength) {
				row.push(''); // add empty cells to fill the row
			}
			return row;
		});
	}

	previewEml = async (arrayBuffer) => {
		const emlString = new TextDecoder().decode(arrayBuffer);
		try {
			const content = await parseEmlString(emlString);

			if (content.html || content.textAsHtml) {
				this.popupData.eml = content.html || content.textAsHtml
			}
			else {
				this.popupData.text = content.text;
			}
		} catch (error) {
			this.popupData.error = 'Failed to parse EML file';
		}
	}

	// for mobile view
	enableGlobalScroll(enable) {
		if (!this.isMobile) {
			return;
		}
		if (enable) {
			$('body').css({'overflow': 'auto'});
		} else {
			$('body').css({'overflow': 'hidden'});
		}
	}

	closePreview = () => {
		this.onClose.emit();
		this.enableGlobalScroll(true);
	}
}

function getBlobUrl(arrayBuffer, attachmentType) {
	const blob = new Blob([arrayBuffer], { type: attachmentType });
	return URL.createObjectURL(blob);
}

function decodeQuotedPrintable(encodedString) {
	let decodedString = encodedString.replace(/=3D/g, '=');
	decodedString = decodedString.replace(/=\r\n/g, '');
	decodedString = decodedString.replace(/=\n/g, '');
	decodedString = decodedString.replace(/&lt;/g, '<');
	decodedString = decodedString.replace(/&gt;/g, '>');
	decodedString = decodedString.replace(/&amp;/g, '&');
	return decodedString;
}

async function parseEmlString(emlString: string): Promise<any> {
	const parser = new PostalMime();
	const email = await parser.parse(emlString);
	return email;
}

function base64ToUint8Array(base64: string) {
	// Decode the base64 string to a binary string
	const binaryString = atob(base64);

	// Create a Uint8Array from the binary string
	const len = binaryString.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}

	return bytes;
}
