import {
	Component,
	ContentChild,
	DoCheck,
	EventEmitter,
	Input,
	IterableDiffers,
	Output,
	TemplateRef
} from '@angular/core';
import util from 'util';
import {dic} from '../../../dictionary';
import _ from 'lodash';
import {GeneralService} from "../../../services/general.service";

@Component({
	selector: 'trustifi-table',
	templateUrl: './trustifi-table.component.html',
})
export class TrustifiTableComponent implements DoCheck {

	constructor(public gs: GeneralService, private iterableDiffers: IterableDiffers) {
		this.iterableDiffer = iterableDiffers.find([]).create(null);
	}

	@Input('list') dataListInput: Array<object> = [];
	@Input() headers: headers;
	@Input() cellsPrototype: cellsPrototype;
	@Input() cellActions?: cellActions;
	@Input() bulkActions?: bulkActions;
	@Output() onAnyItemSelected? : EventEmitter<{item:object, isItemSelectCheckboxClicked: boolean}> = new EventEmitter();
	@Output('searchFunction') searchItemExecute?: EventEmitter<{searchTerm: string, activeFilters?: object}> = new EventEmitter();
	@Input() filterData?: filterData;
	@Output('confirmEditFunction') confirmEdit?: EventEmitter<{item: object, isApproved: boolean}> = new EventEmitter();
	@Input() subRowOptions?: subRowOptions;
	@Input() scopeInstances?: object[];
	@Input() options?: options;
	@Output() onDragAndDrop?: EventEmitter<{draggedItem: object, draggedOntopItem: object, isDroppedOnNextItem?: boolean}> = new EventEmitter();
	@ContentChild('customCells', {static: false}) customCellsTemplate: TemplateRef<any>;
	@ContentChild('subRow', {static: false}) subRowTemplate: TemplateRef<any>;

	dataList = [];
	dataListOriginal = [];
	iterableDiffer;

	dic = dic;
	isEllipsis;
	isAlternated;
	hideRecordsOnReload;
	orderTableBy;
	activeFilters;
	counter;
	selectedItems = [];
	selectedAllItems = false;
	showBulkActions = false;
	bulkActionsArr = [];
	search = {text: ''} // cannot use ng-model with premitive in isolated scope
	_ = _;
	util = util;
	scrollToTopFlag = false;
	showTableMenu = {show: false};
	showChangeTableViewMenu;
	importFromCsvFileModel;
	initiated = false;
	draggedItem; // for drag and drop
	isDraggedToLast; // for drag and drop
	columnsCount;
	autoRefresh = false;
	activeAutoRefreshLoop;

	initialValuesAssignment() {
		// default options
		if (!this.options) {
			this.options = {};
		}
		this.isEllipsis = this.options.isEllipsis !== undefined ? this.options.isEllipsis : true;
		this.isAlternated = this.options.isAlternated !== undefined ? this.options.isAlternated : true;
		this.hideRecordsOnReload = this.options.hideRecordsOnReload !== undefined ? this.options.hideRecordsOnReload : true;

		this.columnsCount = this.cellsPrototype.length + (this.cellActions?.enable ? 1 : 0) + (this.bulkActions?.enable || this.options.showRadioButtons ? 1 : 0);

		this.filterSelectedItems();
		this.orderTableBy = this.options.defaultSortKey || null;
		if (this.options.defaultSortKey) {
			this.updateSort(this.orderTableBy);
			/*const defaultSortHeader = _.find(this.headers, (header:any) => header.sortKey === this.orderTableBy || header.sortKey === '-' + this.orderTableBy);
			if (defaultSortHeader) {
				defaultSortHeader.sortDescending = this.orderTableBy.startsWith('-');
			}*/
		}
		this.initiated = true;
	}

	ngDoCheck() {
		const changes = this.iterableDiffer.diff(this.dataListInput);
		if (changes && changes.collection) {
			setTimeout(() => { // NG0100
				// trigger scrollToTop when new item is added
				if (changes.collection[0]?.isNew && !this.dataList[0]?.isNew) {
					setTimeout(() => {
						this.scrollToTopFlag = true;
					}, 50);
				}
				//

				this.dataListOriginal = changes.collection;
				this.dataList = this.getFlatList(this.dataListOriginal);
				this.countVisibleRecords();

				if (!this.initiated) {
					this.initialValuesAssignment();
				}

				if (this.searchItemExecute) {

					this.searchItem(); // a way to refresh all states: counter, search/filter, selected, selectedAll etc...

				}
				else {
					this.filterSelectedItems();
				}
			});
		}
	}

	headerSortClicked(header) {
		if (!header.sortKey) {
			return;
		}
		header.sortDescending = !header.sortDescending;
		this.orderTableBy = header.sortDescending ? '-' + header.sortKey : header.sortKey;
		this.updateSort(this.orderTableBy);
	}

	updateSort(sortKey) {
		const isDescending = sortKey.startsWith('-');
		let sortKeyStripped = sortKey;
		if (isDescending) {
			sortKeyStripped = sortKey.substring(1);
		}

		const sortOrder = isDescending ? 'desc' : 'asc';

		let sortedList = _.orderBy(this.dataList, ['isAlwaysOnTop', 'isNew', record => this.getSortedValue(record, sortKeyStripped)], ['asc', 'asc', sortOrder]);

		// in case of sub records
		// replace child records aside their parent record
		for (let i = 1; i < 15; i++) {
			const recordsOfDepth = _.remove(sortedList, record => record.depthLevel === i);
			if (!recordsOfDepth.length) {
				break;
			}
			recordsOfDepth.forEach(record => {
				const lastSiblingRecordIndex = _.findLastIndex(sortedList,r => r.recordId === record.recordId);
				if (lastSiblingRecordIndex > -1) {
					sortedList.splice(lastSiblingRecordIndex + 1, 0, record);
				}
				else {
					const parentIndex = _.findIndex(sortedList,r => record.recordId === r.recordId + record.depthLevel.toString());
					sortedList.splice(parentIndex + 1 , 0, record);
				}
			});
		}

		this.dataList = sortedList;
	}

	// external function to extract sorted value to achieve two things: 1. support sorting by nested sort key. 2. make sort not case-sensitive.
	getSortedValue = (record, sortKeyStripped) => {
		let valueForSort;

		// nested sort key for sorting (eg. sortKey:'aaa.bbb')
		if (sortKeyStripped.split('.').length > 1) {
			valueForSort = sortKeyStripped.split('.').reduce((currentValue, property) => {
				return currentValue && currentValue.hasOwnProperty(property) ? currentValue[property] : undefined;
			}, record);
		}
		// regular string for sort key
		else {
			valueForSort = record[sortKeyStripped];
		}

		if (!_.isNil(valueForSort) && typeof valueForSort === 'string') {
			valueForSort = valueForSort.toLowerCase();
		}

		return !_.isNil(valueForSort) ? valueForSort : undefined;
	}


	selectItem(item, isItemSelectCheckboxClicked=false) {
		if (!this.isItemSelectable(item)) { // do not select items inside sub rows
			item.selected = false;
			if (this.onAnyItemSelected) {
				this.onAnyItemSelected.emit({item: item, isItemSelectCheckboxClicked});
			}
			return;
		}
		//

		if (this.options.soloSelected && item.soloSelected) {
			this.dataListOriginal.forEach( listItem => {
				if (listItem !== item) {
					listItem.soloSelected = false;
				}
			});
		}

		// TODO: if is maybe redundant. filterSelectedItems should always be called?
		if (this.options.showRadioButtons) {
			this.filterSelectedItems();
		}

		if (this.bulkActions && this.bulkActions.enable) {
			this.filterSelectedItems();
			if (this.onAnyItemSelected) {
				this.onAnyItemSelected.emit({item: item, isItemSelectCheckboxClicked});
			}
		}
		else if (this.onAnyItemSelected) {
			this.onAnyItemSelected.emit({item: item, isItemSelectCheckboxClicked});
		}
	}

	actionsDropdownClicked(event, item) {
		item.tTableActions = this.cellActions.initActionsFunction(item);
		// dropdown direction (above/below)
		const dropdownEl = event.currentTarget;
		setTimeout(() => {
			const menuSize = dropdownEl.querySelector('.app-dropdown-menu-container').offsetHeight;
			item.actionsDirection = this.determineDropdownDirection(dropdownEl, menuSize || item.tTableActions.length * 36);
		})
	}

	bulkActionsDropdownClicked() {
		if (!this.showBulkActions) { // means that the dropdown is going to open now. so get the actions before opening
			this.bulkActionsArr = this.bulkActions.initBulkActionsFunction();
			this.showBulkActions = true;
		}
		else {
			this.showBulkActions = false;
		}
	}

	searchItem() {
		if (!this.dataList) {
			return;
		}

		// perform search (function is external)
		this.searchItemExecute.emit({searchTerm: this.search.text.toLowerCase(), activeFilters: this.activeFilters});

		this.dataList = this.getFlatList(this.dataListOriginal);
		this.countVisibleRecords();

		// update table view
		this.filterSelectedItems();
		if (this.orderTableBy) {
			this.updateSort(this.orderTableBy);
		}
	};

	filterSelectedItems() {
		const selectableItems = [];
		const selectedItems = [];

		// we check all not-hidden records and their not-hidden sub-records (even if collapsed)
		const listOfAllNotHidden = this.getFlatList(this.dataListOriginal, 0, null, true);

		listOfAllNotHidden.forEach(item => {
			const isSelectable = this.isItemSelectable(item);

			if (isSelectable) {
				selectableItems.push(item);
				if (item.selected) {
					selectedItems.push(item);
				}
			}
		});

		this.selectedItems = selectedItems;

		this.selectedAllItems = this.dataList.length && selectableItems.length && selectedItems.length === selectableItems.length;
	};

	toggleAllItems() {
		this.selectedAllItems = !this.selectedAllItems;

		// we toggle all not-hidden records and their not-hidden sub-records (even if not expanded)
		const listOfAllNotHidden = this.getFlatList(this.dataListOriginal, 0, null, true);

		listOfAllNotHidden.forEach( item => {
			if (this.isItemSelectable(item)) {
				item.selected = this.selectedAllItems;
			}
		});

		this.filterSelectedItems();
	};

	isItemSelectable = (item) => {
		// first-level items are selectable by default unless flagged. second-level and below items are not selectable by default unless flagged
		return !item.isNew && !item.hide && ((!item.depthLevel && item.isSelectable !== false) || (item.depthLevel && item.isSelectable === true));
	}

	// add all nested items (recursively) to first-level (needed for virtual-scroller!)
	getFlatList = (list, depthLevel=0, firstParentId=null, includeHiddenByCollapseRecords = false) => {
		if (!this.subRowOptions?.recursionKey) {
			return _.reject(list, 'hide');
		}


		// recursion flat list:
		return _.flatMapDeep(list, item => {

			// generate a record id to the first parent of records so that all child and grandchild records won't scatter in updateSort
			if (depthLevel === 0 && item[this.subRowOptions.recursionKey]?.length) {
				item.recordId = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 15);
			}
			// give a child the recordId of it's parent plus a digit so that he will stick right after him in updateSort and won't scatter
			else if (depthLevel > 0) {
				item.recordId = firstParentId + depthLevel.toString();
			}

			item.depthLevel = depthLevel;

			if (!item.hide) {
				if (item.isExpanded || includeHiddenByCollapseRecords) {
					return [item, ...(this.getFlatList(item[this.subRowOptions.recursionKey], depthLevel + 1, item.recordId) || [])];
				}
				else {
					return item;
				}
			}
			else {
				return [];
			}
		});

	};

	countVisibleRecords = () => {
		// count only first level
		this.counter = _.reject(this.dataList, 'depthLevel').length;
	}

	toggleFilter() {
		if (!this.filterData) {
			return;
		}
		this.filterData.show = !this.filterData.show;
	};

	clearFilters() {
		this.activeFilters = null;
		if (this.dataListOriginal?.length) {
			this.searchItem();
		}
	};

	applyFilters(activeFilters) {
		if (!activeFilters) return;

		this.activeFilters = activeFilters;
		this.filterData.show = false;

		if (this.initiated) {
			this.searchItem();
		}
	};

	trackByUniqueIdentifier = (index, item) => {
		return item[this.options.itemUniqueIdentifier];
	}

	importFromCsv = (file) => {
		this.options.importFromCsv.cbFunction(file);
		this.importFromCsvFileModel = []; // empty files so they can be re-selected
	}

	initAutoRefresh = () => {
		this.autoRefresh = !this.autoRefresh;

		if (this.autoRefresh) {
			this.activeAutoRefreshLoop = setInterval(() => {
				this.options.autoRefreshFunction();
			}, 60 * 1000); // 60 seconds
		}
		else {
			clearInterval(this.activeAutoRefreshLoop);
		}
	}

	determineDropdownDirection(triggerButton, dropdownSize) {
		const boundaryElement = triggerButton.closest('.listTable-c');
		if (!boundaryElement) {
			return;
		}

		const boundaryElementPosition = boundaryElement.getBoundingClientRect();
		const triggerButtonPosition = triggerButton.getBoundingClientRect();

		// potential free place above trigger button:
		let distanceFromTopBoundary = triggerButtonPosition.top - (boundaryElementPosition.top + 55); // 55 is the header row which is z-indexed on-top of the potential dropdown menu therefore is not calculated
		if (boundaryElementPosition.top + 55 < 0) { // that means that the top border of the boundary element (+ header row) is out of screen (was scrolled out of view)
			distanceFromTopBoundary = triggerButtonPosition.top;
		}

		// potential free place below trigger button:
		const distanceFromBottomBoundary = boundaryElementPosition.bottom - triggerButtonPosition.bottom ;



		// check if there's enough place for whole of the dropdown menu below or above the trigger button
		if (distanceFromBottomBoundary >= dropdownSize) {
			return 'below';
		}
		else  if (distanceFromTopBoundary >= dropdownSize) {
			return 'above';
		}

		// compare distances but now WITH counting the available scrolling space
		/*const leftSpaceToScrollDown = boundaryElement.scrollHeight - boundaryElement.offsetHeight - boundaryElement.scrollTop;

		if (distanceFromBottomBoundary > distanceFromTopBoundary) { // prioritize the already bigger distance to show more menu items right ahead
			if (distanceFromBottomBoundary + leftSpaceToScrollDown > dropdownSize) {
				return "below";
			}
			else if (distanceFromTopBoundary + boundaryElement.scrollTop > dropdownSize) {
				return "above";
			}
		}
		if (distanceFromTopBoundary > distanceFromBottomBoundary) {
			if (distanceFromTopBoundary + boundaryElement.scrollTop > dropdownSize) {
				return "above";
			}
			else if (distanceFromBottomBoundary + leftSpaceToScrollDown > dropdownSize) {
				return "below";
			}
		}*/

		/*if (distanceFromBottomBoundary > distanceFromTopBoundary) {
			return "below";
		}
		else if (distanceFromTopBoundary > distanceFromBottomBoundary) {
			return "above";
		}*/

		// when there's no room for dropdown menu neither below or above (without stretching the table inner height) - drop the menu below (default)
		return 'below';

	}

	// Drag and drop support:


	// itemThatDroppedOn represents the item that the other dragged item was dropped on. the dragged item is aimed to drop BEFORE this item by the user.
	// if itemThatDroppedOn is null - the user dropped the dragged item in the last of the list
	dropItem(itemThatDroppedOn) {
		if (!this.onDragAndDrop.observed) {
			return;
		}

		if (itemThatDroppedOn) {
			itemThatDroppedOn.isDraggedOver = false;

			if (itemThatDroppedOn === this.draggedItem) {
				this.draggedItem = null;
				return;
			}
			if (this.dataList.indexOf(itemThatDroppedOn) === this.dataList.indexOf(this.draggedItem) + 1) {
				this.onDragAndDrop.emit({draggedItem: this.draggedItem, draggedOntopItem: itemThatDroppedOn, isDroppedOnNextItem: true});
				return;
			}

			this.onDragAndDrop.emit({draggedItem: this.draggedItem, draggedOntopItem: itemThatDroppedOn});
		}
		else {
			this.isDraggedToLast = false;

			if (this.dataList.length === this.dataList.indexOf(this.draggedItem) + 1) {
				this.draggedItem = null;
				return;
			}
			this.onDragAndDrop.emit({draggedItem: this.draggedItem, draggedOntopItem: null});
		}

		this.draggedItem = null;
	}

	dragOverItem(event, itemThatDraggedOver) {
		if (!this.onDragAndDrop.observed) {
			return;
		}

		event.preventDefault();
		if (itemThatDraggedOver) {
			itemThatDraggedOver.isDraggedOver = true;
		}
		else {
			this.isDraggedToLast = true;
		}
	}
}




// typing
type headers = {text: string, hide?: boolean, sortKey?: string, width?: string, tooltip?: string, style?: object}[]
	| {html: string, hide?: boolean, sortKey?: string, width?: string, style?: object}[];
type cellsPrototype = {textKey: string, hide?: boolean, textType?: string, tooltip?: string, toolTipHtml?: boolean, style?: object, onClick?: (item: object) => any, edit?: {modelKey: string, placeholder: string} | {html: string}}[]
	| {html: string, hide?: boolean, onClick?: (item: object) => any, edit?: {modelKey: string, placeholder: string} | {html: string, hide?: boolean,}}[];
type cellActions = {enable: boolean, selectItemActionCb: (item: object, action: string | object) => any, initActionsFunction: (item?: object) => string[] | {display: string, submenu?: object}[] | {html: string, submenu?: object}[], showInRed?: {enable: boolean, terms: string[]}};
type bulkActions = {enable: boolean, selectBulkActionCb: (item: object, action: string | object) => any, initBulkActionsFunction: () => string[] | {display: string, submenu?: object}[] | {html: string, submenu?: object}[], showInRed?: {enable: boolean, terms: string[]}};
type filterData = { filterType : string, filters: object, initFiltersFunction: () => void, init: boolean, show: boolean };
type options = { // ordered by A-Z
	defaultSortKey?: string,
	exportToCsvFunction?: (orderKey?: string) => void,
	hideContentAboveTable?: boolean,
	hideCounter?: boolean,
	hideRecordsOnReload?: boolean,
	importFromCsv?: {cbFunction: (csvFile: any) => any, tooltip?: string},
	isAlternated?: boolean,
	isEllipsis?: boolean,
	itemsNameSingular?: string,
	itemUniqueIdentifier?: string,
	loadingTableFlag?: boolean,
	menu?: {[key: string]: {label: string, iconClass?: string, action: () => any}},
	changeTableViewMenu?: {[key: string]: {label: string, iconClass?: string, action: any}},
	noHeader?: boolean,
	refreshFunction?: () => void,
	autoRefreshFunction?: () => void,
	showRadioButtons?: boolean,
	soloSelected?: boolean,
	customButtons?: [{icon: string, function: () => any, tooltip?: string, disabled?: boolean}],
};
type subRowOptions = {
	recursionKey: string,
	allowActions: boolean,
};
